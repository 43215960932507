const LDD_DATA = [
    {
        "drugName": "6-TG",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "ABECMA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "ABILIFY ASIMTUFI",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ABILIFY MAINTENA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ABILIFY MYCITE",
        "pharmacies": [
            "Orsini"
        ]
    },
    {
        "drugName": "ABIRATERONE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ABRAXANE",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ACTEMRA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ACTEMRA SQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "ACTHAR",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ADAGEN",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "ADAKVEO",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ADBRY",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ADCETRIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "ADCIRCA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ADEMPAS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "ADRIAMYCIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ADUHELM",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "ADVATE",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ADYNOVATE",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "AFINITOR/AFINITOR DISPERZ",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "AFSTYLA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "AGRYLIN",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "AIMOVIG",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "AJOVY",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "AKEEGA",
        "pharmacies": [
            "Onco360"
        ]
    },
    {
        "drugName": "ALDURAZYME",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "ALECENSA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "ALFERON N",
        "pharmacies": [
            "CVS/Caremark",
            "Optum"
        ]
    },
    {
        "drugName": "ALIMTA",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ALIQOPA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "ALKERAN",
        "pharmacies": [
            "Optum",
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "ALKINDI",
        "pharmacies": [
            "AnovoRx"
        ]
    },
    {
        "drugName": "ALPHANATE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ALPHANIN SD",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ALPHANINE SD",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ALPROLIX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Hemophilia of Georgia",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ALTUVIIIO",
        "pharmacies": [
            "CVS/Caremark",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ALUNBRIG",
        "pharmacies": [
            "Onco360",
            "Accredo",
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "ALYQ",
        "pharmacies": [
            "AllianceRx Walgreens",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "AMBRISENTAN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "AMICAR",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "AMONDYS 45",
        "pharmacies": [
            "Buy and Bill",
            "Orsini"
        ]
    },
    {
        "drugName": "AMPYRA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "AMVUTTRA",
        "pharmacies": [
            "CVS/Caremark",
            "PANTHERx",
            "Orsini"
        ]
    },
    {
        "drugName": "ANASTROZOLE",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "APOKYN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "APOMORPHINE",
        "pharmacies": [
            "CVS/Caremark",
            "Optum",
            "AllianceRx Walgreens"
        ]
    },
    {
        "drugName": "APRETUDE",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "APTIVUS",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ARALAST NP",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "ARANESP",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Biologics"
        ]
    },
    {
        "drugName": "ARCALYST",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "ARESTIN",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "ARIKAYCE",
        "pharmacies": [
            "Maxor Pharmacy",
            "Accredo",
            "PANTHERx",
            "Pharmaceuticals Specialty Express",
            "Orsini"
        ]
    },
    {
        "drugName": "ARISTADA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ARIXTRA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "AROMASIN",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "ARRANON",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ARSENIC TRIO",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ARZERRA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ASCENIV",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "ASPARLAS",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ASTAGRAF XL",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ATRIPLA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "AUBAGIO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "AUSTEDO",
        "pharmacies": [
            "Accredo",
            "Orsini",
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "AUVI-Q",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "AVASTIN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "AVEED",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "AVONEX",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "AVONEX PEN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "AVONEX/AVONEX PEN/PREFL",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "AVSOLA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "AYVAKIT",
        "pharmacies": [
            "PANTHERx",
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "AZACITIDINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "AZEDRA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "BAFIERTAM",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "BALVERSA",
        "pharmacies": [
            "US Bioservices",
            "Accredo"
        ]
    },
    {
        "drugName": "BANZEL",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "BARACLUDE",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "BAVENCIO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "BELEODAQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "BELRAPZO",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "BENDAMUSTINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "BENDEKA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "BENEFIX",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "BENLYSTA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "BENLYSTA SC",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "BEOVU",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "BERINERT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "BESPONSA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "BESREMI",
        "pharmacies": [
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "BESREMI SC",
        "pharmacies": [
            "US Bioservices",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "BETAINE ANHYDROUS",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "BETASERON",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "BETHKIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "BEXAROTENE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "BICNU",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "BIKTARVY",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "BIVIGAM",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "BLENREP",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "BLEOMYCIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "BLINCYTO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "BONIVA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "BORTEZOMIB",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "BOSENTAN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "BOSULIF",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "BOTOX",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "BOTOX/BOTOX COSMET",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "BRAFTOVI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "BREYANZI",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "BRIDION",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "BRINEURA",
        "pharmacies": [
            "Buy and Bill",
            "Orsini",
            "Accredo"
        ]
    },
    {
        "drugName": "BRIUMVI",
        "pharmacies": [
            "CVS/Caremark",
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "BRONCHITOL",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "BRUKINSA",
        "pharmacies": [
            "Diplomat Specialty Pharmacy",
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "BUPHENYL",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "BUSULFAN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "BYLVAY",
        "pharmacies": [
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "BYNFEZIA",
        "pharmacies": [
            "AllianceRx Walgreens"
        ]
    },
    {
        "drugName": "BYNFEZIA PEN",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "BYOOVIZ",
        "pharmacies": [
            "CVS/Caremark",
            "Optum"
        ]
    },
    {
        "drugName": "CABENUVA",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "CABLIVI",
        "pharmacies": [
            "Buy and Bill",
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "CABOMETYX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "CALQUENCE",
        "pharmacies": [
            "Diplomat Specialty Pharmacy",
            "Accredo",
            "Optum",
            "Biologics",
            "Avella/Optum",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "CAMCEVI",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "CAMPTOSAR",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "CAMZYOS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "CAPECITABINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CAPRELSA",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "CARBAGLU",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "CARBOPLATIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CARGLUMIC ACID",
        "pharmacies": [
            "Accredo",
            "AnovoRx"
        ]
    },
    {
        "drugName": "CARIMUNE NF",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "CARMUSTINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CARVYKTI",
        "pharmacies": [
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "CASODEX",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "CAYSTON",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "CELLCEPT",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "CEPROTIN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "CERDELGA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "CEREZYME",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "CETRORELIX",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CHEMET",
        "pharmacies": [
            "CVS/Caremark",
            "AllianceRx Walgreens"
        ]
    },
    {
        "drugName": "CHENODAL",
        "pharmacies": [
            "Eversana Life Science Services",
            "Accredo"
        ]
    },
    {
        "drugName": "CHOLBAM",
        "pharmacies": [
            "Eversana Life Science Services",
            "Accredo"
        ]
    },
    {
        "drugName": "CHOR GONADOT",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CIBINQO",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "CIMDUO",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "CIMIZIA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "CIMZIA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "CINQAIR",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "CINRYZE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "CISPLATIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CLADRIBINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CLOFARABINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CLOLAR",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CMV-IGIV",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "COAGADEX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "COMBIVIR",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "COMETRIQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "COMPAZINE",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "COMPLERA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "COPAXONE",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "COPEGUS",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "COPIKTRA",
        "pharmacies": [
            "US Bioservices",
            "Diplomat Specialty Pharmacy",
            "Accredo",
            "Optum",
            "Biologics",
            "Avella/Optum",
            "Onco360"
        ]
    },
    {
        "drugName": "CORIFACT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "CORTROPHIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CORTROPHIN GEL",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "COSELA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "COSENTYX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "COSMEGEN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "COTELLIC",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "CRIXIVAN",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "CRYSVITA",
        "pharmacies": [
            "CVS/Caremark",
            "PANTHERx",
            "Accredo",
            "Orsini"
        ]
    },
    {
        "drugName": "CUPRIMINE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "CUTAQUIG",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Crescent Healthcare",
            "Option Care",
            "Critical Care Systems"
        ]
    },
    {
        "drugName": "CUVITRU",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "CUVRIOR",
        "pharmacies": [
            "PANTHERx"
        ]
    },
    {
        "drugName": "CYCLOPHOSPH",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CYCLOPHOSPHAMIDE",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "CYRAMZA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "CYSTADANE",
        "pharmacies": [
            "Accredo",
            "AnovoRx"
        ]
    },
    {
        "drugName": "CYSTADROPS",
        "pharmacies": [
            "Accredo",
            "AnovoRx"
        ]
    },
    {
        "drugName": "CYSTAGON",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "CYSTARAN",
        "pharmacies": [
            "AllianceRx Walgreens",
            "Accredo"
        ]
    },
    {
        "drugName": "CYTARABINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "CYTOGAM",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "CYTOVENE",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "CYTOXAN",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "DACARBAZINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "DACOGEN",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "DACTINOMYCIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "DAKLINZA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "DALFAMPRIDIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "DALFAMPRIDINE",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "DANYELZA",
        "pharmacies": [
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "DARAPRIM",
        "pharmacies": [
            "AllianceRx Walgreens",
            "Accredo"
        ]
    },
    {
        "drugName": "DARZALEX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "DARZALEX FASPRO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "DAUNORUBICIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "DAURISMO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "DAYBUE",
        "pharmacies": [
            "AnovoRx"
        ]
    },
    {
        "drugName": "DECADRON",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "DECITABINE",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "DEFITELIO",
        "pharmacies": [
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "DEMSER",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "DEPEN",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "DEPEN TITRA",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "DESCOVY",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "DESFERAL",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "DESMOPRESSIN",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "DEXRAZOXANE",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "DIACOMIT",
        "pharmacies": [
            "US Bioservices",
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "DIFICID",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "DIMETHYL FUM",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "DOCETAXEL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "DOJOLVI",
        "pharmacies": [
            "CVS/Caremark",
            "PANTHERx",
            "Accredo",
            "Orsini"
        ]
    },
    {
        "drugName": "DOPTELET",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics"
        ]
    },
    {
        "drugName": "DOVATO",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "DOXIL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "DOXORUBICIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "DROXIDOPA",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "DUOPA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "DUPIXENT",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "DUROLANE",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "DURYSTA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "DYSPORT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "EDURANT",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "EGRIFTA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "EGRIFTA SV",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ELAHERE",
        "pharmacies": [
            "Buy and Bill",
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "ELAPRASE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "ELELYSO",
        "pharmacies": [
            "CVS/Caremark",
            "Orsini"
        ]
    },
    {
        "drugName": "ELEVIDYS",
        "pharmacies": [
            "Orsini",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ELEYSO",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "ELIGARD",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ELITEK",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ELLENCE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ELOCTATE",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ELREXFIO",
        "pharmacies": [
            "Onco360"
        ]
    },
    {
        "drugName": "ELZONRIS",
        "pharmacies": [
            "Buy and Bill",
            "Accredo",
            "Optum",
            "Onco360"
        ]
    },
    {
        "drugName": "EMEND",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "EMFLAZA",
        "pharmacies": [
            "Accredo",
            "Orsini"
        ]
    },
    {
        "drugName": "EMPAVELI",
        "pharmacies": [
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "EMPLICITI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "EMTRIVA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ENBREL",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ENDARI",
        "pharmacies": [
            "US Bioservices",
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ENHERTU",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "ENJAYMO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "ENSPRYNG",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ENTYVIO",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ENVARSUS XR",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "EPCLUSA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "EPIDIOLEX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "EPIPEN",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "EPIVIR",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "EPIVIR HBV",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "EPOGEN",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "EPOPROSTENOL",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "EPZICOM",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ERBITUX",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ERGOMAR",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "ERIVEDGE",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "ERLEADA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "ERLOTINIB",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics"
        ]
    },
    {
        "drugName": "ERWINAZE",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ESBRIET",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ESPEROCT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "ETOPOPHOS",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ETOPOSIDE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "EUFLEXXA",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "EVENITY",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "EVEROLIMUS",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "EVKEEZA",
        "pharmacies": [
            "Buy and Bill",
            "Orsini",
            "Accredo"
        ]
    },
    {
        "drugName": "EVOMELA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "EVOTAZ",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "EVRYSDI",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "EVZIO",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "EXJADE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Biologics"
        ]
    },
    {
        "drugName": "EXKIVITY",
        "pharmacies": [
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "EXONDYS 51",
        "pharmacies": [
            "Buy and Bill",
            "Orsini"
        ]
    },
    {
        "drugName": "EXSERVAN",
        "pharmacies": [
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "EXSERVAN ORAL FILM",
        "pharmacies": [
            "PANTHERx"
        ]
    },
    {
        "drugName": "EXTAVIA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "EYLEA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "FABRAZYME",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "FACTOR VIII",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "FARYDAK",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "FASENRA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "FASLODEX",
        "pharmacies": [
            "Buy and Bill",
            "Optum",
            "Biologics"
        ]
    },
    {
        "drugName": "FEIBA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "FEIBA NF",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "FEIBA/FEIBA NF",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "FEMARA",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "FENSOLVI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "FERRIPROX",
        "pharmacies": [
            "Eversana Life Science Services",
            "Accredo"
        ]
    },
    {
        "drugName": "FIBRYGA",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "FILSPARI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "FINGOLIMOD",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "FINTEPLA",
        "pharmacies": [
            "Accredo",
            "AnovoRx"
        ]
    },
    {
        "drugName": "FIRAZYR",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium",
            "Orsini"
        ]
    },
    {
        "drugName": "FIRDAPSE",
        "pharmacies": [
            "Accredo",
            "AnovoRx"
        ]
    },
    {
        "drugName": "FIRMAGON",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "FLEBOGAMMA",
        "pharmacies": [
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "FLOLAN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "FLOLAN DILUENT",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "FLUDARABINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "FLUOROURACIL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "FOLLISTIM AQ",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "FOLOTYN",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "FORTEO",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "FOTIVDA",
        "pharmacies": [
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "FRAGMIN",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "FULPHILA",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "FULVESTRANT",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "FUROSCIX",
        "pharmacies": [
            "Biomatrix"
        ]
    },
    {
        "drugName": "FUSILEV",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "FYARRO",
        "pharmacies": [
            "PANTHERx",
            "Buy and Bill",
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "FYLNETRA",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "FYREMADEL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "GALAFOLD",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "GAMASTAN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "GAMASTAN S-D",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "GAMASTAN S/D",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "GAMASTAN/GAMASTAN S/D",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "GAMIFANT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "GAMMAGARD",
        "pharmacies": [
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "GAMMAGARD LIQUID",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "GAMMAGARD S-D",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "GAMMAKED",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "GAMMAPLEX",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "GAMUNEX-C",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "GANIRELIX AC",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "GATTEX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "GAVRETO",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "PANTHERx",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "GAZYVA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "GEFITINIB",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "GEL-ONE",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "GELSYN-3",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "GEMCITABINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "GENGRAF",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "GENOTROPIN",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "GENVISC 850",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "GENVOYA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "GIAPREZA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "GILENYA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "GILOTRIF",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "GIMOTI",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "GIVLAARI",
        "pharmacies": [
            "PANTHERx",
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "GLASSIA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "GLATIRAMER",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "GLATOPA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "GLEEVEC",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "GLEOSTINE",
        "pharmacies": [
            "Optum",
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "GOCOVRI",
        "pharmacies": [
            "AllianceRx Walgreens",
            "Accredo"
        ]
    },
    {
        "drugName": "GONAL-F",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "GRANIX",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "H.P. ACTHAR GEL",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "HAEGARDA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Orsini",
            "Optum"
        ]
    },
    {
        "drugName": "HALAVEN",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "HARVONI",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "HELIXATE FS",
        "pharmacies": [
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "HEMANGEOL",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "HEMGENIX",
        "pharmacies": [
            "Buy and Bill",
            "Orsini"
        ]
    },
    {
        "drugName": "HEMLIBRA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "HEMOFI L M",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "HEMOFIL M",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "HEPSERA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "HERCEP HYLEC",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "HERCEPTIN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "HERCEPTIN HYLECTA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "HERZUMA",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "HETLIOZ",
        "pharmacies": [
            "PANTHERx",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "HEXALEN",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "HIZENTRA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "HUMATE-P",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "HUMATIN",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "HUMATROPE",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "HUMIRA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "HYALGAN",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "HYCAMTIN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "HYDREA",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "HYDROXY CAPR",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "HYFTOR",
        "pharmacies": [
            "AllianceRx Walgreens"
        ]
    },
    {
        "drugName": "HYFTOR GEL",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "HYMOVIS",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "HYPERRHO S-D",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "HYPERRHO S/D",
        "pharmacies": [
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "HYQVIA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "HYTREK",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "IBRANCE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "ICATIBANT",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ICLUSIG",
        "pharmacies": [
            "Accredo",
            "CA",
            "AcariaHealth",
            "AcariaHealth- Commerce"
        ]
    },
    {
        "drugName": "IDAMYCIN PFS",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "IDARUBICIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "IDELVION",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "IDHIFA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics"
        ]
    },
    {
        "drugName": "IFEX",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "IFOSFAMIDE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ILARIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ILUMYA",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ILUVIEN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "IMATINIB MES",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "IMBRUVICA",
        "pharmacies": [
            "Diplomat Specialty Pharmacy",
            "Accredo",
            "Optum",
            "Biologics",
            "Avella/Optum",
            "Onco360"
        ]
    },
    {
        "drugName": "IMCIVREE",
        "pharmacies": [
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "IMFINZI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "IMJUDO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Onco360"
        ]
    },
    {
        "drugName": "IMLYGIC",
        "pharmacies": [
            "Buy and Bill",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "IMMUNE GLOBULIN",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "IMPAVIDO",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "INBRIJA",
        "pharmacies": [
            "AllianceRx Walgreens",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "INCRELEX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "INFERGEN",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "INFLECTRA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "INFLIXIMAB",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "INFUGEM",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "INGREZZA",
        "pharmacies": [
            "PANTHERx",
            "Accredo",
            "Orsini",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "INLY TA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "INLYTA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "INQOVI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "INREBIC",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "INTELENCE",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "INTRON A",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "INVEGA HAFYERA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "INVEGA SUSTENNA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "INVEGA TRINZA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "INVIRASE",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "IPRIVASK",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "IRESSA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "IRINOTECAN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ISENTRESS",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ISENTRESS HD",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ISTODAX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ISTODAX OVR",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ISTURISA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "IXEMPRA",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "IXEMPRA KIT",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "IXINITY",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "JADENU",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Biologics"
        ]
    },
    {
        "drugName": "JAKAFI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "JAVYGTOR",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "JAYPIRCA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "JELMYTO",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "JEMPERLI",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "JETREA",
        "pharmacies": [
            "Accredo",
            "AllianceRx Walgreens- Pittsburgh"
        ]
    },
    {
        "drugName": "JEVTANA",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "JIVI",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "JOENJA",
        "pharmacies": [
            "PANTHERx"
        ]
    },
    {
        "drugName": "JULUCA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "JUXTAPID",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "JYNARQUE",
        "pharmacies": [
            "AllianceRx Walgreens",
            "PANTHERx",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "KADCYLA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "KALBITOR",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "KALETRA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "KALYDECO",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "KANJINTI",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "KANUMA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "KCENTRA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "KEPIVANCE",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "KESIMPTA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "KEVEYIS",
        "pharmacies": [
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "KEVZARA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "KEYTRUDA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "KHAPZORY",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "KIMMTRAK",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "KINERET",
        "pharmacies": [
            "Accredo",
            "Biologics",
            "RxCrossroads"
        ]
    },
    {
        "drugName": "KISQALI",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "KISQALI FEMARA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "KISQALI FEMARA CO-PACK",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "KISQALI/KISQALI 400/600",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "KITABIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "KITABIS PAK",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "KOATE-DVI",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "KOGENATE FS",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "KORLYM",
        "pharmacies": [
            "Accredo",
            "Optime Care"
        ]
    },
    {
        "drugName": "KORSUVA",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "KOSELUGO",
        "pharmacies": [
            "Accredo",
            "Onco360"
        ]
    },
    {
        "drugName": "KOVALTRY",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "KRAZATI",
        "pharmacies": [
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "KRYSTEXXA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "KUVAN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "KYLEENA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "KYMRIAH",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "KYPROLIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "KYTRIL",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "LAMZEDE",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "LANREOTIDE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "LAPATINIB",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "LEDIP-SOFOSB",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "LEDIPASVIR/SOFOSBUVIR",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "LEMTRADA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "LENALIDOMIDE",
        "pharmacies": [
            "CVS/Caremark",
            "Optum",
            "AllianceRx Walgreens"
        ]
    },
    {
        "drugName": "LENVIMA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "LEQEMBI",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "LETAIRIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "LEUCOVORIN CALCIUM",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "LEUKERAN",
        "pharmacies": [
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "LEUKINE",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "LEUPROLIDE",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "LEVOLEUCOVOR",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "LEXIVA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "LIBTAYO",
        "pharmacies": [
            "Buy and Bill",
            "Accredo",
            "Onco360"
        ]
    },
    {
        "drugName": "LILETTA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "LIORESAL INT INJ",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "LIVMARLI",
        "pharmacies": [
            "Eversana Life Science Services",
            "Accredo"
        ]
    },
    {
        "drugName": "LIVTENCITY",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "LONSURF",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "LORBRENA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "LOVENOX",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "LUCENTIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "LUMAKRAS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "LUMIZYME",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "LUMOXITI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "LUNSUMIO",
        "pharmacies": [
            "CVS/Caremark",
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "LUPANETA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "LUPKYNIS",
        "pharmacies": [
            "PANTHERx",
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "LUPR DEP-PED",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "LUPRON",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "LUPRON DEPOT",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "LUPRON DEPOT-PED",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "LUPRON/LUPRON DEPOT",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "LUTATHERA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "LUXTURNA",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "LYNPARZA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "LYSODREN",
        "pharmacies": [
            "AllianceRx Walgreens",
            "Accredo",
            "Direct Success"
        ]
    },
    {
        "drugName": "LYTGOBI",
        "pharmacies": [
            "Accredo",
            "Onco360"
        ]
    },
    {
        "drugName": "MACI",
        "pharmacies": [
            "Orsini"
        ]
    },
    {
        "drugName": "MACRILEN",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "MACUGEN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "MAKENA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "MARGENZA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "MARQIBO",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "MATULANE",
        "pharmacies": [
            "AllianceRx Walgreens",
            "Accredo"
        ]
    },
    {
        "drugName": "MAVENCLAD",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "MAVYRET",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "MAYZENT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "MEGACE",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "MEKINIST",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "MEKTOVI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "MELPHALAN",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "MENOPUR",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "MEPSEVI",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "MEPSEVII",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "MESNA",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "MESNEX",
        "pharmacies": [
            "Optum",
            "Biologics"
        ]
    },
    {
        "drugName": "METHOTREXATE",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "METHYLPREDNISOLONE",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "METOPIRONE",
        "pharmacies": [
            "Buy and Bill",
            "Direct Success"
        ]
    },
    {
        "drugName": "MICRHOGAM",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "MICRHOGAM PL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "MIGLUSTAT",
        "pharmacies": [
            "Orsini",
            "Optum"
        ]
    },
    {
        "drugName": "MIRCERA",
        "pharmacies": [
            "AllianceRx Walgreens",
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "MIRENA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "MITOMYCIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "MITOXANTRON",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "MITOXANTRONE",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "MODERIBA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "MONJUVI",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "MONOCLATE-P",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "MONONINE",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "MONOVISC",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "MOZOBIL",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "MUGARD",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "MULPLETA",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "MUTAMYCIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "MVASI",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "MYALEPT",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "MYCAPSSA",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "MYFORTIC",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "MYLERAN",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "MYLOTARG",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "MYOBLOC",
        "pharmacies": [
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "MYOZYME",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "MYTESI",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "NAGLAZYME",
        "pharmacies": [
            "CVS/Caremark",
            "Orsini",
            "Accredo"
        ]
    },
    {
        "drugName": "NATPARA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "NELARABINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "NEORAL",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "NERLYNX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "NEULASTA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Biologics"
        ]
    },
    {
        "drugName": "NEUMEGA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "NEUPOGEN",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Biologics"
        ]
    },
    {
        "drugName": "NEXAVAR",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "NEXPLANON",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "NEXVIAZYME",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "NILANDRON",
        "pharmacies": [
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "NILUTAMIDE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "NINLARO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "NIPENT",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "NITISINONE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "NITYR",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "NIVESTYM",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "NORDITROPIN",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "NORTHERA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "NORVIR",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "NOURIANZ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "NOVAREL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "NOVOEIGHT",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "NOVOSEVEN RT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "NOVOSEVEN/NOVOSEVEN RT",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "NOXAFIL",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "NPLATE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "NUBEQA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "NUCALA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "NUCALA POWDER",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "NUCALA SQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "NULIBRY",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "NUPLAZID",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "NUPRO",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "NURTEC ODT",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "NUTROPIN",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "NUTROPIN AQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "NUWIQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "NUZYRA",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "NYVEPRIA",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "OBIZUR",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "OCALIVA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "OCREVUS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "OCTAGAM",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "OCTREOTIDE",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "ODEFSEY",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ODOMZO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "OFEV",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Orsini",
            "Optum"
        ]
    },
    {
        "drugName": "OGIVRI",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "OLUMIANT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "OMNITROPE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium",
            "Orsini"
        ]
    },
    {
        "drugName": "ONCASPAR",
        "pharmacies": [
            "CVS/Caremark",
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "ONGENTYS",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ONIVYDE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ONPATTRO",
        "pharmacies": [
            "PANTHERx",
            "Buy and Bill",
            "Orsini",
            "Accredo"
        ]
    },
    {
        "drugName": "ONSOLIS",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "ONTRUZANT",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ONUREG",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "OPDIVO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "OPDUALAG",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "OPSUMIT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "ORALAIR",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "ORENCIA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ORENITRAM",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "ORFADIN",
        "pharmacies": [
            "Eversana Life Science Services",
            "Accredo"
        ]
    },
    {
        "drugName": "ORGOVYX",
        "pharmacies": [
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "ORKAMBI",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "ORLADEYO",
        "pharmacies": [
            "Accredo",
            "Optime Care"
        ]
    },
    {
        "drugName": "ORSERDU",
        "pharmacies": [
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "ORTHOVISC",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "OSMOLEX ER",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "OTEZLA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "OTREXUP",
        "pharmacies": [
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "OVIDREL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "OXALIPLATIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "OXBRYTA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "OXERVATE",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "OXLUMO",
        "pharmacies": [
            "PANTHERx",
            "Buy and Bill",
            "Orsini",
            "Accredo"
        ]
    },
    {
        "drugName": "OZURDEX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "PACLITAXEL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "PADCEV",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Onco360"
        ]
    },
    {
        "drugName": "PAGASYS",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "PALFORZIA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "PALYNZIQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "PAMIDRONATE",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "PANHEMATIN",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "PANZYGA",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "PARAGARD",
        "pharmacies": [
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "PARAPLATIN",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "PARSABIV",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "PEG-INTRON",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "PEGASYS",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Biologics"
        ]
    },
    {
        "drugName": "PEMAZYRE",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "PEMETREXED",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "PEMFEXY",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "PENICILLAMIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "PENICILLAMINE CAP",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "PENICILLAMINE TAB",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "PEPAXTO",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "PERJETA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "PHEBURANE",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "PHENERGAN",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "PHENYLBUTYRA",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "PHESGO",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "PHOSPHOLINE IODIDE",
        "pharmacies": [
            "AllianceRx Walgreens",
            "Accredo"
        ]
    },
    {
        "drugName": "PHOTREXA",
        "pharmacies": [
            "Orsini"
        ]
    },
    {
        "drugName": "PIPERACILLIN/TAZOBACTAM",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "PIQRAY",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "PIRFENIDONE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "PLEGRIDY",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "PLEGRIDY/PLEGRIDY PEN",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "PLUVICTO",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "POLIVY",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "POMALYST",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "PONVORY",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "PORTRAZZA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "POTELIGEO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "PRADAXA",
        "pharmacies": [
            "Orsini"
        ]
    },
    {
        "drugName": "PRALATREXATE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "PRALUENT",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "PRAVIGARD",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "PREDNISONE",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "PREGNYL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "PREVYMIS",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "PREZCOBIX",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "PREZISTA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "PRIALT",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "PRIVIGEN",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "PROCRIT",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Biologics"
        ]
    },
    {
        "drugName": "PROCYSBI",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "PROFI LNINE/PROFI LNINE SD",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "PROFILNINE",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "PROFILNINE SD",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "PROGRAF",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "PROLASTIN C",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "PROLASTIN-C",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "PROLEUKIN",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Biologics"
        ]
    },
    {
        "drugName": "PROLIA",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "PROMACTA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "PROTHELIAL",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "PROVENGE",
        "pharmacies": [
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "PULMOZYME",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "PURIFIED CORTROPHIN GEL",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "PURINETHOL",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "PURIXAN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "PYRIMETHAMINE",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "PYRUKYND",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "QINLOCK",
        "pharmacies": [
            "CVS/Caremark",
            "PANTHERx",
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "QUTENZA",
        "pharmacies": [
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "RADICAVA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "RADICAVA INJ",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "RADICAVA ORAL SOLUTION",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "RADICAVA ORS",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "RAPAMUNE",
        "pharmacies": [
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "RASUVO",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "RAVICTI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "REBETOL",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "REBIF",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "REBINYN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "REBLOZYL",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "REBYOTA",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "RECLAST",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "RECOMBINATE",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "RECORLEV",
        "pharmacies": [
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "REDITREX",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "RELYVRIO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "REMICADE",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "REMODULIN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "RENFLEXIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "REPATHA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "RETACRIT",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "RETEVMO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "RETISERT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "RETROVIR",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "REVATIO",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "REVCOVI",
        "pharmacies": [
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "REVLIMID",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "REYATAZ",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "REZLIDHIA",
        "pharmacies": [
            "Optime Care",
            "Biologics"
        ]
    },
    {
        "drugName": "REZUROCK",
        "pharmacies": [
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "RHOGAM",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "RHOGAM PLUS",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "RHOPHYLAC",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "RIABNI",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "RIASTAP",
        "pharmacies": [
            "CVS/Caremark",
            "Hemophilia of Georgia",
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "RIBASPHERE",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "RIBASPHERE RIBAPAK",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "RIBAVIRIN",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "RIDAURA",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "RINVOQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "RISPERDAL CONSTA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "RITUXAN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "RITUXAN HYCELA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "RIXUBIS",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ROCTAVIAN",
        "pharmacies": [
            "Orsini"
        ]
    },
    {
        "drugName": "ROLVEDON",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ROMIDEPSIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ROZLYTREK",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "RUBRACA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics"
        ]
    },
    {
        "drugName": "RUCONEST",
        "pharmacies": [
            "CVS/Caremark",
            "Orsini",
            "Accredo"
        ]
    },
    {
        "drugName": "RUXIENCE",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "RUZURGI",
        "pharmacies": [
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "RYBREVANT",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "RYDAPT",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "RYLAZE",
        "pharmacies": [
            "Accredo",
            "Onco360"
        ]
    },
    {
        "drugName": "RYPLAZIM",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "RYSTIGGO",
        "pharmacies": [
            "PANTHERx"
        ]
    },
    {
        "drugName": "SABRIL",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "SAIZEN",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "SAIZEN/SAIZEN PREP",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "SAJAZIR",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SAMSCA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "SANCUSO",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "SANDIMMUNE",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "SANDOSTATIN",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "SANDOSTATIN LAR",
        "pharmacies": [
            "Accredo",
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "SAPHNELO",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SAPROPTERIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "SARCLISA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "SCEMBLIX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "SCENESSE",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SELZENTRY",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "SENSIPAR",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "SEROSTIM",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "SEVENFACT",
        "pharmacies": [
            "CVS/Caremark",
            "Hemophilia of Georgia",
            "Accredo"
        ]
    },
    {
        "drugName": "SIGNIFOR",
        "pharmacies": [
            "Accredo",
            "AnovoRx"
        ]
    },
    {
        "drugName": "SIGNIFOR LAR",
        "pharmacies": [
            "Buy and Bill",
            "Accredo",
            "AnovoRx"
        ]
    },
    {
        "drugName": "SILDENAFIL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "SILIQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "SIMPONI",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "SIMPONI ARIA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "SIMPONI/SIMPONI ARIA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "SINUVA",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "SIRTURO",
        "pharmacies": [
            "AllianceRx Walgreens",
            "MMS Solutions"
        ]
    },
    {
        "drugName": "SKYCLARYS",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "SKYLA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SKYRIZI",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "SKYSONA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SKYTROFA",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "SODIUM HYALURONATE",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SODIUM PHENY",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "SOFOS/VELPAT",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "SOFOSBUVIR/VELPATASVIR",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "SOGROYA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "SOLESTA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SOLIRIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "SOLTAMOX",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "SOMATULINE",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "SOMATULINE DEPOT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "SOMAVERT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "SORAFENIB",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "SOTYKTU",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "SOVALDI",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "SPEVIGO",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SPINRAZA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SPRAVATO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "SPRIX",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SPRYCEL",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "STELARA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "STIMATE",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "STIMUFEND",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "STIVARGA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "STRENSIQ",
        "pharmacies": [
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "STRIBILD",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "SUBLOCADE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "SUCRAID",
        "pharmacies": [
            "US Bioservices",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "SUNITINIB",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "SUNLENCA",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "SUPARTZ",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "SUPARTZ FX",
        "pharmacies": [
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "SUPPRELIN LA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "SUSTIVA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "SUTENT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "SYFOVRE",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "SYLVANT",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "SYMDEKO",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "SYMFI",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "SYMFI LO",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "SYMTUZA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "SYNAGIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "SYNAREL",
        "pharmacies": [
            "AllianceRx Walgreens"
        ]
    },
    {
        "drugName": "SYNRIBO",
        "pharmacies": [
            "Diplomat Specialty Pharmacy",
            "Buy and Bill",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "SYNVISC",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "SYNVISC ONE",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "SYNVISC/SYNVISC ONE",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "SYPRINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TABLOID",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TABRECTA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Biologics"
        ]
    },
    {
        "drugName": "TADALAFIL",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TADLIQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "TAFINLAR",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "TAGRISSO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "TAKHZYRO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Orsini",
            "Optum"
        ]
    },
    {
        "drugName": "TALTZ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "TALZENNA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "TARCEVA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "TARGRETIN",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "TARPEYO",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "TARZENNA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "TASIGNA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "TASIMELTEON",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TAVALISSE",
        "pharmacies": [
            "US Bioservices",
            "Accredo",
            "Optum",
            "Biologics"
        ]
    },
    {
        "drugName": "TAVNEOS",
        "pharmacies": [
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "TAZVERIK",
        "pharmacies": [
            "Accredo",
            "Onco360"
        ]
    },
    {
        "drugName": "TECARTUS",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "TECENTRIQ",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "TECFIDERA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "TECVAYLI",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "TEGSEDI",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "TEMODAR",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "TEMOZOLOMIDE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TEMSIROLIMUS",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TEPADINA",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TEPEZZA",
        "pharmacies": [
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "TEPMETKO",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "TERIPARATIDE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TERLIVAZ",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "TESTOPEL",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "TETRABENAZIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TETRABENAZINE",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "TEZSPIRE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "THALOMID",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "THIOLA",
        "pharmacies": [
            "Eversana Life Science Services",
            "Accredo"
        ]
    },
    {
        "drugName": "THIOLA EC",
        "pharmacies": [
            "Eversana Life Science Services"
        ]
    },
    {
        "drugName": "THIOTEPA",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "THYROGEN",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "TIBSOVO",
        "pharmacies": [
            "Diplomat Specialty Pharmacy",
            "Accredo",
            "Biologics",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "TICE BCG",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TIKOSYN",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "TIOPRONIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TIVDAK",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "TIVICAY",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "TIVZEN",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "TOBI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "TOBI PODHALER",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "TOBI PODHALR",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TOBI/TOBI PODHALER",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "TOBRAMYCIN",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "TOLVAPTAN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TOPOSAR",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TOPOTECAN",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "TORISEL",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "TRACLEER",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "TRAZIMERA",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "TREANDA",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "TRELSTAR MIX",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TRELSTAR MIXJET",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "TRELSTAR/TRELSTAR DEP/LA/MIX",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "TREMFYA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "TREPROSTINIL",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "TRETINOIN",
        "pharmacies": [
            "Optum",
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "TRETTEN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Hemophilia of Georgia",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "TRIENTINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "TRIKAFTA",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "TRILURON",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "TRIPTODUR",
        "pharmacies": [
            "PANTHERx",
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "TRISENOX",
        "pharmacies": [
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "TRIUMEQ",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "TRIVISC",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "TRIZIVIR",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "TRODELVY",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "TRUCELTIQ",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "TRUSELTIQ",
        "pharmacies": [
            "US Bioservices"
        ]
    },
    {
        "drugName": "TRUVADA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "TRUXIMA",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "TUKYSA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "TURALIO",
        "pharmacies": [
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "TYBOST",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "TYKERB",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "TYMLOS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "TYSABRI",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "TYVASO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "TYVASO DPI",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "TYVASO INHALATION",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "TZIELD",
        "pharmacies": [
            "Buy and Bill",
            "Orsini",
            "Accredo"
        ]
    },
    {
        "drugName": "UDENYCA",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "UKONIQ",
        "pharmacies": [
            "Onco360"
        ]
    },
    {
        "drugName": "ULTOMIRIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "UNITUXIN",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "UPLIZNA",
        "pharmacies": [
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "UPTRAVI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "UZEDY",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "VABYSMO",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "VALCHLOR",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "VALRUBICIN",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "VALSTAR",
        "pharmacies": [
            "CVS/Caremark",
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "VANFLYTA",
        "pharmacies": [
            "Onco360"
        ]
    },
    {
        "drugName": "VANTAS",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "VARITHENA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "VARUBI",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "VARUBI TAB",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "VECTIBIX",
        "pharmacies": [
            "AcariaHealth",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "VELCADE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "VELETRI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "VEMLIDY",
        "pharmacies": [
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "VENCLEXTA",
        "pharmacies": [
            "Diplomat Specialty Pharmacy",
            "Accredo",
            "Optum",
            "Biologics",
            "Avella/Optum",
            "Onco360"
        ]
    },
    {
        "drugName": "VENTAVIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "VEOPOZ",
        "pharmacies": [
            "Orsini"
        ]
    },
    {
        "drugName": "VEPESID",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "VERZENIO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "VIDAZA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Biologics"
        ]
    },
    {
        "drugName": "VIDEX",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "VIEKIRA PAK",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Proprium"
        ]
    },
    {
        "drugName": "VIGABATRIN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Orsini",
            "Optum"
        ]
    },
    {
        "drugName": "VIGADRONE",
        "pharmacies": [
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "VIJOICE",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AllianceRx Walgreens",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "VILTEPSO",
        "pharmacies": [
            "Buy and Bill",
            "Orsini"
        ]
    },
    {
        "drugName": "VIMIZIM",
        "pharmacies": [
            "CVS/Caremark",
            "Orsini",
            "Accredo"
        ]
    },
    {
        "drugName": "VINBLASTINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "VINCASAR PFS",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "VINCRISTINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "VINORELBINE",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "VIRACEPT",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "VIRAMUNE",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "VIRAMUNE XR",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "VIREAD",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "VISCO-3",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "VISTOGARD",
        "pharmacies": [
            "Accredo",
            "Cardinal Health Specialty",
            "Biologics"
        ]
    },
    {
        "drugName": "VISUDYNE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "VITEKTA",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "VITRAKVI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "VIVIMUSTA",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "VIVITROL",
        "pharmacies": [
            "Accredo",
            "Orsini",
            "AcariaHealth",
            "Optum"
        ]
    },
    {
        "drugName": "VIZIMPRO",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "VONJO",
        "pharmacies": [
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "VONVENDI",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "VORAXAZE",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "VOSEVI",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "VOTRIENT",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "VOWST",
        "pharmacies": [
            "Orsini"
        ]
    },
    {
        "drugName": "VOXZOGO",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "VPRIV",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Orsini"
        ]
    },
    {
        "drugName": "VUMERITY",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "VYEPTI",
        "pharmacies": [
            "Buy and Bill",
            "Orsini",
            "Accredo"
        ]
    },
    {
        "drugName": "VYJUVEK",
        "pharmacies": [
            "Orsini"
        ]
    },
    {
        "drugName": "VYNDAMAX",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "VYNDAQEL",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "VYNDAQEL/VYNDAMAX",
        "pharmacies": [
            "Orsini"
        ]
    },
    {
        "drugName": "VYONDYS 53",
        "pharmacies": [
            "Buy and Bill",
            "Orsini"
        ]
    },
    {
        "drugName": "VYVGART",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "VYVGART/VYVGART HYTRULO",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "VYXEOS",
        "pharmacies": [
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "WAKIX",
        "pharmacies": [
            "CVS/Caremark",
            "PANTHERx",
            "Accredo"
        ]
    },
    {
        "drugName": "WELIREG",
        "pharmacies": [
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "WILATE",
        "pharmacies": [
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "WINRHO SD",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "WINRHO SDF",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "XALKORI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "XELJANZ",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "XELJANZ XR",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "XELODA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "XEMBIFY",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "XENAZINE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "XENLETA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "XENLETA INJECTION",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "XENLETA TABLET",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "XENPOZYME",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Orsini",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "XEOMIN",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "XERMELO",
        "pharmacies": [
            "Diplomat Specialty Pharmacy",
            "Accredo",
            "Biologics"
        ]
    },
    {
        "drugName": "XGEVA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "XIAFLEX",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "XIFAXAN",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "XIPERE",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "XOFIGO",
        "pharmacies": [
            "Buy and Bill",
            "Accredo"
        ]
    },
    {
        "drugName": "XOLAIR",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "XOLAIR PF SYRINGE",
        "pharmacies": [
            "CVS/Caremark"
        ]
    },
    {
        "drugName": "XOSPATA",
        "pharmacies": [
            "US Bioservices",
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "XPOVIO",
        "pharmacies": [
            "US Bioservices",
            "Accredo",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "XTANDI",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "XURIDEN",
        "pharmacies": [
            "Accredo",
            "Cardinal Health Specialty"
        ]
    },
    {
        "drugName": "XYNTHA",
        "pharmacies": [
            "Accredo",
            "Proprium"
        ]
    },
    {
        "drugName": "XYNTHA/XYNTHA SOLOF",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "XYREM",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "XYWAV",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "YCANTH",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "YERVOY",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "YESCARTA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "YONDELIS",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "YONSA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "YUTIQ",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ZALTRAP",
        "pharmacies": [
            "Accredo",
            "Optum",
            "Onco360"
        ]
    },
    {
        "drugName": "ZANOSAR",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ZARXIO",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ZAVESCA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "ZEJULA",
        "pharmacies": [
            "US Bioservices",
            "Diplomat Specialty Pharmacy",
            "Accredo",
            "Optum",
            "Biologics",
            "Proprium"
        ]
    },
    {
        "drugName": "ZELBORAF",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360"
        ]
    },
    {
        "drugName": "ZEMAIRA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "ZEMDRI",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ZEPATIER",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ZEPOSIA",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ZEPZELCA",
        "pharmacies": [
            "Accredo",
            "Onco360"
        ]
    },
    {
        "drugName": "ZERIT",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ZIAGEN",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ZIEXTENZO",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ZILRETTA",
        "pharmacies": [
            "Buy and Bill",
            "Accredo",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ZINPLAVA",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ZIRABEV",
        "pharmacies": [
            "Accredo",
            "Optum"
        ]
    },
    {
        "drugName": "ZOFRAN",
        "pharmacies": [
            "Biologics"
        ]
    },
    {
        "drugName": "ZOKINVY",
        "pharmacies": [
            "US Bioservices",
            "Accredo"
        ]
    },
    {
        "drugName": "ZOLADEX",
        "pharmacies": [
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ZOLEDRONIC",
        "pharmacies": [
            "Optum"
        ]
    },
    {
        "drugName": "ZOLEDRONIC ACID",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ZOLGENSMA",
        "pharmacies": [
            "Accredo",
            "Orsini"
        ]
    },
    {
        "drugName": "ZOLINZA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ZOMACTON",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ZOMETA",
        "pharmacies": [
            "Accredo"
        ]
    },
    {
        "drugName": "ZORBTIVE",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "AcariaHealth",
            "Proprium"
        ]
    },
    {
        "drugName": "ZORTRESS",
        "pharmacies": [
            "Proprium"
        ]
    },
    {
        "drugName": "ZOSYN",
        "pharmacies": [
            "Buy and Bill"
        ]
    },
    {
        "drugName": "ZTALMY",
        "pharmacies": [
            "Accredo",
            "Orsini"
        ]
    },
    {
        "drugName": "ZULRESSO",
        "pharmacies": [
            "Accredo",
            "Orsini"
        ]
    },
    {
        "drugName": "ZYDELIG",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "Onco360"
        ]
    },
    {
        "drugName": "ZYKADIA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    },
    {
        "drugName": "ZYNLONTA",
        "pharmacies": [
            "Buy and Bill",
            "Accredo",
            "Onco360"
        ]
    },
    {
        "drugName": "ZYNTEGLO",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo"
        ]
    },
    {
        "drugName": "ZYPREXA RELPREVV",
        "pharmacies": [
            "AcariaHealth"
        ]
    },
    {
        "drugName": "ZYTIGA",
        "pharmacies": [
            "CVS/Caremark",
            "Accredo",
            "Optum",
            "Biologics",
            "AcariaHealth",
            "Onco360",
            "Proprium"
        ]
    }
]

let PHARMACIES_LIST = [];

LDD_DATA.forEach(item => {
    item.pharmacies.forEach(pharma => {
        if (PHARMACIES_LIST.indexOf(pharma) === -1) {
            PHARMACIES_LIST.push(pharma);
        }
    })
})

const LDD_DRUG_LIST = LDD_DATA.map(item => item.drugName);

export {LDD_DATA, LDD_DRUG_LIST, PHARMACIES_LIST}