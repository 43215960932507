const dateHandling = (format, entry) => {
    let passTest = false;
    if (format === "year") {
        const yearReg = /^[12][0-9]{3}$/
        if (yearReg.test(entry) === true) {
            passTest = true;
        }
    } else if (format === "month") {
        const monthReg = /^(0[1-9]|1[0-2])\/(20\d{2}|[3-9]\d{3})$/;
        if (monthReg.test(entry) === true) {
            passTest = true;
        }
    } else if (format === "quarter") {
        const quarterReg = /^Q([1-4])\/(20\d{2}|[3-9]\d{3})$/;
        if (quarterReg.test(entry) === true) {
            passTest = true;
        }
    } else {
        console.warn("sus code");
    }
    return passTest
}

const getDatePlaceHolder = (format) => {
    if (format === "year") {
        return "yyyy";
    } else if (format === "month") {
        return 'mm/yyyy';
    } else if (format === 'quarter') {
        return 'Qx/yyyy';
    } else {
        console.warn("sus code");
    }
}

const getPreLaunchPeriod = (colData) => {
    if (colData.modelPeriod === "year") {
        let asNum = parseInt(colData.modelStart) - (colData.index + 1);
        return asNum.toString();
    }
    if (colData.modelPeriod === "month") {
        let mo = parseInt(colData.modelStart.slice(0,2));
        let ye = parseInt(colData.modelStart.slice(3));
        for (let i = 0; i < (colData.index + 1); i++) {
            mo--;
            if (mo === 0) {
                mo = 12;
                ye--;
            }
        }
        let moString = mo.toString();
        if (moString.length === 1) moString = "0" + moString;
        let toPush = moString + "/" + ye.toString();
        return toPush;
    }
    if (colData.modelPeriod === "quarter") {
        let q = parseInt(colData.modelStart.slice(1,2));
        let ye = parseInt(colData.modelStart.slice(3));
        for (let i = 0; i < (colData.index + 1); i++) {
            q--;
            if (q === 0) {
                q = 4;
                ye--;
            }
        }
        let toPush = "Q" + q.toString() + "/" + ye.toString();
        return toPush;
    }
}

const getDatePeriods = (startDate, period, length, dateOfLOE) => {
    let returnArr = [];
    if (period === "year") {
        for (let i = 0; i < length; i++) {
            let nummed = parseInt(startDate) + i;
            let toPush = nummed.toString();
            if (dateOfLOE) {
                if (dateOfLOE === toPush) {
                    return i;
                }
            }
            returnArr.push(toPush);
        }
        return returnArr
    } else if (period === "month") {
        let mo = parseInt(startDate.slice(0,2));
        let ye = parseInt(startDate.slice(3));
        for (let i = 0; i < length; i++) {
            let moString = mo.toString();
            if (moString.length === 1) moString = "0" + moString;
            let toPush = moString + "/" + ye.toString();
            if (dateOfLOE) {
                if (dateOfLOE === toPush) {
                    return i;
                }
            }
            returnArr.push(toPush);
            mo++;
            if (mo === 13) {
                mo = 1;
                ye++;
            }
        }
        return returnArr
    } else if (period === "quarter") {
        let q = parseInt(startDate.slice(1,2));
        let ye = parseInt(startDate.slice(3));
        for (let i = 0; i < length; i++) {
            let toPush = "Q" + q.toString() + "/" + ye.toString();
            if (dateOfLOE) {
                if (dateOfLOE === toPush) {
                    return i;
                }
            }
            returnArr.push(toPush);
            q++;
            if (q === 5) {
                q = 1;
                ye++;
            }
        }
        return returnArr
    } else {
        console.warn("sus code")
        return "error"
    }
}

export default dateHandling;
export {getDatePlaceHolder, getDatePeriods, getPreLaunchPeriod};