import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import userIcon from "../../icons/bancy-user-black.svg"
import forecastIcon from "../../icons/bancy-forecast-icon.svg"
import expandIcon from "../../icons/down-carot.svg"
import quickAnalysisIcon from "../../icons/bancroft_icon_guage.svg"
import prevalenceIcon from "../../icons/bancroft_icon_grouping.svg"
import "../../css/userPage.css"

const UserPage = ({activeUser, setActiveUser}) => {

    const [moduleState, setModuleState] = useState({...activeUser.modules});
    const [errorMessage, setErrorMessage] = useState("");
    const [profileExpanded, setProfileExpanded] = useState(false);

    const navigate = useNavigate();

    console.log(moduleState)

    const handleLogout = () => {
        localStorage.removeItem('jwtToken');
        setActiveUser(null);
        return navigate("/")
    }

    const handlePurchase = () => {
        const token = localStorage.getItem("jwtToken");
        if (token) {
            const data = {modules: {...moduleState}}
            // axios.post('http://localhost:8000/user/modules', 

            axios.post('https://bancy-backend-01dc0125253f.herokuapp.com/user/modules', 
                { data },
                { headers: { Authorization: `Bearer ${token}` }}
                )
                .then((response) => {
                    if (response.data.user) {
                        console.log(response.data.user)
                        setActiveUser(response.data.user)
                    } else {
                        setErrorMessage(response.data.message);
                    }
    
                })
                .catch(err => {
                    const errorMsg = err.response.data.message ? err.response.data.message : "Signup Failed"
                    setErrorMessage(errorMsg);
                })
        } else {
            navigate("/login")
        }
    }

    const handleNavigate = (url) => {
        console.log(url)
        navigate(url)
    }

    const getGreeting = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
          return 'Good morning';
        } else if (currentHour < 18) {
          return 'Good afternoon';
        } else {
          return 'Good evening';
        }
      };

  return (
    <div className='user-page-master'>
        <div className='flex-row alignCenter up-nav-div'>
            <h1 className='lpn-title'>bancroft</h1>
            <div onClick={handleLogout} className='flex-row alignCenter user-page-signout'>
                <img className="svg-icon" src={userIcon} alt="user" />
                <h4 className='user-page-signout-txt'>Sign Out</h4>
            </div>
        </div>
        <h1 className='up-welcome-wagon'>{getGreeting()}, {activeUser.givenName}</h1>
        <div className='up-main'>
            <div className='up-links-container'>
                <div className='up-link-div' onClick={() => handleNavigate("/forecast")}>
                    <div className='up-link-inner'>
                        <img className="svg-icon" id="up-forecast-icon" src={forecastIcon} alt="forecast link" />
                        <h2 className='up-link-txt'>Forecast</h2>
                    </div>
                </div>
                <div className='up-link-div' onClick={() => handleNavigate('/quickanalysis')}>
                    <div className='up-link-inner'>
                        <img className="svg-icon" id="up-qa-icon" src={quickAnalysisIcon} alt="quick analysis link" />
                        <h2 className='up-link-txt'>Quick Analysis</h2>
                    </div>                
                </div>
                <div className='up-link-div' onClick={() => handleNavigate('/prevalence')}>
                    <div className='up-link-inner'>
                        <img className="svg-icon" id="up-prevalence-icon" src={prevalenceIcon} alt="prevalence link" />
                        <h2 className='up-link-txt'>Prevalence</h2>
                    </div>                
                </div>
            </div>
            {/* {activeUser && activeUser.modules.forecast  */}
            {false
            ?
            <div className='up-subscribe-div'>

            </div>
            :
            <div className='up-profile-details'>
                <div className='up-details-banner'>
                    <h4 className='up-details-banner-txt'>Account Details</h4>
                </div>
                {!profileExpanded 
                ?
                <div className='up-details-expand'>
                    <img onClick={() => setProfileExpanded(!profileExpanded)} className="svg-icon" id="up-expand-icon" src={expandIcon} alt="expand-section" />
                </div>
                :
                <React.Fragment>
                    <div className='up-details-main'>
                        <div className='up-details-login flex-row'>
                            <div id='up-dl-num1' className='up-details-login-container'>
                                <p className='up-dl-header'>LOGIN INFORMATION</p>
                            </div>
                            <div id='up-dl-num2' className='up-details-login-container'>
                                <p className='up-dl-txt' >Name: {activeUser.givenName}</p>
                                <p className='up-dl-txt up-dl-email'>Email: {activeUser.email}</p>
                            </div>
                            <div id='up-dl-num3' className='up-details-login-container'>
                                <p className='up-dl-txt-btn'>Change Name</p>
                                <p className='up-dl-txt-btn'>Change Email</p>
                                <p className='up-dl-txt-btn'>Change Password</p>
                            </div>
                        </div>
                        <div className='up-hr'></div>
                        <div className='up-details-login flex-row'>
                            <div id='up-dl-num1' className='up-details-login-container'>
                                <p className='up-dl-header'>PLAN DETAILS</p>
                            </div>
                            <div id='up-dl-num2' className='up-details-login-container'>
                                <p className='up-dl-txt'>Professional Industry Preview</p>
                            </div>
                            <div id='up-dl-num3' className='up-details-login-container'>
                                <p className='up-dl-txt-btn'>Change Plan</p>
                                <p className='up-dl-txt-btn'>Manage Payment  Method</p>
                            </div>
                        </div>
                    </div>
                    <div className='up-details-expand'>
                        <img onClick={() => setProfileExpanded(!profileExpanded)} className="svg-icon" id="up-expand-icon-down" src={expandIcon} alt="expand-section" />
                    </div>
                </React.Fragment>
                }
            </div>
            }
        </div>

        {/* <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <h3>my modules</h3>
        {Object.keys(activeUser.modules).every(key => activeUser.modules[key] === false) ? 
            <h4>YOU HAVE NO MODULES--purchase below</h4>
            :
            <ul>
                {Object.keys(activeUser.modules).map((mod, index) => {
                    if (activeUser.modules[mod]) {
                        return <li key={`user-mod${index}`}>{mod}</li>
                    }
                    return null;
                })}
            </ul>
            }


        <br></br>
        <h5>edit modules-- dev to check permissions-will eventually be directed to payment</h5>

        <div className='flex-col alignCenter'>
                <div className='flex-row'>
                <p>forecast</p>
            <input type='checkbox' checked={moduleState.forecast} onChange={() => setModuleState({...moduleState, forecast: !moduleState.forecast})}></input>
            </div>
                <div className='flex-row'>
                <p>summary</p>
            <input type='checkbox' checked={moduleState.summary} onChange={() => setModuleState({...moduleState, summary: !moduleState.summary})}></input>
            </div>
                <div className='flex-row'>
                <p>data</p>
            <input type='checkbox' checked={moduleState.data} onChange={() => setModuleState({...moduleState, data: !moduleState.data})}></input>
                
            </div>
        </div>

        {errorMessage.length > 0 && <h6>{errorMessage}</h6>}
        <button className='input-action-btn' onClick={handlePurchase}>PURCHASE MODULES--dev</button> */}

    </div>
  )
}

export default UserPage