import React, { useEffect, useState, useMemo } from 'react'
import { BIRTHS_CONST, POPULATIONS_CONST } from '../../../../../constants'
import DiseaseExcerpt from './DiseaseExcerpt'
import { countDecimals, toCommaSeperatedString } from '../../../../../functions/helpers/generalHelpers'
import searchDiseaseDB from '../../../../../functions/helpers/diseaseFuzzyMatch'
import WarningPopupSmall from '../../../../util/WarningPopupSmall'
import closeIcon from "../../../../../icons/bancroft_X_icon.svg"
const PatientCalculator = ({diseaseState, patientsState, setPatientsState, workingGeographies, workingForecast, setWorkingForecast, setShowCalculator}) => {

  const [inputState, setInputState] = useState(0);

  // for gene thereapy --- incident pop
  const [inputState2, setInputState2] = useState(0);
  const [diseaseExcerpts, setDiseaseExcerpts] = useState(searchDiseaseDB(diseaseState));
  const [alterAllGeo, setAlterAllGeo] = useState(true);
  const [calculatorType, setCalculatorType] = useState("prevalence");
  const [calculatorType2, setCalculatorType2] = useState("incidence");

  const [geoState, setGeoState] = useState({});
  const [warning, setWarning] = useState(false)

  useEffect(() => {
    console.log(calculatorType, calculatorType2)
  }, [calculatorType, calculatorType2])

  useEffect(() => {
    setDiseaseExcerpts(searchDiseaseDB(diseaseState))
  }, [diseaseState])

  useEffect(() => {
      let initGeoState = {}
      let allSame = [];
      workingGeographies.forEach((geo, index) => {
          const totalPop = POPULATIONS_CONST[geo];
          const births = BIRTHS_CONST[geo];
          let ret = workingForecast.type === "drug" ? {total: totalPop, births: births, patients: 0} : {total: totalPop, births: births, prevalentPatients: 0, incidentPatients: 0}
          let copy = {}
          console.log(patientsState)
          if (Object.keys(patientsState).length === workingGeographies.length) {
            copy = {...patientsState}
          } else {
            copy = {...workingForecast};
            copy = {...copy.geographiesData[geo].general.geographiesPatients};
          }
          if (copy[geo]) {

            // This code finds out and preserves the calculator as it last were

            let item = copy[geo];
            if (workingForecast.type === "drug") {
                if (item.patients) {
                    ret.patients = item.patients;
                }
                if (item.prevalence) {
                    ret.prevalence = item.prevalence;
                    ret.primary = "prevalence";
                }
                if (item.incidence) {
                    ret.incidence = item.incidence;
                    ret.primary = "incidence";
                }
                if (item.birthsIncidence) {
                    ret.birthsIncidence = item.birthsIncidence;
                    ret.primary = "birthsIncidence";
                }
                if (!ret.primary) {
                    ret.primary = "population"
                }
            } else {
                if (item.prevalentPatients) {
                    ret.prevalentPatients = item.prevalentPatients;
                }
                if (item.incidentPatients) {
                    ret.incidentPatients = item.incidentPatients;
                }
                if (item.prevalence) {
                    ret.prevalence = item.prevalence;
                    ret.primary = "prevalence";
                }
                if (item.incidence) {
                    ret.incidence = item.incidence;
                    ret.primary = "incidence";
                }
                if (item.birthsIncidence) {
                    ret.birthsIncidence = item.birthsIncidence;
                    ret.primary = "birthsIncidence";
                }
                if (!ret.primary) {
                    ret.primary = "population"
                }
                if (item.prevalence2) {
                    ret.prevalence2 = item.prevalence2;
                    ret.primary2 = "prevalence";
                }
                if (item.incidence2) {
                    ret.incidence2 = item.incidence2;
                    ret.primary2 = "incidence";
                }
                if (item.birthsIncidence2) {
                    ret.birthsIncidence2 = item.birthsIncidence2;
                    ret.primary2 = "birthsIncidence";
                }
                if (!ret.primary2) {
                    ret.primary2 = "population"
                }
            }

            allSame.push({primary: ret.primary, decimal: ret.primary === "population" ? undefined : ret[ret.primary].decimal});
            if (index === 0) {
                if (!ret.patients) {
                    setCalculatorType("prevalence");
                    setCalculatorType2("incidence")
                } else {
                    setCalculatorType(ret.primary)
                }
            }
          } else {
            // issueWarning no found geography in database
          }
    
          initGeoState[geo] = ret;
      });
      setGeoState({...initGeoState});
    
      // if geographies are calculated differently we want to head to the same calculator type
      if (allSame.length > 0) {
        let passed = allSame.every(val => {
            return (val.primary === allSame[0].primary && val.decimal === allSame[0].decimal)
        })
        if(!passed) {
          setAlterAllGeo(false)
          setCalculatorType(allSame[0].primary);
        }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
    
  const [alteredGeo, setAlteredGeo] = useState(workingGeographies[0]);

  useEffect(() => {
      if (inputState && inputState !== 0) {
        if (calculatorType === "prevalence") {
            if (alterAllGeo) {
                setGeoState(prev => {
                    let newState = {...prev};
                    Object.keys(newState).forEach(k => {
                        if (workingForecast.type === "drug") {
                            newState[k].patients = Math.ceil(inputState[0] * newState[k].total);
                        } else {
                            newState[k].prevalentPatients = Math.ceil(inputState[0] * newState[k].total);
                        }
                        newState[k].prevalence = {decimal: inputState[0], ratio: inputState[1]}
                        newState[k].primary = "prevalence";
                    })
                    return {...newState};
                })
            } else {
                setGeoState(prev => {
                    let newState = {...prev};
                    if (workingForecast.type === "drug") {
                        newState[alteredGeo].patients = Math.ceil(inputState[0] * newState[alteredGeo].total);
                    } else {
                        newState[alteredGeo].prevalentPatients = Math.ceil(inputState[0] * newState[alteredGeo].total);
                    }
                    newState[alteredGeo].prevalence = {decimal: inputState[0], ratio: inputState[1]}
                    newState[alteredGeo].primary = "prevalence";
                    return {...newState};
                })
            }
        } else if (calculatorType === "birthsIncidence" || calculatorType === "incidence") {
            let indication = calculatorType;
            if (alterAllGeo) {
                setGeoState(prev => {
                    let newState = {...prev};
                    Object.keys(newState).forEach(k => {
                        if (workingForecast.type === "drug") {
                            newState[k].patients = Math.ceil(inputState[0] * inputState[2] * newState[k].births);
                        } else {
                            newState[k].prevalentPatients = Math.ceil(inputState[0] * inputState[2] * newState[k].births);
                        }
                        newState[k][indication] = {decimal: inputState[0], ratio: inputState[1], lifeExpectancy: inputState[2]}
                        newState[k].primary = indication;
                    })
                    return {...newState};
                })
            } else {
                setGeoState(prev => {
                    let newState = {...prev};
                    if (workingForecast.type === "drug") {
                        newState[alteredGeo].patients = Math.ceil(inputState[0] * inputState[2] * newState[alteredGeo].births);
                    } else {
                        newState[alteredGeo].prevalentPatients = Math.ceil(inputState[0] * inputState[2] * newState[alteredGeo].births);
                    }
                    newState[alteredGeo][indication] = {decimal: inputState[0], ratio: inputState[1], lifeExpectancy: inputState[2]};
                    newState[alteredGeo].primary = indication;
                    return {...newState};
                })
            }
        } else if (calculatorType === "population") {
            setGeoState(prev => {
                let newState = {...prev};
                if (workingForecast.type === "drug") {
                    newState[alteredGeo].patients = Math.ceil(inputState);
                } else {
                    newState[alteredGeo].prevalentPatients = Math.ceil(inputState);
                }
                newState[alteredGeo].primary = "population"
                return {...newState};
            })
        }
        if (workingForecast.type !== "drug") {
            if (calculatorType2 === "prevalence") {
                if (alterAllGeo) {
                    setGeoState(prev => {
                        let newState = {...prev};
                        Object.keys(newState).forEach(k => {
                            newState[k].incidentPatients = Math.ceil(inputState2[0] * newState[k].total);
                            newState[k].prevalence2 = {decimal: inputState2[0], ratio: inputState2[1]}
                            newState[k].primary2 = "prevalence";
                        })
                        return {...newState};
                    })
                } else {
                    setGeoState(prev => {
                        let newState = {...prev};
                        newState[alteredGeo].incidentPatients = Math.ceil(inputState2[0] * newState[alteredGeo].total);
                        newState[alteredGeo].prevalence2 = {decimal: inputState2[0], ratio: inputState2[1]}
                        newState[alteredGeo].primary2 = "prevalence";
                        return {...newState};
                    })
                }
            } else if (calculatorType2 === "birthsIncidence" || calculatorType2 === "incidence") {
                let indication = `${calculatorType2}2`;
                if (alterAllGeo) {
                    setGeoState(prev => {
                        let newState = {...prev};
                        Object.keys(newState).forEach(k => {
                    
                            newState[k].incidentPatients = Math.ceil(inputState2[0] * inputState2[2] * newState[k].births);
                            newState[k][indication] = {decimal: inputState2[0], ratio: inputState2[1], lifeExpectancy: inputState2[2]}
                            newState[k].primary2 = indication;
                        })

                        return {...newState};
                    })
                } else {
                    setGeoState(prev => {
                        let newState = {...prev};
                        newState[alteredGeo].incidentPatients = Math.ceil(inputState2[0] * inputState2[2] * newState[alteredGeo].births);
                        newState[alteredGeo][indication] = {decimal: inputState2[0], ratio: inputState2[1], lifeExpectancy: inputState2[2]};
                        newState[alteredGeo].primary = indication;
                        return {...newState};
                    })
                }
            } else if (calculatorType2 === "population") {
                setGeoState(prev => {
                    let newState = {...prev};
                    newState[alteredGeo].incidentPatients = Math.ceil(inputState2);
                    newState[alteredGeo].primary2 = "population"
                    return {...newState};
                })
            }
        }
    }
  }, [setGeoState, inputState, calculatorType, alterAllGeo, alteredGeo, workingForecast.type, calculatorType2, inputState2])

  useEffect(() => {
    if (warning) {
        let passed = Object.keys(geoState).every(key => {
            if (workingForecast.type === "drug") {
                return !isNaN(geoState[key].patients) && geoState[key].patients !== 0
            } else {
                return !isNaN(geoState[key].incidentPatients) && geoState[key].incidentPatients !== 0 && !isNaN(geoState[key].prevalentPatients) && geoState[key].prevalentPatients !== 0
            }
        })
        if (passed) {
            setWarning(false)
        }
    }
  }, [geoState, warning, workingForecast.type])

  const handleSubmit = (e) => {
    e.preventDefault();
    let passed = Object.keys(geoState).every(key => {
        if (workingForecast.type === "drug") {
            return !isNaN(geoState[key].patients) && geoState[key].patients !== 0
        } else {
            return !isNaN(geoState[key].incidentPatients) && geoState[key].incidentPatients !== 0 && !isNaN(geoState[key].prevalentPatients) && geoState[key].prevalentPatients !== 0
        }
    })
    if (passed) {
        // removing other calculations by default 
        let newGeoState = {...geoState}
        Object.keys(newGeoState).forEach(k => {
            let unSafe = ["prevalence", "incidence", "birthsIncidence", "population", "prevalence2", "incidence2", "birthsIncidence2", "population2"];
            unSafe.splice(unSafe.indexOf(newGeoState[k].primary), 1);
            if (workingForecast.type !== "drug") unSafe.splice(unSafe.indexOf(newGeoState[k].primary2), 1);
            for (let c = 0; c < unSafe.length; c++) {
                if (newGeoState[k][unSafe[c]]) {
                    delete newGeoState[k][unSafe[c]];
                }
            }
        })
    
        setPatientsState({...newGeoState})
        setShowCalculator(false);
    } else {
        setWarning(true);
    }
  }

  const handleCalculator = (e, type) => {
    e.preventDefault();
    if (type === "incident") {
        setCalculatorType2(e.target.value);
        setInputState2(0);
    } else {
        setCalculatorType(e.target.value);
        // setGeoState(initGeoState);
        setInputState(0);
    }
  }

  const handleAlteredGeo = (e) => {
    setAlteredGeo(e.target.value);
    setCalculatorType(geoState[e.target.value].primary)
    setInputState(0);
  }

  useEffect(() => {
    console.log(geoState)
  }, [geoState])

  return (
    <div className='patient-calculator-outer'>
        <div className='flex-row p-c-t-d'>
            <h4 className='p-calc-title'>Patient Calculator</h4>
            <img id="calc-icon-x" onClick={() => setShowCalculator(false)} className='container-icon' src={closeIcon} alt={"close calculator"}></img>
        </div>
        {diseaseExcerpts.length > 0 &&
            <React.Fragment>
                <div className='disease-excerpts-container'>
                    <h5 id="interested">Related Diseases</h5>
                    {diseaseExcerpts.map((data, index) => {
                        return <DiseaseExcerpt key={`d-excerpt${index}`} excerpt={data.item}/>
                    })}
                </div>
                <div className='modal-hr'></div>
            </React.Fragment>
        }
        {workingForecast.type === "drug" ?
        <CalculatorInner inputState={inputState} workingForecast={workingForecast} calculatorType={calculatorType} setAlterAllGeo={setAlterAllGeo} handleCalculator={handleCalculator} alterAllGeo={alterAllGeo} workingGeographies={workingGeographies} handleAlteredGeo={handleAlteredGeo} setInputState={setInputState} geoState={geoState} alteredGeo={alteredGeo}/>
        :
        <React.Fragment>
            <CalculatorInner inputState={inputState} workingForecast={workingForecast} type="prevalent" calculatorType={calculatorType} setAlterAllGeo={setAlterAllGeo} handleCalculator={handleCalculator} alterAllGeo={alterAllGeo} workingGeographies={workingGeographies} handleAlteredGeo={handleAlteredGeo} setInputState={setInputState} geoState={geoState} alteredGeo={alteredGeo}/>
            <CalculatorInner inputState={inputState2} workingForecast={workingForecast} isGeneCalc2={true} type="incident" calculatorType={calculatorType2} setAlterAllGeo={setAlterAllGeo} handleCalculator={(e) => handleCalculator(e, "incident")} alterAllGeo={alterAllGeo} workingGeographies={workingGeographies} handleAlteredGeo={handleAlteredGeo} setInputState={setInputState2} geoState={geoState} alteredGeo={alteredGeo}/>
        </React.Fragment>
        }

        <button id="explicit-width-input" className='input-action-btn general-btn' onClick={handleSubmit}>SUBMIT</button>
        {warning && <WarningPopupSmall message={"Patients should not be zero."}/>}

    </div>
  )
}

const CalculatorInner = ({inputState, workingForecast, isGeneCalc2, type, calculatorType, setAlterAllGeo, handleCalculator, alterAllGeo, workingGeographies, alteredGeo, handleAlteredGeo, setInputState, geoState}) => {
    const displayText = type === "incident" ? "Incident Population" : type === "prevalent" ? "Prevalent Population" : null;
    return (
        <React.Fragment>
            <div className='p-calc-type'>
            <div className='calc-by-div flex-row'>
                <h5 id="calculate-text" className='input-header'>Calculate {displayText}:</h5>
                <select id="calculate-selector" value={calculatorType} onChange={handleCalculator}>
                    {type !== "incident" &&<option className='input-option-standard' value="prevalence">Prevalence Ratio</option> }
                    {type !== "prevalent" && <option className='input-option-standard' value="incidence">Yearly Incidence</option> }
                    {type !== "prevalent" && <option className='input-option-standard' value="birthsIncidence">Incidence in Yearly Births</option> }
                    <option className='input-option-standard' value="population">Patient Population</option>
                </select>
            </div>
        </div>
        {calculatorType !== "population" ?
            <React.Fragment>
                {workingGeographies.length > 1 && alterAllGeo === true &&
                    <div className='alter-geos-div'>
                        <label id="alter-geo-label" htmlFor="alter-geos">Affect all geographies</label>
                        <input type="checkbox" id="alter-geos" checked={alterAllGeo} onChange={() => setAlterAllGeo(!alterAllGeo)} value={alterAllGeo} />
                    </div>
                }
                {workingGeographies.length > 1 && alterAllGeo === false &&
                    <React.Fragment>
                    <div className='alter-geos-div'>
                        <label id="alter-geo-label" htmlFor="alter-geos">Affect all geographies</label>
                        <input type="checkbox" id="alter-geos" onChange={() => setAlterAllGeo(!alterAllGeo)} value={alterAllGeo} />
                    </div>
                    <div className='calc-by-div flex-row'>
                        <h5 id="geography-text" className='input-header'>Geography: </h5>
                        <select id="calculate-selector" value={alteredGeo} onChange={handleAlteredGeo}>
                            {workingGeographies.map(geo => {
                                return <option key={`alter-geo-input${geo}`} className='input-option-standard' value={geo}>{geo}</option>
                            })}
                        </select>
                    </div>
                    </React.Fragment> 
                }
            </React.Fragment>
            :
            <React.Fragment>
                {workingGeographies.length > 1 && Object.keys(geoState).length !== 0 &&
                    <div className='calc-by-div flex-row'>
                        <h5 id="geography-text" className='input-header'>Geography: </h5>
                        <select id="calculate-selector" value={alteredGeo} onChange={handleAlteredGeo}>
                            {workingGeographies.map(geo => {
                                return <option key={`alter-geo-input${geo}`} className='input-option-standard' value={geo}>{geo}</option>
                            })}
                        </select>
                    </div>
                }
            </React.Fragment>
        }
        {Object.keys(geoState).length !== 0 &&
            <div className='p-calc-main'>            
                {calculatorType === "prevalence" &&
                    <PrevalenceCalc isGeneCalc2={isGeneCalc2} setInputState={setInputState} initialVals={geoState[alteredGeo]} />
                }
                {calculatorType === "incidence" &&
                    <IncidenceCalc isGeneCalc2={isGeneCalc2} setInputState={setInputState} initialVals={geoState[alteredGeo]} />
                }
                {calculatorType === "birthsIncidence" &&
                    <PerBirthsCalc isGeneCalc2={isGeneCalc2} setInputState={setInputState} initialVals={geoState[alteredGeo]} />
                }
                {calculatorType === "population" &&
                    <PopulationCalc inputState={inputState} isGeneCalc2={isGeneCalc2} setInputState={setInputState} initialVals={workingForecast.type === "drug" ? geoState[alteredGeo].patients : isGeneCalc2 ? geoState[alteredGeo].prevalentPatients : geoState[alteredGeo].incidentPatients} />
                }
            </div>
        }
        {!type && <div className='modal-hr'></div>}
        {Object.keys(geoState).length !== 0 &&
            <div className='p-calc-geo'>
                <h4 id="patients-p-c-text">{type === "incident" ? "Incident" : type === "prevalent" ? "Prevalent" : null} Patients</h4>
                {workingGeographies.map((item, index) => {
                    let typeOfPatients = type === "incident" ? geoState[item].incidentPatients : type === "prevalent" ? geoState[item].prevalentPatients : geoState[item].patients;
                    return <h4 key={`p-calc${index}-geo`} className='p-calc-geo-name'>{item}: <span className='p-calc-geo-val'>{isNaN(typeOfPatients) ? 0 : toCommaSeperatedString(typeOfPatients)}</span></h4>
                })}
            </div>
        }
        {type && <div className='modal-hr'></div>}

        </React.Fragment>
    )
  }

const PopulationCalc = ({setInputState, initialVals, inputState}) => {
    return (
        <React.Fragment>
            <h5 className='input-header'>Patient Population</h5>
            <input className='text-input-standard text-left' type="number" onChange={(e) => setInputState(e.target.value)} value={inputState}></input>
        </React.Fragment>
    )
}

const PrevalenceCalc = ({setInputState, initialVals, isGeneCalc2}) => {
    let leader = isGeneCalc2 ? "prevalence2" : "prevalence";
    const initVals = useMemo(() => {
        return initialVals[leader] ? initialVals[leader] : {ratio: {numerator: 1, denominator: 10000}, decimal: .0001}
    }, [initialVals])
    const [prevalenceRatio, setPrevalenceRatio] = useState({...initVals.ratio});
    const [prevalenceDecimal, setPrevalenceDecimal] = useState(initVals.decimal);

    useEffect(() => {
        setPrevalenceDecimal(initVals.decimal)
        setPrevalenceRatio({...initVals.ratio})
    }, [initVals])

    useEffect(() => {
        if(prevalenceDecimal <= 1 && prevalenceDecimal >= 0) {
            setInputState([parseFloat(prevalenceDecimal), prevalenceRatio])
        } 
    }, [prevalenceDecimal, prevalenceRatio, setInputState])

    const handlePrevalenceRatio = (e, target) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        if (target === 'numerator') {
            setPrevalenceRatio({...prevalenceRatio, numerator: thisVal})
            if (thisVal !== "" && thisVal <= prevalenceRatio.denominator) {
                let pre = (thisVal / prevalenceRatio.denominator);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else if (target === 'denominator') {
            setPrevalenceRatio({...prevalenceRatio, denominator: thisVal})
            if (thisVal !== "" && thisVal >= prevalenceRatio.numerator) {
                let pre = (prevalenceRatio.numerator / thisVal);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else {
            console.warn("sus code")
        }
    }

      const handlePrevalenceDecimal = (e) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        setPrevalenceDecimal(thisVal);
        if (thisVal !== "" && (thisVal <= 1 && thisVal >= 0)) {
            let decimals = countDecimals(thisVal);
            let denValue = 1 * (10 ** decimals)
            let numValue = Math.round(thisVal * (10 ** decimals));  // Rounded here
            setPrevalenceRatio({numerator: numValue, denominator: denValue})
        }
      }

    return (
        <React.Fragment>
            <h5 className='input-header'>Prevalence in Total Population</h5>
            <div className='flex-row quarter-div-inputs'>
                <input id="numerator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "numerator")} value={prevalenceRatio.numerator}></input>
                <p id="ratio-slash">/</p>
                <input id="denominator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "denominator")} value={prevalenceRatio.denominator}></input>
            </div>
            <h5 id="or-text" className='input-header'>or</h5>
            <input id="prev-deci" className='text-input-standard text-left' type="number" max={1}  onChange={handlePrevalenceDecimal} value={prevalenceDecimal}></input>
        </React.Fragment>
    )
}

const PerBirthsCalc = ({setInputState, initialVals, isGeneCalc2}) => {
    let leader = isGeneCalc2 ? "birthsIncidence2" : "birthsIncidence";

    const initVals = useMemo(() => {
        return initialVals[leader] ? initialVals[leader] : {ratio: {numerator: 1, denominator: 1000}, decimal: .001, lifeExpectancy: 50};
    }, [initialVals])

    const [prevalenceRatio, setPrevalenceRatio] = useState({...initVals.ratio});
    const [prevalenceDecimal, setPrevalenceDecimal] = useState(initVals.decimal);
    const [lifeExpectancy, setLifeExpectancy] = useState(initVals.lifeExpectancy);

    useEffect(() => {
        setPrevalenceDecimal(initVals.decimal)
        setPrevalenceRatio({...initVals.ratio})
        setLifeExpectancy(initVals.lifeExpectancy)
    }, [initVals])

    useEffect(() => {
        if(prevalenceDecimal <= 1 && prevalenceDecimal >= 0) {
            if (lifeExpectancy > 0) {
                setInputState([parseFloat(prevalenceDecimal), prevalenceRatio, lifeExpectancy])
            }
            // setNeedsAlert((prevAlert) => ({...prevAlert, prevalence: false}))
        } 
    }, [prevalenceDecimal, lifeExpectancy, setInputState, prevalenceRatio])

    const handlePrevalenceRatio = (e, target) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        if (target === 'numerator') {
            setPrevalenceRatio({...prevalenceRatio, numerator: thisVal})
            if (thisVal !== "" && thisVal <= prevalenceRatio.denominator) {
                let pre = (thisVal / prevalenceRatio.denominator);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else if (target === 'denominator') {
            setPrevalenceRatio({...prevalenceRatio, denominator: thisVal})
            if (thisVal !== "" && thisVal >= prevalenceRatio.numerator) {
                let pre = (prevalenceRatio.numerator / thisVal);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else {
            console.warn("sus code")
        }
    }

      const handlePrevalenceDecimal = (e) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        setPrevalenceDecimal(thisVal);
        if (thisVal !== "" && (thisVal <= 1 && thisVal >= 0)) {
            let decimals = countDecimals(thisVal);
            let denValue = 1 * (10 ** decimals)
            let numValue = Math.round(thisVal * (10 ** decimals));  // Rounded here
            setPrevalenceRatio({numerator: numValue, denominator: denValue})
        }
      }

    return (
        <React.Fragment>
            <h5 className='input-header'>Incidence in Live Births Per Year</h5>
            <div className='flex-row quarter-div-inputs'>
                <input id="numerator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "numerator")} value={prevalenceRatio.numerator}></input>
                <h5 id="ratio-slash">/</h5>
                <input id="denominator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "denominator")} value={prevalenceRatio.denominator}></input>
            </div>
            <h5 id="or-text" className='input-header'>or</h5>
            <input className='text-input-standard text-left' type="number" max={1}  onChange={handlePrevalenceDecimal} value={prevalenceDecimal}></input>
            <h5 className='input-header'>Life Expectancy ( Years )</h5>
            <input className='text-input-standard text-left' type="number" max={1}  onChange={(e) => setLifeExpectancy(e.target.value)} value={lifeExpectancy}></input>
        </React.Fragment>
    )
}

const IncidenceCalc = ({setInputState, initialVals, isGeneCalc2}) => {

    let leader = isGeneCalc2 ? "incidence2" : "incidence";

    const initVals = useMemo(() => {
        // console.log(initialVals, leader)
        return initialVals[leader] ? initialVals[leader] : {ratio: {numerator: 1, denominator: 10000}, decimal: .0001, lifeExpectancy: 50};
    }, [initialVals])

    const [prevalenceRatio, setPrevalenceRatio] = useState({...initVals.ratio});
    const [prevalenceDecimal, setPrevalenceDecimal] = useState(initVals.decimal);
    const [lifeExpectancy, setLifeExpectancy] = useState(initVals.lifeExpectancy);

    useEffect(() => {
        setPrevalenceDecimal(initVals.decimal)
        setPrevalenceRatio({...initVals.ratio})
        setLifeExpectancy(initVals.lifeExpectancy)
    }, [initVals])

    useEffect(() => {
        if(prevalenceDecimal <= 1 && prevalenceDecimal >= 0) {
            if (lifeExpectancy > 0) {
                setInputState([parseFloat(prevalenceDecimal), prevalenceRatio, lifeExpectancy])
            }
            // setNeedsAlert((prevAlert) => ({...prevAlert, prevalence: false}))
        } 
    }, [prevalenceDecimal, lifeExpectancy, setInputState, prevalenceRatio])

    const handlePrevalenceRatio = (e, target) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        if (target === 'numerator') {
            setPrevalenceRatio({...prevalenceRatio, numerator: thisVal})
            if (thisVal !== "" && thisVal <= prevalenceRatio.denominator) {
                let pre = (thisVal / prevalenceRatio.denominator);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else if (target === 'denominator') {
            setPrevalenceRatio({...prevalenceRatio, denominator: thisVal})
            if (thisVal !== "" && thisVal >= prevalenceRatio.numerator) {
                let pre = (prevalenceRatio.numerator / thisVal);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else {
            console.warn("sus code")
        }
    }

      const handlePrevalenceDecimal = (e) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        setPrevalenceDecimal(thisVal);
        if (thisVal !== "" && (thisVal <= 1 && thisVal >= 0)) {
            let decimals = countDecimals(thisVal);
            let denValue = 1 * (10 ** decimals)
            let numValue = Math.round(thisVal * (10 ** decimals));  // Rounded here
            setPrevalenceRatio({numerator: numValue, denominator: denValue})
        }
      }

    return (
        <React.Fragment>
            <h5 className='input-header'>Yearly Incidence</h5>
            <div className='flex-row quarter-div-inputs'>
                <input id="numerator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "numerator")} value={prevalenceRatio.numerator}></input>
                <h5 id="ratio-slash">/</h5>
                <input id="denominator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "denominator")} value={prevalenceRatio.denominator}></input>
            </div>
            <h5 id="or-text" className='input-header'>or</h5>
            <input className='text-input-standard text-left' type="number" max={1}  onChange={handlePrevalenceDecimal} value={prevalenceDecimal}></input>
            <h5 className='input-header'>Life Expectancy ( Years )</h5>
            <input className='text-input-standard text-left' type="number" max={1}  onChange={(e) => setLifeExpectancy(e.target.value)} value={lifeExpectancy}></input>
        </React.Fragment>
    )
}

export default PatientCalculator