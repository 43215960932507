import React from 'react'
import { useState, useRef, useEffect } from 'react'

import WarningPopupSmall from '../../../util/WarningPopupSmall'
import CollapsingContainer from '../../../util/CollapsingContainer'
import DateFields from './DateFields'

const InputsDrug = ({workingGeographies, workingForecast, engagedSection, setEngagedSection, graphPeriod, setWorkingForecast}) => {

  const [inputState, setInputState] = useState({...workingForecast.geographiesData[workingGeographies[0]].drug});
  const [needsAlert, setNeedsAlert] = useState({price: false, preLOE: false, postLOE: false, LOE: false, dateOfLOE: false, route: false, duration: false, cogs: false});
  const [isFocused, setIsFocused] = useState({price: "never", preLOE: "never", postLOE: "never", LOE: "never", dateOfLOE: "never", route: "never", duration: "never", cogs: "never"});

  useEffect(() => {
    setInputState({...workingForecast.geographiesData[workingGeographies[0]].drug});
  }, [workingForecast.drug, workingForecast.geographiesData, workingGeographies]);
  // REFS AND USEEFFECT
  // Used to keep track of an elements focus, 
  // When focused and then unfocused if error handling is passed then graph will update.

  const priceRef = useRef(isFocused.price);
  const preLOERef = useRef(isFocused.preLOE);
  const postLOERef = useRef(isFocused.postLOE);
  const LOERef = useRef(isFocused.LOE)
  const dateOfLOERef = useRef(isFocused.dateOfLOE);
  const cogsRef = useRef(isFocused.cogs);


//   useEffect(() => {
//     // price
//     if (isFocused.price === "no" && priceRef.current === "yes") {
//         if (inputState.price >= 0) {
//             setWorkingForecast(prevForecast => {
//                 let copyF = {...prevForecast};
//                 let copyG = {...copyF.geographiesData}
//                 workingGeographies.forEach(geo => {
//                     copyG[geo].drug = {...copyG[geo].drug, cogs: inputState.cogs};
//                 })
//                 return {...copyF, geographiesData: {...copyG}};
//             })
//             setNeedsAlert((prevAlert) => ({...prevAlert, price: false}))
//         } else {
//             setNeedsAlert((prevAlert) => ({...prevAlert, price: true}))
//         }
//     }
//     priceRef.current = isFocused.price;

//   }, [inputState.price, isFocused.price, setWorkingForecast])

  useEffect(() => {
    // preLOE
    if (isFocused.preLOE === "no" && preLOERef.current === "yes") {
        if (inputState.preLOE >= -100 && inputState.preLOE <= 100) {
            setWorkingForecast(prevForecast => {
                let copyF = {...prevForecast};
                let copyG = {...copyF.geographiesData}
                workingGeographies.forEach(geo => {
                    copyG[geo].drug = {...copyG[geo].drug, preLOE: inputState.preLOE};
                })
                return {...copyF, geographiesData: {...copyG}};
            })
            setNeedsAlert((prevAlert) => ({...prevAlert, preLOE: false}))
        } else {
            setNeedsAlert((prevAlert) => ({...prevAlert, preLOE: true}))
        }
    }
    preLOERef.current = isFocused.preLOE;

  }, [inputState.preLOE, isFocused.preLOE, setWorkingForecast, workingGeographies])

  useEffect(() => {
    // postLOE
    if (isFocused.postLOE === "no" && postLOERef.current === "yes") {
        if (inputState.postLOE >= -100 && inputState.postLOE <= 100) {
            setWorkingForecast(prevForecast => {
                let copyF = {...prevForecast};
                let copyG = {...copyF.geographiesData}
                workingGeographies.forEach(geo => {
                    copyG[geo].drug = {...copyG[geo].drug, postLOE: inputState.postLOE};
                })
                return {...copyF, geographiesData: {...copyG}};
            })
            setNeedsAlert((prevAlert) => ({...prevAlert, postLOE: false}))
        } else {
            setNeedsAlert((prevAlert) => ({...prevAlert, postLOE: true}))
        }
    }
    postLOERef.current = isFocused.postLOE;

  }, [inputState.postLOE, isFocused.postLOE, setWorkingForecast, workingGeographies])

  useEffect(() => {
    // LOE
    if (isFocused.LOE === "no" && LOERef.current === "yes") {
        if (inputState.LOE >= -100 && inputState.LOE <= 100) {
            setWorkingForecast(prevForecast => {
                let copyF = {...prevForecast};
                let copyG = {...copyF.geographiesData}
                workingGeographies.forEach(geo => {
                    copyG[geo].drug = {...copyG[geo].drug, LOE: inputState.LOE};
                })
                return {...copyF, geographiesData: {...copyG}};
            })
            setNeedsAlert((prevAlert) => ({...prevAlert, LOE: false}))
        } else {
            setNeedsAlert((prevAlert) => ({...prevAlert, LOE: true}))
        }
    }
    LOERef.current = isFocused.LOE;

  }, [inputState.LOE, isFocused.LOE, setWorkingForecast, workingGeographies])

//   useEffect(() => {
//     // dateOfLOE
//     if (isFocused.dateOfLOE === "no" && dateOfLOERef.current === "yes") {
//         if (dateHandling(graphPeriod, inputState.dateOfLOE)) {
//             setWorkingForecast(prevForecast => {
//                 let newState = {...prevForecast};
//                 newState.drug.dateOfLOE = inputState.dateOfLOE
//                 return {...newState}
//             })
//             setNeedsAlert((prevAlert) => ({...prevAlert, dateOfLOE: false}))
//         } else {
//             setNeedsAlert((prevAlert) => ({...prevAlert, dateOfLOE: true}))
//         }
//     }
//     dateOfLOERef.current = isFocused.dateOfLOE;

//   }, [inputState.dateOfLOE, isFocused.dateOfLOE, setWorkingForecast, graphPeriod])

  useEffect(() => {
    // route
        if (inputState.route === "oral" ||
            inputState.route === "intravenous" ||
            inputState.route === "intramuscular" ||
            inputState.route === "subcutaneous" ||
            inputState.route === "intrathecal" ||
            inputState.route === "other") {
            setWorkingForecast(prevForecast => {
                let copyF = {...prevForecast};
                let copyG = {...copyF.geographiesData}
                workingGeographies.forEach(geo => {
                    copyG[geo].drug = {...copyG[geo].drug, route: inputState.route};
                })
                return {...copyF, geographiesData: {...copyG}};
            })
            setNeedsAlert((prevAlert) => ({...prevAlert, route: false}))
        } else {
            setNeedsAlert((prevAlert) => ({...prevAlert, route: true}))
        }

  }, [inputState.route, setWorkingForecast, workingGeographies])

  useEffect(() => {
    // duration
        if (inputState.duration === "chronic" ||
            inputState.duration === "acute" ||
            inputState.duration === "short" ||
            inputState.duration === "intermediate") {
                setWorkingForecast(prevForecast => {
                    let copyF = {...prevForecast};
                    let copyG = {...copyF.geographiesData}
                    workingGeographies.forEach(geo => {
                        copyG[geo].drug = {...copyG[geo].drug, duration: inputState.duration};
                    })
                    return {...copyF, geographiesData: {...copyG}};
                })
            setNeedsAlert((prevAlert) => ({...prevAlert, duration: false}))
        } else {
            setNeedsAlert((prevAlert) => ({...prevAlert, duration: true}))
        }

  }, [inputState.duration, setWorkingForecast, workingGeographies])

  useEffect(() => {
    // cogs
    if (isFocused.cogs === "no" && cogsRef.current === "yes") {

        if (inputState.cogs >= 0 && inputState.cogs <= 100) {
            setWorkingForecast(prevForecast => {
                let copyF = {...prevForecast};
                let copyG = {...copyF.geographiesData}
                workingGeographies.forEach(geo => {
                    copyG[geo].drug = {...copyG[geo].drug, cogs: inputState.cogs};
                })
                return {...copyF, geographiesData: {...copyG}};
            })
            setNeedsAlert((prevAlert) => ({...prevAlert, cogs: false}))
        } else {
            setNeedsAlert((prevAlert) => ({...prevAlert, cogs: true}))
        }
    }
    cogsRef.current = isFocused.cogs;

  }, [inputState.cogs, isFocused.cogs, setWorkingForecast, workingGeographies])

  const handleFocus = (type) => {
    setIsFocused({...isFocused, [type]: "yes"});
    setNeedsAlert((prev) => ({...prev, [type]: false}));
  }

  const handleBlur = (type) => {
    setIsFocused({...isFocused, [type]: "no"});
  }

  const updateInputs = (e, target) => {
    let value = e.target.value
    if (e.target.value.length > 0) {
        if (target === 'price' || target === 'preLOE' || target === 'postLOE' || target === 'LOE') {
            value = parseFloat(e.target.value) // Convert to number if the target is one of the numeric fields
        }
    }
    setInputState({...inputState, [target]: value});
  }

//   const pricePopup = <span><WarningPopupSmall message={"Please input a valid price."}/></span>
  const preLOEPopup = <span><WarningPopupSmall message={"Please input a value ( -100 to 100 )"}/></span>
  const postLOEPopup = <span><WarningPopupSmall message={"Please input a value ( -100 to 100 )"}/></span>
  const LOEPopup = <span><WarningPopupSmall message={"Please input a value ( -100 to 100 )"}/></span>
  const routePopup = <span><WarningPopupSmall message={"Please input a valid route."}/></span>
  const durationPopup = <span><WarningPopupSmall message={"Please input a valid duration."}/></span>
  const cogsPopup = <span><WarningPopupSmall message={"Please enter a value ( 0 - 100 )"}/></span>

  const data = (
    <div className='pad-horz-20'>
        {/* <div className='input-and-label-standard'>
            <h5 className='input-header'>WAC / Price</h5>
            {needsAlert.price && pricePopup}
            <input className='text-input-standard text-left' min={0} type="number" onBlur={() => handleBlur("price")} onFocus={() => handleFocus("price")} onChange={(e) => updateInputs(e, "price")} value={inputState.price}></input>
        </div> */}

        <div className='input-and-label-standard'>
            <h5 className='input-header'>Cost of Goods Sold ( % )</h5>
            {needsAlert.cogs && cogsPopup}
            <input className='text-input-standard text-left' type="number" min={0} max={100} onBlur={() => handleBlur("cogs")} onFocus={() => handleFocus("cogs")} onChange={(e) => updateInputs(e, "cogs")} value={inputState.cogs}></input>
        </div>
        <div className='input-and-label-standard'>
            <h5 className='input-header'>Change in Price Year Over Year (pre-LOE) ( % )</h5>
            {needsAlert.preLOE && preLOEPopup}
            <input className='text-input-standard text-left' min={-100} max={100} type="number" onBlur={() => handleBlur("preLOE")} onFocus={() => handleFocus("preLOE")} onChange={(e) => updateInputs(e, "preLOE")} value={inputState.preLOE}></input>
        </div>

        <DateFields section="dateOfLOE" workingGeographies={workingGeographies} graphPeriod={graphPeriod} workingForecast={workingForecast} setWorkingForecast={setWorkingForecast} />

        <div className='input-and-label-standard'>
            <h5 className='input-header'>Change in Price at LOE ( % )</h5>
            {needsAlert.LOE && LOEPopup}
            <input className='text-input-standard text-left' min={-100} max={100} type="number" onBlur={() => handleBlur("LOE")} onFocus={() => handleFocus("LOE")} onChange={(e) => updateInputs(e, "LOE")} value={inputState.LOE}></input>
        </div>
        <div className='input-and-label-standard'>
            <h5 className='input-header'>Change in Price Year Over Year (post-LOE) ( % )</h5>
            {needsAlert.postLOE && postLOEPopup}
            <input className='text-input-standard text-left' min={-100} max={100} type="number" onBlur={() => handleBlur("postLOE")} onFocus={() => handleFocus("postLOE")} onChange={(e) => updateInputs(e, "postLOE")} value={inputState.postLOE}></input>
        </div>
        {/* TBD and needs work
        <div className='input-and-label-standard'>
            <h5 className='input-header'>Exclusivity Types</h5>
            <input className='text-input-standard text-left' type="text" onBlur={() => handleBlur("exclusivity")} onFocus={() => handleFocus("exclusivity")} onChange={(e) => updateInputs(e, "exclusivity")} value={inputState.exclusivity}></input>
        </div>
        */}
        <div className='input-and-label-standard'>
            <h5 className='input-header'>Route of Administration</h5>
            {needsAlert.route && routePopup}
            <select className='text-input-select text-left' value={inputState.route} onBlur={() => handleBlur("route")} onFocus={() => handleFocus("route")} onChange={(e) => updateInputs(e, "route")}>
                <option className='input-option-standard' value="oral">Oral</option>
                <option className='input-option-standard' value="intravenous">Intravenous</option>
                <option className='input-option-standard' value="subcutaneous">Subcutaneous</option>
                <option className='input-option-standard' value="intramuscular">Intramuscular</option>
                <option className='input-option-standard' value="intrathecal">Intrathecal</option>
                <option className='input-option-standard' value="other">Other</option>
            </select>
        </div>

        {/* TBD and needs work
        <div className='input-and-label-standard'>
            <h5 className='input-header'>Efficacy</h5>
            <input className='text-input-standard text-left' type="text" onBlur={() => handleBlur("efficacy")} onFocus={() => handleFocus("efficacy")} onChange={(e) => updateInputs(e, "efficacy")} value={inputState.efficacy}></input>
        </div> 
        */}

        <div className='input-and-label-standard'>
            <h5 className='input-header'>Duration of Use</h5>
            {needsAlert.duration && durationPopup}
            <select className='text-input-select text-left' value={inputState.duration} onChange={(e) => updateInputs(e, "duration")}>
                <option className='input-option-standard' value="chronic">Chronic</option>
                <option className='input-option-standard' value="acute">Acute</option>
                <option className='input-option-standard' value="short">Short Term</option>
                <option className='input-option-standard' value="intermediate">Intermediate</option>
            </select>
        </div>
    </div>
  )

  return (
    <CollapsingContainer containerName="Drug / Sales" data={data} engagedSection={engagedSection} setEngagedSection={setEngagedSection}/>
  )
}

export default InputsDrug