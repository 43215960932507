const COMPANY_DATA = [
    {
        "companyName": "Acura Pharmaceuticals, Inc.",
        "companyTicker": "ACUR",
        "companyURL": "http://www.acurapharm.com/",
        "companyPipelineURL": "http://acurapharm.com/productpipeline/#"
    },
    {
        "companyName": "Eledon Pharmaceuticals, Inc.",
        "companyTicker": "ELDN",
        "companyURL": "http://eledon.com/",
        "companyPipelineURL": "http://eledon.com/research-and-development/pipeline"
    },
    {
        "companyName": "Imunon, Inc.",
        "companyTicker": "IMNN",
        "companyURL": "http://imunon.com/",
        "companyPipelineURL": "http://imunon.com/pipeline/"
    },
    {
        "companyName": "Indaptus Therapeutics, Inc.",
        "companyTicker": "INDP",
        "companyURL": "http://indaptusrx.com/",
        "companyPipelineURL": "http://indaptusrx.com/science-pipeline/"
    },
    {
        "companyName": "Larimar Therapeutics, Inc.",
        "companyTicker": "LRMR",
        "companyURL": "http://larimartx.com/",
        "companyPipelineURL": "http://larimartx.com/our-programs/pipeline"
    },
    {
        "companyName": "Oculis Holding AG",
        "companyTicker": "OCS",
        "companyURL": "http://oculis.com/",
        "companyPipelineURL": "http://oculis.com/pipeline/overview"
    },
    {
        "companyName": "Sorrento Therapeutics, Inc.",
        "companyTicker": "SRNEQ",
        "companyURL": "http://sorrentotherapeutics.com/",
        "companyPipelineURL": "http://sorrentotherapeutics.com/research/pipeline"
    },
    {
        "companyName": "Third Harmonic Bio, Inc.",
        "companyTicker": "THRD",
        "companyURL": "http://thirdharmonicbio.com/",
        "companyPipelineURL": "http://thirdharmonicbio.com/programs/"
    },
    {
        "companyName": "Turnstone Biologics Corp.",
        "companyTicker": "TSBX",
        "companyURL": "http://turnstonebio.com/",
        "companyPipelineURL": "http://turnstonebio.com/#pipeline"
    },
    {
        "companyName": "180 Life Sciences Corp.",
        "companyTicker": "ATNF",
        "companyURL": "http://www.180lifesciences.com/",
        "companyPipelineURL": "http://www.180lifesciences.com/pipeline"
    },
    {
        "companyName": "2seventy bio, Inc.",
        "companyTicker": "TSVT",
        "companyURL": "http://www.2seventybio.com/",
        "companyPipelineURL": "http://www.2seventybio.com/our-science"
    },
    {
        "companyName": "Addex Therapeutics Ltd",
        "companyTicker": "ADXN",
        "companyURL": "http://www.addextherapeutics.com/",
        "companyPipelineURL": "http://www.addextherapeutics.com/en/pipeline/clinical-pipeline/"
    },
    {
        "companyName": "Appili Therapeutics Inc.",
        "companyTicker": "APLIF",
        "companyURL": "http://www.appilitherapeutics.com/",
        "companyPipelineURL": "https://appilitherapeutics.com/pipeline/"
    },
    {
        "companyName": "Manuka, Inc.",
        "companyTicker": "MNKA",
        "companyURL": "http://www.artemis-therapeutics.com/",
        "companyPipelineURL": "http://www.artemis-therapeutics.com/research-development/"
    },
    {
        "companyName": "Biophytis SA",
        "companyTicker": "BPTS",
        "companyURL": "http://www.biophytis.com/",
        "companyPipelineURL": "https://www.biophytis.com/en/pipeline/"
    },
    {
        "companyName": "Blue Water Biotech, Inc.",
        "companyTicker": "BWV",
        "companyURL": "http://www.bluewatervaccines.com/",
        "companyPipelineURL": "http://www.bluewatervaccines.com/pipeline"
    },
    {
        "companyName": "Ceapro Inc.",
        "companyTicker": "CRPOF",
        "companyURL": "http://www.ceapro.com/",
        "companyPipelineURL": "https://www.ceapro.com/pipeline/overview"
    },
    {
        "companyName": "Century Therapeutics, Inc.",
        "companyTicker": "IPSC",
        "companyURL": "http://www.centurytx.com/",
        "companyPipelineURL": "https://www.centurytx.com/pipeline/"
    },
    {
        "companyName": "EyePoint Pharmaceuticals, Inc.",
        "companyTicker": "EYPT",
        "companyURL": "http://www.eyepointpharma.com/",
        "companyPipelineURL": "https://eyepointpharma.com/pipeline/"
    },
    {
        "companyName": "Inhibrx, Inc.",
        "companyTicker": "INBX",
        "companyURL": "http://www.inhibrx.com/",
        "companyPipelineURL": "https://inhibrx.com/pipeline/"
    },
    {
        "companyName": "Innovation Pharmaceuticals Inc.",
        "companyTicker": "IPIX",
        "companyURL": "http://www.ipharminc.com/",
        "companyPipelineURL": "http://www.ipharminc.com/stages-of-development"
    },
    {
        "companyName": "Keros Therapeutics, Inc.",
        "companyTicker": "KROS",
        "companyURL": "http://www.kerostx.com/",
        "companyPipelineURL": "https://kerostx.com/pipeline/"
    },
    {
        "companyName": "NeuroBo Pharmaceuticals, Inc.",
        "companyTicker": "NRBO",
        "companyURL": "http://www.neurobopharma.com/",
        "companyPipelineURL": "http://www.neurobopharma.com/clinical-pipeline-technology"
    },
    {
        "companyName": "XBiotech Inc.",
        "companyTicker": "XBIT",
        "companyURL": "http://www.xbiotech.com/",
        "companyPipelineURL": "http://www.xbiotech.com/"
    },
    {
        "companyName": "Zynerba Pharmaceuticals, Inc.",
        "companyTicker": "ZYNE",
        "companyURL": "http://www.zynerba.com/",
        "companyPipelineURL": "https://www.zynerba.com/in-development/"
    },
    {
        "companyName": "4D Molecular Therapeutics, Inc.",
        "companyTicker": "FDMT",
        "companyURL": "http://www.4dmoleculartherapeutics.com/",
        "companyPipelineURL": "https://4dmoleculartherapeutics.com/pipeline/"
    },
    {
        "companyName": "Aadi Bioscience, Inc.",
        "companyTicker": "AADI",
        "companyURL": "http://www.aadibio.com/",
        "companyPipelineURL": "https://aadibio.com/pipeline-clinical-studies/"
    },
    {
        "companyName": "BETTERLIFE PHARMA INC.",
        "companyTicker": "BETRF",
        "companyURL": "http://www.abetterlifepharma.com/",
        "companyPipelineURL": "https://abetterlifepharma.com/pipeline/"
    },
    {
        "companyName": "ABVC BioPharma, Inc.",
        "companyTicker": "ABVC",
        "companyURL": "http://www.abvcpharma.com/",
        "companyPipelineURL": "https://abvcpharma.com/?page_id=16692"
    },
    {
        "companyName": "ACADIA Pharmaceuticals Inc.",
        "companyTicker": "ACAD",
        "companyURL": "https://acadia.com/",
        "companyPipelineURL": "https://acadia.com/pipeline/"
    },
    {
        "companyName": "ACELYRIN, INC.",
        "companyTicker": "SLRN",
        "companyURL": "http://www.acelyrin.com/",
        "companyPipelineURL": "https://acelyrin.com/pipeline"
    },
    {
        "companyName": "Achieve Life Sciences, Inc.",
        "companyTicker": "ACHV",
        "companyURL": "http://www.achievelifesciences.com/",
        "companyPipelineURL": "https://achievelifesciences.com/products/"
    },
    {
        "companyName": "Achilles Therapeutics plc",
        "companyTicker": "ACHL",
        "companyURL": "http://www.achillestx.com/",
        "companyPipelineURL": "https://achillestx.com/pipeline"
    },
    {
        "companyName": "Acrivon Therapeutics, Inc.",
        "companyTicker": "ACRV",
        "companyURL": "http://acrivon.com/",
        "companyPipelineURL": "https://acrivon.com/pipeline/"
    },
    {
        "companyName": "Acumen Pharmaceuticals, Inc.",
        "companyTicker": "ABOS",
        "companyURL": "http://www.acumenpharm.com/",
        "companyPipelineURL": "https://acumenpharm.com/pipeline/"
    },
    {
        "companyName": "Adverum Biotechnologies, Inc.",
        "companyTicker": "ADVM",
        "companyURL": "http://www.adverum.com/",
        "companyPipelineURL": "https://adverum.com/pipeline/"
    },
    {
        "companyName": "AEON Biopharma, Inc.",
        "companyTicker": "AEON",
        "companyURL": "http://www.aeonbiopharma.com/",
        "companyPipelineURL": "https://aeonbiopharma.com/"
    },
    {
        "companyName": "Agenus Inc.",
        "companyTicker": "AGEN",
        "companyURL": "http://www.agenusbio.com/",
        "companyPipelineURL": "https://agenusbio.com/pipeline/"
    },
    {
        "companyName": "Agile Therapeutics, Inc.",
        "companyTicker": "AGRX",
        "companyURL": "http://www.agiletherapeutics.com/",
        "companyPipelineURL": "https://agiletherapeutics.com/product-pipeline/"
    },
    {
        "companyName": "Aileron Therapeutics, Inc.",
        "companyTicker": "ALRN",
        "companyURL": "http://www.aileronrx.com/",
        "companyPipelineURL": "https://aileronrx.com/our-science/"
    },
    {
        "companyName": "AIM ImmunoTech Inc.",
        "companyTicker": "AIM",
        "companyURL": "http://www.aimimmuno.com/",
        "companyPipelineURL": "https://aimimmuno.com/pipeline/"
    },
    {
        "companyName": "Akebia Therapeutics, Inc.",
        "companyTicker": "AKBA",
        "companyURL": "http://www.akebia.com/",
        "companyPipelineURL": "https://akebia.com/research-and-development/"
    },
    {
        "companyName": "Akero Therapeutics, Inc.",
        "companyTicker": "AKRO",
        "companyURL": "http://www.akerotx.com/",
        "companyPipelineURL": "https://akerotx.com/pipeline/"
    },
    {
        "companyName": "Alaunos Therapeutics, Inc.",
        "companyTicker": "TCRT",
        "companyURL": "http://www.alaunos.com/",
        "companyPipelineURL": "https://alaunos.com/pipeline/"
    },
    {
        "companyName": "Alimera Sciences, Inc.",
        "companyTicker": "ALIM",
        "companyURL": "http://www.alimerasciences.com/",
        "companyPipelineURL": "https://alimerasciences.com/products/"
    },
    {
        "companyName": "Allarity Therapeutics, Inc.",
        "companyTicker": "ALLR",
        "companyURL": "http://www.allarity.com/",
        "companyPipelineURL": "https://allarity.com/pipeline/"
    },
    {
        "companyName": "Allogene Therapeutics, Inc.",
        "companyTicker": "ALLO",
        "companyURL": "http://www.allogene.com/",
        "companyPipelineURL": "https://allogene.com/pipeline/"
    },
    {
        "companyName": "Altamira Therapeutics Ltd.",
        "companyTicker": "CYTO",
        "companyURL": "http://www.altamiratherapeutics.com/",
        "companyPipelineURL": "https://altamiratherapeutics.com/rna-programs"
    },
    {
        "companyName": "Alterity Therapeutics Limited",
        "companyTicker": "PRNAF",
        "companyURL": "http://www.alteritytherapeutics.com/",
        "companyPipelineURL": "https://alteritytherapeutics.com/pipeline/"
    },
    {
        "companyName": "Altimmune, Inc.",
        "companyTicker": "ALT",
        "companyURL": "http://www.altimmune.com/",
        "companyPipelineURL": "https://altimmune.com/pipeline/"
    },
    {
        "companyName": "Alzamend Neuro, Inc.",
        "companyTicker": "ALZN",
        "companyURL": "http://www.alzamend.com/",
        "companyPipelineURL": "https://alzamend.com/pipeline/"
    },
    {
        "companyName": "Amarin Corporation plc",
        "companyTicker": "AMRN",
        "companyURL": "http://www.amarincorp.com/",
        "companyPipelineURL": "https://amarincorp.com/science-health/our-pipeline"
    },
    {
        "companyName": "Ambrx Biopharma Inc.",
        "companyTicker": "AMAM",
        "companyURL": "http://ambrx.com/",
        "companyPipelineURL": "https://ambrx.com/pipeline/"
    },
    {
        "companyName": "Amicus Therapeutics, Inc.",
        "companyTicker": "FOLD",
        "companyURL": "http://www.amicusrx.com/",
        "companyPipelineURL": "https://amicusrx.com/programs-pipeline/pipeline/"
    },
    {
        "companyName": "Amneal Pharmaceuticals, Inc.",
        "companyTicker": "AMRX",
        "companyURL": "http://www.amneal.com/",
        "companyPipelineURL": "https://amneal.com/research-development/specialty-rd/"
    },
    {
        "companyName": "Amphastar Pharmaceuticals, Inc.",
        "companyTicker": "AMPH",
        "companyURL": "http://www.amphastar.com/",
        "companyPipelineURL": "https://amphastar.com/pipeline.html"
    },
    {
        "companyName": "Annexon, Inc.",
        "companyTicker": "ANNX",
        "companyURL": "http://www.annexonbio.com/",
        "companyPipelineURL": "https://annexonbio.com/pipeline/"
    },
    {
        "companyName": "Apellis Pharmaceuticals, Inc.",
        "companyTicker": "APLS",
        "companyURL": "http://www.apellis.com/",
        "companyPipelineURL": "https://apellis.com/our-science/our-pipeline/"
    },
    {
        "companyName": "Apogee Therapeutics, Inc.",
        "companyTicker": "APGE",
        "companyURL": "http://www.apogeetherapeutics.com/",
        "companyPipelineURL": "https://apogeetherapeutics.com/pipeline"
    },
    {
        "companyName": "Aptevo Therapeutics Inc.",
        "companyTicker": "APVO",
        "companyURL": "http://www.aptevotherapeutics.com/",
        "companyPipelineURL": "https://aptevotherapeutics.com/pipeline/"
    },
    {
        "companyName": "Aravive, Inc.",
        "companyTicker": "ARAV",
        "companyURL": "http://www.aravive.com/",
        "companyPipelineURL": "https://aravive.com/pipeline/"
    },
    {
        "companyName": "ARCA biopharma, Inc.",
        "companyTicker": "ABIO",
        "companyURL": "http://www.arcabio.com/",
        "companyPipelineURL": "https://arcabio.com/our-science/"
    },
    {
        "companyName": "Arcturus Therapeutics Holdings Inc.",
        "companyTicker": "ARCT",
        "companyURL": "http://www.arcturusrx.com/",
        "companyPipelineURL": "https://arcturusrx.com/mrna-medicines-pipeline/"
    },
    {
        "companyName": "Arcus Biosciences, Inc.",
        "companyTicker": "RCUS",
        "companyURL": "http://www.arcusbio.com/",
        "companyPipelineURL": "https://arcusbio.com/our-science/pipeline/"
    },
    {
        "companyName": "Ardelyx, Inc.",
        "companyTicker": "ARDX",
        "companyURL": "http://www.ardelyx.com/",
        "companyPipelineURL": "https://ardelyx.com/science-pipeline/"
    },
    {
        "companyName": "Arrowhead Pharmaceuticals, Inc.",
        "companyTicker": "ARWR",
        "companyURL": "http://www.arrowheadpharma.com/",
        "companyPipelineURL": "https://arrowheadpharma.com/pipeline/"
    },
    {
        "companyName": "ARS Pharmaceuticals, Inc.",
        "companyTicker": "SPRY",
        "companyURL": "http://www.ars-pharma.com/",
        "companyPipelineURL": "https://ars-pharma.com/product/"
    },
    {
        "companyName": "Artelo Biosciences, Inc.",
        "companyTicker": "ARTL",
        "companyURL": "http://www.artelobio.com/",
        "companyPipelineURL": "https://artelobio.com/pipeline/"
    },
    {
        "companyName": "Ascendis Pharma A/S",
        "companyTicker": "ASND",
        "companyURL": "http://www.ascendispharma.com/",
        "companyPipelineURL": "https://ascendispharma.com/pipeline/"
    },
    {
        "companyName": "ASLAN Pharmaceuticals Limited",
        "companyTicker": "ASLN",
        "companyURL": "http://www.aslanpharma.com/",
        "companyPipelineURL": "https://aslanpharma.com/pipeline/"
    },
    {
        "companyName": "Astria Therapeutics, Inc.",
        "companyTicker": "ATXS",
        "companyURL": "http://www.astriatx.com/",
        "companyPipelineURL": "https://astriatx.com/our-science/"
    },
    {
        "companyName": "ATAI Life Sciences N.V.",
        "companyTicker": "ATAI",
        "companyURL": "http://www.atai.life/",
        "companyPipelineURL": "https://atai.life/programs/"
    },
    {
        "companyName": "Atea Pharmaceuticals, Inc.",
        "companyTicker": "AVIR",
        "companyURL": "http://www.ateapharma.com/",
        "companyPipelineURL": "https://ateapharma.com/our-science/pipeline/"
    },
    {
        "companyName": "Atossa Therapeutics, Inc.",
        "companyTicker": "ATOS",
        "companyURL": "http://www.atossatherapeutics.com/",
        "companyPipelineURL": "https://atossatherapeutics.com/product-pipeline/"
    },
    {
        "companyName": "aTyr Pharma, Inc.",
        "companyTicker": "LIFE",
        "companyURL": "http://www.atyrpharma.com/",
        "companyPipelineURL": "https://atyrpharma.com/programs/pipeline-2/"
    },
    {
        "companyName": "Aura Biosciences, Inc.",
        "companyTicker": "AURA",
        "companyURL": "http://www.aurabiosciences.com/",
        "companyPipelineURL": "https://aurabiosciences.com/pipeline-programs/pipeline/"
    },
    {
        "companyName": "Avenue Therapeutics, Inc.",
        "companyTicker": "ATXI",
        "companyURL": "http://www.avenuetx.com/",
        "companyPipelineURL": "https://avenuetx.com/pipe-line/"
    },
    {
        "companyName": "Axcella Health Inc.",
        "companyTicker": "AXLA",
        "companyURL": "http://www.axcellahealth.com/",
        "companyPipelineURL": "https://axcellatx.com/pipeline/"
    },
    {
        "companyName": "Aytu BioPharma, Inc.",
        "companyTicker": "AYTU",
        "companyURL": "http://aytubio.com/",
        "companyPipelineURL": "https://aytubio.com/pipeline/"
    },
    {
        "companyName": "Azitra Inc",
        "companyTicker": "AZTR",
        "companyURL": "http://www.azitrainc.com/",
        "companyPipelineURL": "https://azitrainc.com/pipeline/"
    },
    {
        "companyName": "Beam Therapeutics Inc.",
        "companyTicker": "BEAM",
        "companyURL": "http://www.beamtx.com/",
        "companyPipelineURL": "https://beamtx.com/pipeline/"
    },
    {
        "companyName": "Belite Bio, Inc",
        "companyTicker": "BLTE",
        "companyURL": "http://www.belitebio.com/",
        "companyPipelineURL": "https://belitebio.com/"
    },
    {
        "companyName": "Bellerophon Therapeutics, Inc.",
        "companyTicker": "BLPH",
        "companyURL": "http://www.bellerophon.com/",
        "companyPipelineURL": "https://bellerophon.com/pipeline/"
    },
    {
        "companyName": "Benitec Biopharma Inc.",
        "companyTicker": "BNTC",
        "companyURL": "http://www.benitec.com/",
        "companyPipelineURL": "https://benitec.com/our-programs/pipeline/"
    },
    {
        "companyName": "BeyondSpring, Inc.",
        "companyTicker": "BYSI",
        "companyURL": "http://www.beyondspringpharma.com/",
        "companyPipelineURL": "https://beyondspringpharma.com/pipeline/"
    },
    {
        "companyName": "Biodexa Pharmaceuticals plc",
        "companyTicker": "BDRX",
        "companyURL": "http://www.midatechpharma.com/",
        "companyPipelineURL": "https://biodexapharma.com/pipeline/"
    },
    {
        "companyName": "BioLineRx Ltd.",
        "companyTicker": "BLRX",
        "companyURL": "http://www.biolinerx.com/",
        "companyPipelineURL": "https://biolinerx.com/pipeline/"
    },
    {
        "companyName": "Biomea Fusion, Inc.",
        "companyTicker": "BMEA",
        "companyURL": "http://biomeafusion.com/",
        "companyPipelineURL": "https://biomeafusion.com/pipeline/"
    },
    {
        "companyName": "Brainstorm Cell Therapeutics Inc.",
        "companyTicker": "BCLI",
        "companyURL": "http://www.brainstorm-cell.com/",
        "companyPipelineURL": "https://brainstorm-cell.com/pipeline/"
    },
    {
        "companyName": "BridgeBio Pharma, Inc.",
        "companyTicker": "BBIO",
        "companyURL": "http://www.bridgebio.com/",
        "companyPipelineURL": "https://bridgebio.com/pipeline/#pipeline"
    },
    {
        "companyName": "Bright Minds Biosciences Inc.",
        "companyTicker": "DRUG",
        "companyURL": "http://www.brightmindsbio.com/",
        "companyPipelineURL": "https://brightmindsbio.com/pipeline/"
    },
    {
        "companyName": "C4 Therapeutics, Inc.",
        "companyTicker": "CCCC",
        "companyURL": "http://www.c4therapeutics.com/",
        "companyPipelineURL": "https://c4therapeutics.com/our-pipeline/"
    },
    {
        "companyName": "CalciMedica, Inc.",
        "companyTicker": "CALC",
        "companyURL": "http://www.calcimedica.com/",
        "companyPipelineURL": "https://calcimedica.com/technologies-pipeline/#our-pipeline"
    },
    {
        "companyName": "Carisma Therapeutics, Inc.",
        "companyTicker": "CARM",
        "companyURL": "http://carismatx.com/",
        "companyPipelineURL": "https://carismatx.com/pipeline/"
    },
    {
        "companyName": "Catalyst Pharmaceuticals, Inc.",
        "companyTicker": "CPRX",
        "companyURL": "http://www.catalystpharma.com/",
        "companyPipelineURL": "https://catalystpharma.com/pipeline-clinical/"
    },
    {
        "companyName": "Cel-Sci Corporation",
        "companyTicker": "CVM",
        "companyURL": "http://www.cel-sci.com/",
        "companyPipelineURL": "https://cel-sci.com/product-pipeline/"
    },
    {
        "companyName": "Celyad Oncology SA",
        "companyTicker": "CYADY",
        "companyURL": "http://www.celyad.com/",
        "companyPipelineURL": "https://celyad.com/our-pipeline/"
    },
    {
        "companyName": "Centessa Pharmaceuticals plc",
        "companyTicker": "CNTA",
        "companyURL": "http://www.centessa.com/",
        "companyPipelineURL": "https://centessa.com/pipeline/"
    },
    {
        "companyName": "Compugen Ltd.",
        "companyTicker": "CGEN",
        "companyURL": "http://www.cgen.com/",
        "companyPipelineURL": "https://cgen.com/pipeline/"
    },
    {
        "companyName": "Checkpoint Therapeutics",
        "companyTicker": "CKPT",
        "companyURL": "http://www.checkpointtx.com/",
        "companyPipelineURL": "https://checkpointtx.com/pipeline/"
    },
    {
        "companyName": "Clearside Biomedical, Inc.",
        "companyTicker": "CLSD",
        "companyURL": "http://www.clearsidebio.com/",
        "companyPipelineURL": "https://clearsidebio.com/pipeline/"
    },
    {
        "companyName": "Clene Inc.",
        "companyTicker": "CLNN",
        "companyURL": "http://www.clene.com/",
        "companyPipelineURL": "https://clene.com/pipeline/"
    },
    {
        "companyName": "CNS Pharmaceuticals, Inc.",
        "companyTicker": "CNSP",
        "companyURL": "http://www.cnspharma.com/",
        "companyPipelineURL": "https://cnspharma.com/pipeline/"
    },
    {
        "companyName": "Cocrystal Pharma, Inc.",
        "companyTicker": "COCP",
        "companyURL": "http://www.cocrystalpharma.com/",
        "companyPipelineURL": "https://cnspharma.com/pipeline/"
    },
    {
        "companyName": "Cognition Therapeutics, Inc.",
        "companyTicker": "CGTX",
        "companyURL": "http://www.cogrx.com/",
        "companyPipelineURL": "https://cogrx.com/pipeline/"
    },
    {
        "companyName": "Comera Life Sciences Holdings, Inc.",
        "companyTicker": "CMRA",
        "companyURL": "http://comeralifesciences.com/",
        "companyPipelineURL": "https://comeralifesciences.com/pipeline"
    },
    {
        "companyName": "CorMedix Inc.",
        "companyTicker": "CRMD",
        "companyURL": "http://www.cormedix.com/",
        "companyPipelineURL": "https://cormedix.com/pipeline/"
    },
    {
        "companyName": "Crinetics Pharmaceuticals, Inc.",
        "companyTicker": "CRNX",
        "companyURL": "http://www.crinetics.com/",
        "companyPipelineURL": "https://crinetics.com/pipeline/"
    },
    {
        "companyName": "CRISPR Therapeutics AG",
        "companyTicker": "CRSP",
        "companyURL": "http://www.crisprtx.com/",
        "companyPipelineURL": "https://crisprtx.com/programs/pipeline"
    },
    {
        "companyName": "Cullinan Oncology, Inc.",
        "companyTicker": "CGEM",
        "companyURL": "http://www.cullinanoncology.com/",
        "companyPipelineURL": "https://cullinanoncology.com/pipeline/"
    },
    {
        "companyName": "CURATIVE BIOSCIENCES INC",
        "companyTicker": "CBDX",
        "companyURL": "https://curativebiotech.com/",
        "companyPipelineURL": "https://curativebiotech.com/pipeline"
    },
    {
        "companyName": "Cybin Inc.",
        "companyTicker": "CYBN",
        "companyURL": "http://www.cybin.com/",
        "companyPipelineURL": "https://cybin.com/development-pipeline/"
    },
    {
        "companyName": "Cyclacel Pharmaceuticals, Inc.",
        "companyTicker": "CYCC",
        "companyURL": "http://www.cyclacel.com/",
        "companyPipelineURL": "https://cyclacel.com/pipeline/"
    },
    {
        "companyName": "Cyclo Therapeutics, Inc.",
        "companyTicker": "CYTH",
        "companyURL": "http://www.cyclotherapeutics.com/",
        "companyPipelineURL": "https://cyclotherapeutics.com/pipeline/"
    },
    {
        "companyName": "Cyteir Therapeutics, Inc.",
        "companyTicker": "CYT",
        "companyURL": "http://www.cyteir.com/",
        "companyPipelineURL": "https://cyteir.com/cyteir-pipeline"
    },
    {
        "companyName": "Cytokinetics, Incorporated",
        "companyTicker": "CYTK",
        "companyURL": "http://www.cytokinetics.com/",
        "companyPipelineURL": "https://cytokinetics.com/medicines-research/pipeline/"
    },
    {
        "companyName": "CytomX Therapeutics, Inc.",
        "companyTicker": "CTMX",
        "companyURL": "http://www.cytomx.com/",
        "companyPipelineURL": "https://cytomx.com/pipeline/"
    },
    {
        "companyName": "Dare Bioscience, Inc.",
        "companyTicker": "DARE",
        "companyURL": "http://www.darebioscience.com/",
        "companyPipelineURL": "https://darebioscience.com/pipeline/"
    },
    {
        "companyName": "DBV Technologies S.A.",
        "companyTicker": "DBVT",
        "companyURL": "http://www.dbv-technologies.com/",
        "companyPipelineURL": "https://dbv-technologies.com/pipeline/"
    },
    {
        "companyName": "Magenta Therapeutics, Inc.",
        "companyTicker": "MGTA",
        "companyURL": "http://www.magentatx.com/",
        "companyPipelineURL": "https://dianthustx.com/approach-and-pipeline/"
    },
    {
        "companyName": "Edgewise Therapeutics, Inc.",
        "companyTicker": "EWTX",
        "companyURL": "http://www.edgewisetx.com/",
        "companyPipelineURL": "https://edgewisetx.com/index.php?cID=211"
    },
    {
        "companyName": "eFFECTOR Therapeutics, Inc.",
        "companyTicker": "EFTR",
        "companyURL": "http://www.effector.com/",
        "companyPipelineURL": "https://effector.com/pipeline/"
    },
    {
        "companyName": "Elevation Oncology, Inc.",
        "companyTicker": "ELEV",
        "companyURL": "https://elevationoncology.com/",
        "companyPipelineURL": "https://elevationoncology.com/pipeline/"
    },
    {
        "companyName": "Elicio Therapeutics, Inc.",
        "companyTicker": "ELTX",
        "companyURL": "http://www.elicio.com/",
        "companyPipelineURL": "https://elicio.com/programs/pipeline/"
    },
    {
        "companyName": "Eliem Therapeutics, Inc",
        "companyTicker": "ELYM",
        "companyURL": "http://www.eliemtx.com/",
        "companyPipelineURL": "https://eliemtx.com/pipeline/"
    },
    {
        "companyName": "Enlivex Therapeutics Ltd.",
        "companyTicker": "ENLV",
        "companyURL": "http://www.enlivex.com/",
        "companyPipelineURL": "https://enlivex.com/pipeline/"
    },
    {
        "companyName": "Ensysce Biosciences, Inc.",
        "companyTicker": "ENSC",
        "companyURL": "http://www.ensysce.com/",
        "companyPipelineURL": "https://ensysce.com/our-strategy/#pipeline"
    },
    {
        "companyName": "Entera Bio Ltd.",
        "companyTicker": "ENTX",
        "companyURL": "http://www.enterabio.com/",
        "companyPipelineURL": "https://enterabio.com/pipeline/"
    },
    {
        "companyName": "Evelo Biosciences, Inc.",
        "companyTicker": "EVLO",
        "companyURL": "http://www.evelobio.com/",
        "companyPipelineURL": "https://evelobio.com/our-pipeline/"
    },
    {
        "companyName": "Eyenovia, Inc.",
        "companyTicker": "EYEN",
        "companyURL": "http://www.eyenovia.com/",
        "companyPipelineURL": "https://eyenovia.com/pipeline/"
    },
    {
        "companyName": "Fate Therapeutics, Inc.",
        "companyTicker": "FATE",
        "companyURL": "http://www.fatetherapeutics.com/",
        "companyPipelineURL": "https://fatetherapeutics.com/pipeline/"
    },
    {
        "companyName": "Foghorn Therapeutics Inc.",
        "companyTicker": "FHTX",
        "companyURL": "http://www.foghorntx.com/",
        "companyPipelineURL": "https://foghorntx.com/pipeline/"
    },
    {
        "companyName": "Freeline Therapeutics Holdings plc",
        "companyTicker": "FRLN",
        "companyURL": "http://www.freeline.life/",
        "companyPipelineURL": "https://freeline.life/freeline/innovation/our-pipeline/"
    },
    {
        "companyName": "Fusion Pharmaceuticals Inc.",
        "companyTicker": "FUSN",
        "companyURL": "http://www.fusionpharma.com/",
        "companyPipelineURL": "https://fusionpharma.com/fusion-pipeline/"
    },
    {
        "companyName": "Galectin Therapeutics Inc.",
        "companyTicker": "GALT",
        "companyURL": "http://www.galectintherapeutics.com/",
        "companyPipelineURL": "https://galectintherapeutics.com/pipeline-overview/"
    },
    {
        "companyName": "Galecto, Inc.",
        "companyTicker": "GLTO",
        "companyURL": "http://www.galecto.com/",
        "companyPipelineURL": "https://galecto.com/pipeline/"
    },
    {
        "companyName": "Galmed Pharmaceuticals Ltd.",
        "companyTicker": "GLMD",
        "companyURL": "http://galmedpharma.com/",
        "companyPipelineURL": "https://galmedpharma.com/pipeline/"
    },
    {
        "companyName": "Genelux Corporation",
        "companyTicker": "GNLX",
        "companyURL": "http://www.genelux.com/",
        "companyPipelineURL": "https://genelux.com/overview/"
    },
    {
        "companyName": "Generation Bio Co.",
        "companyTicker": "GBIO",
        "companyURL": "http://www.generationbio.com/",
        "companyPipelineURL": "https://generationbio.com/our-portfolio/"
    },
    {
        "companyName": "GlycoMimetics, Inc.",
        "companyTicker": "GLYC",
        "companyURL": "http://www.glycomimetics.com/",
        "companyPipelineURL": "https://glycomimetics.com/pipeline/"
    },
    {
        "companyName": "Graphite Bio, Inc.",
        "companyTicker": "GRPH",
        "companyURL": "http://www.graphitebio.com/",
        "companyPipelineURL": "https://graphitebio.com/pipeline/"
    },
    {
        "companyName": "Gritstone bio, Inc.",
        "companyTicker": "GRTS",
        "companyURL": "http://www.gritstonebio.com/",
        "companyPipelineURL": "https://gritstonebio.com/our-pipeline/"
    },
    {
        "companyName": "Halozyme Therapeutics, Inc.",
        "companyTicker": "HALO",
        "companyURL": "http://www.halozyme.com/",
        "companyPipelineURL": "https://halozyme.com/drug-delivery-technologies/enhanze/"
    },
    {
        "companyName": "HCW Biologics Inc.",
        "companyTicker": "HCWB",
        "companyURL": "http://www.hcwbiologics.com/",
        "companyPipelineURL": "https://hcwbiologics.com/pipeline/"
    },
    {
        "companyName": "Hepion Pharmaceuticals, Inc.",
        "companyTicker": "HEPA",
        "companyURL": "http://www.hepionpharma.com/",
        "companyPipelineURL": "https://hepionpharma.com/pipeline/"
    },
    {
        "companyName": "Hillstream BioPharma, Inc.",
        "companyTicker": "HILS",
        "companyURL": "http://www.hillstreambio.com/",
        "companyPipelineURL": "https://hillstreambio.com/pipeline/"
    },
    {
        "companyName": "Hoth Therapeutics, Inc.",
        "companyTicker": "HOTH",
        "companyURL": "http://www.hoththerapeutics.com/",
        "companyPipelineURL": "https://hoththerapeutics.com/pipeline/"
    },
    {
        "companyName": "Humacyte, Inc.",
        "companyTicker": "HUMA",
        "companyURL": "http://humacyte.com/",
        "companyPipelineURL": "https://humacyte.com/development-pipeline/"
    },
    {
        "companyName": "iBio, Inc.",
        "companyTicker": "IBIO",
        "companyURL": "http://www.ibioinc.com/",
        "companyPipelineURL": "https://ibioinc.com/pipeline/"
    },
    {
        "companyName": "Icosavax, Inc.",
        "companyTicker": "ICVX",
        "companyURL": "http://www.icosavax.com/",
        "companyPipelineURL": "https://icosavax.com/pipeline/"
    },
    {
        "companyName": "IGC Pharma, Inc.",
        "companyTicker": "IGC",
        "companyURL": "http://www.igcinc.us/",
        "companyPipelineURL": "https://igcpharma.com/pipeline/"
    },
    {
        "companyName": "IGM Biosciences, Inc.",
        "companyTicker": "IGMS",
        "companyURL": "http://www.igmbio.com/",
        "companyPipelineURL": "https://igmbio.com/pipeline/"
    },
    {
        "companyName": "Ikena Oncology, Inc.",
        "companyTicker": "IKNA",
        "companyURL": "http://www.ikenaoncology.com/",
        "companyPipelineURL": "https://ikenaoncology.com/pipeline/"
    },
    {
        "companyName": "Immatics N.V.",
        "companyTicker": "IMTX",
        "companyURL": "http://www.immatics.com/",
        "companyPipelineURL": "https://immatics.com/our-pipeline/"
    },
    {
        "companyName": "Immix Biopharma, Inc.",
        "companyTicker": "IMMX",
        "companyURL": "http://www.immixbio.com/",
        "companyPipelineURL": "https://immixbio.com/pipeline/"
    },
    {
        "companyName": "Immuneering Corporation",
        "companyTicker": "IMRX",
        "companyURL": "http://www.immuneering.com/",
        "companyPipelineURL": "https://immuneering.com/pipeline/"
    },
    {
        "companyName": "ImmunityBio, Inc.",
        "companyTicker": "IBRX",
        "companyURL": "http://www.immunitybio.com/",
        "companyPipelineURL": "https://immunitybio.com/pipeline/"
    },
    {
        "companyName": "Impel Pharmaceuticals Inc.",
        "companyTicker": "IMPL",
        "companyURL": "http://impelpharma.com/",
        "companyPipelineURL": "https://impelpharma.com/our-science/"
    },
    {
        "companyName": "Immunic, Inc.",
        "companyTicker": "IMUX",
        "companyURL": "http://www.immunic-therapeutics.com/",
        "companyPipelineURL": "https://imux.com/pipeline/"
    },
    {
        "companyName": "IN8bio, Inc.",
        "companyTicker": "INAB",
        "companyURL": "http://www.in8bio.com/",
        "companyPipelineURL": "https://in8bio.com/pipeline/"
    },
    {
        "companyName": "Inhibitor Therapeutics Inc.",
        "companyTicker": "INTI",
        "companyURL": "http://www.inhibitortx.com/",
        "companyPipelineURL": "https://inhibitortx.com/pipeline/"
    },
    {
        "companyName": "Inovio Pharmaceuticals, Inc.",
        "companyTicker": "INO",
        "companyURL": "http://www.inovio.com/",
        "companyPipelineURL": "https://inovio.com/dna-medicines-pipeline/"
    },
    {
        "companyName": "Insmed Incorporated",
        "companyTicker": "INSM",
        "companyURL": "http://www.insmed.com/",
        "companyPipelineURL": "https://insmed.com/science/pipeline/"
    },
    {
        "companyName": "Instil Bio, Inc.",
        "companyTicker": "TIL",
        "companyURL": "http://www.instilbio.com/",
        "companyPipelineURL": "https://instilbio.com/pipeline/"
    },
    {
        "companyName": "Intensity Therapeutics, Inc.",
        "companyTicker": "INTS",
        "companyURL": "http://www.intensitytherapeutics.com/",
        "companyPipelineURL": "https://intensitytherapeutics.com/clinical-research/pipeline/"
    },
    {
        "companyName": "Inventiva S.A. - American Depository Shares",
        "companyTicker": "IVA",
        "companyURL": "http://www.inventivapharma.com/",
        "companyPipelineURL": "https://inventivapharma.com/pipeline/"
    },
    {
        "companyName": "Invivyd, Inc.",
        "companyTicker": "IVVD",
        "companyURL": "http://www.adagiotx.com/",
        "companyPipelineURL": "https://invivyd.com/pipeline/#overview"
    },
    {
        "companyName": "IO Biotech, Inc.",
        "companyTicker": "IOBT",
        "companyURL": "http://www.iobiotech.com/",
        "companyPipelineURL": "https://iobiotech.com/pipeline/"
    },
    {
        "companyName": "Aeglea BioTherapeutics, Inc.",
        "companyTicker": "AGLE",
        "companyURL": "http://www.aeglea.com/",
        "companyPipelineURL": "https://ir.aeglea.com/investors-and-media/default.aspx"
    },
    {
        "companyName": "Ironwood Pharmaceuticals, Inc.",
        "companyTicker": "IRWD",
        "companyURL": "http://www.ironwoodpharma.com/",
        "companyPipelineURL": "https://ironwoodpharma.com/science-medicine/pipeline/"
    },
    {
        "companyName": "Jaguar Health, Inc.",
        "companyTicker": "JAGX",
        "companyURL": "http://www.jaguar.health/",
        "companyPipelineURL": "https://jaguar.health/pipeline/"
    },
    {
        "companyName": "Karuna Therapeutics, Inc.",
        "companyTicker": "KRTX",
        "companyURL": "http://www.karunatx.com/",
        "companyPipelineURL": "https://karunatx.com/pipeline-programs/#pipeline"
    },
    {
        "companyName": "Kezar Life Sciences, Inc.",
        "companyTicker": "KZR",
        "companyURL": "http://www.kezarlifesciences.com/",
        "companyPipelineURL": "https://kezarlifesciences.com/pipeline"
    },
    {
        "companyName": "Kineta, Inc.",
        "companyTicker": "KA",
        "companyURL": "http://www.kinetabio.com/",
        "companyPipelineURL": "https://kinetabio.com/pipeline/"
    },
    {
        "companyName": "Kiora Pharmaceuticals, Inc.",
        "companyTicker": "KPRX",
        "companyURL": "http://www.kiorapharma.com/",
        "companyPipelineURL": "https://kiorapharma.com/pipeline/"
    },
    {
        "companyName": "Kiromic BioPharma, Inc.",
        "companyTicker": "KRBP",
        "companyURL": "http://www.kiromic.com/",
        "companyPipelineURL": "https://kiromic.com/drug-development-pipeline/"
    },
    {
        "companyName": "Kodiak Sciences Inc",
        "companyTicker": "KOD",
        "companyURL": "http://www.kodiak.com/",
        "companyPipelineURL": "https://kodiak.com/our-pipeline/"
    },
    {
        "companyName": "Kura Oncology, Inc.",
        "companyTicker": "KURA",
        "companyURL": "http://www.kuraoncology.com/",
        "companyPipelineURL": "https://kuraoncology.com/pipeline/"
    },
    {
        "companyName": "Landos Biopharma, Inc.",
        "companyTicker": "LABP",
        "companyURL": "http://www.landosbiopharma.com/",
        "companyPipelineURL": "https://landosbiopharma.com/product-candidates/our-pipeline/"
    },
    {
        "companyName": "Legend Biotech Corporation",
        "companyTicker": "LEGN",
        "companyURL": "http://www.legendbiotech.com/",
        "companyPipelineURL": "https://legendbiotech.com/research-development/pipeline/"
    },
    {
        "companyName": "Liminal BioSciences Inc.",
        "companyTicker": "LMNL",
        "companyURL": "http://www.liminalbiosciences.com/",
        "companyPipelineURL": "https://liminalbiosciences.com/pipeline/"
    },
    {
        "companyName": "Lineage Cell Therapeutics, Inc.",
        "companyTicker": "LCTX",
        "companyURL": "http://www.lineagecell.com/",
        "companyPipelineURL": "https://lineagecell.com/products-pipeline/"
    },
    {
        "companyName": "Lixte Biotechnology Holdings, Inc.",
        "companyTicker": "LIXT",
        "companyURL": "http://www.lixte.com/",
        "companyPipelineURL": "https://lixte.com/product-development/"
    },
    {
        "companyName": "Lumos Pharma, Inc.",
        "companyTicker": "LUMO",
        "companyURL": "http://www.lumos-pharma.com/",
        "companyPipelineURL": "https://lumos-pharma.com/our-pipeline/"
    },
    {
        "companyName": "Lyell Immunopharma, Inc.",
        "companyTicker": "LYEL",
        "companyURL": "http://www.lyell.com/",
        "companyPipelineURL": "https://lyell.com/our-pipeline/"
    },
    {
        "companyName": "Lyra Therapeutics, Inc.",
        "companyTicker": "LYRA",
        "companyURL": "http://www.lyratherapeutics.com/",
        "companyPipelineURL": "https://lyratherapeutics.com/pipeline/pipeline/"
    },
    {
        "companyName": "MacroGenics, Inc.",
        "companyTicker": "MGNX",
        "companyURL": "http://www.macrogenics.com/",
        "companyPipelineURL": "https://macrogenics.com/pipeline/"
    },
    {
        "companyName": "MAIA Biotechnology, Inc.",
        "companyTicker": "MAIA",
        "companyURL": "http://maiabiotech.com/",
        "companyPipelineURL": "https://maiabiotech.com/pipeline/"
    },
    {
        "companyName": "MannKind Corporation",
        "companyTicker": "MNKD",
        "companyURL": "http://www.mannkindcorp.com/",
        "companyPipelineURL": "https://mannkindcorp.com/pipeline/"
    },
    {
        "companyName": "Marinus Pharmaceuticals, Inc.",
        "companyTicker": "MRNS",
        "companyURL": "http://www.marinuspharma.com/",
        "companyPipelineURL": "https://marinuspharma.com/pipeline/"
    },
    {
        "companyName": "Marker Therapeutics, Inc.",
        "companyTicker": "MRKR",
        "companyURL": "http://www.markertherapeutics.com/",
        "companyPipelineURL": "https://markertherapeutics.com/product-candidates/pipeline/"
    },
    {
        "companyName": "MediciNova, Inc.",
        "companyTicker": "MNOV",
        "companyURL": "http://www.medicinova.com/",
        "companyPipelineURL": "https://medicinova.com/clinical-development/development-pipeline/"
    },
    {
        "companyName": "MeiraGTx Holdings plc",
        "companyTicker": "MGTX",
        "companyURL": "http://www.meiragtx.com/",
        "companyPipelineURL": "https://meiragtx.com/research-development/pipeline/"
    },
    {
        "companyName": "Merus N.V.",
        "companyTicker": "MRUS",
        "companyURL": "http://www.merus.nl/",
        "companyPipelineURL": "https://merus.nl/pipeline/"
    },
    {
        "companyName": "Milestone Pharmaceuticals Inc.",
        "companyTicker": "MIST",
        "companyURL": "http://www.milestonepharma.com/",
        "companyPipelineURL": "https://milestonepharma.com/research-development/"
    },
    {
        "companyName": "Mind Medicine (MindMed) Inc.",
        "companyTicker": "MNMD",
        "companyURL": "http://www.mindmed.co/",
        "companyPipelineURL": "https://mindmed.co/programs-research/"
    },
    {
        "companyName": "Mineralys Therapeutics, Inc.",
        "companyTicker": "MLYS",
        "companyURL": "http://www.mineralystx.com/",
        "companyPipelineURL": "https://mineralystx.com/clinical-trials/"
    },
    {
        "companyName": "MiNK Therapeutics, Inc.",
        "companyTicker": "INKT",
        "companyURL": "http://www.minktherapeutics.com/",
        "companyPipelineURL": "https://minktherapeutics.com/#pipeline"
    },
    {
        "companyName": "Mirum Pharmaceuticals, Inc.",
        "companyTicker": "MIRM",
        "companyURL": "http://www.mirumpharma.com/",
        "companyPipelineURL": "https://mirumpharma.com/our-science/pipeline/"
    },
    {
        "companyName": "Moleculin Biotech, Inc.",
        "companyTicker": "MBRX",
        "companyURL": "http://www.moleculin.com/",
        "companyPipelineURL": "https://moleculin.com/pipeline/"
    },
    {
        "companyName": "MoonLake Immunotherapeutics",
        "companyTicker": "MLTX",
        "companyURL": "http://www.moonlaketx.com/",
        "companyPipelineURL": "https://moonlaketx.com/pipeline/"
    },
    {
        "companyName": "NexImmune, Inc.",
        "companyTicker": "NEXI",
        "companyURL": "http://www.neximmune.com/",
        "companyPipelineURL": "https://neximmune.com/our-pipeline/"
    },
    {
        "companyName": "NLS Pharmaceutics Ltd.",
        "companyTicker": "NLSP",
        "companyURL": "http://www.nlspharma.com/",
        "companyPipelineURL": "https://nlspharma.com/science/pipeline/"
    },
    {
        "companyName": "Novan, Inc.",
        "companyTicker": "NOVNQ",
        "companyURL": "http://www.novan.com/",
        "companyPipelineURL": "https://novan.com/profile/"
    },
    {
        "companyName": "Nuvectis Pharma, Inc.",
        "companyTicker": "NVCT",
        "companyURL": "http://nuvectis.com/",
        "companyPipelineURL": "https://nuvectis.com/pipeline/"
    },
    {
        "companyName": "Peak Bio, Inc.",
        "companyTicker": "PKBO",
        "companyURL": "https://peak-bio.com/",
        "companyPipelineURL": "https://peak-bio.com/pipeline/"
    },
    {
        "companyName": "Purple Biotech Ltd.",
        "companyTicker": "PPBT",
        "companyURL": "https://purple-biotech.com/",
        "companyPipelineURL": "https://purple-biotech.com/pipeline/#1PipeOverview"
    },
    {
        "companyName": "RAPT Therapeutics, Inc.",
        "companyTicker": "RAPT",
        "companyURL": "http://rapt.com/",
        "companyPipelineURL": "https://rapt.com/pipeline/"
    },
    {
        "companyName": "Renovaro Biosciences Inc.",
        "companyTicker": "RENB",
        "companyURL": "http://www.enochianbio.com/",
        "companyPipelineURL": "https://renovarobio.com/Pipeline--Technology/Overview/default.aspx"
    },
    {
        "companyName": "Rocket Pharmaceuticals, Inc.",
        "companyTicker": "RCKT",
        "companyURL": "http://www.rocketpharma.com/",
        "companyPipelineURL": "https://rocketpharma.com/our-disease-focus/"
    },
    {
        "companyName": "Tarsus Pharmaceuticals, Inc.",
        "companyTicker": "TARS",
        "companyURL": "http://tarsusrx.com/",
        "companyPipelineURL": "https://tarsusrx.com/pipeline/"
    },
    {
        "companyName": "CytoMed Therapeutics Limited",
        "companyTicker": "GDTC",
        "companyURL": "http://www.cytomed.sg/",
        "companyPipelineURL": "https://w2.cytomed.sg/pipeline/"
    },
    {
        "companyName": "89bio, Inc.",
        "companyTicker": "ETNB",
        "companyURL": "http://www.89bio.com/",
        "companyPipelineURL": "https://www.89bio.com/pipeline/"
    },
    {
        "companyName": "AbbVie Inc.",
        "companyTicker": "ABBV",
        "companyURL": "http://www.abbvie.com/",
        "companyPipelineURL": "https://www.abbvie.com/science/pipeline.html"
    },
    {
        "companyName": "Abeona Therapeutics Inc.",
        "companyTicker": "ABEO",
        "companyURL": "http://www.abeonatherapeutics.com/",
        "companyPipelineURL": "https://www.abeonatherapeutics.com/science#pipeline"
    },
    {
        "companyName": "Acasti Pharma, Inc.",
        "companyTicker": "ACST",
        "companyURL": "http://www.acastipharma.com/",
        "companyPipelineURL": "https://www.acasti.com/en/pipeline"
    },
    {
        "companyName": "AcelRx Pharmaceuticals, Inc.",
        "companyTicker": "ACRX",
        "companyURL": "http://www.acelrx.com/",
        "companyPipelineURL": "https://www.acelrx.com/"
    },
    {
        "companyName": "Acer Therapeutics Inc.",
        "companyTicker": "ACER",
        "companyURL": "http://www.acertx.com/",
        "companyPipelineURL": "https://www.acertx.com/rare-disease-research/"
    },
    {
        "companyName": "AC Immune SA",
        "companyTicker": "ACIU",
        "companyURL": "http://www.acimmune.com/",
        "companyPipelineURL": "https://www.acimmune.com/pipeline/"
    },
    {
        "companyName": "Aclaris Therapeutics, Inc.",
        "companyTicker": "ACRS",
        "companyURL": "http://www.aclaristx.com/",
        "companyPipelineURL": "https://www.aclaristx.com/drugdiscovery/"
    },
    {
        "companyName": "Acorda Therapeutics, Inc.",
        "companyTicker": "ACOR",
        "companyURL": "http://www.acorda.com/",
        "companyPipelineURL": "https://www.acorda.com/products"
    },
    {
        "companyName": "Actinium Pharmaceuticals, Inc. (Delaware)",
        "companyTicker": "ATNM",
        "companyURL": "http://www.actiniumpharma.com/",
        "companyPipelineURL": "https://www.actiniumpharma.com/product-pipeline"
    },
    {
        "companyName": "Acurx Pharmaceuticals, Inc.",
        "companyTicker": "ACXP",
        "companyURL": "http://www.acurxpharma.com/",
        "companyPipelineURL": "https://www.acurxpharma.com/pipeline/overview"
    },
    {
        "companyName": "Adagene Inc.",
        "companyTicker": "ADAG",
        "companyURL": "http://www.adagene.com/",
        "companyPipelineURL": "https://www.adagene.com/pipeline/pipeline-programs/"
    },
    {
        "companyName": "Adaptimmune Therapeutics plc",
        "companyTicker": "ADAP",
        "companyURL": "http://www.adaptimmune.com/",
        "companyPipelineURL": "https://www.adaptimmune.com/pipeline"
    },
    {
        "companyName": "Adaptive Biotechnologies Corporation",
        "companyTicker": "ADPT",
        "companyURL": "http://www.adaptivebiotech.com/",
        "companyPipelineURL": "https://www.adaptivebiotech.com/mrd-pipeline/"
    },
    {
        "companyName": "ADC Therapeutics SA",
        "companyTicker": "ADCT",
        "companyURL": "http://www.adctherapeutics.com/",
        "companyPipelineURL": "https://www.adctherapeutics.com/our-pipeline/"
    },
    {
        "companyName": "Adial Pharmaceuticals, Inc",
        "companyTicker": "ADIL",
        "companyURL": "http://www.adialpharma.com/",
        "companyPipelineURL": "https://www.adial.com/pipeline/"
    },
    {
        "companyName": "Adicet Bio, Inc.",
        "companyTicker": "ACET",
        "companyURL": "http://www.adicetbio.com/",
        "companyPipelineURL": "https://www.adicetbio.com/pipeline/"
    },
    {
        "companyName": "Aerovate Therapeutics, Inc.",
        "companyTicker": "AVTE",
        "companyURL": "https://www.aerovatetx.com/",
        "companyPipelineURL": "https://www.aerovatetx.com/programs/"
    },
    {
        "companyName": "Affimed N.V.",
        "companyTicker": "AFMD",
        "companyURL": "http://www.affimed.com/",
        "companyPipelineURL": "https://www.affimed.com/pipeline/"
    },
    {
        "companyName": "AgeX Therapeutics, Inc.",
        "companyTicker": "AGE",
        "companyURL": "http://www.agexinc.com/",
        "companyPipelineURL": "https://www.agexinc.com/pipeline/"
    },
    {
        "companyName": "Agios Pharmaceuticals, Inc.",
        "companyTicker": "AGIO",
        "companyURL": "http://www.agios.com/",
        "companyPipelineURL": "https://www.agios.com/pipeline/"
    },
    {
        "companyName": "Dominari Holdings Inc.",
        "companyTicker": "DOMH",
        "companyURL": "http://www.aikidopharma.com/",
        "companyPipelineURL": "https://www.aikidopharma.com/portfolio"
    },
    {
        "companyName": "Akari Therapeutics Plc",
        "companyTicker": "AKTX",
        "companyURL": "http://www.akaritx.com/",
        "companyPipelineURL": "https://www.akaritx.com/pipeline/"
    },
    {
        "companyName": "Aldeyra Therapeutics, Inc.",
        "companyTicker": "ALDX",
        "companyURL": "http://www.aldeyra.com/",
        "companyPipelineURL": "https://www.aldeyra.com/pipeline-disease-areas/"
    },
    {
        "companyName": "Alector, Inc.",
        "companyTicker": "ALEC",
        "companyURL": "http://www.alector.com/",
        "companyPipelineURL": "https://www.alector.com/pipeline/"
    },
    {
        "companyName": "Aligos Therapeutics, Inc.",
        "companyTicker": "ALGS",
        "companyURL": "http://www.aligos.com/",
        "companyPipelineURL": "https://www.aligos.com/scientific-overview/"
    },
    {
        "companyName": "Alkermes plc",
        "companyTicker": "ALKS",
        "companyURL": "http://www.alkermes.com/",
        "companyPipelineURL": "https://www.alkermes.com/research-and-development#pipeline"
    },
    {
        "companyName": "Allakos Inc.",
        "companyTicker": "ALLK",
        "companyURL": "http://www.allakos.com/",
        "companyPipelineURL": "https://www.allakos.com/science/"
    },
    {
        "companyName": "AlloVir, Inc.",
        "companyTicker": "ALVR",
        "companyURL": "http://www.allovir.com/",
        "companyPipelineURL": "https://www.allovir.com/pipeline"
    },
    {
        "companyName": "Alnylam Pharmaceuticals, Inc.",
        "companyTicker": "ALNY",
        "companyURL": "http://www.alnylam.com/",
        "companyPipelineURL": "https://www.alnylam.com/alnylam-rnai-pipeline"
    },
    {
        "companyName": "Alpine Immune Sciences, Inc.",
        "companyTicker": "ALPN",
        "companyURL": "http://www.alpineimmunesciences.com/",
        "companyPipelineURL": "https://www.alpineimmunesciences.com/pipeline/"
    },
    {
        "companyName": "Alvotech",
        "companyTicker": "ALVO",
        "companyURL": "http://www.alvotech.com/",
        "companyPipelineURL": "https://www.alvotech.com/pipeline"
    },
    {
        "companyName": "Amgen Inc.",
        "companyTicker": "AMGN",
        "companyURL": "http://www.amgen.com/",
        "companyPipelineURL": "https://www.amgenpipeline.com/"
    },
    {
        "companyName": "Amylyx Pharmaceuticals, Inc.",
        "companyTicker": "AMLX",
        "companyURL": "http://www.amylyx.com/",
        "companyPipelineURL": "https://www.amylyx.com/pipeline"
    },
    {
        "companyName": "AN2 Therapeutics, Inc.",
        "companyTicker": "ANTX",
        "companyURL": "http://www.an2therapeutics.com/",
        "companyPipelineURL": "https://www.an2therapeutics.com/pipeline/"
    },
    {
        "companyName": "AnaptysBio, Inc.",
        "companyTicker": "ANAB",
        "companyURL": "http://www.anaptysbio.com/",
        "companyPipelineURL": "https://www.anaptysbio.com/pipeline/overview/"
    },
    {
        "companyName": "Anavex Life Sciences Corp.",
        "companyTicker": "AVXL",
        "companyURL": "http://www.anavex.com/",
        "companyPipelineURL": "https://www.anavex.com/therapeutic-candidates"
    },
    {
        "companyName": "Anebulo Pharmaceuticals, Inc.",
        "companyTicker": "ANEB",
        "companyURL": "http://www.anebulo.com/",
        "companyPipelineURL": "https://www.anebulo.com/pipeline/"
    },
    {
        "companyName": "Anixa Biosciences, Inc.",
        "companyTicker": "ANIX",
        "companyURL": "http://www.anixa.com/",
        "companyPipelineURL": "https://www.anixa.com/pipeline"
    },
    {
        "companyName": "Annovis Bio, Inc.",
        "companyTicker": "ANVS",
        "companyURL": "http://www.annovisbio.com/",
        "companyPipelineURL": "https://www.annovisbio.com/our-science"
    },
    {
        "companyName": "Apollomics Inc.",
        "companyTicker": "APLM",
        "companyURL": "http://www.apollomicsinc.com/",
        "companyPipelineURL": "https://www.apollomicsinc.com/pipeline/"
    },
    {
        "companyName": "Applied Therapeutics, Inc.",
        "companyTicker": "APLT",
        "companyURL": "http://www.appliedtherapeutics.com/",
        "companyPipelineURL": "https://www.appliedtherapeutics.com/pipeline/"
    },
    {
        "companyName": "Aprea Therapeutics, Inc.",
        "companyTicker": "APRE",
        "companyURL": "http://www.aprea.com/",
        "companyPipelineURL": "https://www.aprea.com/our-pipeline/"
    },
    {
        "companyName": "Aptorum Group Limited",
        "companyTicker": "APM",
        "companyURL": "http://www.aptorumgroup.com/",
        "companyPipelineURL": "https://www.aptorumgroup.com/pipeline"
    },
    {
        "companyName": "Aptose Biosciences, Inc.",
        "companyTicker": "APTO",
        "companyURL": "http://www.aptose.com/",
        "companyPipelineURL": "https://www.aptose.com/product-pipeline"
    },
    {
        "companyName": "Aquestive Therapeutics, Inc.",
        "companyTicker": "AQST",
        "companyURL": "http://www.aquestive.com/",
        "companyPipelineURL": "https://www.aquestive.com/pipeline/"
    },
    {
        "companyName": "Arbutus Biopharma Corporation",
        "companyTicker": "ABUS",
        "companyURL": "http://www.arbutusbio.com/",
        "companyPipelineURL": "https://www.arbutusbio.com/pipeline/"
    },
    {
        "companyName": "Arcellx, Inc.",
        "companyTicker": "ACLX",
        "companyURL": "http://www.arcellx.com/",
        "companyPipelineURL": "https://www.arcellx.com/pipeline-focus-area/"
    },
    {
        "companyName": "Arcutis Biotherapeutics, Inc.",
        "companyTicker": "ARQT",
        "companyURL": "http://www.arcutis.com/",
        "companyPipelineURL": "https://www.arcutis.com/pipeline/"
    },
    {
        "companyName": "argenx SE",
        "companyTicker": "ARGX",
        "companyURL": "http://www.argenx.com/",
        "companyPipelineURL": "https://www.argenx.com/pipeline"
    },
    {
        "companyName": "Aridis Pharmaceuticals Inc.",
        "companyTicker": "ARDS",
        "companyURL": "http://www.aridispharma.com/",
        "companyPipelineURL": "https://www.aridispharma.com/product-overview-2/"
    },
    {
        "companyName": "Armata Pharmaceuticals, Inc.",
        "companyTicker": "ARMP",
        "companyURL": "http://www.armatapharma.com/",
        "companyPipelineURL": "https://www.armatapharma.com/pipeline/pipeline-overview/"
    },
    {
        "companyName": "Arvinas, Inc.",
        "companyTicker": "ARVN",
        "companyURL": "http://www.arvinas.com/",
        "companyPipelineURL": "https://www.arvinas.com/research-and-development/pipeline/"
    },
    {
        "companyName": "Assembly Biosciences, Inc.",
        "companyTicker": "ASMB",
        "companyURL": "http://www.assemblybio.com/",
        "companyPipelineURL": "https://www.assemblybio.com/pipeline/"
    },
    {
        "companyName": "Atara Biotherapeutics, Inc.",
        "companyTicker": "ATRA",
        "companyURL": "http://www.atarabio.com/",
        "companyPipelineURL": "https://www.atarabio.com/pipeline/"
    },
    {
        "companyName": "Athenex, Inc.",
        "companyTicker": "ATNXQ",
        "companyURL": "http://www.athenex.com/",
        "companyPipelineURL": "https://www.athenex.com/pipeline/"
    },
    {
        "companyName": "Athersys, Inc.",
        "companyTicker": "ATHX",
        "companyURL": "http://www.athersys.com/",
        "companyPipelineURL": "https://www.athersys.com/multistem-therapy/clinical-pipeline/default.aspx"
    },
    {
        "companyName": "Athira Pharma, Inc.",
        "companyTicker": "ATHA",
        "companyURL": "http://www.athira.com/",
        "companyPipelineURL": "https://www.athira.com/pipeline/"
    },
    {
        "companyName": "Atreca, Inc.",
        "companyTicker": "BCEL",
        "companyURL": "http://www.atreca.com/",
        "companyPipelineURL": "https://www.atreca.com/pipeline/"
    },
    {
        "companyName": "Aurinia Pharmaceuticals Inc",
        "companyTicker": "AUPH",
        "companyURL": "http://www.auriniapharma.com/",
        "companyPipelineURL": "https://www.auriniapharma.com/our-science/our-pipeline"
    },
    {
        "companyName": "Autolus Therapeutics plc",
        "companyTicker": "AUTL",
        "companyURL": "http://www.autolus.com/",
        "companyPipelineURL": "https://www.autolus.com/innovation/pipeline-products/"
    },
    {
        "companyName": "Avalo Therapeutics, Inc.",
        "companyTicker": "AVTX",
        "companyURL": "http://www.avalotx.com/",
        "companyPipelineURL": "https://www.avalotx.com/pipeline"
    },
    {
        "companyName": "Avidity Biosciences, Inc.",
        "companyTicker": "RNA",
        "companyURL": "http://www.aviditybiosciences.com/",
        "companyPipelineURL": "https://www.aviditybiosciences.com/pipeline/pipeline-overview/"
    },
    {
        "companyName": "AVROBIO, Inc.",
        "companyTicker": "AVRO",
        "companyURL": "http://www.avrobio.com/",
        "companyPipelineURL": "https://www.avrobio.com/our-pipeline/"
    },
    {
        "companyName": "Axsome Therapeutics, Inc.",
        "companyTicker": "AXSM",
        "companyURL": "http://www.axsome.com/",
        "companyPipelineURL": "https://www.axsome.com/axs-portfolio/pipeline"
    },
    {
        "companyName": "Baudax Bio, Inc.",
        "companyTicker": "BXRX",
        "companyURL": "http://www.baudaxbio.com/",
        "companyPipelineURL": "https://www.baudaxbio.com/rd/product-portfolio"
    },
    {
        "companyName": "Bausch Health Companies Inc.",
        "companyTicker": "BHC",
        "companyURL": "http://www.bauschhealth.com/",
        "companyPipelineURL": "https://www.bauschhealth.com/r-d/our-pipeline/"
    },
    {
        "companyName": "Bavarian Nordic A/S",
        "companyTicker": "BVNRY",
        "companyURL": "http://www.bavarian-nordic.com/",
        "companyPipelineURL": "https://www.bavarian-nordic.com/what-we-do/pipeline.aspx"
    },
    {
        "companyName": "BeiGene, Ltd.",
        "companyTicker": "BGNE",
        "companyURL": "http://www.beigene.com/",
        "companyPipelineURL": "https://www.beigene.com/our-science-and-medicines/pipeline/"
    },
    {
        "companyName": "Bicycle Therapeutics plc",
        "companyTicker": "BCYC",
        "companyURL": "http://www.bicycletherapeutics.com/",
        "companyPipelineURL": "https://www.bicycletherapeutics.com/pipeline/pipeline/"
    },
    {
        "companyName": "BioAtla, Inc.",
        "companyTicker": "BCAB",
        "companyURL": "http://www.bioatla.com/",
        "companyPipelineURL": "https://www.bioatla.com/cab-portfolio/"
    },
    {
        "companyName": "BioCardia, Inc.",
        "companyTicker": "BCDA",
        "companyURL": "http://www.biocardia.com/",
        "companyPipelineURL": "https://www.biocardia.com/pipeline/pipeline-overview/id/1039"
    },
    {
        "companyName": "BioCryst Pharmaceuticals, Inc.",
        "companyTicker": "BCRX",
        "companyURL": "http://www.biocryst.com/",
        "companyPipelineURL": "https://www.biocryst.com/science/pipeline/"
    },
    {
        "companyName": "Biofrontera Inc.",
        "companyTicker": "BFRI",
        "companyURL": "http://www.biofrontera-us.com/",
        "companyPipelineURL": "https://www.biofrontera-us.com/research/product-pipeline"
    },
    {
        "companyName": "Biogen Inc.",
        "companyTicker": "BIIB",
        "companyURL": "http://www.biogen.com/",
        "companyPipelineURL": "https://www.biogen.com/science-and-innovation/pipeline.html"
    },
    {
        "companyName": "Biohaven Ltd.",
        "companyTicker": "BHVN",
        "companyURL": "http://www.biohaven.com/",
        "companyPipelineURL": "https://www.biohaven.com/pipeline/"
    },
    {
        "companyName": "BioMarin Pharmaceutical Inc.",
        "companyTicker": "BMRN",
        "companyURL": "http://www.biomarin.com/",
        "companyPipelineURL": "https://www.biomarin.com/our-treatments/pipeline/"
    },
    {
        "companyName": "BiomX Inc.",
        "companyTicker": "PHGE",
        "companyURL": "http://www.biomx.com/",
        "companyPipelineURL": "https://www.biomx.com/our-pipeline/"
    },
    {
        "companyName": "BioNTech SE",
        "companyTicker": "BNTX",
        "companyURL": "https://www.biontech.com/int/en/home.html",
        "companyPipelineURL": "https://www.biontech.com/int/en/home/pipeline-and-products/pipeline.html"
    },
    {
        "companyName": "Bio-Path Holdings, Inc.",
        "companyTicker": "BPTH",
        "companyURL": "http://www.biopathholdings.com/",
        "companyPipelineURL": "https://www.biontech.com/int/en/home/pipeline-and-products/pipeline.html"
    },
    {
        "companyName": "Immune Therapeutics, Inc.",
        "companyTicker": "IMUN",
        "companyURL": "http://www.biostaxcorp.com/",
        "companyPipelineURL": "https://www.biostaxcorp.com/programs/"
    },
    {
        "companyName": "BioXcel Therapeutics, Inc.",
        "companyTicker": "BTAI",
        "companyURL": "http://www.bioxceltherapeutics.com/",
        "companyPipelineURL": "https://www.bioxceltherapeutics.com/our-pipeline/"
    },
    {
        "companyName": "Black Diamond Therapeutics, Inc.",
        "companyTicker": "BDTX",
        "companyURL": "http://www.blackdiamondtherapeutics.com/",
        "companyPipelineURL": "https://www.blackdiamondtherapeutics.com/pipeline-programs/pipeline"
    },
    {
        "companyName": "bluebird bio, Inc.",
        "companyTicker": "BLUE",
        "companyURL": "http://www.bluebirdbio.com/",
        "companyPipelineURL": "https://www.bluebirdbio.com/our-science/pipeline"
    },
    {
        "companyName": "Blueprint Medicines Corporation",
        "companyTicker": "BPMC",
        "companyURL": "http://www.blueprintmedicines.com/",
        "companyPipelineURL": "https://www.blueprintmedicines.com/pipeline/"
    },
    {
        "companyName": "Bristol-Myers Squibb Company",
        "companyTicker": "BMY",
        "companyURL": "http://www.bms.com/",
        "companyPipelineURL": "https://www.bms.com/researchers-and-partners/in-the-pipeline.html"
    },
    {
        "companyName": "Bolt Biotherapeutics, Inc.",
        "companyTicker": "BOLT",
        "companyURL": "http://www.boltbio.com/",
        "companyPipelineURL": "https://www.boltbio.com/pipeline-new/"
    },
    {
        "companyName": "Cabaletta Bio, Inc.",
        "companyTicker": "CABA",
        "companyURL": "http://www.cabalettabio.com/",
        "companyPipelineURL": "https://www.cabalettabio.com/pipeline"
    },
    {
        "companyName": "Cadrenal Therapeutics, Inc.",
        "companyTicker": "CVKD",
        "companyURL": "http://www.cadrenal.com/",
        "companyPipelineURL": "https://www.cadrenal.com/our-focus/"
    },
    {
        "companyName": "Calliditas Therapeutics AB",
        "companyTicker": "CALT",
        "companyURL": "https://www.calliditas.se/en/",
        "companyPipelineURL": "https://www.calliditas.se/en/our-science/#pipeline"
    },
    {
        "companyName": "Can-Fite Biopharma Ltd Sponsored",
        "companyTicker": "CANF",
        "companyURL": "http://www.canfite.com/",
        "companyPipelineURL": "https://www.canfite.com/category/PIPELINE-Overview"
    },
    {
        "companyName": "Capricor Therapeutics, Inc.",
        "companyTicker": "CAPR",
        "companyURL": "http://www.capricor.com/",
        "companyPipelineURL": "https://www.capricor.com/pipeline"
    },
    {
        "companyName": "Cara Therapeutics, Inc.",
        "companyTicker": "CARA",
        "companyURL": "http://www.caratherapeutics.com/",
        "companyPipelineURL": "https://www.caratherapeutics.com/pipeline/"
    },
    {
        "companyName": "Cardiol Therapeutics Inc.",
        "companyTicker": "CRDL",
        "companyURL": "http://www.cardiolrx.com/",
        "companyPipelineURL": "https://www.cardiolrx.com/pipeline/"
    },
    {
        "companyName": "Caribou Biosciences, Inc.",
        "companyTicker": "CRBU",
        "companyURL": "http://www.cariboubio.com/",
        "companyPipelineURL": "https://www.cariboubio.com/pipeline/"
    },
    {
        "companyName": "Carmell Therapeutics Corporation",
        "companyTicker": "CTCX",
        "companyURL": "http://www.carmellrx.com/",
        "companyPipelineURL": "https://www.carmellrx.com/products/"
    },
    {
        "companyName": "CASI Pharmaceuticals, Inc.",
        "companyTicker": "CASI",
        "companyURL": "http://www.casipharmaceuticals.com/",
        "companyPipelineURL": "https://www.casipharmaceuticals.com/pipeline.html"
    },
    {
        "companyName": "Cassava Sciences, Inc.",
        "companyTicker": "SAVA",
        "companyURL": "http://www.cassavasciences.com/",
        "companyPipelineURL": "https://www.cassavasciences.com/science"
    },
    {
        "companyName": "Cellectar Biosciences, Inc.",
        "companyTicker": "CLRB",
        "companyURL": "http://www.cellectar.com/",
        "companyPipelineURL": "https://www.cellectar.com/product-pipeline"
    },
    {
        "companyName": "Cellectis S.A.",
        "companyTicker": "CLLS",
        "companyURL": "http://www.cellectis.com/",
        "companyPipelineURL": "https://www.cellectis.com/en/products/product-candidates/"
    },
    {
        "companyName": "Cerevel Therapeutics Holdings, Inc.",
        "companyTicker": "CERE",
        "companyURL": "http://www.cerevel.com/",
        "companyPipelineURL": "https://www.cerevel.com/rd/"
    },
    {
        "companyName": "Chemomab Therapeutics Ltd.",
        "companyTicker": "CMMB",
        "companyURL": "http://www.chemomab.com/",
        "companyPipelineURL": "https://www.chemomab.com/pipeline/"
    },
    {
        "companyName": "Chimerix, Inc.",
        "companyTicker": "CMRX",
        "companyURL": "http://www.chimerix.com/",
        "companyPipelineURL": "https://www.chimerix.com/our-pipeline/"
    },
    {
        "companyName": "Chugai Pharmaceutical Co., Ltd.",
        "companyTicker": "CHGCY",
        "companyURL": "https://www.chugai-pharm.co.jp/english/index.html",
        "companyPipelineURL": "https://www.chugai-pharm.co.jp/english/ir/product/pipeline.html"
    },
    {
        "companyName": "Cidara Therapeutics, Inc.",
        "companyTicker": "CDTX",
        "companyURL": "http://www.cidara.com/",
        "companyPipelineURL": "https://www.cidara.com/pipeline/"
    },
    {
        "companyName": "Cingulate Inc.",
        "companyTicker": "CING",
        "companyURL": "http://www.cingulate.com/",
        "companyPipelineURL": "https://www.cingulate.com/pipeline/pipeline-overview"
    },
    {
        "companyName": "Clearmind Medicine Inc.",
        "companyTicker": "CMND",
        "companyURL": "http://www.clearmindmedicine.com/",
        "companyPipelineURL": "https://www.clearmindmedicine.com/"
    },
    {
        "companyName": "Cogent Biosciences, Inc.",
        "companyTicker": "COGT",
        "companyURL": "http://www.cogentbio.com/",
        "companyPipelineURL": "https://www.cogentbio.com/pipelines-targets/#pipeline"
    },
    {
        "companyName": "Coherus BioSciences, Inc.",
        "companyTicker": "CHRS",
        "companyURL": "http://www.coherus.com/",
        "companyPipelineURL": "https://www.coherus.com/products-and-pipeline/"
    },
    {
        "companyName": "Compass Therapeutics, Inc.",
        "companyTicker": "CMPX",
        "companyURL": "http://www.compasstherapeutics.com/",
        "companyPipelineURL": "https://www.compasstherapeutics.com/pipeline/"
    },
    {
        "companyName": "Connect Biopharma Holdings Limited",
        "companyTicker": "CNTB",
        "companyURL": "http://www.connectbiopharm.com/",
        "companyPipelineURL": "https://www.connectbiopharm.com/pipeline2/"
    },
    {
        "companyName": "Context Therapeutics Inc.",
        "companyTicker": "CNTX",
        "companyURL": "http://www.contexttherapeutics.com/",
        "companyPipelineURL": "https://www.contexttherapeutics.com/ctim76"
    },
    {
        "companyName": "ContraFect Corporation",
        "companyTicker": "CFRX",
        "companyURL": "http://www.contrafect.com/",
        "companyPipelineURL": "https://www.contrafect.com/pipeline/overview"
    },
    {
        "companyName": "Corbus Pharmaceuticals Holdings, Inc.",
        "companyTicker": "CRBP",
        "companyURL": "http://www.corbuspharma.com/",
        "companyPipelineURL": "https://www.corbuspharma.com/our-pipeline"
    },
    {
        "companyName": "Corcept Therapeutics Incorporated",
        "companyTicker": "CORT",
        "companyURL": "http://www.corcept.com/",
        "companyPipelineURL": "https://www.corcept.com/research-pipeline/pipeline/"
    },
    {
        "companyName": "Corvus Pharmaceuticals, Inc.",
        "companyTicker": "CRVS",
        "companyURL": "http://www.corvuspharma.com/",
        "companyPipelineURL": "https://www.corvuspharma.com/our-science/our-pipeline/"
    },
    {
        "companyName": "Cue Biopharma, Inc.",
        "companyTicker": "CUE",
        "companyURL": "http://www.cuebiopharma.com/",
        "companyPipelineURL": "https://www.cuebiopharma.com/pipeline/"
    },
    {
        "companyName": "Cumberland Pharmaceuticals Inc.",
        "companyTicker": "CPIX",
        "companyURL": "http://www.cumberlandpharma.com/",
        "companyPipelineURL": "https://www.cumberlandpharma.com/our-products"
    },
    {
        "companyName": "CureVac N.V.",
        "companyTicker": "CVAC",
        "companyURL": "https://www.curevac.com/en/",
        "companyPipelineURL": "https://www.curevac.com/en/pipeline/"
    },
    {
        "companyName": "Curis, Inc.",
        "companyTicker": "CRIS",
        "companyURL": "http://www.curis.com/",
        "companyPipelineURL": "https://www.curis.com/pipeline/pipeline-overview/"
    },
    {
        "companyName": "Cyclerion Therapeutics, Inc.",
        "companyTicker": "CYCN",
        "companyURL": "http://www.cyclerion.com/",
        "companyPipelineURL": "https://www.cyclerion.com/our-assets/"
    },
    {
        "companyName": "CymaBay Therapeutics Inc.",
        "companyTicker": "CBAY",
        "companyURL": "http://www.cymabay.com/",
        "companyPipelineURL": "https://www.cymabay.com/our-science"
    },
    {
        "companyName": "CytoDyn Inc.",
        "companyTicker": "CYDY",
        "companyURL": "http://www.cytodyn.com/",
        "companyPipelineURL": "https://www.cytodyn.com/pipeline"
    },
    {
        "companyName": "Day One Biopharmaceuticals, Inc.",
        "companyTicker": "DAWN",
        "companyURL": "http://www.dayonebio.com/",
        "companyPipelineURL": "https://www.dayonebio.com/our-pipeline/"
    },
    {
        "companyName": "Decibel Therapeutics, Inc.",
        "companyTicker": "DBTX",
        "companyURL": "http://www.decibeltx.com/",
        "companyPipelineURL": "https://www.decibeltx.com/pipeline/"
    },
    {
        "companyName": "Deciphera Pharmaceuticals, Inc.",
        "companyTicker": "DCPH",
        "companyURL": "http://www.deciphera.com/",
        "companyPipelineURL": "https://www.deciphera.com/pipeline"
    },
    {
        "companyName": "Denali Therapeutics Inc.",
        "companyTicker": "DNLI",
        "companyURL": "http://www.denalitherapeutics.com/",
        "companyPipelineURL": "https://www.denalitherapeutics.com/pipeline"
    },
    {
        "companyName": "Dermata Therapeutics, Inc.",
        "companyTicker": "DRMA",
        "companyURL": "http://www.dermatarx.com/",
        "companyPipelineURL": "https://www.dermatarx.com/new-index"
    },
    {
        "companyName": "Design Therapeutics, Inc.",
        "companyTicker": "DSGN",
        "companyURL": "http://www.designtx.com/",
        "companyPipelineURL": "https://www.designtx.com/our-programs/"
    },
    {
        "companyName": "DiaMedica Therapeutics Inc.",
        "companyTicker": "DMAC",
        "companyURL": "http://www.diamedica.com/",
        "companyPipelineURL": "https://www.diamedica.com/pipeline"
    },
    {
        "companyName": "Disc Medicine, Inc.",
        "companyTicker": "IRON",
        "companyURL": "http://www.discmedicine.com/",
        "companyPipelineURL": "https://www.discmedicine.com/our-pipeline/"
    },
    {
        "companyName": "DURECT Corporation",
        "companyTicker": "DRRX",
        "companyURL": "http://www.durect.com/",
        "companyPipelineURL": "https://www.durect.com/clinical-development/"
    },
    {
        "companyName": "Dynavax Technologies Corporation",
        "companyTicker": "DVAX",
        "companyURL": "http://www.dynavax.com/",
        "companyPipelineURL": "https://www.dynavax.com/Pipeline/"
    },
    {
        "companyName": "Dyne Therapeutics, Inc.",
        "companyTicker": "DYN",
        "companyURL": "http://www.dyne-tx.com/",
        "companyPipelineURL": "https://www.dyne-tx.com/pipeline/"
    },
    {
        "companyName": "Eagle Pharmaceuticals, Inc.",
        "companyTicker": "EGRX",
        "companyURL": "http://www.eagleus.com/",
        "companyPipelineURL": "https://www.eagleus.com/pipeline/"
    },
    {
        "companyName": "Edesa Biotech, Inc.",
        "companyTicker": "EDSA",
        "companyURL": "http://www.edesabiotech.com/",
        "companyPipelineURL": "https://www.edesabiotech.com/clinical-pipeline/"
    },
    {
        "companyName": "Editas Medicine, Inc.",
        "companyTicker": "EDIT",
        "companyURL": "http://www.editasmedicine.com/",
        "companyPipelineURL": "https://www.editasmedicine.com/gene-editing-pipeline/"
    },
    {
        "companyName": "Eiger BioPharmaceuticals, Inc.",
        "companyTicker": "EIGR",
        "companyURL": "http://www.eigerbio.com/",
        "companyPipelineURL": "https://www.eigerbio.com/programs/"
    },
    {
        "companyName": "Eloxx Pharmaceuticals, Inc.",
        "companyTicker": "ELOX",
        "companyURL": "http://www.eloxxpharma.com/",
        "companyPipelineURL": "https://www.eloxxpharma.com/pipeline/"
    },
    {
        "companyName": "Emergent Biosolutions, Inc.",
        "companyTicker": "EBS",
        "companyURL": "http://www.emergentbiosolutions.com/",
        "companyPipelineURL": "https://www.emergentbiosolutions.com/products-services/pipeline/"
    },
    {
        "companyName": "Enanta Pharmaceuticals, Inc.",
        "companyTicker": "ENTA",
        "companyURL": "http://www.enanta.com/",
        "companyPipelineURL": "https://www.enanta.com/pipeline/pipeline-overview/"
    },
    {
        "companyName": "Endo International plc",
        "companyTicker": "ENDPQ",
        "companyURL": "http://www.endo.com/",
        "companyPipelineURL": "https://www.endo.com/r-d/products-in-development"
    },
    {
        "companyName": "Enliven Therapeutics, Inc.",
        "companyTicker": "ELVN",
        "companyURL": "http://www.enliventherapeutics.com/",
        "companyPipelineURL": "https://www.enliventherapeutics.com/pipeline/"
    },
    {
        "companyName": "Entrada Therapeutics, Inc.",
        "companyTicker": "TRDA",
        "companyURL": "http://www.entradatx.com/",
        "companyPipelineURL": "https://www.entradatx.com/pipeline"
    },
    {
        "companyName": "Enveric Biosciences, Inc.",
        "companyTicker": "ENVB",
        "companyURL": "http://www.enveric.com/",
        "companyPipelineURL": "https://www.enveric.com/pipeline/"
    },
    {
        "companyName": "Enzo Biochem, Inc.",
        "companyTicker": "ENZ",
        "companyURL": "http://www.enzo.com/",
        "companyPipelineURL": "https://www.enzo.com/therapeutics/pipeline/overview"
    },
    {
        "companyName": "Equillium, Inc.",
        "companyTicker": "EQ",
        "companyURL": "http://www.equilliumbio.com/",
        "companyPipelineURL": "https://www.equilliumbio.com/clinical/"
    },
    {
        "companyName": "Erasca, Inc.",
        "companyTicker": "ERAS",
        "companyURL": "http://www.erasca.com/",
        "companyPipelineURL": "https://www.erasca.com/pipeline/"
    },
    {
        "companyName": "Esperion Therapeutics, Inc.",
        "companyTicker": "ESPR",
        "companyURL": "http://www.esperion.com/",
        "companyPipelineURL": "https://www.esperion.com/science/pipeline"
    },
    {
        "companyName": "ESSA Pharma Inc.",
        "companyTicker": "EPIX",
        "companyURL": "http://www.essapharma.com/",
        "companyPipelineURL": "https://www.essapharma.com/product-candidates/pipeline/"
    },
    {
        "companyName": "Eton Pharmaceuticals, Inc.",
        "companyTicker": "ETON",
        "companyURL": "http://www.etonpharma.com/",
        "companyPipelineURL": "https://www.etonpharma.com/pipeline/"
    },
    {
        "companyName": "Evaxion Biotech A/S",
        "companyTicker": "EVAX",
        "companyURL": "http://www.evaxion-biotech.com/",
        "companyPipelineURL": "https://www.evaxion-biotech.com/pipeline"
    },
    {
        "companyName": "Evofem Biosciences, Inc.",
        "companyTicker": "EVFM",
        "companyURL": "http://www.evofem.com/",
        "companyPipelineURL": "https://www.evofem.com/pipeline/in-development/"
    },
    {
        "companyName": "Exelixis, Inc.",
        "companyTicker": "EXEL",
        "companyURL": "http://www.exelixis.com/",
        "companyPipelineURL": "https://www.exelixis.com/clinical-trials-pipeline/"
    },
    {
        "companyName": "FibroGen, Inc",
        "companyTicker": "FGEN",
        "companyURL": "http://www.fibrogen.com/",
        "companyPipelineURL": "https://www.fibrogen.com/r-d-pipeline"
    },
    {
        "companyName": "First Wave BioPharma, Inc.",
        "companyTicker": "FWBI",
        "companyURL": "http://www.firstwavebio.com/",
        "companyPipelineURL": "https://www.firstwavebio.com/pipeline"
    },
    {
        "companyName": "Forte Biosciences, Inc.",
        "companyTicker": "FBRX",
        "companyURL": "http://www.fortebiorx.com/",
        "companyPipelineURL": "https://www.fortebiorx.com/pipeline/default.aspx"
    },
    {
        "companyName": "Fortress Biotech, Inc.",
        "companyTicker": "FBIO",
        "companyURL": "http://www.fortressbiotech.com/",
        "companyPipelineURL": "https://www.fortressbiotech.com/programs"
    },
    {
        "companyName": "Frequency Therapeutics, Inc.",
        "companyTicker": "FREQ",
        "companyURL": "http://www.frequencytx.com/",
        "companyPipelineURL": "https://www.frequencytx.com/pipeline-programs/pipeline/"
    },
    {
        "companyName": "Fresh Tracks Therapeutics, Inc.",
        "companyTicker": "FRTX",
        "companyURL": "http://www.frtx.com/",
        "companyPipelineURL": "https://www.frtx.com/pipeline/overview/"
    },
    {
        "companyName": "Fulcrum Therapeutics, Inc.",
        "companyTicker": "FULC",
        "companyURL": "http://www.fulcrumtx.com/",
        "companyPipelineURL": "https://www.fulcrumtx.com/pipeline/"
    },
    {
        "companyName": "G1 Therapeutics, Inc.",
        "companyTicker": "GTHX",
        "companyURL": "http://www.g1therapeutics.com/",
        "companyPipelineURL": "https://www.g1therapeutics.com/science/pipeline/"
    },
    {
        "companyName": "Gain Therapeutics, Inc.",
        "companyTicker": "GANX",
        "companyURL": "http://www.gaintherapeutics.com/",
        "companyPipelineURL": "https://www.gaintherapeutics.com/pipeline/brief-introduction.html"
    },
    {
        "companyName": "Galera Therapeutics, Inc.",
        "companyTicker": "GRTX",
        "companyURL": "http://www.galeratx.com/",
        "companyPipelineURL": "https://www.galeratx.com/pipeline/"
    },
    {
        "companyName": "Gamida Cell Ltd.",
        "companyTicker": "GMDA",
        "companyURL": "http://www.gamida-cell.com/",
        "companyPipelineURL": "https://www.gamida-cell.com/our-rd/"
    },
    {
        "companyName": "Genenta Science S.p.A.",
        "companyTicker": "GNTA",
        "companyURL": "http://www.genenta.com/",
        "companyPipelineURL": "https://www.genenta.com/our-pipeline/"
    },
    {
        "companyName": "GENFIT S.A.",
        "companyTicker": "GNFT",
        "companyURL": "http://www.genfit.com/",
        "companyPipelineURL": "https://www.genfit.com/therapeutics/"
    },
    {
        "companyName": "Genprex, Inc.",
        "companyTicker": "GNPX",
        "companyURL": "http://www.genprex.com/",
        "companyPipelineURL": "https://www.genprex.com/clinical-trials/pipeline/"
    },
    {
        "companyName": "GeoVax Labs, Inc.",
        "companyTicker": "GOVX",
        "companyURL": "http://www.geovax.com/",
        "companyPipelineURL": "https://www.geovax.com/technology-pipeline"
    },
    {
        "companyName": "Geron Corporation",
        "companyTicker": "GERN",
        "companyURL": "http://www.geron.com/",
        "companyPipelineURL": "https://www.geron.com/research-and-development/"
    },
    {
        "companyName": "GH Research PLC",
        "companyTicker": "GHRS",
        "companyURL": "http://www.ghres.com/",
        "companyPipelineURL": "https://www.ghres.com/pipeline"
    },
    {
        "companyName": "Gilead Sciences, Inc.",
        "companyTicker": "GILD",
        "companyURL": "http://www.gilead.com/",
        "companyPipelineURL": "https://www.gilead.com/science-and-medicine/pipeline"
    },
    {
        "companyName": "Galapagos NV",
        "companyTicker": "GLPG",
        "companyURL": "http://www.glpg.com/",
        "companyPipelineURL": "https://www.glpg.com/pipeline"
    },
    {
        "companyName": "Gossamer Bio, Inc.",
        "companyTicker": "GOSS",
        "companyURL": "http://www.gossamerbio.com/",
        "companyPipelineURL": "https://www.gossamerbio.com/pipeline/pipeline-overview/"
    },
    {
        "companyName": "Gracell Biotechnologies Inc.",
        "companyTicker": "GRCL",
        "companyURL": "http://www.gracellbio.com/",
        "companyPipelineURL": "https://www.gracellbio.com/pipeline/"
    },
    {
        "companyName": "Grifols, S.A.",
        "companyTicker": "GRFS",
        "companyURL": "http://www.grifols.com/",
        "companyPipelineURL": "https://www.grifols.com/en/key-therapeutic-areas"
    },
    {
        "companyName": "GSK plc",
        "companyTicker": "GSK",
        "companyURL": "https://us.gsk.com/",
        "companyPipelineURL": "https://www.gsk.com/en-gb/innovation/pipeline/"
    },
    {
        "companyName": "GT Biopharma, Inc.",
        "companyTicker": "GTBP",
        "companyURL": "http://www.gtbiopharma.com/",
        "companyPipelineURL": "https://www.gtbiopharma.com/product-pipeline/overview"
    },
    {
        "companyName": "Harmony Biosciences Holdings, Inc.",
        "companyTicker": "HRMY",
        "companyURL": "http://www.harmonybiosciences.com/",
        "companyPipelineURL": "https://www.harmonybiosciences.com/science"
    },
    {
        "companyName": "Harpoon Therapeutics, Inc.",
        "companyTicker": "HARP",
        "companyURL": "http://www.harpoontx.com/",
        "companyPipelineURL": "https://www.harpoontx.com/pipeline/"
    },
    {
        "companyName": "Heron Therapeutics, Inc.",
        "companyTicker": "HRTX",
        "companyURL": "http://www.herontx.com/",
        "companyPipelineURL": "https://www.herontx.com/product-portfolio/pipeline/"
    },
    {
        "companyName": "HilleVax, Inc.",
        "companyTicker": "HLVX",
        "companyURL": "http://www.hillevax.com/",
        "companyPipelineURL": "https://www.hillevax.com/pipeline/"
    },
    {
        "companyName": "Homology Medicines, Inc.",
        "companyTicker": "FIXX",
        "companyURL": "http://www.homologymedicines.com/",
        "companyPipelineURL": "https://www.homologymedicines.com/pipeline/"
    },
    {
        "companyName": "HOOKIPA Pharma Inc.",
        "companyTicker": "HOOK",
        "companyURL": "http://www.hookipapharma.com/",
        "companyPipelineURL": "https://www.hookipapharma.com/our-programs/our-pipeline/"
    },
    {
        "companyName": "Horizon Therapeutics Public Limited Company",
        "companyTicker": "HZNP",
        "companyURL": "http://www.horizontherapeutics.com/",
        "companyPipelineURL": "https://www.horizontherapeutics.com/science/pipeline"
    },
    {
        "companyName": "Humanigen, Inc.",
        "companyTicker": "HGEN",
        "companyURL": "http://www.humanigen.com/",
        "companyPipelineURL": "https://www.humanigen.com/business-development"
    },
    {
        "companyName": "Hutchison China Ltd.",
        "companyTicker": "HMDCF",
        "companyURL": "http://www.hutch-med.com/",
        "companyPipelineURL": "https://www.hutch-med.com/pipeline-and-products/our-pipeline/"
    },
    {
        "companyName": "IDEAYA Biosciences, Inc.",
        "companyTicker": "IDYA",
        "companyURL": "http://www.ideayabio.com/",
        "companyPipelineURL": "https://www.ideayabio.com/pipeline/"
    },
    {
        "companyName": "I-MAB",
        "companyTicker": "IMAB",
        "companyURL": "http://www.i-mabbiopharma.com/",
        "companyPipelineURL": "https://www.i-mabbiopharma.com/science/#key-products"
    },
    {
        "companyName": "ImmunoGen, Inc.",
        "companyTicker": "IMGN",
        "companyURL": "http://www.immunogen.com/",
        "companyPipelineURL": "https://www.immunogen.com/what-we-do/our-pipeline/"
    },
    {
        "companyName": "Immunome, Inc.",
        "companyTicker": "IMNM",
        "companyURL": "http://www.immunome.com/",
        "companyPipelineURL": "https://www.immunome.com/pipeline/"
    },
    {
        "companyName": "Immunovant, Inc.",
        "companyTicker": "IMVT",
        "companyURL": "http://www.immunovant.com/",
        "companyPipelineURL": "https://www.immunovant.com/our-science/pipeline-and-therapeutic-areas"
    },
    {
        "companyName": "Immutep Limited",
        "companyTicker": "IMMP",
        "companyURL": "http://www.immutep.com/",
        "companyPipelineURL": "https://www.immutep.com/pipeline/clinical-pipeline.html"
    },
    {
        "companyName": "IMV Inc.",
        "companyTicker": "IMVIQ",
        "companyURL": "http://www.imv-inc.com/",
        "companyPipelineURL": "https://www.imv-inc.com/pipeline"
    },
    {
        "companyName": "Incyte Corporation",
        "companyTicker": "INCY",
        "companyURL": "http://www.incyte.com/",
        "companyPipelineURL": "https://www.incyte.com/what-we-do/pharmaceutical-portfolio"
    },
    {
        "companyName": "Indivior PLC",
        "companyTicker": "INDV",
        "companyURL": "http://indivior.com/en/",
        "companyPipelineURL": "https://www.indivior.com/en/our-science/pipeline"
    },
    {
        "companyName": "Infinity Pharmaceuticals, Inc.",
        "companyTicker": "INFI",
        "companyURL": "http://www.infi.com/",
        "companyPipelineURL": "https://www.infi.com/home/our-development-program/our-pipeline/"
    },
    {
        "companyName": "InflaRx N.V.",
        "companyTicker": "IFRX",
        "companyURL": "http://www.inflarx.de/",
        "companyPipelineURL": "https://www.inflarx.de/Home/Research---Development/Pipeline.html"
    },
    {
        "companyName": "Inhibikase Therapeutics, Inc.",
        "companyTicker": "IKT",
        "companyURL": "http://www.inhibikase.com/",
        "companyPipelineURL": "https://www.inhibikase.com/pipeline"
    },
    {
        "companyName": "InMed Pharmaceuticals Inc.",
        "companyTicker": "INM",
        "companyURL": "http://www.inmedpharma.com/",
        "companyPipelineURL": "https://www.inmedpharma.com/pharmaceutical/cannabinoids-in-development/"
    },
    {
        "companyName": "INmune Bio Inc.",
        "companyTicker": "INMB",
        "companyURL": "http://www.inmunebio.com/",
        "companyPipelineURL": "https://www.inmunebio.com/science/pipeline"
    },
    {
        "companyName": "Innate Pharma S.A.",
        "companyTicker": "IPHA",
        "companyURL": "http://www.innate-pharma.com/en",
        "companyPipelineURL": "https://www.innate-pharma.com/products/pipeline-overview"
    },
    {
        "companyName": "Inozyme Pharma, Inc.",
        "companyTicker": "INZY",
        "companyURL": "http://www.inozyme.com/",
        "companyPipelineURL": "https://www.inozyme.com/pipeline/our-science#main"
    },
    {
        "companyName": "IntelGenx Technologies Corp.",
        "companyTicker": "IGXT",
        "companyURL": "http://www.intelgenx.com/",
        "companyPipelineURL": "https://www.intelgenx.com/products/overview"
    },
    {
        "companyName": "Intellia Therapeutics, Inc.",
        "companyTicker": "NTLA",
        "companyURL": "http://www.intelliatx.com/",
        "companyPipelineURL": "https://www.intelliatx.com/pipeline/"
    },
    {
        "companyName": "Intercept Pharmaceuticals, Inc.",
        "companyTicker": "ICPT",
        "companyURL": "http://www.interceptpharma.com/",
        "companyPipelineURL": "https://www.interceptpharma.com/our-research/pipeline/"
    },
    {
        "companyName": "Intra-Cellular Therapies Inc.",
        "companyTicker": "ITCI",
        "companyURL": "http://www.intracellulartherapies.com/",
        "companyPipelineURL": "https://www.intracellulartherapies.com/pipeline/"
    },
    {
        "companyName": "Innoviva, Inc.",
        "companyTicker": "INVA",
        "companyURL": "http://www.inva.com/",
        "companyPipelineURL": "https://www.inva.com/strategic-assets/"
    },
    {
        "companyName": "Johnson & Johnson",
        "companyTicker": "JNJ",
        "companyURL": "http://www.jnj.com/",
        "companyPipelineURL": "https://www.investor.jnj.com/pharmaceutical-pipeline-information"
    },
    {
        "companyName": "InVivo Therapeutics Holdings Corp.",
        "companyTicker": "NVIV",
        "companyURL": "http://www.invivotherapeutics.com/",
        "companyPipelineURL": "https://www.invivotherapeutics.com/our-pipeline/"
    },
    {
        "companyName": "Ionis Pharmaceuticals, Inc.",
        "companyTicker": "IONS",
        "companyURL": "http://www.ionispharm.com/",
        "companyPipelineURL": "https://www.ionispharma.com/ionis-technology/antisense-pipeline/"
    },
    {
        "companyName": "Iovance Biotherapeutics, Inc.",
        "companyTicker": "IOVA",
        "companyURL": "http://www.iovance.com/",
        "companyPipelineURL": "https://www.iovance.com/clinical-pipeline/"
    },
    {
        "companyName": "iTeos Therapeutics, Inc.",
        "companyTicker": "ITOS",
        "companyURL": "http://www.iteostherapeutics.com/",
        "companyPipelineURL": "https://www.iteostherapeutics.com/pipeline/"
    },
    {
        "companyName": "Janux Therapeutics, Inc.",
        "companyTicker": "JANX",
        "companyURL": "http://www.januxrx.com/",
        "companyPipelineURL": "https://www.januxrx.com/developement-pipeline/"
    },
    {
        "companyName": "Jasper Therapeutics, Inc.",
        "companyTicker": "JSPR",
        "companyURL": "http://www.jaspertherapeutics.com/",
        "companyPipelineURL": "https://www.jaspertherapeutics.com/pipeline/"
    },
    {
        "companyName": "Jazz Pharmaceuticals plc",
        "companyTicker": "JAZZ",
        "companyURL": "http://www.jazzpharma.com/",
        "companyPipelineURL": "https://www.jazzpharma.com/science/pipeline/"
    },
    {
        "companyName": "KALA BIO, Inc.",
        "companyTicker": "KALA",
        "companyURL": "http://www.kalarx.com/",
        "companyPipelineURL": "https://www.kalarx.com/pipeline.htm"
    },
    {
        "companyName": "KalVista Pharmaceuticals, Inc.",
        "companyTicker": "KALV",
        "companyURL": "http://www.kalvista.com/",
        "companyPipelineURL": "https://www.kalvista.com/products-pipeline"
    },
    {
        "companyName": "Kamada Ltd.",
        "companyTicker": "KMDA",
        "companyURL": "http://www.kamada.com/",
        "companyPipelineURL": "https://www.kamada.com/pipeline/"
    },
    {
        "companyName": "Karyopharm Therapeutics Inc.",
        "companyTicker": "KPTI",
        "companyURL": "http://www.karyopharm.com/",
        "companyPipelineURL": "https://www.karyopharm.com/science/pipeline/"
    },
    {
        "companyName": "Kazia Therapeutics Limited",
        "companyTicker": "KZIA",
        "companyURL": "http://www.kaziatherapeutics.com/",
        "companyPipelineURL": "https://www.kaziatherapeutics.com/site/for-researchers"
    },
    {
        "companyName": "Kiniksa Pharmaceuticals, Ltd.",
        "companyTicker": "KNSA",
        "companyURL": "http://www.kiniksa.com/",
        "companyPipelineURL": "https://www.kiniksa.com/pipeline"
    },
    {
        "companyName": "Kinnate Biopharma Inc.",
        "companyTicker": "KNTE",
        "companyURL": "http://www.kinnate.com/",
        "companyPipelineURL": "https://www.kinnate.com/science-and-pipeline/"
    },
    {
        "companyName": "Kintara Therapeutics, Inc.",
        "companyTicker": "KTRA",
        "companyURL": "http://www.kintara.com/",
        "companyPipelineURL": "https://www.kintara.com/pipeline"
    },
    {
        "companyName": "Kronos Bio, Inc.",
        "companyTicker": "KRON",
        "companyURL": "http://www.kronosbio.com/",
        "companyPipelineURL": "https://www.kronosbio.com/pipeline/"
    },
    {
        "companyName": "Krystal Biotech, Inc.",
        "companyTicker": "KRYS",
        "companyURL": "http://www.krystalbio.com/",
        "companyPipelineURL": "https://www.krystalbio.com/focus/pipeline/"
    },
    {
        "companyName": "Kymera Therapeutics, Inc.",
        "companyTicker": "KYMR",
        "companyURL": "http://www.kymeratx.com/",
        "companyPipelineURL": "https://www.kymeratx.com/pipeline/"
    },
    {
        "companyName": "Lantern Pharma Inc.",
        "companyTicker": "LTRN",
        "companyURL": "http://www.lanternpharma.com/",
        "companyPipelineURL": "https://www.lanternpharma.com/pipeline"
    },
    {
        "companyName": "LAVA Therapeutics N.V.",
        "companyTicker": "LVTX",
        "companyURL": "http://www.lavatherapeutics.com/",
        "companyPipelineURL": "https://www.lavatherapeutics.com/pipeline-programs/pipeline/"
    },
    {
        "companyName": "Leap Therapeutics, Inc.",
        "companyTicker": "LPTX",
        "companyURL": "http://www.leaptx.com/",
        "companyPipelineURL": "https://www.leaptx.com/pipeline/"
    },
    {
        "companyName": "Lexicon Pharmaceuticals, Inc.",
        "companyTicker": "LXRX",
        "companyURL": "http://www.lexpharma.com/",
        "companyPipelineURL": "https://www.lexpharma.com/pipeline"
    },
    {
        "companyName": "LianBio",
        "companyTicker": "LIAN",
        "companyURL": "http://www.lianbio.com/",
        "companyPipelineURL": "https://www.lianbio.com/pipeline"
    },
    {
        "companyName": "Ligand Pharmaceuticals Incorporated",
        "companyTicker": "LGND",
        "companyURL": "http://www.ligand.com/",
        "companyPipelineURL": "https://www.ligand.com/portfolio-summary/portfolio"
    },
    {
        "companyName": "Eli Lilly and Company",
        "companyTicker": "LLY",
        "companyURL": "http://www.lilly.com/",
        "companyPipelineURL": "https://www.lilly.com/discovery/clinical-development-pipeline"
    },
    {
        "companyName": "Lipocine Inc.",
        "companyTicker": "LPCN",
        "companyURL": "http://www.lipocine.com/",
        "companyPipelineURL": "https://www.lipocine.com/pipeline/"
    },
    {
        "companyName": "Liquidia Corporation",
        "companyTicker": "LQDA",
        "companyURL": "http://www.liquidia.com/",
        "companyPipelineURL": "https://www.liquidia.com/products-and-pipeline/overview"
    },
    {
        "companyName": "Lisata Therapeutics, Inc.",
        "companyTicker": "LSTA",
        "companyURL": "http://www.lisata.com/",
        "companyPipelineURL": "https://www.lisata.com/product-development-pipeline/"
    },
    {
        "companyName": "Madrigal Pharmaceuticals, Inc.",
        "companyTicker": "MDGL",
        "companyURL": "http://www.madrigalpharma.com/",
        "companyPipelineURL": "https://www.madrigalpharma.com/our-programs/"
    },
    {
        "companyName": "Medicenna Therapeutics Corp.",
        "companyTicker": "MDNA",
        "companyURL": "http://www.medicenna.com/",
        "companyPipelineURL": "https://www.medicenna.com/pipeline/"
    },
    {
        "companyName": "Medicure Inc.",
        "companyTicker": "MCUJF",
        "companyURL": "http://www.medicure.com/",
        "companyPipelineURL": "https://www.medicure.com/pipeline"
    },
    {
        "companyName": "MediWound Ltd.",
        "companyTicker": "MDWD",
        "companyURL": "http://www.mediwound.com/",
        "companyPipelineURL": "https://www.mediwound.com/products/"
    },
    {
        "companyName": "MEI Pharma, Inc.",
        "companyTicker": "MEIP",
        "companyURL": "http://www.meipharma.com/",
        "companyPipelineURL": "https://www.meipharma.com/pipeline"
    },
    {
        "companyName": "Merck & Company, Inc.",
        "companyTicker": "MRK",
        "companyURL": "http://www.merck.com/",
        "companyPipelineURL": "https://www.merck.com/research/product-pipeline/"
    },
    {
        "companyName": "Mereo BioPharma Group plc",
        "companyTicker": "MREO",
        "companyURL": "http://www.mereobiopharma.com/",
        "companyPipelineURL": "https://www.mereobiopharma.com/our-pipeline/"
    },
    {
        "companyName": "Mersana Therapeutics, Inc.",
        "companyTicker": "MRSN",
        "companyURL": "http://www.mersana.com/",
        "companyPipelineURL": "https://www.mersana.com/pipeline/overview/"
    },
    {
        "companyName": "Mesoblast Limited",
        "companyTicker": "MESO",
        "companyURL": "http://www.mesoblast.com/",
        "companyPipelineURL": "https://www.mesoblast.com/product-candidates/product-candidates-overview"
    },
    {
        "companyName": "Minerva Neurosciences, Inc",
        "companyTicker": "NERV",
        "companyURL": "http://www.minervaneurosciences.com/",
        "companyPipelineURL": "https://www.minervaneurosciences.com/programs/"
    },
    {
        "companyName": "MIRA Pharmaceuticals, Inc.",
        "companyTicker": "MIRA",
        "companyURL": "http://mirapharmaceuticals.com/",
        "companyPipelineURL": "https://www.mirapharmaceuticals.com/mira1a/pipeline"
    },
    {
        "companyName": "Mirati Therapeutics, Inc.",
        "companyTicker": "MRTX",
        "companyURL": "http://www.mirati.com/",
        "companyPipelineURL": "https://www.mirati.com/science/pipeline/"
    },
    {
        "companyName": "Miromatrix Medical Inc.",
        "companyTicker": "MIRO",
        "companyURL": "http://www.miromatrix.com/",
        "companyPipelineURL": "https://www.miromatrix.com/pipeline"
    },
    {
        "companyName": "Moderna, Inc.",
        "companyTicker": "MRNA",
        "companyURL": "http://www.modernatx.com/",
        "companyPipelineURL": "https://www.modernatx.com/research/product-pipeline"
    },
    {
        "companyName": "Molecular Partners AG",
        "companyTicker": "MOLN",
        "companyURL": "http://www.molecularpartners.com/",
        "companyPipelineURL": "https://www.molecularpartners.com/pipeline/"
    },
    {
        "companyName": "Monopar Therapeutics Inc.",
        "companyTicker": "MNPR",
        "companyURL": "http://www.monopartx.com/",
        "companyPipelineURL": "https://www.monopartx.com/pipeline/pipeline-chart"
    },
    {
        "companyName": "Monte Rosa Therapeutics, Inc.",
        "companyTicker": "GLUE",
        "companyURL": "http://www.monterosatx.com/",
        "companyPipelineURL": "https://www.monterosatx.com/queen/"
    },
    {
        "companyName": "MorphoSys AG",
        "companyTicker": "MOR",
        "companyURL": "https://www.morphosys.com/en/home",
        "companyPipelineURL": "https://www.morphosys.com/en/our-pipeline"
    },
    {
        "companyName": "Mosaic ImmunoEngineering Inc.",
        "companyTicker": "CPMV",
        "companyURL": "http://www.mosaicie.com/",
        "companyPipelineURL": "https://www.mosaicie.com/copy-of-pipeline"
    },
    {
        "companyName": "Molecular Templates, Inc.",
        "companyTicker": "MTEM",
        "companyURL": "http://www.mtem.com/",
        "companyPipelineURL": "https://www.mtem.com/pipeline"
    },
    {
        "companyName": "Mustang Bio, Inc.",
        "companyTicker": "MBIO",
        "companyURL": "http://www.mustangbio.com/",
        "companyPipelineURL": "https://www.mustangbio.com/pipeline/"
    },
    {
        "companyName": "MyMD Pharmaceuticals, Inc.",
        "companyTicker": "MYMD",
        "companyURL": "http://www.mymd.com/",
        "companyPipelineURL": "https://www.mymd.com/pipeline"
    },
    {
        "companyName": "Nabriva Therapeutics plc",
        "companyTicker": "NBRVF",
        "companyURL": "http://www.nabriva.com/",
        "companyPipelineURL": "https://www.nabriva.com/pipeline-research"
    },
    {
        "companyName": "Nascent Biotech Inc.",
        "companyTicker": "NBIO",
        "companyURL": "http://www.nascentbiotech.com/",
        "companyPipelineURL": "https://www.nascentbiotech.com/products/"
    },
    {
        "companyName": "Nektar Therapeutics",
        "companyTicker": "NKTR",
        "companyURL": "http://www.nektar.com/",
        "companyPipelineURL": "https://www.nektar.com/pipeline/rd-pipeline"
    },
    {
        "companyName": "Neoleukin Therapeutics, Inc.",
        "companyTicker": "NLTX",
        "companyURL": "http://www.neoleukin.com/",
        "companyPipelineURL": "https://www.neoleukin.com/programs/"
    },
    {
        "companyName": "NeuBase Therapeutics, Inc.",
        "companyTicker": "NBSE",
        "companyURL": "http://www.neubasetherapeutics.com/",
        "companyPipelineURL": "https://www.neubasetherapeutics.com/pipeline/"
    },
    {
        "companyName": "Neurocrine Biosciences, Inc.",
        "companyTicker": "NBIX",
        "companyURL": "http://www.neurocrine.com/",
        "companyPipelineURL": "https://www.neurocrine.com/pipeline/"
    },
    {
        "companyName": "NeuroSense Therapeutics Ltd.",
        "companyTicker": "NRSN",
        "companyURL": "http://www.neurosense-tx.com/",
        "companyPipelineURL": "https://www.neurosense-tx.com/pipeline/"
    },
    {
        "companyName": "NewAmsterdam Pharma Company N.V.",
        "companyTicker": "NAMS",
        "companyURL": "http://www.newamsterdampharma.com/",
        "companyPipelineURL": "https://www.newamsterdampharma.com/obicetrapibta8995"
    },
    {
        "companyName": "NextCure, Inc.",
        "companyTicker": "NXTC",
        "companyURL": "http://www.nextcure.com/",
        "companyPipelineURL": "https://www.nextcure.com/pipeline/"
    },
    {
        "companyName": "NGM Biopharmaceuticals, Inc.",
        "companyTicker": "NGM",
        "companyURL": "http://www.ngmbio.com/",
        "companyPipelineURL": "https://www.ngmbio.com/pipeline/"
    },
    {
        "companyName": "Nkarta, Inc.",
        "companyTicker": "NKTX",
        "companyURL": "http://www.nkartatx.com/",
        "companyPipelineURL": "https://www.nkartatx.com/pipeline/"
    },
    {
        "companyName": "Novartis AG",
        "companyTicker": "NVSEF",
        "companyURL": "http://www.novartis.com/",
        "companyPipelineURL": "https://www.novartis.com/research-development/novartis-pipeline"
    },
    {
        "companyName": "Novavax, Inc.",
        "companyTicker": "NVAX",
        "companyURL": "http://www.novavax.com/",
        "companyPipelineURL": "https://www.novavax.com/science-technology/vaccine-pipeline"
    },
    {
        "companyName": "Novo Nordisk A/S",
        "companyTicker": "NVO",
        "companyURL": "http://www.novonordisk.com/",
        "companyPipelineURL": "https://www.novonordisk.com/science-and-technology/r-d-pipeline.html"
    },
    {
        "companyName": "NRX Pharmaceuticals, Inc.",
        "companyTicker": "NRXP",
        "companyURL": "http://www.nrxpharma.com/",
        "companyPipelineURL": "https://www.nrxpharma.com/pipeline/"
    },
    {
        "companyName": "NuCana plc",
        "companyTicker": "NCNA",
        "companyURL": "http://www.nucana.com/",
        "companyPipelineURL": "https://www.nucana.com/protides.html"
    },
    {
        "companyName": "Nurix Therapeutics, Inc.",
        "companyTicker": "NRIX",
        "companyURL": "http://www.nurixtx.com/",
        "companyPipelineURL": "https://www.nurixtx.com/pipeline/"
    },
    {
        "companyName": "Orchard Therapeutics plc",
        "companyTicker": "ORTX",
        "companyURL": "http://www.orchard-tx.com/",
        "companyPipelineURL": "https://www.orchard-tx.com/focus/"
    },
    {
        "companyName": "Passage Bio, Inc.",
        "companyTicker": "PASG",
        "companyURL": "http://www.passagebio.com/",
        "companyPipelineURL": "https://www.passagebio.com/our-science/pipeline/"
    },
    {
        "companyName": "PepGen Inc.",
        "companyTicker": "PEPG",
        "companyURL": "http://pepgen.com/",
        "companyPipelineURL": "https://www.pepgen.com/pipeline/"
    },
    {
        "companyName": "Pharming Group NV",
        "companyTicker": "PHGUF",
        "companyURL": "http://www.pharming.com/",
        "companyPipelineURL": "https://www.pharming.com/pipeline"
    },
    {
        "companyName": "Recursion Pharmaceuticals, Inc.",
        "companyTicker": "RXRX",
        "companyURL": "http://www.recursion.com/",
        "companyPipelineURL": "https://www.reatapharma.com/our-science/default.aspx#pipeline"
    },
    {
        "companyName": "Revolution Medicines, Inc.",
        "companyTicker": "RVMD",
        "companyURL": "http://www.revmed.com/",
        "companyPipelineURL": "https://www.revmed.com/pipeline"
    },
    {
        "companyName": "Roivant Sciences Ltd.",
        "companyTicker": "ROIV",
        "companyURL": "http://www.roivant.com/",
        "companyPipelineURL": "https://www.roivant.com/pipeline"
    },
    {
        "companyName": "Scinai Immunotherapeutics Ltd.",
        "companyTicker": "SCNI",
        "companyURL": "https://www.scinai.com/",
        "companyPipelineURL": "https://www.scinai.com/pipeline"
    },
    {
        "companyName": "Sensei Biotherapeutics, Inc.",
        "companyTicker": "SNSE",
        "companyURL": "http://senseibio.com/",
        "companyPipelineURL": "https://www.senseibio.com/pipeline/"
    },
    {
        "companyName": "X4 Pharmaceuticals, Inc.",
        "companyTicker": "XFOR",
        "companyURL": "http://www.x4pharma.com/",
        "companyPipelineURL": "https://www.x4pharma.com/pipeline/"
    },
    {
        "companyName": "Zai Lab Limited",
        "companyTicker": "ZLAB",
        "companyURL": "http://www.zailaboratory.com/",
        "companyPipelineURL": "https://www.zailaboratory.com/rd/"
    },
    {
        "companyName": "Zentalis Pharmaceuticals, Inc.",
        "companyTicker": "ZNTL",
        "companyURL": "http://www.zentalis.com/",
        "companyPipelineURL": "https://www.zentalis.com/pipeline/pipeline"
    },
    {
        "companyName": "Aeterna Zentaris Inc.",
        "companyTicker": "AEZS",
        "companyURL": "http://www.zentaris.com/",
        "companyPipelineURL": "https://www.zentaris.com/our-pipeline-1"
    },
    {
        "companyName": "Zymeworks Inc.",
        "companyTicker": "ZYME",
        "companyURL": "http://www.zymeworks.com/",
        "companyPipelineURL": "https://www.zymeworks.com/pipeline/"
    },
    {
        "companyName": "ZyVersa Therapeutics, Inc.",
        "companyTicker": "ZVSA",
        "companyURL": "http://www.zyversa.com/",
        "companyPipelineURL": "https://www.zyversa.com/pipeline"
    },
    {
        "companyName": "Zevra Therapeutics, Inc.",
        "companyTicker": "ZVRA",
        "companyURL": "http://zevra.com/",
        "companyPipelineURL": "https://zevra.com/science-pipeline/#zevra-pipeline"
    },
    {
        "companyName": "Johnson & Johnson",
        "companyTicker": "JNJ",
        "companyURL": "https://www.jnj.com/",
        "companyPipelineURL": "https://www.investor.jnj.com/pharmaceutical-pipeline-information"
    },
    {
        "companyName": "Pfizer Inc.",
        "companyTicker": "PFE",
        "companyURL": "https://www.pfizer.com/",
        "companyPipelineURL": "https://www.pfizer.com/science/drug-product-pipeline"
    },
    {
        "companyName": "Merck & Co., Inc.",
        "companyTicker": "MRK",
        "companyURL": "https://www.merck.com/",
        "companyPipelineURL": "https://www.merck.com/research/product-pipeline/"
    },
    {
        "companyName": "Novartis AG",
        "companyTicker": "NVS",
        "companyURL": "https://www.novartis.com/us-en/",
        "companyPipelineURL": "https://www.novartis.com/research-development/novartis-pipeline"
    },
    {
        "companyName": "Sanofi",
        "companyTicker": "SNY",
        "companyURL": "https://www.sanofi.com/en",
        "companyPipelineURL": "https://www.sanofi.com/en/our-science/our-pipeline"
    },
    {
        "companyName": "GlaxoSmithKline plc (GSK)",
        "companyTicker": "GSK",
        "companyURL": "https://us.gsk.com/",
        "companyPipelineURL": "https://www.gsk.com/en-gb/innovation/pipeline/"
    },
    {
        "companyName": "Eli Lilly and Company",
        "companyTicker": "LLY",
        "companyURL": "https://www.lilly.com/",
        "companyPipelineURL": "https://www.lilly.com/discovery/clinical-development-pipeline"
    },
    {
        "companyName": "Teva Pharmaceutical Industries Ltd.",
        "companyTicker": "TEVA",
        "companyURL": "https://www.tevapharm.com/",
        "companyPipelineURL": "https://www.tevapharm.com/product-focus/research/"
    },
    {
        "companyName": "AstraZeneca",
        "companyTicker": "AZN",
        "companyURL": "https://www.astrazeneca.com/",
        "companyPipelineURL": "https://www.astrazeneca.com/our-therapy-areas/pipeline.html"
    },
    {
        "companyName": "Takeda Pharmaceutical Company Limited",
        "companyTicker": "TAK",
        "companyURL": "https://www.takeda.com/",
        "companyPipelineURL": "https://www.takeda.com/science/pipeline/"
    },
    {
        "companyName": "Bristol Myers Squibb",
        "companyTicker": "BMY",
        "companyURL": "https://www.bms.com/",
        "companyPipelineURL": "https://www.bms.com/researchers-and-partners/in-the-pipeline.html"
    },
    {
        "companyName": "Gilead Sciences",
        "companyTicker": "GILD",
        "companyURL": "https://www.gilead.com/",
        "companyPipelineURL": "https://www.gilead.com/science-and-medicine/pipeline"
    },
    {
        "companyName": "Roche Holding AG",
        "companyTicker": "RHHBY",
        "companyURL": "https://www.roche.com/",
        "companyPipelineURL": "https://www.roche.com/solutions/pipeline"
    },
    {
        "companyName": "Zealand Pharma A/S",
        "companyTicker": "ZLDPF",
        "companyURL": "http://www.zealandpharma.com/",
        "companyPipelineURL": "https://www.zealandpharma.com/rd/product-pipeline/"
    },
    {
        "companyName": "ZENITH CAPITAL CORPORATION",
        "companyTicker": "ZHCLF",
        "companyURL": "http://www.zenithepigenetics.com/",
        "companyPipelineURL": "https://www.zenithepigenetics.com/programs/pipeline"
    },
    {
        "companyName": "Boehringer Ingelheim",
        "companyTicker": NaN,
        "companyURL": "https://www.boehringer-ingelheim.com/",
        "companyPipelineURL": "https://www.boehringer-ingelheim.com/human-pharma-clinical-pipeline"
    },
    {
        "companyName": "Taisho Pharmaceutical Holdings Co Ltd",
        "companyTicker": "TAIPY",
        "companyURL": "https://www.taisho.co.jp/global/",
        "companyPipelineURL": "https://www.taiho.co.jp/en/science/pipeline/"
    },
    {
        "companyName": "Takeda Pharmaceutical Co., Ltd.",
        "companyTicker": "TKPHF",
        "companyURL": "https://www.takeda.com/",
        "companyPipelineURL": "https://www.takeda.com/science/pipeline/"
    },
    {
        "companyName": "Shionogi & Co. Ltd.",
        "companyTicker": "SGIOY",
        "companyURL": "https://www.shionogi.com/global/en/",
        "companyPipelineURL": "https://www.shionogi.com/global/en/innovation/pipeline.html"
    },
    {
        "companyName": "Santen Pharmaceutical Co., Ltd.",
        "companyTicker": "SNPHY",
        "companyURL": "https://www.santen.com/en",
        "companyPipelineURL": "https://www.santen.com/en/business/rd"
    },
    {
        "companyName": "Ono Pharmaceutical Co., Ltd.",
        "companyTicker": "OPHLY",
        "companyURL": "https://www.ono-pharma.com/en",
        "companyPipelineURL": "https://www.ono-pharma.com/en/ir/library/pipeline.html"
    },
    {
        "companyName": "OBSEVA SA",
        "companyTicker": "OBSEF",
        "companyURL": "http://www.obseva.com/",
        "companyPipelineURL": "https://www.obseva.com/our-pipeline-overview/"
    },
    {
        "companyName": "Medexus Pharmaceuticals Inc",
        "companyTicker": "MEDXF",
        "companyURL": "http://www.medexusinc.com/",
        "companyPipelineURL": "https://www.medexus.com/en_US/divisions/us-product-portfolio"
    },
    {
        "companyName": "LadRx Corporation",
        "companyTicker": "LADX",
        "companyURL": "http://www.ladrxcorp.com/",
        "companyPipelineURL": "https://www.ladrxcorp.com/about-us/"
    },
    {
        "companyName": "Kyowa Hakko Kogyo Co., Ltd.",
        "companyTicker": "KYKOF",
        "companyURL": "http://www.kyowa-kirin.co.jp/",
        "companyPipelineURL": "https://www.kyowakirin.com/what_we_do/index.html#anc-pipeline"
    },
    {
        "companyName": "Ipsen S.A.",
        "companyTicker": "IPSEY",
        "companyURL": "http://www.ipsen.com/",
        "companyPipelineURL": "https://www.ipsen.com/pipeline/"
    },
    {
        "companyName": "Innovent Biologics Inc.",
        "companyTicker": "IVBXF",
        "companyURL": "http://www.innoventbio.com/",
        "companyPipelineURL": "https://www.innoventbio.com/ScienceAndProducts/Pipeline/ProductRAndD"
    },
    {
        "companyName": "Imugene, Ltd.",
        "companyTicker": "IUGNF",
        "companyURL": "http://www.imugene.com/",
        "companyPipelineURL": "https://www.imugene.com/clinical-pipeline"
    },
    {
        "companyName": "Idorsia AG",
        "companyTicker": "IDRSF",
        "companyURL": "http://www.idorsia.com/",
        "companyPipelineURL": "https://www.idorsia.com/about-idorsia/idorsia-today/our-pipeline"
    },
    {
        "companyName": "HLS Therapeutics Inc.",
        "companyTicker": "HLTRF",
        "companyURL": "http://www.hlstherapeutics.com/",
        "companyPipelineURL": "https://www.hlstherapeutics.com/products/"
    },
    {
        "companyName": "Hansa Medical AB",
        "companyTicker": "HNSBF",
        "companyURL": "http://www.hansabiopharma.com/",
        "companyPipelineURL": "https://www.hansabiopharma.com/our-pipeline-and-products/pipeline/"
    },
    {
        "companyName": "Genmab A/S",
        "companyTicker": "GNMSF",
        "companyURL": "http://www.genmab.com/",
        "companyPipelineURL": "https://www.genmab.com/pipeline/"
    },
    {
        "companyName": "GENOCEA BIOSCIENCES INC",
        "companyTicker": "GNCAQ",
        "companyURL": "http://www.genocea.com/",
        "companyPipelineURL": "https://www.genocea.com/pipeline/"
    },
    {
        "companyName": "Futura Medical Plc",
        "companyTicker": "FAMDF",
        "companyURL": "https://www.futuramedical.com/",
        "companyPipelineURL": "https://www.futuramedical.com/what-we-do/our-product-development-pipeline/"
    },
    {
        "companyName": "Shanghai Fosun Pharmaceutical Group Co Ltd",
        "companyTicker": "SFOSF",
        "companyURL": "https://www.fosunpharma.com/en/",
        "companyPipelineURL": "https://www.fosunpharma.com/en/innovate/pipeline.html"
    },
    {
        "companyName": "Filament Health Corp.",
        "companyTicker": "FLHLF",
        "companyURL": "http://www.filament.health/",
        "companyPipelineURL": "https://www.filament.health/drug-development"
    },
    {
        "companyName": "EOM Pharmaceutical Holdings Inc.",
        "companyTicker": "IMUC",
        "companyURL": "http://www.eompharma.com/",
        "companyPipelineURL": "https://www.eompharma.com/pipeline/"
    },
    {
        "companyName": "Daiichi Sankyo Co., Ltd.",
        "companyTicker": "DSNKY",
        "companyURL": "https://www.daiichisankyo.com/",
        "companyPipelineURL": "https://www.daiichisankyo.com/rd/pipeline/"
    },
    {
        "companyName": "CSL Ltd.",
        "companyTicker": "CSLLY",
        "companyURL": "https://www.csl.com/",
        "companyPipelineURL": "https://www.csl.com/research-and-development/product-pipeline"
    },
    {
        "companyName": "Cipher Pharmaceuticals, Inc.",
        "companyTicker": "CPHRF",
        "companyURL": "http://www.cipherpharma.com/",
        "companyPipelineURL": "https://www.cipherpharma.com/pipeline/"
    },
    {
        "companyName": "CANSINO BIOLOGICS INC",
        "companyTicker": "CASBF",
        "companyURL": "http://www.cansinotech.com/",
        "companyPipelineURL": "https://www.cansinotech.com/html/1//156/159/index.html"
    },
    {
        "companyName": "Bioxytran, Inc.",
        "companyTicker": "BIXT",
        "companyURL": "http://www.bioxytraninc.com/",
        "companyPipelineURL": "https://www.bioxytraninc.com/pipeline"
    },
    {
        "companyName": "Bayer A.G.",
        "companyTicker": "BAYRY",
        "companyURL": "https://www.bayer.com/en/",
        "companyPipelineURL": "https://www.bayer.com/en/pharma/development-pipeline"
    },
    {
        "companyName": "Astellas Pharma, Inc.",
        "companyTicker": "ALPMY",
        "companyURL": "http://www.astellas.com/",
        "companyPipelineURL": "https://www.astellas.com/en/innovation/pipeline"
    },
    {
        "companyName": "ASCENTAGE PHARMA GROUP",
        "companyTicker": "ASPHF",
        "companyURL": "http://www.ascentage.com/",
        "companyPipelineURL": "https://www.ascentage.com/science/pipeline/"
    },
    {
        "companyName": "Antibe Therapeutics, Inc.",
        "companyTicker": "ATBPF",
        "companyURL": "http://www.antibethera.com/",
        "companyPipelineURL": "https://www.antibethera.com/pipeline/overview/"
    },
    {
        "companyName": "Amplia Therapeutics Ltd.",
        "companyTicker": "INNMF",
        "companyURL": "http://www.ampliatx.com/",
        "companyPipelineURL": "https://www.ampliatx.com/site/pipeline/amplias-pipeline"
    },
    {
        "companyName": "Alpha Cognition Inc.",
        "companyTicker": "ACOGF",
        "companyURL": "http://www.alphacognition.com/",
        "companyPipelineURL": "https://www.alphacognition.com/our-approach/our-pipeline/"
    },
    {
        "companyName": "Alk Abello AS",
        "companyTicker": "AKBLF",
        "companyURL": "http://www.alk.net/",
        "companyPipelineURL": "https://www.alk.net/our-science/pipeline"
    },
    {
        "companyName": "Algernon Pharmaceuticals Inc.",
        "companyTicker": "AGNPF",
        "companyURL": "http://www.algernonpharmaceuticals.com/",
        "companyPipelineURL": "https://www.algernonpharmaceuticals.com/pipeline"
    },
    {
        "companyName": "Aequus Pharmaceuticals Inc.",
        "companyTicker": "AQSZF",
        "companyURL": "http://www.aequuspharma.ca/",
        "companyPipelineURL": "https://www.aequuspharma.ca/#pipeline"
    },
    {
        "companyName": "Acrux Ltd.",
        "companyTicker": "ARUXF",
        "companyURL": "http://www.acrux.com.au/",
        "companyPipelineURL": "https://www.acrux.com.au/what-we-do/research-development/product-pipeline/"
    },
    {
        "companyName": "Abivax SA",
        "companyTicker": "AAVXF",
        "companyURL": "http://www.abivax.com/",
        "companyPipelineURL": "https://www.abivax.com/pipeline/"
    },
    {
        "companyName": "Eisai Co., Ltd.",
        "companyTicker": "ESALF",
        "companyURL": "https://www.eisai.com/",
        "companyPipelineURL": "https://us.eisai.com/our-science/global-pipeline"
    },
    {
        "companyName": "Tryp Therapeutics Inc.",
        "companyTicker": "TRYPF",
        "companyURL": "http://tryptherapeutics.com/",
        "companyPipelineURL": "https://tryptherapeutics.com/pipeline"
    },
    {
        "companyName": "Sosei Co., Ltd.",
        "companyTicker": "SOLTF",
        "companyURL": "https://soseiheptares.com/",
        "companyPipelineURL": "https://soseiheptares.com/our-pipeline/rd-pipeline.html"
    },
    {
        "companyName": "SKYE BIOSCIENCE INC",
        "companyTicker": "SKYED",
        "companyURL": "https://skyebioscience.com/",
        "companyPipelineURL": "https://skyebioscience.com/technology/"
    },
    {
        "companyName": "PureTech Health plc",
        "companyTicker": "PTCHF",
        "companyURL": "https://puretechhealth.com/",
        "companyPipelineURL": "https://puretechhealth.com/programs"
    },
    {
        "companyName": "NervGen Pharma Corp.",
        "companyTicker": "NGENF",
        "companyURL": "http://www.nervgen.com/",
        "companyPipelineURL": "https://nervgen.com/pipeline/"
    },
    {
        "companyName": "MYDECINE INNOVATIONS GROUP INC",
        "companyTicker": NaN,
        "companyURL": "https://mydecine.com/",
        "companyPipelineURL": "https://mydecine.com/technology"
    },
    {
        "companyName": "Mindset Pharma Inc.",
        "companyTicker": "MSSTF",
        "companyURL": "http://www.mindsetpharma.com/",
        "companyPipelineURL": "https://mindsetpharma.com/pipeline/"
    },
    {
        "companyName": "MGC Pharmaceuticals Ltd",
        "companyTicker": "MGCLF",
        "companyURL": "http://www.mgcpharma.com.au/",
        "companyPipelineURL": "https://mgcpharma.co.uk/medicines"
    },
    {
        "companyName": "MediGene AG",
        "companyTicker": "MDGEF",
        "companyURL": "https://medigene.com/",
        "companyPipelineURL": "https://medigene.com/science/pipeline/"
    },
    {
        "companyName": "MARVEL BIOSCIENCES CORP.",
        "companyTicker": "MBCOF",
        "companyURL": "http://www.marvelbiotechnology.com/",
        "companyPipelineURL": "https://marvelbiotechnology.com/technology/"
    },
    {
        "companyName": "KINTOR PHARMACEUTICAL LTD.",
        "companyTicker": "KNTPF",
        "companyURL": "http://www.kintor.com.cn/",
        "companyPipelineURL": "https://en.kintor.com.cn/intro/30.html"
    },
    {
        "companyName": "EASTGATE BIOTECH CORP",
        "companyTicker": "ETBI",
        "companyURL": "http://www.eastgatebiotech.com/",
        "companyPipelineURL": "https://eastgatebiotech.com/research/"
    },
    {
        "companyName": "COMPASS Pathways Plc",
        "companyTicker": NaN,
        "companyURL": "http://www.compasspathways.com/",
        "companyPipelineURL": "https://compasspathways.com/our-work/pipeline/"
    },
    {
        "companyName": "Botanix Pharmaceuticals Ltd.",
        "companyTicker": "BXPHF",
        "companyURL": "http://www.botanixpharma.com/",
        "companyPipelineURL": "https://botanixpharma.com/pipeline/"
    },
    {
        "companyName": "Biocure Technology Inc.",
        "companyTicker": "BICTF",
        "companyURL": "http://www.biocuretech.com/",
        "companyPipelineURL": "https://biocuretech.com/#Products"
    },
    {
        "companyName": "Avivagen, Inc.",
        "companyTicker": "VIVXF",
        "companyURL": "http://www.avivagen.com/",
        "companyPipelineURL": "https://avivagen.com/science/"
    },
    {
        "companyName": "Arch Biopartners Inc.",
        "companyTicker": "ACHFF",
        "companyURL": "http://www.archbiopartners.com/",
        "companyPipelineURL": "https://archbiopartners.com/our-science/"
    }
];

let COMPANY_LIST = [];

COMPANY_DATA.forEach(company => {
    if (COMPANY_LIST.indexOf(company.companyName) === -1){
        COMPANY_LIST.push(company.companyName);
    }
})



export {COMPANY_DATA, COMPANY_LIST};