import React, { useEffect, useState, useRef } from 'react'
import TableHeader from './subsections/TableHeader'
import TableKeys from './subsections/TableKeys';
import TableColumn from './subsections/TableColumn';


import '../../../css/table.css'
import PreLaunchColumn from './subsections/PreLaunchColumn';
import TotalsColumn from './subsections/TotalsColumn';

const TABLE_KEYS = [
    "Patient Population",
    "Penetration %",
    "Patients on Drug",
    "Cost",
    "Sales",
    "COGS",
    "Expenses",
    "Gross Profit",
]

const TABLE_KEYS_GENE = [
    "( P ) Population",
    "( I ) Population",
    "( P ) Penetration %",
    "( I ) Penetration %",
    "( P ) Patients",
    "( I ) Patients",
    "Cost",
    "Sales",
    "COGS",
    "Expenses",
    "Gross Profit",
]

const TABLE_KEYS_TOTALS = [
    "Patients Treated",
    "Sales",
    "COGS",
    "Expenses",
    "Gross Profit",
]

const TableContainer = ({workingForecast, tableDataArray, setCurrGeographyIndex, currGeographyIndex, handlePreLaunch, workingGeographies, setWorkingForecast}) => {
  
  const _ = workingForecast;
  const currGeography = _.geographies[currGeographyIndex];

  const [totalsView, setTotalsView] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [currTable, setCurrTable] = useState(tableDataArray[currGeographyIndex].data)
  const [dimensions, setDimensions] = useState({});
  const [currHover, setCurrHover] = useState(-1);

  // may have to make changes here eventually based on max width
  useEffect(() => {
      let initDims = {};
      for (let i = 0; i < currTable.length; i++) {
        initDims[i] = {width: 0, height: 0};
      }
      setDimensions({...initDims})
  }, [])

  let geoSimple = tableDataArray.map(item => item.geography);
  
  useEffect(() => {
    setCurrTable(tableDataArray[currGeographyIndex].data)
  }, [currGeographyIndex, tableDataArray])
  

  
  let preLaunchData = _.geographiesData[currGeography].preLaunch.map((col, index) => {
        const modelStart = _.geographiesData[currGeography].general.startDate;
        const modelPeriod = _.geographiesData[currGeography].general.period;
        const thisColData = {modelPeriod: modelPeriod, modelStart: modelStart, index: index, ...col}
       return <PreLaunchColumn key={`${index}${currGeography}-pre-l`} colData={thisColData} setWorkingForecast={setWorkingForecast} activeGeography={currGeography}/>
  });
  preLaunchData.reverse();

  const tableData = currTable.main.map((col, index) => {
    if (workingForecast.type !== "drug") {
        let newCol = []
        col.forEach((item, xindex) => {
            if (item.incident) {
                newCol.push(item.prevalent);
                newCol.push(item.incident);
            } else {
                newCol.push(item)
            }
        })
        col = newCol;
    }
    let evenBackground = index % 2 !== 0 ? true : false
    let expenses = _.geographiesData[currGeography].expenses[index] || 0;
    if (col.length === 9 || col.length === 12) {
        return <TableColumn forecastType={workingForecast.type} currGeography={currGeography} outerIndex={index} setWorkingForecast={setWorkingForecast} expenses={expenses} currHover={currHover} setCurrHover={setCurrHover} forecastEvents={currTable.events[index]} colIndex={index} dimensions={dimensions} setDimensions={setDimensions}  key={`${currGeography}${index}-mshare-tbl`} colData={col} evenBackground={evenBackground} marketShare={true} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
    } else {
        return <TableColumn forecastType={workingForecast.type} currGeography={currGeography} outerIndex={index} setWorkingForecast={setWorkingForecast} expenses={expenses} currHover={currHover} setCurrHover={setCurrHover} forecastEvents={currTable.events[index]} colIndex={index} dimensions={dimensions} setDimensions={setDimensions} key={`${currGeography}${index}-tbl-col`}  colData={col} evenBackground={evenBackground} />
    }
  });

  const allGeosSums = ["All Geogs", 0, 0, 0, 0, 0];
  const totalsData = tableDataArray.map((geo, index) => {
    let evenBackground = index % 2 !== 0 ? true : false

    const sh = geo.data.main;
    let colData = [geo.geography];
    if (sh[0].length === 9) {
        let patientsSum = sh.reduce((partialSum, a) => partialSum + a[4][0].patientsOnDrug, 0);
        allGeosSums[1] += patientsSum;
        colData.push(patientsSum)
    } else {
        let patientsSum = sh.reduce((partialSum, a) => partialSum + a[3], 0);
        allGeosSums[1] += patientsSum;
        colData.push(patientsSum)
    }
    let totalSales = sh.reduce((partialSum, a) => partialSum + a[a.length-3], 0);
    allGeosSums[2] += totalSales;
    let totalCOGS = sh.reduce((partialSum, a) => partialSum + a[a.length-2], 0);
    allGeosSums[3] += totalCOGS;
    let totalExpenses = workingForecast.geographiesData[geo.geography].preLaunch ? workingForecast.geographiesData[geo.geography].preLaunch.reduce((partialSum, a) => partialSum + Math.abs(parseInt(a.expenses)), 0) : 0;
    workingForecast.geographiesData[geo.geography].expenses.forEach(expense => {
        if (expense) {
            totalExpenses += Math.abs(expense);
        }
    })
    allGeosSums[4] += totalExpenses;
    let totalGross = sh.reduce((partialSum, a) => partialSum + a[a.length-1], 0);
    totalGross -= totalExpenses;
    allGeosSums[5] += totalGross;
    colData.push(totalSales, totalCOGS, totalExpenses, totalGross);
    return <TotalsColumn key={`${index}-total-tbl`} colData={colData} evenBackground={evenBackground}/>

  })
  totalsData.push(<TotalsColumn key={`all-total-tbl`} colData={allGeosSums} evenBackground={totalsData.length % 2 !== 0}/>)

  const marketShareHeight = dimensions[0] ? dimensions[0].height : 0;

  const handleTabChange = (index) => {
    setCurrGeographyIndex(index);
    setTotalsView(false);
  }

  return (
      <div className='table-container-border'>
            {tableDataArray.length > 0 &&
                <div className='flex-row'>
                    <div className='flex-row table-geo-tabs'>
                        {geoSimple.map((geo, index) => {
                            return <div key={`geo-btn-tbl${geo}`} id={!totalsView && index === currGeographyIndex ? "curr-geo-btn" : null} className="geo-btn-tbl" onClick={() => handleTabChange(index)}>{geo}</div>
                        })}
                    </div>
                    <div className="table-geo-tabs table-totals-tab">
                        <div id={totalsView ? "curr-geo-btn" : null} className="geo-btn-tbl" onClick={() => setTotalsView(true)}>Totals</div>
                    </div>
                </div>
            }
        <div className='extra-padding-right'>
            <div className='table-container-outer border-basic flex-row'>
                <div className='table-keys-container'>
                    {totalsView ? 
                    <TableKeys forecastName={_.name} values={TABLE_KEYS_TOTALS} totalsView={true}/>
                    :
                    <TableKeys forecastName={_.name} values={workingForecast.type === "drug" ? TABLE_KEYS : TABLE_KEYS_GENE} marketShare={currTable.main[0].length === 9 ? true : false} marketShareHeight={marketShareHeight} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} handlePreLaunch={handlePreLaunch} forecastType={workingForecast.type}/>
                    }
                </div>
                <div className='table-data-container flex-row'>
                    {!totalsView && preLaunchData}
                    {totalsView ? totalsData : tableData}
                </div>
            </div>
        </div>
    </div>
  )
}

export default TableContainer