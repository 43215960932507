
import axios from 'axios';
import React, {useState} from 'react'

import "../css/helpers.css"
import "../css/login.css"
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const LoginRoute = ({setActiveUser, activeUser, isSignup, visitShareLink, recoveryStart, setIsSignup}) => {

    const [formType, setFormType] = useState(isSignup === true ? "signup" : "login");
    const [signupState, setSignupState] = useState({email: "", password: "", givenName: ""});
    const [loginState, setLoginState] = useState({email: "", password: ""});
    const [resetState, setResetState] = useState({password2: "", password: ""});

    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [recoverySent, setRecoverySent] = useState(false);
    const [recoveryError, setRecoveryError] = useState("");
    const [successfulReset, setSuccessfulReset] = useState(false);

    const navigate = useNavigate();
    const { recoveryID } = useParams();

    const handleReset = () => {
        setSuccessfulReset(false);
        setErrorMessage("")
        if (resetState.password !== resetState.password2) {
            setErrorMessage("Passwords Do Not Match!")
        } else {
            const data = {newPassword: resetState.password}
            // axios.post(`http://localhost:8000/user/reset-password/${recoveryID}`, { data },   {

                axios.post(`https://bancy-backend-01dc0125253f.herokuapp.com/user/reset-password/${recoveryID}`, { data },   {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    
                  })
                .then((response) => {
                        setSuccessfulReset(true);
                        setErrorMessage("")
                })
                .catch(err => {
                    console.log(err)
                    setErrorMessage("Link expired");
                })
        }
    }
    const handleForgotPassword = () => {
        setRecoveryError("")
        setRecoverySent(false);
        if (loginState.email.length === 0) {
            setRecoveryError("Please enter the email you wish to recover");
        } else {
            const data = {email: loginState.email}
            // axios.post('http://localhost:8000/user/forgot-password', { data },   {

                axios.post('https://bancy-backend-01dc0125253f.herokuapp.com/user/forgot-password', { data },   {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    
                  })
                .then((response) => {
        
                    setRecoverySent(true);
                    setRecoveryError("")
                })
                .catch(err => {
                    setRecoveryError("Error Sending Recovery Email, try again");
                })
        }
    }

    const handleFormType = (type) => {
        setFormType(type);
        setErrorMessage("");
    }

    const handleSignup = () => {
        if (signupState.email && signupState.password) {
            const data = {email: signupState.email, password: signupState.password, givenName: signupState.givenName};
            // axios.post('http://localhost:8000/user/signup', { data })

            axios.post('https://bancy-backend-01dc0125253f.herokuapp.com/user/signup', { data },   {
                headers: {
                  'Content-Type': 'application/json',
                },
                
              })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem('jwtToken', response.data.token);
                    setActiveUser(response.data.user);
                    if (visitShareLink !== false) {
                        navigate(`/share/${visitShareLink}`)
                    } else {

                        navigate('/user')
                    }
                } else {
                    setErrorMessage(response.data.message);
                }

            })
            .catch(err => {
                const errorMsg = err.response.data.message ? err.response.data.message : "Signup Failed"
                setErrorMessage(errorMsg);
            })
        }
    }

    const handleLogin = () => {
        if (loginState.email && loginState.password) {
            const data = {email: loginState.email, password: loginState.password};
            // axios.post('http://localhost:8000/user/login', data)

            axios.post('https://bancy-backend-01dc0125253f.herokuapp.com/user/login',  data,  {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem('jwtToken', response.data.token);
                    setActiveUser(response.data.user);
                    if (visitShareLink !== false) {
                        navigate(`/share/${visitShareLink}`)
                    } else {
                        navigate('/user')
                    }
                } else {
                    setErrorMessage("Unknown failure, please try again.");
                }

            })
            .catch(err => {
                const errorMsg = err.response.data.message ? err.response.data.message : "Login Failed"
                setErrorMessage(errorMsg);
            })
        }
    }

    const handleChangeToLogin = () => {
        setIsSignup(false);
        navigate("/login");
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };


  return (
    <div className='login-page-master'>
        <div className='log-banc-div'>
            <h1 className='login-banc-title'>bancroft</h1>
        </div> 
        {recoveryStart ? 
            <div className='login-div'>
                <h1 className='login-header'>Reset Password</h1>
                {errorMessage.length > 0 && <h2>{errorMessage}</h2>}
                <p className='login-subtext extra-pad'>New Password</p>
                <input id="login-input" className='text-input-standard' name="current-password" type={showPassword ? "text" : "password"} onChange={(e) => setResetState({...resetState, password: e.target.value})} value={resetState.password}></input>
                <span className="show-hide-password"  onClick={togglePasswordVisibility}>
                    {showPassword ? 'Hide' : 'Show'}
                </span>
                <p className='login-subtext extra-pad'>Repeat New Password</p>
                <input id="login-input" className='text-input-standard' name="current-password" type={showPassword2 ? "text" : "password"} onChange={(e) => setResetState({...resetState, password2: e.target.value})} value={resetState.password2}></input>
                <span className="show-hide-password"  onClick={togglePasswordVisibility2}>
                    {showPassword2 ? 'Hide' : 'Show'}
                </span>           
                <br></br>        
                <button id="login-btn" className='input-action-btn' onClick={handleReset}>Reset Password</button>
                {successfulReset && <p className='successfulReset'>Password reset success, please <span onClick={handleChangeToLogin} className='successfulReset-click'>login now</span></p>}
            </div>
        :
        <React.Fragment>
            {formType === "login" ? (
                <div className='login-div'>
                    <h1 className='login-header'>Log In</h1>
                    {errorMessage.length > 0 && <h2>{errorMessage}</h2>}
                    <p className='login-subtext'>Email</p>
                    <input id="login-input" className='text-input-standard' name="email" onChange={(e) => setLoginState({...loginState, email: e.target.value})} value={loginState.email}></input>
                    <p className='login-subtext extra-pad'>Password</p>
                    
                    <input id="login-input" className='text-input-standard' name="current-password" type={showPassword ? "text" : "password"} onChange={(e) => setLoginState({...loginState, password: e.target.value})} value={loginState.password}></input>
                    
                    <span className="show-hide-password"  onClick={togglePasswordVisibility}>
                        {showPassword ? 'Hide' : 'Show'}
                    </span>                
                    <br></br>
                    <button id="login-btn" className='input-action-btn' onClick={handleLogin}>LOGIN</button>
                    <p onClick={handleForgotPassword} className='forgot-password'>Forgot your password?</p>
                    {recoverySent && <p>Recovery email sent, please check your inbox.</p>}
                    {recoveryError && <p>{recoveryError}</p>}
                    <p className='no-acc-txt'>Dont have an account? <span onClick={() => handleFormType("signup")} className='form-type-change'>Sign Up</span></p>
                </div>
            )
            :
            (
                <div className='login-div'>
                    <h1 className='login-header'>Sign Up</h1>
                    {errorMessage.length > 0 && <h2>{errorMessage}</h2>}
                    <p style={{fontWeight: 700}} className='login-subtext'>What would you like us to call you?</p>
                    <input id="login-input" className='text-input-standard' type="text" onChange={(e) => setSignupState({...signupState, givenName: e.target.value})} value={signupState.givenName}></input>
                    <p className='login-subtext extra-pad'>Email</p>
                    <input id="login-input" className='text-input-standard' type="email" name="email" onChange={(e) => setSignupState({...signupState, email: e.target.value})} value={signupState.email}></input>
                    <p className='login-subtext extra-pad'>Password</p>
    
                    <input id="login-input" className='text-input-standard' name="new-password" autocomplete="new-password" type={showPassword ? "text" : "password"}
                         onChange={(e) => setSignupState({...signupState, password: e.target.value})} value={signupState.password}></input>
    
                    <span className="show-hide-password"  onClick={togglePasswordVisibility}>
                        {showPassword ? 'Hide' : 'Show'}
                    </span>
                    <br></br>
                    <button id="login-btn" className='input-action-btn' onClick={handleSignup}>SIGN UP</button>
                    <p className='no-acc-txt'>Already with us? <span onClick={() => handleFormType("login")} className='form-type-change'>Log In</span></p>
                </div>
            )
            }
        </React.Fragment>
        }
    </div>
  )
}

export default LoginRoute