import axios from 'axios';

import React, {useState, useEffect} from 'react'

import { Document, HeadingLevel, Packer, Paragraph} from "docx";



const ActiveSummaryView = ({saveOrUpdateSummaries, activeSummary, summariesState, setSummariesState, internalMakeNote}) => {
    const [userText, setUserText] = useState("");
    const [GPTText, setGPTText] = useState("");
    const [summaryTitle, setSummaryTitle] = useState("");
    const [summaryInProgress, setSummaryInProgress] = useState(false);
    const [timeToGenerate, setTimeToGenerate] = useState(0);
    const [adultReadingRate, setAdultReadingRate] = useState(0);

    const [summaryType, setSummaryType] = useState("Clinical Trial Result");
    const [summaryLength, setSummaryLength] = useState("normal");
    const [url, setUrl] = useState("");

    useEffect(() => {
        if(activeSummary && activeSummary !== "new") {
            setUserText(activeSummary.source);
            setGPTText(activeSummary.summary);
            if (activeSummary.title) setSummaryTitle(activeSummary.title);
        }
    }, [])

    useEffect(() => {
        console.log(activeSummary)
        if (activeSummary === "new") {
            setGPTText("");
            setUserText("");
            setSummaryTitle("");
        }
    }, [activeSummary])


    useEffect(() => {
        let wordCount = userText.split(" ").length;
        setAdultReadingRate(Math.ceil(wordCount / 300));
    }, [userText])

    const summaryToNote = () => {
        internalMakeNote(summaryTitle, GPTText);
    }

    const handleUrl = (e) => {
        setUrl(e.target.value);
    }

    const handleChange = (e) => {
        setUserText(e.target.value)
      }  
    
      const handleSubmitSummary = (e) => {
        e.preventDefault();
        if (userText.length > 0) {
            callGPT();
        }
      }


      const callGPT = () => {
        setSummaryInProgress(true);
        const timer = setInterval(() => {
            setTimeToGenerate((prevCounter) => prevCounter + 1);
          }, 1000); // Update every second
        const data = {userText: userText, prompt: {type: summaryType, length: summaryLength}};
            axios.post('https://bancy-backend-01dc0125253f.herokuapp.com/summary/generate', { data })
            .then((response) => {
                let output = response.data.output.content;
                setTimeout(() => {
                    setGPTText(output)
                    setSummaryInProgress(false);
                    setTimeToGenerate(0);
                }, 1500)
                clearInterval(timer);
            })
            .catch(err => {
                console.log(err)
                clearInterval(timer);
                setSummaryInProgress(false);
                setTimeToGenerate(0);
            })
      }
    
      const handleFile = (e) => {
        if (e.target.files.length === 0) {
            setUserText("");
            return;
        }
        const accepted = [
            "text/plain",
            "text/html",
            "application/pdf",
            "text/csv",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/json",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            
            // tried and failed
            // "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            // "text/rtf",
            // might not work
            // "application/msword",
            // "application/vnd.oasis.opendocument.text",
            // "application/vnd.ms-powerpoint",
            "application/vnd.ms-excel",
        ]
        const thisFile = e.target.files[0];
        const fileType = thisFile.type;
       
        if (accepted.findIndex(item => item === fileType) === -1) {
            console.log(fileType)
            // setImproperFormat(true);
        } else {
            extractText(thisFile, fileType);
        }
      }

      const extractURL = () => {
        if (url.length > 0) {
            const data = {url: url}
            axios({
                method: 'post',
                url: 'https://bancy-backend-01dc0125253f.herokuapp.com/summary/extracturl',
                data: { data },
              })
              .then((response) => {
                console.log(response.data.output)
                setUserText(response.data.output);
              })
              .catch((error) => {
                console.log(error);
              });
        }
      }
    
      const extractText = (file, fileType) => {
        const formData = new FormData();
        formData.append('file', file);

        axios({
          method: 'post',
          url: 'https://bancy-backend-01dc0125253f.herokuapp.com/summary/extract',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          setUserText(response.data.output);
        })
        .catch((error) => {
          console.log(error);
        });
      }


  return (
    <div className="flex-col active-summary-master">
        <div className='gpt-sum-and-submit sum-basic-container'>
            <div className='gpt-summary-div'>
                {GPTText.length > 0 ? 
                    <React.Fragment>
                        {summaryTitle && <h3 className='gpt-summary-title'>{summaryTitle}</h3>}
                        <SummaryOutput GPTText={GPTText} />
                        {/* <button className='input-action-btn' onClick={summaryToNote}>SAVE AS NOTE</button> */}

                    </React.Fragment>
                    :
                    <div className='welcome-sum'>
                        {summaryInProgress ? 
                        (
                            <React.Fragment>
                            <h2 className='welcome-sum-title'>Summary In Progress</h2>
                            <h3 className='welcome-sum-subtitle'>The average adult would take {adultReadingRate} minutes to read this.</h3>
                            <h3 className='welcome-sum-subtitle'>Bancroft read this in <span style={{fontWeight: 700}}>{timeToGenerate} seconds</span></h3>
                            </React.Fragment>
                        )
                        :
                        (
                            <React.Fragment>
                            <h2 className='welcome-sum-title'>Bancroft Summary Generator</h2>
                            <h3 className='welcome-sum-subtitle'>Take your articles or documents and summarize them into a brief and articulate file <br></br> that can be downloaded or saved as needed.</h3>
                            <h3 className='welcome-sum-subtitle'><span style={{fontWeight: 700}}>Step 1:</span> Upload, Scrape Url, or Paste below</h3>
                            <h3 className='welcome-sum-subtitle'><span style={{fontWeight: 700}}>Step 2:</span> Select document type and summary length</h3>
                            <h3 className='welcome-sum-subtitle'><span style={{fontWeight: 700}}>Step 3:</span> Submit</h3>
                            <h3 className='welcome-sum-subtitle'><span style={{fontWeight: 700}}>Step 4:</span> Follow options for save or download</h3>
                            </React.Fragment>
                        )
                        }
                    </div>
                }
            </div>
            <div className='txt-border-options'></div>
            {GPTText.length > 0 ? 
                <SaveSummaryElem saveOrUpdateSummaries={saveOrUpdateSummaries} setGPTText={setGPTText} summaryTitle={summaryTitle} setSummaryTitle={setSummaryTitle} summariesState={summariesState} setSummariesState={setSummariesState} userText={userText} GPTText={GPTText} callGPT={callGPT}/> 
                : 
               <GenerateSummaryOptions summaryLength={summaryLength} summaryType={summaryType} handleSubmitSummary={handleSubmitSummary} setSummaryType={setSummaryType} setSummaryLength={setSummaryLength} />
            }
        </div>
        <div className='user-input-div sum-basic-container flex-col'>
            <div className='as-user-inputs flex-row'>
                <div className='flex-row as-input-type'>
                    <h5 className='as-input-txt'>File Upload</h5>
                    <input className="as-file-upload" type="file" onChange={handleFile}></input>
                </div>
                {/* <div className='flex-row as-input-type'>
                    <h5 className='as-input-txt'>Url Scrape</h5>
                    <input className='name-summary-input' onChange={handleUrl}></input>
                    <button onClick={extractURL} id="url-action-btn" className='input-action-btn'>PROCESS URL</button>
                </div> */}
            </div>
            <textarea onChange={handleChange} value={userText} placeholder="or paste text" className='summary-textarea'></textarea>
        </div>
    </div>
  )
}

const SummaryOutput = ({GPTText}) => {
    const local = GPTText.split("\n");
    return (
        <React.Fragment>
            {local.map((para, index) => {
                return <p key={`gpt${index}para`} id={index === local.length - 1 ? "last-para" : undefined} className='gpt-text-output'>{para}<br></br></p>
            })}
        </React.Fragment>
    )
}

const GenerateSummaryOptions = ({handleSubmitSummary, setSummaryLength, setSummaryType, summaryType, summaryLength}) => {
    const [mainType, setMainType] = useState("Press Release");

    const ds = {
        "Press Release": [
            "Clinical Trial Result",
            "Financial Report",
            "Regulatory Update",
            "Other"
        ],
        "Literature / Academic Article": [
            "Clinical Trial Data",
            "Reviews and Meta-Analyses",
            "Guidelines and Recommendations",
            "Case Studies",
            "Regulatory and Policy",
            "Basic Science",
            "Other or Non-Medical",
        ],
        "General": [],
    }

    const dsKeys = ["Press Release", "Literature / Academic Article", "General"];

    useEffect(() => {
        if (mainType === "General") {
            setSummaryType("General");
        } else {
            setSummaryType(ds[mainType][0])
        }
    }, [mainType, setSummaryType])


    return (
        <div className='gpt-submit flex-col'>
            <h4 className='sum-save-options'>Summary Generator</h4>
            <h5 className='save-to-txt'>Select Document Type</h5>
            <select id="sum-save-select" className='text-input-select text-left' value={mainType} onChange={(e) => setMainType(e.target.value)}>
                {dsKeys.map((mType, index) => {
                    return <option key={`main-type-sum${index}`} className='input-option-standard' value={mType}>{mType}</option>
                })}
            </select>
            {mainType !== "General" && 
                <select id="sum-save-select" className='text-input-select text-left' value={summaryType} onChange={(e) => setSummaryType(e.target.value)}>
                    {ds[mainType].map((sumType, index) => {
                        return <option key={`sub-type-sum${index}`} className='input-option-standard' value={sumType}>{sumType}</option>
                    })}
                </select>
            }
            <h5 className='save-to-txt'>Summary Length</h5>
            <select id="sum-save-select" className='text-input-select text-left' value={summaryLength} onChange={(e) => setSummaryLength(e.target.value)}>
                <option className='input-option-standard' value={"short"}>Short</option>
                <option className='input-option-standard' value={"normal"}>Normal</option>
                <option className='input-option-standard' value={"detailed"}>Detailed</option>
            </select>
            <button id="gen-submit" onClick={handleSubmitSummary} className='input-action-btn'>SUBMIT</button>
        </div>
    )
}

const SaveSummaryElem = ({saveOrUpdateSummaries, summariesState, setSummariesState, userText, GPTText, setGPTText, summaryTitle, setSummaryTitle}) => {
    const folderList = summariesState.map(sum => sum.title);
    const [saveLocation, setSaveLocation] = useState(folderList[0]);
    const [successMessage, setSuccessMessage] = useState(false);
    const [sumDownloadType, setSumDownloadType] = useState("txt")

    const saveSummary = () => {
        const newSum = {
            title: summaryTitle,
            source: userText,
            summary: GPTText,
            timeStamp: Date.now(),
        }
        let success = false;
        let forDB;
        let target = summariesState.findIndex(itm => itm.title === saveLocation)
        setSummariesState(prev => {
            if (target !== -1) {
                target = prev[target];
                target.documents = [{...newSum}, ...target.documents];
                forDB = target;
                success = true;
            }
            return [...prev];
        })
        if (success) {
            setSuccessMessage(true);
            saveOrUpdateSummaries("update", target)
        }
    }

    const downloadSummary = () => {
        let fileName = summaryTitle.length > 0 ? summaryTitle : "summary"
        if (sumDownloadType === "txt") {
            const docText = `${fileName}\n\n${GPTText}`
            const element = document.createElement("a");
            const file = new Blob([docText], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = `${fileName}.txt`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        } else {
            let paras = GPTText.split("\n");
            paras = paras.filter(para => para.length > 0);
            console.log(paras)
            const summaryParagraphs = paras.map(para => {
                return new Paragraph({
                    text: para,
                    style: "textStyle",
                    spacing: {
                        after: 240, // 240 twips = 12 points = 1 line
                    },
                })
            })
            const doc = new Document({
                styles: {
                    paragraphStyles: [
                        {
                            id: 'headerStyle',
                            name: 'summaryTitle',
                            basedOn: 'Normal',
                            next: 'Normal',
                            quickFormat: true,
                            run: {
                                font: {
                                    name: 'Arial',
                                },
                                heading: HeadingLevel.HEADING_1,
                                size: 36,
                                color: "#2e74b5"
                            },
                        },
                        {
                            id: 'textStyle',
                            name: 'summaryTitle',
                            basedOn: 'Normal',
                            next: 'Normal',
                            quickFormat: true,
                            run: {
                                font: {
                                    name: 'Arial',
                                },
                            },
                        },
                    ],
                },
                sections: [
                  {
                    properties: {},
                    children: [
                          new Paragraph({
                            text: fileName,
                            style: 'headerStyle',
                            spacing: {
                                after: 240, //240 twips = 12 points
                            }
                          }),
                          ...summaryParagraphs,
                        ],
                  },
                ],
              });

              Packer.toBlob(doc).then((blob) => {
                // blob contains the docx file, download it or do whatever you like
                const blobUrl = URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = blobUrl;
                anchor.download = `${fileName}.docx`;
                anchor.click();
            
                // Free up blob memory
                URL.revokeObjectURL(blobUrl);
              });
        }
    }

    return (
        <div className='gpt-submit flex-col'>
            <h4 className='sum-save-options'>Save Options</h4>
            <h5 className='save-to-txt'>Summary Name</h5>
            <input id="sum-name-txt" className='text-input-standard text-left' onChange={(e) => setSummaryTitle(e.target.value)} value={summaryTitle}></input>
            <h5 className='save-to-txt'>Save To</h5>
            <select id="sum-save-select" className='text-input-select text-left' value={saveLocation} onChange={(e) => setSaveLocation(e.target.value)}>
                {folderList.map((folderName, index) => {
                    return <option key={`sum-save-opt${index}`} className='input-option-standard' value={folderName}>{folderName}</option>
                })}
            </select>
            <div className='flex-row alignCenter'>
                <button id="save-btn-sg" className='input-action-btn' onClick={saveSummary}>SAVE</button>
                {successMessage && <p className='save-sum-success'>Summary Saved!</p>}
            </div>
            <div className='flex-row alignCenter'>
                <button id="no-marg" className='input-action-btn' onClick={downloadSummary}>DOWNLOAD</button>
                <select id="sum-save-select-y" className='text-input-select text-left' value={sumDownloadType} onChange={(e) => setSumDownloadType(e.target.value)}>
                    <option className='input-option-standard' value={"txt"}>.txt file</option>
                    <option className='input-option-standard' value={"docx"}>.docx file</option>
                </select>
            </div>
            <button id="regen-sum-btn" className='input-action-btn' onClick={() => setGPTText("")}>REGENERATE</button>
        </div>
    )
}

export default ActiveSummaryView