import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import LoginRoute from './LoginRoute';

const ShareRoute = ({activeUser, setVisitShareLink}) => {
  const [foundModel, setFoundModel] = useState("unset");
  const [modelData, setModelData] = useState({});

  const { parameter } = useParams();

  const navigate = useNavigate()


  const handleNavigation = () => {
    if (foundModel === "valid") {
        navigate(`/forecast/${modelData._id}`)
    }
  }
  useEffect(() => {
        setVisitShareLink(parameter)
        const token = localStorage.getItem("jwtToken");

        const data = { modelId: parameter }
        if (token) {
            // Fetch user info
            axios.post(`https://bancy-backend-01dc0125253f.herokuapp.com/forecast/share-recieve`, data, { 
            // axios.get("http://localhost:8000/summary", { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                console.log(response)
                if (response.data.savedCopiedModel) {
                    setFoundModel("valid");
                    setModelData(response.data.savedCopiedModel)
                } else {
                    setFoundModel("invalid")
                }
            })
            .catch(err => {
                console.log(err);
                setFoundModel("invalid")
            });
        } else {
            navigate("/login")
        }
  }, [])

  return (
    <div className='max-window'>
        <h1>Someone had shared a model with you.</h1>
        <button onClick={() => handleNavigation()}>VIEW MODEL</button>
    </div>
  )
}

export default ShareRoute