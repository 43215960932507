const phaseDataTherapeuticArea = {
    allergy: {
        phaseOne: 0.6760,
        phaseTwo: 0.3250,
        phaseThree: 0.7140,
        approval: 0.88
    },
    autoimmune: {
        phaseOne: 0.6840,
        phaseTwo: 0.3960,
        phaseThree: 0.6610,
        approval: 0.88
    },
    cardiovascular: {   
        phaseOne: 0.6150,
        phaseTwo: 0.3073,
        phaseThree: 0.5877,
        approval: 0.88
    }, 
    endocrine: {
        phaseOne: 0.6005,
        phaseTwo: 0.3385,
        phaseThree: 0.7210,
        approval: 0.88
    }, 
    gastrointestinal: {
        phaseOne: 0.7155,
        phaseTwo: 0.3530,
        phaseThree: 0.5530,
        approval: 0.88
    }, 
    hematology: {
        phaseOne: 0.7330,
        phaseTwo: 0.5660,
        phaseThree: 0.7500,
        approval: 0.88
    },
    infectious_disease: {
        phaseOne: 0.6487,
        phaseTwo: 0.4693,
        phaseThree: 0.7367,
        approval: 0.88
    },
    metabolic: {
        phaseOne: 0.6110,
        phaseTwo: 0.4520,
        phaseThree: 0.7140,
        approval: 0.88
    },
    musculoskeletal: {
        phaseOne: 0.7240,
        phaseTwo: 0.3520,
        phaseThree: 0.8000,
        approval: 0.88
    },
    neurology: {
        phaseOne: 0.6047,
        phaseTwo: 0.3237,
        phaseThree: 0.5687,
        approval: 0.88
    },
    psychiatry: {
        phaseOne: 0.5330,
        phaseTwo: 0.2370,
        phaseThree: 0.5570,
        approval: 0.88
    },
    oncology: {
        phaseOne: 0.6783,
        phaseTwo: 0.3677,
        phaseThree: 0.5337,
        approval: 0.88
    },
    ophthalmology: {
        phaseOne: 0.8480,
        phaseTwo: 0.4460,
        phaseThree: 0.5830,
        approval: 0.88
    },
    respiratory: {
        phaseOne: 0.6907,
        phaseTwo: 0.2797,
        phaseThree: 0.7747,
        approval: 0.88
    },
    urology: {
        phaseOne: 0.5710,
        phaseTwo: 0.3270,
        phaseThree: 0.7140,
        approval: 0.88
    },
    none: {
        phaseOne: 0.6490,
        phaseTwo: 0.3673,
        phaseThree: 0.6323,
        approval: 0.88
    }
}
const Phase = {
    preclinical: 0,
    phaseOne: "phaseOne",
    phaseTwo: "phaseTwo",
    phaseThree: "phaseThree",
}

const TherapeuticArea = {
    allergy: "allergy",
    autoimmune: "autoimmune",
    cardiovascular: "cardiovascular", 
    endocrine: "endocrine", 
    gastrointestinal: "gastrointestinal", 
    hematology: "hematology",
    infectious_disease: "infectious_disease",
    metabolic: "metabolic",
    musculoskeletal: "musculoskeletal",
    neurology: "neurology",
    psychiatry: "psychiatry",
    oncology: "oncology",
    ophthalmology: "ophthalmology",
    respiratory: "respiratory",
    urology: "urology",
    none: "none"
}


// BiologicalRationale is a function that takes a user-entered score and returns a multiplier
// used for POS calculation
function qualitativeConverter(score) {
    return 1.0 + (score * 0.025);
}

function CalculatePhaseOnePOS(po) { // po is passObject
    let phaseOnePOS = phaseDataTherapeuticArea[String(po.therapeuticArea)]["phaseOne"];

    return phaseOnePOS;
}

function CalculatePhaseTwoPOS(po) {
    let phaseTwoPOS = phaseDataTherapeuticArea[String(po.therapeuticArea)]["phaseTwo"];

    return phaseTwoPOS;
}

function CalculatePhaseThreePOS(po) {
    let phaseThreePOS = phaseDataTherapeuticArea[String(po.therapeuticArea)]["phaseThree"];

    return phaseThreePOS;
}   

function CalculateApprovalPOS(po) {

    let approvalPOS = phaseDataTherapeuticArea[String(po.therapeuticArea)]["approval"];

    return approvalPOS;
}


function AdjustedCalculatePhaseOnePOS(po) { // po is passObject
    let rareMultiplierPhaseOne = po.isRare === true ? 1.19 : 1.0;
    let chronicHighPrevalenceMultiplier = po.isChronicHighPrevalence === true ? 0.916 : 1.0;
    let biomarkersMultiplier = 1.0; 

    let phaseOnePOS = phaseDataTherapeuticArea[String(po.therapeuticArea)]["phaseOne"] * rareMultiplierPhaseOne * chronicHighPrevalenceMultiplier * biomarkersMultiplier;
   

    return phaseOnePOS;
}

function AdjustedCalculatePhaseTwoPOS(po) {
    let rareMultiplierPhaseTwo = po.isRare === true ? 1.32 : 1.0;
    let chronicHighPrevalenceMultiplier = po.isChronicHighPrevalence === true ? 0.724 : 1.0;
    let biomarkersMultiplier = 1.0;

    let phaseTwoPOS = phaseDataTherapeuticArea[String(po.therapeuticArea)]["phaseTwo"] * rareMultiplierPhaseTwo * chronicHighPrevalenceMultiplier * biomarkersMultiplier;
    console.log("phaseTwoPOS: " + phaseTwoPOS)
    return phaseTwoPOS;
}

function AdjustedCalculatePhaseThreePOS(po) {
    console.log(po)
    let rareMultiplierPhaseThree = po.isRare === true ? 1.16 : 1.0;
    let chronicHighPrevalenceMultiplier = po.isChronicHighPrevalence === true ? 0.970 : 1.0;
    let biomarkersMultiplier = 1.0;

    console.log(rareMultiplierPhaseThree, chronicHighPrevalenceMultiplier, "HERE I AM")
    let phaseThreePOS = phaseDataTherapeuticArea[String(po.therapeuticArea)]["phaseThree"] * rareMultiplierPhaseThree * chronicHighPrevalenceMultiplier * biomarkersMultiplier;

    return phaseThreePOS;
}   

function AdjustedCalculateApprovalPOS(po) {
    let rareMultiplierApproval = 1.0;
    let chronicHighPrevalenceMultiplier = po.isChronicHighPrevalence === true ? 1.0 : 1.0;
    let biomarkersMultiplier = 1.0;

    let approvalPOS = phaseDataTherapeuticArea[String(po.therapeuticArea)]["approval"] * rareMultiplierApproval * chronicHighPrevalenceMultiplier * biomarkersMultiplier;

    return approvalPOS;
}


// adjust variables with default values, to not require them to be passed in (e.g. inHouse = true, etc.)
// , isOrphanDrug = false, isFirstInClass = false, isFastTrack = false, isBreakthroughTherapy = false, isAcceleratedApproval = false, isPriorityReview = false, isConditionalApproval = false, isAcceleratedAssessment = false, isConditionalMarketingAuthorization = false, isAcceleratedAccess = false, isConditionalReimbursement = false, isAcceleratedReimbursement = false"


export const calculatePOS = ({startPhase, therapeuticArea, biologicalRationaleScore, observedTreatmentEffect, isRare = false, inHouse = true, isChronicHighPrevalence = false, isBiomarkers = "Unknown"}) => {
    let passObject = {
        startPhase: startPhase,
        therapeuticArea: therapeuticArea, 
        isRare: isRare, 
        isChronicHighPrevalence: isChronicHighPrevalence, 
        isBiomarkers: isBiomarkers,
    }
    console.log(passObject, "BULLISH")

    let resPOS = {
        startPhase: startPhase,
        phaseOne: CalculatePhaseOnePOS(passObject),
        phaseTwo: CalculatePhaseTwoPOS(passObject),
        phaseThree: CalculatePhaseThreePOS(passObject),
        approval: CalculateApprovalPOS(passObject),
        total: 0.0,
        AdjustedPhaseOne: AdjustedCalculatePhaseOnePOS(passObject),
        AdjustedPhaseTwo: AdjustedCalculatePhaseTwoPOS(passObject),
        AdjustedPhaseThree: AdjustedCalculatePhaseThreePOS(passObject),
        AdjustedApproval: AdjustedCalculateApprovalPOS(passObject),
        AdjustedTotal: 0.0,
    }

    console.log("adjustedApproval" + resPOS.AdjustedApproval);

    if (startPhase === "Preclinical") {
        startPhase = Phase.phaseOne;
    }
    else if (startPhase === "Phase 1") {
        startPhase = Phase.phaseOne;
    }
    else if (startPhase === "Phase 2") {
        startPhase = Phase.phaseTwo;
    }
    else if (startPhase === "Phase 3") {
        startPhase = Phase.phaseThree;
    }

    console.log("observedTreatmentEffect: " + observedTreatmentEffect);

    console.log(startPhase);

    if (startPhase === Phase.preclinical || startPhase === Phase.phaseOne) {
        resPOS.total = resPOS.phaseOne * resPOS.phaseTwo * resPOS.phaseThree * resPOS.approval;
    }
    else if (startPhase === Phase.phaseTwo) {
        resPOS.total = resPOS.phaseTwo * resPOS.phaseThree * resPOS.approval;
    }
    else if (startPhase === Phase.phaseThree) {
        resPOS.total = resPOS.phaseThree * resPOS.approval;
    }
    else if (startPhase === Phase.approval) {
        resPOS.total = resPOS.approval;
    }

    


    //let inHouse_multiple = inHouse === true ? 1.16 : 1.0;
    let inHouse_multiple = 1;
    let biologicalRationaleMultiplier = qualitativeConverter(biologicalRationaleScore);
    let observedTreatmentEffectMultiplier = qualitativeConverter(observedTreatmentEffect);

    // adjust Adjusted probabilities at each stage
    resPOS.AdjustedPhaseOne = resPOS.AdjustedPhaseOne * biologicalRationaleMultiplier * observedTreatmentEffectMultiplier * inHouse_multiple;
    resPOS.AdjustedPhaseTwo = resPOS.AdjustedPhaseTwo * biologicalRationaleMultiplier * observedTreatmentEffectMultiplier * inHouse_multiple;
    resPOS.AdjustedPhaseThree = resPOS.AdjustedPhaseThree * biologicalRationaleMultiplier * observedTreatmentEffectMultiplier * inHouse_multiple;

    resPOS.AdjustedApproval = resPOS.AdjustedApproval * biologicalRationaleMultiplier * observedTreatmentEffectMultiplier * inHouse_multiple;

    //resPOS.total = resPOS.total * biologicalRationaleMultiplier * observedTreatmentEffectMultiplier * inHouse_multiple;
    resPOS.AdjustedTotal = resPOS.total * biologicalRationaleMultiplier * observedTreatmentEffectMultiplier * inHouse_multiple;

    if (resPOS.AdjustedTotal > 0.95) {
        resPOS.AdjustedTotal = 0.95;
    }
    if (resPOS.AdjustedApproval > 0.95) {
        resPOS.AdjustedApproval = 0.95;
    }
    if (resPOS.AdjustedPhaseThree > 0.95) {
        resPOS.AdjustedPhaseThree = 0.95;
    }
    if (resPOS.AdjustedPhaseTwo > 0.95) {
        resPOS.AdjustedPhaseTwo = 0.95;
    }
    if (resPOS.AdjustedPhaseOne > 0.95) {
        resPOS.AdjustedPhaseOne = 0.95;
    }


    


    return resPOS;    
}


// CalculatePOS(startPhase, therapeuticArea, biologicalRational, observedTreatmentEffect, isRare = false, inHouse = true, isChronicHighPrevalence = false, isBiomarkers = "Unknown")
/*
console.log(CalculatePOS(Phase.approval, TherapeuticArea.ophthalmology, 2, 2, isRare = true, inHouse = false, isChronicHighPrevalence = false, "Unknown"));
        function submitForm() {
            var biologicalRationaleScore = document.getElementById("biological-rationale").value;
            var observedTreatmentEffect = document.getElementById("observed-treatment-effect").value;
            var therapeuticArea = document.getElementById("therapeutic-area").value;
            var phase = document.getElementById("phase").value;
            var isRare = document.getElementById("approvedCheckbox").checked; // Assuming 'isRare' corresponds to the 'approvedCheckbox'

            // Map phase to appropriate Phase constant
            var startPhase;
            switch (phase) {
                case "Preclinical": startPhase = Phase.preclinical; break;
                case "Phase 1": startPhase = Phase.phaseOne; break;
                case "Phase 2": startPhase = Phase.phaseTwo; break;
                case "Phase 3": startPhase = Phase.phaseThree; break;
                default: startPhase = "unknown"; // Default case, adjust as necessary
            }

            // Assuming 'inHouse' and 'isChronicHighPrevalence' are always true and false respectively for this example
            var result = CalculatePOS(startPhase, therapeuticArea, biologicalRationaleScore, observedTreatmentEffect, isRare, true, false, "Unknown");

            var peak_sales = (document.getElementById("peak-penetration").value) * (document.getElementById("prevalence-tam").value) * (document.getElementById("drug-price").value / 100);
            console.log(number.toLocaleString(peak_sales))

            document.getElementById("result").innerHTML = "Result: " + JSON.stringify(result) + peak_sales;
        }
*/