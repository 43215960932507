import React, { useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import ForecastPage from '../components/forecastPage/ForecastPage';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { example1 } from '../exampleForecasts';

const ForecastRoute = (props) => {
    const {quickStart, addNoteToDB, delNoteFromDB, activeUser, notepadRef, userSettings, setUserSettings, notes, setNotes, notepadPresets, setNotepadPresets} = props;
  
    const [userForecasts, setUserForecasts] = useState(false);
    const [workingProjects, setWorkingProjects] = useState({scenarios: [{name: "base", forecast: {...example1}},{name: "best", forecast: {...example1}},{name: "worst", forecast: {...example1}}]});

    const navigate = useNavigate();
    const { projectID } = useParams();

  useEffect(() => {
    if (activeUser) {

    //   if (activeUser && activeUser.modules.forecast) {
        const token = localStorage.getItem("jwtToken");
        
        if (token) {
            // Fetch user info
            axios.get("https://bancy-backend-01dc0125253f.herokuapp.com/forecast", { 
            // axios.get("http://localhost:8000/forecast", { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                // console.log(response.data.forecasts)
                console.log(response)
                setUserForecasts(response.data.forecasts);
            })
            .catch(err => {
                console.log(err);
            });
        } else {
            navigate("/login")
        }
    }
  }, [activeUser, navigate])

  const saveOrUpdateForecasts = useCallback((action, forecast) => {
    const token = localStorage.getItem("jwtToken");
    if (!token) return navigate("/login");
    let data;
    switch (action) {
        case "new":             
            data = { forecast: forecast };
            axios.post("https://bancy-backend-01dc0125253f.herokuapp.com/forecast", data, { 

            // axios.post("http://localhost:8000/summary", data, { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                setUserForecasts(prev => {
                    const copy =[...prev];
                    let workingForecast = prev.findIndex(elem => {
                        if (!elem.tempId) return false;
                        return elem.tempId === forecast.tempId;
                    });
                    if (workingForecast !== -1) {
                        copy[workingForecast] = response.data.savedForecast;
                        delete copy[workingForecast].tempId
                    }
                    return [...copy];
                });

                if (workingProjects.scenarios) {
                    setWorkingProjects(prev => {
                        const copy = response.data.savedForecast;
                        delete copy.tempId
                        return copy
                    })
                }
            })
            .catch(err => {
                console.log(err);
            });
            break;
        case "update":
            // "https://bancy-backend-01dc0125253f.herokuapp.com/summary" 

            data = { forecast: forecast };
            axios.put("https://bancy-backend-01dc0125253f.herokuapp.com/forecast", data, { 

            // axios.put("http://localhost:8000/summary", data, { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                setUserForecasts(prev => {
                    const copy = [...prev];
                    let workingForecast = prev.findIndex(elem => elem._id === forecast._id);
                    if (workingForecast !== -1) {
                        copy[workingForecast] = response.data.updatedForecast;
                    }
                    return [...copy];
                });

                if (workingProjects.scenarios) {
                    setWorkingProjects(prev => {
                        const copy = response.data.updatedForecast;
                        return copy
                    })
                }
            })
            .catch(err => {
                console.log(err);
            });
            break;
        case "delForecast":
            // "https://bancy-backend-01dc0125253f.herokuapp.com/summary" 
            axios.delete(`https://bancy-backend-01dc0125253f.herokuapp.com/forecast?forecastId=${forecast._id}`, { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                setUserForecasts(prev => {
                    const copy = [...prev];
                    let workingForecast = prev.findIndex(elem => elem._id === forecast._id);
                    if (workingForecast !== -1) {
                        copy.splice(workingForecast, 1);
                    }
                    return [...copy];
                });
            })
            .catch(err => {
                console.log(err);
            });
            break;
        default:
            break;
    }
}, [navigate]);

    return (

        // (activeUser && activeUser.modules.forecast)
        (activeUser)
        ?
        Array.isArray(userForecasts) && <ForecastPage quickStart={quickStart} quickStartID={quickStart ? projectID : null} workingProjects={workingProjects} setWorkingProjects={setWorkingProjects} saveOrUpdateForecasts={saveOrUpdateForecasts} setUserForecasts={setUserForecasts} addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} userForecasts={userForecasts} activeUser={activeUser} notepadRef={notepadRef} userSettings={userSettings} setUserSettings={setUserSettings} notes={notes} setNotes={setNotes} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets} />
        :
        <ForecastPreview />

    )
}

const ForecastPreview = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/user')
    }

    return (
        <div className='preview max-window'>
            <h1>Forecast</h1>
            <h3>Unlock bancrofts powerful forecasting tool.</h3>
            <button className='input-action-btn' onClick={handleRedirect}>ADD MODULE</button>
        </div>
    )
}

export default ForecastRoute