import React, { useEffect, useState, useMemo } from 'react'
import { BIRTHS_CONST, POPULATIONS_CONST, GEOGRAPHIES_CONST } from '../../constants'
import { countDecimals, roundToTwoDecimals, toCommaSeperatedString, roundToNDecimals } from '../../functions/helpers/generalHelpers'
import WarningPopupSmall from '../util/WarningPopupSmall'
import closeIcon from "../../icons/bancroft_X_icon.svg"
const IsolatedCalculator = ({geographyList}) => {

  const [inputState, setInputState] = useState(0);

  // for gene thereapy --- incident pop
  const [inputState2, setInputState2] = useState(0);
  const [alterAllGeo, setAlterAllGeo] = useState(true);
  const [calculatorType, setCalculatorType] = useState("prevalence");
  const [calculatorType2, setCalculatorType2] = useState("incidence");

  const [calculatorFormat, setCalculatorFormat] = useState("patients")
  const [totalPatients, setTotalPatients] = useState(0);
  const [resultingPrevalence, setResultingPrevalence] = useState(0);

  const [resultPatients, setResultPatients] = useState([0]);
  const [geoState, setGeoState] = useState({});
  const [warning, setWarning] = useState(false)

  useEffect(() => {
    console.log(calculatorType, calculatorType2)
  }, [calculatorType, calculatorType2])


  useEffect(() => {
    if (inputState && inputState !== 0) {
      if (calculatorType === "prevalence") {
        const resPatients = geographyList.map(geo => {
            return Math.ceil(inputState[0] * POPULATIONS_CONST[geo])
        })
        setResultPatients(resPatients)
      } else if (calculatorType === "birthsIncidence" || calculatorType === "incidence") {
        const resPatients = geographyList.map(geo => {
            return Math.ceil(inputState[0] * inputState[2] * BIRTHS_CONST[geo])
        })
        setResultPatients(resPatients)
      }
    }
}, [inputState, calculatorType, geographyList])

useEffect(() => {
    if (geographyList.length > 0) {
        let thePatients = parseInt(totalPatients);
        console.log(thePatients)
        if (thePatients && thePatients !== 0) {
            let popTotal = 0;
            geographyList.forEach(geo => {
                popTotal += POPULATIONS_CONST[geo];
            })
            let val = (100 * (thePatients / popTotal))
            let decimalPoints = Math.floor(Math.log10(Math.abs(val)));
            console.log(decimalPoints)
            if (decimalPoints > -1) {
                decimalPoints = 2;
            } else if (decimalPoints < -3) {
                decimalPoints = 4
            } else {
                decimalPoints = 2 + Math.abs(decimalPoints)
            }
            console.log(val, decimalPoints)
            
            const resPrev = val.toFixed(decimalPoints)
            setResultingPrevalence(resPrev)
        }
    }
}, [totalPatients, geographyList])

const handleCalculatorFormat = (e) => {
    setCalculatorFormat(e.target.value);
}

const handleTotalPatients = (e) => {
    setTotalPatients(e.target.value)
}

  const handleCalculator = (e, type) => {
    e.preventDefault();
    if (type === "incident") {
        setCalculatorType2(e.target.value);
        setInputState2(0);
    } else {
        setCalculatorType(e.target.value);
        // setGeoState(initGeoState);
        setInputState(0);
    }
  }

  return (
    <div id="pp-calculator">
        <div id="iso-calc-header-div" className='flex-row p-c-t-d'>
            <h4 id='iso-title-txt' className='p-calc-title'>Patient Calculator</h4>
        </div>
        <div className='iso-calc-format flex-row alignCenter'>
            <h5 className='input-header'>Format:</h5>
            <select id="iso-calc-format-selector" className='text-input-select' value={calculatorFormat} onChange={handleCalculatorFormat}>
                <option className='input-option-standard' value="patients">Patients</option> 
                <option className='input-option-standard' value="prevalence">Prevalence</option> 
            </select>
        </div>
    
        {calculatorFormat === "patients" ?
        <CalculatorInner geographyList={geographyList} resultPatients={resultPatients} inputState={inputState} calculatorType={calculatorType} handleCalculator={handleCalculator} setInputState={setInputState} geoState={geoState}/>
        :
        <CalcByGeoInner geographyList={geographyList} resultingPrevalence={resultingPrevalence} resultPatients={resultPatients} inputState={inputState} calculatorType={calculatorType} handleCalculator={handleCalculator} setInputState={setInputState} geoState={geoState} handleTotalPatients={handleTotalPatients} totalPatients={totalPatients}/>
        }

    </div>
  )
}

const CalcByGeoInner = ({geographyList, resultPatients, resultingPrevalence, isGeneCalc2, type, calculatorType, handleTotalPatients, totalPatients, setInputState}) => {
    const [outputList, setOutputList] = useState([]);
    const [outputRes, setOutputRes] = useState([]);
    const [newGeography, setNewGeography] = useState("")
    

    const handleAddGeography = () => {
        if (POPULATIONS_CONST[newGeography]) {
            setOutputList(prev => {
                const copy = [...prev]
                return [...copy, newGeography]
            })
            setNewGeography("")
        }
    }

    const handleNewGeography = (e) => {
        setNewGeography(e.target.value)
    }

    const handleRemoveGeo = (geo) => {
        console.log(geo)
        const foundIndex = outputList.indexOf(geo);
        if (foundIndex !== -1) {
            setOutputList(prev => {
                const copy = [...prev];
                copy.splice(foundIndex, 1);
                return copy;
            })
        }
    }

    useEffect(() => {

        console.log(outputList)
        const resPatients = outputList.map(geo => {
            return Math.ceil(POPULATIONS_CONST[geo] * (resultingPrevalence / 100))
        })

        setOutputRes(resPatients)
      
    }, [outputList, resultingPrevalence])

    return (
        <React.Fragment>
            <div className='p-calc-type'>
            <div className='calc-by-div'>
                <h5 id="iso-calculate-text" className='input-header'>Total Patients</h5>
                <input className='text-input-standard text-left' type="number" onChange={handleTotalPatients} value={totalPatients}></input>
            </div>
        </div>
            <div className='p-calc-main'>            
                <h3 className='iso-res-prev'>Resulting Prevalence</h3>
                <h5 className='iso-res-prev-num'>{resultingPrevalence}%</h5>
            </div>
        <div id="iso-p-calc-geo" className='p-calc-geo'>
            <h4 id="iso-patients-p-c-text">{type === "incident" ? "Incident" : type === "prevalent" ? "Prevalent" : null} Patients</h4>
            <h5 style={{margin: 0}} className='input-header'>Output Geographies</h5>
            <div className='iso-target-by-geo-div flex-row alignCenter'>
                {outputList.length <= 3 ? 
                    <React.Fragment>
                        <input placeholder='Select' style={{marginBottom: "7px"}} id="input-select-geo-nav-iso" className='text-input-standard text-left' list="geographies" type="text" onChange={handleNewGeography} value={newGeography}></input>
                            <datalist id="geographies">
                                {GEOGRAPHIES_CONST.map(item => {
                                    return <option key={"geo-dropdown" + item}>{item}</option>
                                })}
                            </datalist>
                    </React.Fragment>
                    :
                    <h5 className='iso-max-geos'>Max Geographies</h5>
                    }
                    {outputList.length <= 3 && <button id="add-geo-btn-iso" className='input-action-btn general-btn' onClick={handleAddGeography}>Add</button>}
            </div>
            {outputRes.map((item, index) => {
                const thisGeo = outputList[index];
                return <h4 key={`p-calc${thisGeo}-geo`} id='iso-p-calc-geo-name' className='p-calc-geo-name'>{thisGeo}: <span style={{fontWeight: 400}}>{isNaN(item) ? 0 : toCommaSeperatedString(item)}</span><span><img onClick={() => handleRemoveGeo(thisGeo)} id="calc-icon-x-iso-2" className='container-icon' src={closeIcon} alt={"remove geography"}></img></span></h4>
            })}
        </div>
        {type && <div className='modal-hr'></div>}

        </React.Fragment>
    )
}

const CalculatorInner = ({geographyList, resultPatients, inputState, workingForecast, isGeneCalc2, type, calculatorType, setAlterAllGeo, handleCalculator, alterAllGeo, workingGeographies, alteredGeo, handleAlteredGeo, setInputState, geoState}) => {
    const displayText = type === "incident" ? "Incident Population" : type === "prevalent" ? "Prevalent Population" : null;
    return (
        <React.Fragment>
            <div className='p-calc-type'>
            <div className='calc-by-div'>
                <h5 id="iso-calculate-text" className='input-header'>Calculate</h5>
                <select id="iso-calc-type-selector" className='text-input-select' value={calculatorType} onChange={handleCalculator}>
                    {type !== "incident" &&<option className='input-option-standard' value="prevalence">Prevalence Ratio</option> }
                    {type !== "prevalent" && <option className='input-option-standard' value="incidence">Yearly Incidence</option> }
                    {type !== "prevalent" && <option className='input-option-standard' value="birthsIncidence">Incidence in Yearly Births</option> }
                </select>
            </div>
        </div>
            <div className='p-calc-main'>            
                {calculatorType === "prevalence" &&
                    <PrevalenceCalc isGeneCalc2={isGeneCalc2} setInputState={setInputState} />
                }
                {calculatorType === "incidence" &&
                    <IncidenceCalc isGeneCalc2={isGeneCalc2} setInputState={setInputState} />
                }
                {calculatorType === "birthsIncidence" &&
                    <PerBirthsCalc isGeneCalc2={isGeneCalc2} setInputState={setInputState} />
                }
            </div>
        <div id="iso-p-calc-geo" className='p-calc-geo'>
            <h4 id="iso-patients-p-c-text">{type === "incident" ? "Incident" : type === "prevalent" ? "Prevalent" : null} Patients</h4>
            {/* <h4 className='p-calc-geo-name'>{currGeography}: <span className='p-calc-geo-val'>{isNaN(resultPatients) ? 0 : toCommaSeperatedString(resultPatients)}</span></h4> */}
            {resultPatients.map((item, index) => {
                const thisGeo = geographyList[index];
                return <h4 key={`p-calc${thisGeo}-geo`} id='iso-p-calc-geo-name' className='p-calc-geo-name'>{thisGeo}: <span style={{fontWeight: 400}}>{isNaN(item) ? 0 : toCommaSeperatedString(item)}</span></h4>
            })}
            {resultPatients.length === 0 && <h4 id='iso-p-calc-geo-name'>No Geographies Selected</h4>}
        </div>
        {type && <div className='modal-hr'></div>}

        </React.Fragment>
    )
  }

const PopulationCalc = ({setInputState, inputState}) => {
    return (
        <React.Fragment>
            <h5 className='input-header'>Patient Population</h5>
            <input className='text-input-standard text-left' type="number" onChange={(e) => setInputState(e.target.value)} value={inputState}></input>
        </React.Fragment>
    )
}

const PrevalenceCalc = ({setInputState, isGeneCalc2}) => {
    let leader = isGeneCalc2 ? "prevalence2" : "prevalence";
    const [prevalenceRatio, setPrevalenceRatio] = useState({numerator: 1, denominator: 1000});
    const [prevalenceDecimal, setPrevalenceDecimal] = useState(.001);

    useEffect(() => {
        if(prevalenceDecimal <= 1 && prevalenceDecimal >= 0) {
            setInputState([parseFloat(prevalenceDecimal), prevalenceRatio])
        } 
    }, [prevalenceDecimal, prevalenceRatio, setInputState])

    const handlePrevalenceRatio = (e, target) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        if (target === 'numerator') {
            setPrevalenceRatio({...prevalenceRatio, numerator: thisVal})
            if (thisVal !== "" && thisVal <= prevalenceRatio.denominator) {
                let pre = (thisVal / prevalenceRatio.denominator);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else if (target === 'denominator') {
            setPrevalenceRatio({...prevalenceRatio, denominator: thisVal})
            if (thisVal !== "" && thisVal >= prevalenceRatio.numerator) {
                let pre = (prevalenceRatio.numerator / thisVal);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else {
            console.warn("sus code")
        }
    }

      const handlePrevalenceDecimal = (e) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        setPrevalenceDecimal(thisVal);
        if (thisVal !== "" && (thisVal <= 1 && thisVal >= 0)) {
            let decimals = countDecimals(thisVal);
            let denValue = 1 * (10 ** decimals)
            let numValue = Math.round(thisVal * (10 ** decimals));  // Rounded here
            setPrevalenceRatio({numerator: numValue, denominator: denValue})
        }
      }

    return (
        <React.Fragment>
            <h5 className='iso-calc-header'>Prevalence in Total Population</h5>
            <div className='flex-row quarter-div-inputs alignCenter'>
                <input id="numerator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "numerator")} value={prevalenceRatio.numerator}></input>
                <p id="iso-ratio-slash">/</p>
                <input id="denominator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "denominator")} value={prevalenceRatio.denominator}></input>
                <h5 id="iso-or-text" className='input-header'>or</h5>
                <input id="prev-deci" className='text-input-standard text-left' type="number" max={1}  onChange={handlePrevalenceDecimal} value={prevalenceDecimal}></input>
            </div>
        </React.Fragment>
    )
}

const PerBirthsCalc = ({setInputState, isGeneCalc2}) => {
    let leader = isGeneCalc2 ? "birthsIncidence2" : "birthsIncidence";

    const [prevalenceRatio, setPrevalenceRatio] = useState({numerator: 1, denominator: 10000});
    const [prevalenceDecimal, setPrevalenceDecimal] = useState(.0001);
    const [lifeExpectancy, setLifeExpectancy] = useState(50);

    useEffect(() => {
        if(prevalenceDecimal <= 1 && prevalenceDecimal >= 0) {
            if (lifeExpectancy > 0) {
                setInputState([parseFloat(prevalenceDecimal), prevalenceRatio, lifeExpectancy])
            }
            // setNeedsAlert((prevAlert) => ({...prevAlert, prevalence: false}))
        } 
    }, [prevalenceDecimal, lifeExpectancy, setInputState, prevalenceRatio])

    const handlePrevalenceRatio = (e, target) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        if (target === 'numerator') {
            setPrevalenceRatio({...prevalenceRatio, numerator: thisVal})
            if (thisVal !== "" && thisVal <= prevalenceRatio.denominator) {
                let pre = (thisVal / prevalenceRatio.denominator);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else if (target === 'denominator') {
            setPrevalenceRatio({...prevalenceRatio, denominator: thisVal})
            if (thisVal !== "" && thisVal >= prevalenceRatio.numerator) {
                let pre = (prevalenceRatio.numerator / thisVal);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else {
            console.warn("sus code")
        }
    }

      const handlePrevalenceDecimal = (e) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        setPrevalenceDecimal(thisVal);
        if (thisVal !== "" && (thisVal <= 1 && thisVal >= 0)) {
            let decimals = countDecimals(thisVal);
            let denValue = 1 * (10 ** decimals)
            let numValue = Math.round(thisVal * (10 ** decimals));  // Rounded here
            setPrevalenceRatio({numerator: numValue, denominator: denValue})
        }
      }

    return (
        <React.Fragment>
            <h5 className='iso-calc-header'>Incidence in Live Births Per Year</h5>
            <div className='flex-row quarter-div-inputs alignCenter'>
                <input id="numerator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "numerator")} value={prevalenceRatio.numerator}></input>
                <h5 id="iso-ratio-slash">/</h5>
                <input id="denominator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "denominator")} value={prevalenceRatio.denominator}></input>
                <h5 id="iso-or-text" className='input-header'>or</h5>
                <input className='text-input-standard text-left' type="number" max={1}  onChange={handlePrevalenceDecimal} value={prevalenceDecimal}></input>
            </div>
            <h5 className='iso-calc-header'>Life Expectancy ( Years )</h5>
            <input className='text-input-standard text-left' type="number" max={1}  onChange={(e) => setLifeExpectancy(e.target.value)} value={lifeExpectancy}></input>
        </React.Fragment>
    )
}

const IncidenceCalc = ({setInputState, isGeneCalc2}) => {

    let leader = isGeneCalc2 ? "incidence2" : "incidence";

    const [prevalenceRatio, setPrevalenceRatio] = useState({numerator: 1, denominator: 1000});
    const [prevalenceDecimal, setPrevalenceDecimal] = useState(.001);
    const [lifeExpectancy, setLifeExpectancy] = useState(50);

    useEffect(() => {
        if(prevalenceDecimal <= 1 && prevalenceDecimal >= 0) {
            if (lifeExpectancy > 0) {
                setInputState([parseFloat(prevalenceDecimal), prevalenceRatio, lifeExpectancy])
            }
            // setNeedsAlert((prevAlert) => ({...prevAlert, prevalence: false}))
        } 
    }, [prevalenceDecimal, lifeExpectancy, setInputState, prevalenceRatio])

    const handlePrevalenceRatio = (e, target) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        if (target === 'numerator') {
            setPrevalenceRatio({...prevalenceRatio, numerator: thisVal})
            if (thisVal !== "" && thisVal <= prevalenceRatio.denominator) {
                let pre = (thisVal / prevalenceRatio.denominator);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else if (target === 'denominator') {
            setPrevalenceRatio({...prevalenceRatio, denominator: thisVal})
            if (thisVal !== "" && thisVal >= prevalenceRatio.numerator) {
                let pre = (prevalenceRatio.numerator / thisVal);
                if (pre.toString().length > 10) pre = pre.toFixed(10);
                setPrevalenceDecimal(pre)
            }
        } else {
            console.warn("sus code")
        }
    }

      const handlePrevalenceDecimal = (e) => {
        let thisVal = parseFloat(e.target.value);
        if (isNaN(thisVal)) thisVal = "";
        setPrevalenceDecimal(thisVal);
        if (thisVal !== "" && (thisVal <= 1 && thisVal >= 0)) {
            let decimals = countDecimals(thisVal);
            let denValue = 1 * (10 ** decimals)
            let numValue = Math.round(thisVal * (10 ** decimals));  // Rounded here
            setPrevalenceRatio({numerator: numValue, denominator: denValue})
        }
      }

    return (
        <React.Fragment>
            <h5 className='iso-calc-header'>Yearly Incidence</h5>
            <div className='flex-row quarter-div-inputs alignCenter'>
                <input id="numerator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "numerator")} value={prevalenceRatio.numerator}></input>
                <h5 id="iso-ratio-slash">/</h5>
                <input id="denominator-ratio" className='text-input-standard text-left' type="number" onChange={(e) => handlePrevalenceRatio(e, "denominator")} value={prevalenceRatio.denominator}></input>
                <h5 id="iso-or-text" className='input-header'>or</h5>
                <input className='text-input-standard text-left' type="number" max={1}  onChange={handlePrevalenceDecimal} value={prevalenceDecimal}></input>
            </div>
            <h5 className='iso-calc-header'>Life Expectancy ( Years )</h5>
            <input className='text-input-standard text-left' type="number" max={1}  onChange={(e) => setLifeExpectancy(e.target.value)} value={lifeExpectancy}></input>
        </React.Fragment>
    )
}

export default IsolatedCalculator