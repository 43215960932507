import React, { useState, useEffect } from 'react'

import DataPage from '../components/dataPage/DataPage'

import { useNavigate } from 'react-router-dom'
import PrevalencePage from '../components/prevalencePage/PrevalencePage'


const PrevalenceRoute = ({activeUser, addNoteToDB, delNoteFromDB, notepadRef, notes, setNotes, notepadPresets, setNotepadPresets }) => {

  return (

    // (activeUser && activeUser.modules.data)
    // ?
    <div className='max-window'>
        <PrevalencePage addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} notepadRef={notepadRef} notes={notes} setNotes={setNotes} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets}/>
    </div>
    // :
    // <DataPreview />

  )
}

const DataPreview = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/user')
    }

    return (
        <div className='preview max-window'>
            <h1>Data</h1>
            <h3>Unlock bancrofts insightful data options</h3>
            <button className='input-action-btn' onClick={handleRedirect}>ADD MODULE</button>
        </div>
    )
}

export default PrevalenceRoute