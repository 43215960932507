import React, {useState, useEffect, useRef} from 'react'
import { Link, useNavigate } from "react-router-dom";

import InputSidebar from './sidebar/InputSidebar';

import Notepad from '../notepad/Notepad';

import settingIcon from "../../icons/bancy-settings.svg";
import userIcon from "../../icons/bancy-user.svg";
import notepadIcon from "../../icons/bancroft_top_note_icon.svg";


import QuickAnalysisBody from './QuickAnalysisBody';

import '../../css/quickAnalysis.css';

import { calculatePOS } from './components/CalculatePOS';



const QuickAnalysisPage = ({addNoteToDB, delNoteFromDB, notepadRef, notes, setNotes, notepadPresets, setNotepadPresets}) => {

    const [sidebarSize, setSidebarSize] = useState("shown");
    const [showNotepad, setShowNotepad] = useState(false);
    const [approvalType, setApprovalType] = useState(false)

    const [drugType, setDrugType] = useState('Small Molecule');
    const [geographies, setGeographies] = useState('United States'); // Note: This is disabled in your htmlForm
    const [therapeuticArea, setTherapeuticArea] = useState('allergy');

    const [phase, setPhase] = useState('phaseOne');
    const [timeToLaunch, setTimeToLaunch] = useState('<1 Year');
    const [adcomm, setAdcomm] = useState('');
    const [biologicalRationaleScore, setBiologicalRationaleScore] = useState(0);
    const [prevalenceTam, setPrevalenceTam] = useState(0);
    const [drugPrice, setDrugPrice] = useState(0);
    const [peakPenetration, setPeakPenetration] = useState(0);

    const [observedTreatmentEffect, setObservedTreatmentEffect] = useState(0);
    const [qaNotes, setqaNotes] = useState('');
    const [routeOfAdministration, setRouteOfAdministration] = useState('oral');
    const [lossOfExclusivity, setLossOfExclusivity] = useState('');
    const [approvedCompetitionProducts, setApprovedCompetitionProducts] = useState(0);
    const [approvedCompetitionProductsDetails, setApprovedCompetitionProductsDetails] = useState('');
    const [pipelineCompetitionProducts, setPipelineCompetitionProducts] = useState(0);
    const [pipelineCompetitionProductsDetails, setPipelineCompetitionProductsDetails] = useState('');

    const navigate = useNavigate();


    const [result, setResult] = useState({
        startPhase: "PhaseOne",
        phaseOne: 0.0, //Unadjusted
        phaseTwo: 0.0,  //Unadjusted
        phaseThree: 0.0,  //Unadjusted
        approval: 0.0,  //Unadjusted
        total: 0.0, //Unadjusted
        AdjustedPhaseOne: 0.0,  //Adjusted
        AdjustedPhaseTwo: 0.0,  //Adjusted
        AdjustedPhaseThree: 0.0,  //Adjusted
        AdjustedApproval: 0.0,  //Adjusted
        AdjustedTotal: 0.0  //Adjusted

    });

    const [peakSales, setPeakSales] = useState(0.0);
    
    useEffect(() => {
        let passObject = {
            startPhase: phase,
            therapeuticArea: therapeuticArea, 
            biologicalRationaleScore: biologicalRationaleScore,
            observedTreatmentEffect: observedTreatmentEffect,
            // isRare: isRare, 
            // isChronicHighPrevalence: isChronicHighPrevalence, 
            // isBiomarkers: isBiomarkers,
        }

        const calculatedResult = calculatePOS(passObject);

        setResult(calculatedResult);
    }, [biologicalRationaleScore, observedTreatmentEffect, phase, therapeuticArea])
    
    useEffect(() => {
        let res = drugPrice * (prevalenceTam * (peakPenetration/100))
        setPeakSales(res);
    }, [drugPrice, peakPenetration, prevalenceTam])

    const closeNotepad = () => {
      if (!showNotepad) {
          setShowNotepad(true);
      } else {
          if (notepadRef.current) {
              notepadRef.current.handleCloseNotepad();
          }
      }
    }
  

  return (
    <div className={sidebarSize === "shown" ? 'master-container' : "master-container-x"}>
            <InputSidebar approvalType={approvalType} 
                          setApprovalType={setApprovalType} 
                          observedTreatmentEffect={observedTreatmentEffect}
                          setObservedTreatmentEffect={setObservedTreatmentEffect}
                          qaNotes={qaNotes}
                          setqaNotes={setqaNotes}
                          routeOfAdministration={routeOfAdministration}
                          setRouteOfAdministration={setRouteOfAdministration}
                          lossOfExclusivity={lossOfExclusivity}
                          setLossOfExclusivity={setLossOfExclusivity}
                          approvedCompetitionProducts={approvedCompetitionProducts}
                          setApprovedCompetitionProducts={setApprovedCompetitionProducts}
                          approvedCompetitionProductsDetails={approvedCompetitionProductsDetails}
                          setApprovedCompetitionProductsDetails={setApprovedCompetitionProductsDetails}
                          pipelineCompetitionProducts={pipelineCompetitionProducts}
                          setPipelineCompetitionProducts={setPipelineCompetitionProducts}
                          pipelineCompetitionProductsDetails={pipelineCompetitionProductsDetails}
                          setPipelineCompetitionProductsDetails={setPipelineCompetitionProductsDetails} 
                          drugPrice={drugPrice} drugType={drugType} therapeuticArea={therapeuticArea} 
                          setDrugPrice={setDrugPrice} 
                          peakPenetration={peakPenetration} 
                          setPeakPenetration={setPeakPenetration} 
                          prevalenceTam={prevalenceTam} 
                          setPrevalenceTam={setPrevalenceTam} 
                          adcomm={adcomm} 
                          setAdcomm={setAdcomm} 
                          biologicalRationaleScore={biologicalRationaleScore} 
                          setBiologicalRationaleScore={setBiologicalRationaleScore} 
                          timeToLaunch={timeToLaunch} 
                          setTimeToLaunch={setTimeToLaunch} 
                          setDrugType={setDrugType} 
                          setTherapeuticArea={setTherapeuticArea} 
                          setGeographies={setGeographies} 
                          phase={phase} 
                          setPhase={setPhase}
                          peakSales={peakSales} />
            <div className='content-container flex-col'>
                <div className='navBar-main'>
                <div className='flex-row alignCenter'>
                    <h1 className='nav-forecast-title'>Quick Analysis</h1>
                    </div>
                    <div className='nav-elems'>
                        <div id="nav-notepad" onClick={closeNotepad} className='nav-elem flex-row'>
                            <img className="svg-icon" src={notepadIcon} alt="notepad" />
                            <h4 className='nav-elem-text'>Notes</h4>
                        </div>
                        <div className='nav-elem flex-row' onClick={()=>navigate("/user")}>
                            <img className="svg-icon" src={userIcon} alt="user" />
                            <h4 className='nav-elem-text'>User Profile</h4>
                        </div>
                        <div className='nav-elem flex-row'>
                            <img className="svg-icon" src={settingIcon} alt="settings" />
                            <h4 className='nav-elem-text'>Settings</h4>
                        </div>
                    </div>
                    {showNotepad && <Notepad ref={notepadRef} addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets} showNotepad={showNotepad} setShowNotepad={setShowNotepad} notes={notes} setNotes={setNotes}/>}
                </div>
                <QuickAnalysisBody approvalType={approvalType} result={result} peakSales={peakSales} 
                                  qaNotes={qaNotes}
                                  setqaNotes={setqaNotes}
                                  routeOfAdministration={routeOfAdministration}
                                  setRouteOfAdministration={setRouteOfAdministration}
                                  lossOfExclusivity={lossOfExclusivity}
                                  setLossOfExclusivity={setLossOfExclusivity}
                                  approvedCompetitionProducts={approvedCompetitionProducts}
                                  setApprovedCompetitionProducts={setApprovedCompetitionProducts}
                                  approvedCompetitionProductsDetails={approvedCompetitionProductsDetails}
                                  setApprovedCompetitionProductsDetails={setApprovedCompetitionProductsDetails}
                                  pipelineCompetitionProducts={pipelineCompetitionProducts}
                                  setPipelineCompetitionProducts={setPipelineCompetitionProducts}
                                  pipelineCompetitionProductsDetails={pipelineCompetitionProductsDetails}
                                  setPipelineCompetitionProductsDetails={setPipelineCompetitionProductsDetails}/>
            </div>
        </div>
  )
}

export default QuickAnalysisPage