import React, { useEffect } from 'react'
import { useState } from 'react';
import '../../../css/InputSidebar.css';

import InputsCompetition from './subsections/InputsCompetition'
import InputsDrug from './subsections/InputsDrug'
import InputsGeneral from './subsections/InputsGeneral';
// import InputsSales from './subsections/InputsSales'
import NewForecast from './subsections/NewForecast';
import PriorForecasts from './subsections/PriorForecasts';
import InputsGeography from './subsections/InputsGeography';
import { v4 as uuidv4 } from 'uuid';


import { example1, molgramostimExample, zygelExample } from '../../../exampleForecasts';

import caretIcon from "../../../icons/bancy-caret2.svg";
import { deepClone } from '../../../functions/helpers/generalHelpers';

const InputSidebar = ({quickStart, workingProjects, setWorkingProjects, saveOrUpdateForecasts, userForecasts, setUserForecasts, userSettings, changeUserSettings, workingGeographies, setWorkingGeographies, currGeographyIndex, workingForecast, setWorkingForecast, setCurrGeographyIndex, sidebarSize, setSidebarSize}) => {
  
    console.log(workingForecast, workingGeographies)
  const [displayState, setDisplayState] = useState(quickStart ? "engaged": "default");
  const [engagedSection, setEngagedSection] = useState(quickStart ? "general" : "inactive");
  //easy access variable to help with cross components

  let initGP = "year";
if (workingGeographies.length > 0) {
  initGP = workingForecast.geographiesData[workingGeographies[0]].general.period;
}

const [graphPeriod, setGraphPeriod] = useState(initGP);

useEffect(() => {
  let newGP = "year";
  if (workingGeographies.length > 0) {
    newGP = workingForecast.geographiesData[workingGeographies[0]].general.period;
  }
  setGraphPeriod(newGP);
}, [workingGeographies, workingForecast]);

  const issueNewForecast = (forecastName, forecastType) => {
        const newForecast = {...workingForecast};
        newForecast.name = forecastName;
        newForecast.type = forecastType;
        setWorkingForecast(newForecast);
        const newProject = {
            scenarios: [
                {name: "base", forecast: deepClone(newForecast)},
                {name: "best", forecast: deepClone(newForecast)},
                {name: "worst", forecast: deepClone(newForecast)}
            ],
        }
        newProject.tempId = uuidv4();
        setUserForecasts(prev => {
            const copy = [...prev];
            return [newProject, ...copy];
        })
        saveOrUpdateForecasts("new", newProject);
        setWorkingProjects(newProject)
   
    setDisplayState("engaged");
    setEngagedSection("General Data");
  }

  const engageOldForecast = (forecast) => {
    setWorkingForecast(forecast.scenarios[0].forecast);
    setWorkingProjects(forecast)
    setDisplayState("engaged");
    setEngagedSection("General Data");
  }

  // CAN REVERT BACK TO THIS WITH SOME DIFICULTY
  //     // this code reflects changes in the period
  //     // if period is changed to months from years and dateofLOE is enetered 2005
  //     // the value is auto updated to 01/2005
  //     // will likely add support for competitiors here too.

//   useEffect(() => {
//     const sh = workingForecast.geographiesData[workingGeographies[0]]
//     if (graphPeriod === sh.general.period) return;
//     console.log(graphPeriod, sh.general.period)
//     //competition approval date
//     let newCompetition = [];
//     if (sh.competition.length > 0) {
//         newCompetition = [...sh.competition];

//         for (let i = 0; i < sh.competition.length; i++) {
//             if (sh.competition[i].approvalDate.length > 3) {
    
//                 let targetYear = sh.competition[i].approvalDate;
//                 let value = targetYear;
//                 // targetYear.length > 4 aka in month or quarter format
//                 if (targetYear.length > 4) {
//                     targetYear = sh.competition[i].approvalDate.slice(3);
//                     value = targetYear;
//                 }
//                 if (sh.general.period === "month") {
//                     value = `01/${targetYear}`;
//                 } else if (sh.general.period === "quarter") {
//                     value = `Q1/${targetYear}`
//                 }
//                 newCompetition[i].approvalDate = value;
//             } 
//         }
//     }

//     if (sh.drug.dateOfLOE.length > 3) {
//         let targetYear = sh.drug.dateOfLOE;
//         let value = targetYear;
//         // targetYear.length > 4 aka in month or quarter format
//         if (targetYear.length > 4) {
//             targetYear = sh.drug.dateOfLOE.slice(3);
//             value = targetYear;
//         }
//         if (sh.general.period === "month") {
//             value = `01/${targetYear}`;
//         } else if (sh.general.period === "quarter") {
//             value = `Q1/${targetYear}`
//         }
//         setWorkingForecast(prevForecast => {
//             let copyF = {...prevForecast};
//             let copyG = {...copyF.geographiesData}
//             workingGeographies.forEach(geo => {
//                 copyG[geo].drug.dateOfLOE = value;
//             })
//             return {...copyF, geographiesData: {...copyG}};
//         });
//     } else if (newCompetition) {
//         setWorkingForecast(prev => ({
//             ...prev,
//             competition: [...newCompetition],
//         }))
//         setWorkingForecast(prevForecast => {
//             let copyF = {...prevForecast};
//             let copyG = {...copyF.geographiesData}
//             workingGeographies.forEach(geo => {
//                 copyG[geo].competition = [deepClone(newCompetition)];
//             })
//             return {...copyF, geographiesData: {...copyG}};
//         });
//     }

//   }, [workingGeographies, graphPeriod, setWorkingForecast, workingForecast])

  return ( sidebarSize === "shown" && 
    <div className='InputSidebar-main flex-col'>
        <div className='fixed-sidebar-items'>
            <div className='Bancroft-title'>
                <div className='banc-title-sub'>
                    <h1 id='Bancroft-title'>bancroft</h1>
                    {displayState === "engaged" && <img onClick={() => setSidebarSize("hidden")} className='close-sb-icon' src={caretIcon} alt={"close-sidebar"}></img>}
                </div>
            </div>
            {displayState === "engaged" && <InputsGeography userSettings={userSettings} changeUserSettings={changeUserSettings} currGeographyIndex={currGeographyIndex} setCurrGeographyIndex={setCurrGeographyIndex} workingGeographies={workingGeographies} setWorkingGeographies={setWorkingGeographies} workingForecast={workingForecast} engagedSection={engagedSection} setEngagedSection={setEngagedSection} setWorkingForecast={setWorkingForecast}/>}
        </div>

        {
            displayState === 'default' &&
                <div className='inputs-container max-window flex-col'>
                    <NewForecast issueNewForecast={issueNewForecast} />
                    <div className='basic-hr'></div>
                    <PriorForecasts userForecasts={userForecasts} engageOldForecast={engageOldForecast} saveOrUpdateForecasts={saveOrUpdateForecasts} issueNewForecast={issueNewForecast}/>
                </div>
        }
        {
            displayState === 'engaged' &&
                <div className='inputs-container max-window flex-col'>
                    {workingGeographies && workingGeographies.length >= 1 &&
                        <React.Fragment>
                            <InputsGeneral setGraphPeriod={setGraphPeriod} workingGeographies={workingGeographies} setCurrGeographyIndex={setCurrGeographyIndex} workingForecast={workingForecast} engagedSection={engagedSection} setEngagedSection={setEngagedSection} setWorkingForecast={setWorkingForecast} graphPeriod={graphPeriod} />
                            {/* <InputsSales workingForecast={workingForecast} GEOGRAPHIES_CONST={GEOGRAPHIES_CONST} engagedSection={engagedSection} setEngagedSection={setEngagedSection} setWorkingForecast={setWorkingForecast}/> */}
                            <InputsDrug workingGeographies={workingGeographies} workingForecast={workingForecast} engagedSection={engagedSection} setEngagedSection={setEngagedSection} setWorkingForecast={setWorkingForecast} graphPeriod={graphPeriod} />
                            <InputsCompetition workingGeographies={workingGeographies} workingForecast={workingForecast} engagedSection={engagedSection} setEngagedSection={setEngagedSection} setWorkingForecast={setWorkingForecast} graphPeriod={graphPeriod} />
                        </React.Fragment>
                    }
                    <div className='bottom-sidebar'>
                        <p className='copyright'>&copy; BANCROFT SOFTWARE LLC 2023</p>
                    </div>
                </div>
        }
    </div>
  )
}

export default InputSidebar