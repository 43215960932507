import React from 'react'
import { useState } from 'react';
import forecastIcon from "../../../../icons/bancy-forecast-icon.svg"

const NewForecast = ({issueNewForecast}) => {

  const [forecastName, setForecastName] = useState(""); 
  const [forecastType, setForecastType] = useState("drug");
  const [needsAlert, setNeedsAlert] = useState(false);

  const handleChange = (e) => {
    setForecastName(e.target.value);
    if (needsAlert) {
        setNeedsAlert(false);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    let isValid = false;
    for (let i = 0; i < forecastName.length; i++) {
        if (forecastName[i].charCodeAt() > 32 && forecastName[i].charCodeAt() < 127) {
            isValid = true;
            break;
        }
    }
    if (isValid) {
        issueNewForecast(forecastName, forecastType);
    } else {
        setNeedsAlert(true);
    }
  }

//   const handleGeographies = (e) => {
//     setNewGeography(e.target.value)
//   }

//   const submitNewGeography = (e) => {
//     e.preventDefault()
//     if(geographies.indexOf(newGeography) === -1) {
//         console.log(newGeography)
//         setGeographies(prev => [...prev, newGeography]);
//         setNewGeography("");
//         setNewGeoInput(false);
//     }
//   }

//   const removeGeography = (geo) => {
//     setGeographies(prev => {
//         let newGeographies = [...geographies];
//         newGeographies.splice(newGeographies.indexOf(geo), 1);
//         return [...newGeographies];
//     })
//   }
  
  const popup = (
        <div id="warning-popup-no-name">
            <p className='warning-popup-small-text'>Please enter a forecast name.</p>
        </div>
  )
  const geoPopup = (
    <div id="warning-popup-no-name">
        <p className='warning-popup-small-text'>Please enter at least one geography.</p>
    </div>
)

  return (
    <div id="new-forecast" className='flex-col pad-horz-20'>
        <div className="flex-row">
            <img className='new-f-icon' src={forecastIcon} alt={"new forecast"}></img>
            <h4 id="new-forecast-header" >New Forecast</h4>
        </div>
        <select className='text-input-select' onChange={(e) => setForecastType(e.target.value)}>
            <option value={"drug"}>Drug</option>
            <option value={"gene"}>Gene Therapy</option>
        </select>
        {needsAlert && popup}
        <p id="label-drugname">Drug name</p>
        <input id="new-forecast-text-input" className='text-input-standard' style={{color: "rgb(85, 163, 85) !important"}} type="text" value={forecastName} onChange={handleChange}></input>
        <button className='input-action-btn' onClick={handleClick}>BEGIN FORECAST</button>
    </div>
  )
}

export default NewForecast