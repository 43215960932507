import React, {useState, useEffect} from 'react'

import '../../../../css/scenarios.css'
import { deepClone } from '../../../../functions/helpers/generalHelpers';
import geoCheckIcon from "../../../../icons/bancroft_checkbox.svg"


const Scenarios = ({scenarioIndex, handleScenarioIndexChange, workingForecast, setWorkingForecast, workingProjects, setWorkingProjects}) => {

    const [inputState, setInputState] = useState("");

    const [copyState, setCopyState] = useState(0);



    useEffect(() => {
        let newIndex;
        if (scenarioIndex === 0) newIndex = 1;
        if (scenarioIndex === 1) newIndex = 0;
        if (scenarioIndex === 2) newIndex = 0;
        setCopyState(newIndex);
        setInputState(workingProjects.scenarios[scenarioIndex].name)

    }, [scenarioIndex])


    const handleInput = (e) => {
        setInputState(e.target.value)
    }

    const submitNameChange = () => {
        setWorkingProjects(prev => {
            const copy = {...prev};
            copy.scenarios[scenarioIndex].name = inputState;
            return copy;
        })
    }

    const handleCopyChange = (e) => {
        setCopyState(e.target.value)
    }

    const submitCopy = () => {
        setWorkingProjects(prev => {
            const copy = {...prev};
            copy.scenarios[scenarioIndex].forecast = deepClone(copy.scenarios[copyState].forecast)
            return copy;
        })
        setWorkingForecast(workingProjects.scenarios[copyState].forecast)
    }

  return (
    <div className='pad-horz-20 scenarios-container'>
        <div className='flex-row alignCenter'>
            <h5>Active Scenario:</h5>
            <select className='scenario-select' value={scenarioIndex} onChange={(e) => handleScenarioIndexChange(e)}>
                <option className='input-option-standard' value={1}>{workingProjects.scenarios[1].name}</option>
                <option className='input-option-standard' value={0}>{workingProjects.scenarios[0].name}</option>
                <option className='input-option-standard' value={2}>{workingProjects.scenarios[2].name}</option>

            </select>
        </div>
        <div className='flex-row alignCenter'>
            <h5>Copy From:</h5>
            <select className='scenario-select' value={copyState} onChange={(e) => handleCopyChange(e)}>
                {scenarioIndex !== 1 && <option className='input-option-standard' value={1}>{workingProjects.scenarios[1].name}</option>}
                {scenarioIndex !== 0 && <option className='input-option-standard' value={0}>{workingProjects.scenarios[0].name}</option>}
                {scenarioIndex !== 2 && <option className='input-option-standard' value={2}>{workingProjects.scenarios[2].name}</option>}
            </select>
            <button className='scenario-copy-submit input-action-btn' onClick={() => submitCopy(0)}>COPY</button>
        </div>
        <div className='flex-row alignCenter'>
            <h5>Edit Name:</h5>
            <input className="scenarios-inputs" value={inputState} onChange={(e) => handleInput(e)}></input>
            <img onClick={submitNameChange} className='check-geo-sb' src={geoCheckIcon} alt={"confirm-name-change"}></img>
        </div>
    </div>
  )
}


export default Scenarios