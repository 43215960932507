import React from 'react'
import { useState, useEffect } from 'react'

import { COMPETITION_OBJECT } from '../../../../initials'

import DateFields from './DateFields'
import WarningPopupSmall from '../../../util/WarningPopupSmall'
import CollapsingContainer from '../../../util/CollapsingContainer'


const InputsCompetition = ({workingGeographies, workingForecast, engagedSection, setEngagedSection, setWorkingForecast, graphPeriod}) => {

    const [displayState, setDisplayState] = useState(workingForecast.geographiesData[workingGeographies[0]].competition.length > 0 ? 0 : -1);
    const [needsAlert, setNeedsAlert] = useState({name: false, approval: false, approvalDate: false, dateOfLOE: false, penetration: false, duration: false, tooManyCompetitorsPopup: false, yearsToPeakPenetration: false});
    const [competitorTally, setCompetitorTally] = useState(workingForecast.geographiesData[workingGeographies[0]].competition.length > 0 ? workingForecast.geographiesData[workingGeographies[0]].competition.length : 0);
    const [newCompetitorView, setNewCompetitorView] = useState(false);
    const [newCompetitor, setNewCompetitor] = useState({...COMPETITION_OBJECT, name: `drug ${competitorTally + 1}`})

  const _ = workingForecast.geographiesData[workingGeographies[0]].competition;


  const nameValidation = (nameValue) => {
    let isUnique = _.filter(val => val.name === nameValue).length === 0;
    if (isUnique) {
        return true;
    } else {
        return false;
    }
  }

  const approvalValidation = (fieldValue) => {
    if (fieldValue === "approved" ||
        fieldValue === "notApproved") {
        return true;
    } else {
        return false;
    }
  }

  const penetrationValidation = (fieldValue) => {
    if (fieldValue >= 0 && fieldValue <= 100) {
        return true;
    } else {
        return false;
    }
  }

  const durationValidation = (fieldValue) => {
    if (fieldValue === "intermediate" ||
        fieldValue === "chronic" ||
        fieldValue === "acute" ||
        fieldValue === "intermediate") {
        return true;
    } else {
        return false;
    }
  }

  const yearsToPeakValidation = (fieldValue) => {

    // gotta figure some bs when the field is empty
    return true
    // if (fieldValue > 0) {
    //     return true;
    // } else {
    //     return false;
    // }
  }
  const updateNewCompetitor = (e, target) => {
    const validationObj = {
        name: nameValidation,
        approval: approvalValidation,
        penetration: penetrationValidation,
        duration: durationValidation,
        yearsToPeakPenetration: yearsToPeakValidation,
    }

    const validationFunction = validationObj[target];
    if (validationFunction && validationFunction(e.target.value)) {
        setNeedsAlert({...needsAlert, [target]: false})
        setNewCompetitor(prev => {
            let newState = {...prev};
            let value = e.target.value;
            if (target === "yearsToPeakPenetration" && e.target.value) {
                value = parseFloat(e.target.value);
            }
            newState[target] = value;
            return newState;
        });
    } else if (needsAlert[target] === false) {
        setNeedsAlert({...needsAlert, [target]: true})
    }
  }

  const updateInputs = (e, target) => {
    const validationObj = {
        name: nameValidation,
        approval: approvalValidation,
        penetration: penetrationValidation,
        duration: durationValidation,
        yearsToPeakPenetration: yearsToPeakValidation,
    }

    const validationFunction = validationObj[target];
    if (validationFunction && validationFunction(e.target.value)) {
        setNeedsAlert({...needsAlert, [target]: false})
        setWorkingForecast(prevForecast => {
            let copyF = {...prevForecast};
            let copyG = {...copyF.geographiesData}
            workingGeographies.forEach(geo => {
                let copyA = [...copyG[geo].competition];
                let value = e.target.value;
                if (target === "yearsToPeakPenetration" && e.target.value) {
                    value = parseFloat(e.target.value);
                }
                copyA[displayState][target] = value;
                copyG[geo].competition = [...copyA];
            })
            return {...copyF, geographiesData: {...copyG}};
        });
    } else if (needsAlert[target] === false) {
        setNeedsAlert({...needsAlert, [target]: true})
    }
  }

  const addNewCompetitor = () => {
    if (_.length === 4) {
        setNeedsAlert({...needsAlert, tooManyCompetitors: true})
    } else {
        setWorkingForecast(prevForecast => {
            let copyF = {...prevForecast};
            let copyG = {...copyF.geographiesData}
            workingGeographies.forEach(geo => {
                copyG[geo].competition = [...copyG[geo].competition, newCompetitor];
            })
            return {...copyF, geographiesData: {...copyG}};
        })
        setNewCompetitorView(false);
        setCompetitorTally(competitorTally + 1)
        setDisplayState("inactive")
    }
  }

  const removeCompetitor = () => {
    let displayIndex = 0;
    let tempState = displayState;
    if (workingForecast.geographiesData[workingGeographies[0]].competition.length === 1) {
      displayIndex = -1;
    }
    setDisplayState(displayIndex);
    setWorkingForecast(prevForecast => {
        let copyF = {...prevForecast};
        let copyG = {...copyF.geographiesData}
        workingGeographies.forEach(geo => {
            let copyC = [...copyG[geo].competition];
            copyC.splice(tempState, 1);
            copyG[geo].competition = [...copyC];
        })
        return {...copyF, geographiesData: {...copyG}};
    });
  }  

  const handleUnsetCompetitor = () => {
    setNewCompetitorView(true);
    setDisplayState("inactive");
    setNewCompetitor({...COMPETITION_OBJECT, name: `drug${competitorTally + 1}`})
  }
  
  const handleSetDisplay = (ind) => {
    if (ind === displayState) {
        setDisplayState("inactive")
    } else {
        setDisplayState(ind);
    }
  }

  const namePopup = <span><WarningPopupSmall message={"Please input a unique value."}/></span>
  const tooManyCompetitorsPopup = <span><WarningPopupSmall message={"Maximum of 5 competitors."}/></span>
  const approvalPopup = <span><WarningPopupSmall message={"Please input a valid value."}/></span>
  const yearsToPeakPenetrationPopup = <span><WarningPopupSmall message={"Please input a value > 0"}/></span>
  const penetrationPopup = <span><WarningPopupSmall message={"Please input a a valid value."}/></span>
  const durationPopup = <span><WarningPopupSmall message={"Please input a a valid duration."}/></span>

  
  const newCompetitorInputs = (
                    <div className='horz-30'>
                    <div className='input-and-label-standard'>
                        <h5 className='input-header'>Name</h5>
                        {needsAlert.name && namePopup}
                        <input className='text-input-standard text-left' type="text" onChange={(e) => updateNewCompetitor(e, "name")} value={newCompetitor.name}></input>
                    </div>
                    <div className='input-and-label-standard'>
                        <h5 className='input-header'>Approval</h5>
                        {needsAlert.approval && approvalPopup}
                        <select className='text-input-select text-left' value={newCompetitor.approval} onChange={(e) => updateNewCompetitor(e, "approval")}>
                            <option className='input-option-standard' value={"approved"}>Approved</option>
                            <option className='input-option-standard' value={"notApproved"}>Not Approved</option>
                        </select>
                    </div>

                    <DateFields section="approvalDateCompetition" workingGeographies={workingGeographies} graphPeriod={graphPeriod} workingForecast={workingForecast} setWorkingForecast={setWorkingForecast} competitorIndex={displayState} isNewComp={true} setNewCompetitor={setNewCompetitor}/>

                    <div className='input-and-label-standard'>
                        <h5 className='input-header'> Years Until Peak Penetration</h5>
                        {needsAlert.yearsToPeakPenetration && yearsToPeakPenetrationPopup}
                        <input className='text-input-standard text-left' min={0} max={20} type="number" onChange={(e) => updateNewCompetitor(e, "yearsToPeakPenetration")} value={newCompetitor.yearsToPeakPenetration}></input>
                    </div>

                    <div className='input-and-label-standard'>
                        <h5 className='input-header'>Duration of Use</h5>
                        {needsAlert.duration && durationPopup}
                        <select className='text-input-select text-left' value={newCompetitor.duration} onChange={(e) => updateNewCompetitor(e, "duration")}>
                            <option className='input-option-standard' value="chronic">Chronic</option>
                            <option className='input-option-standard' value="acute">Acute</option>
                            <option className='input-option-standard' value="short">Short Term</option>
                            <option className='input-option-standard' value="intermediate">Intermediate</option>
                        </select>
                    </div>
        </div>
  )

  const data = (
    <div>
        {!_.length && 
            <React.Fragment>
                {newCompetitorInputs}
                {_.length < 4 && <button style={{marginTop: "20px"}} id="competitor-btn-bottom" className='input-action-btn' onClick={addNewCompetitor}>Add</button>}
            </React.Fragment>
        }
        {(displayState === "inactive" || (displayState >= 0 && _.length > 0 && displayState <= _.length - 1)) &&
            <div className='competitor-inputs-body'>
                {_.map((competitor, index) => {
                    return (
                        <React.Fragment key={`r-k-yd${index}`}>
                            <div style={(displayState !== "inactive" && index !== 0 && index !== displayState) ? {borderTop: "solid rgb(226, 226, 226) 1px"} : undefined} onClick={() => handleSetDisplay(index)} key={`competitor-${competitor.name}-panel-${index}`} id={displayState !== "inactive" && _[displayState].name === competitor.name ? "engagedCompetitorTab" : ""} className='competitor-drug-panel-item flex-row horz-30 hoverShading'>
                                <p className='competitor-drug-text'>{competitor.name}</p>
                                <p className='competitor-drug-text' id={displayState === index ? "competitor-tiny-btn" : "competitor-tiny-btn-plus"}>{displayState === index ? "-" : "+"}</p>
                            </div>
                        {displayState === index &&
                        <React.Fragment>
                        <div className='input-and-label-standard horz-30'>
                            <h5 className='input-header'>Name</h5>
                            {needsAlert.name && namePopup}
                            <input className='text-input-standard text-left' type="text" onChange={(e) => updateInputs(e, "name")} value={_[displayState].name}></input>
                        </div>
                        <div className='input-and-label-standard horz-30'>
                            <h5 className='input-header'>Approval</h5>
                            {needsAlert.approval && approvalPopup}
                            <select className='text-input-select text-left' value={_[displayState].approval} onChange={(e) => updateInputs(e, "approval")}>
                                <option className='input-option-standard' value={"approved"}>Approved</option>
                                <option className='input-option-standard' value={"notApproved"}>Not Approved</option>
                            </select>
                        </div>

                        <div className='horz-30'>

                        <DateFields section="approvalDateCompetition" workingGeographies={workingGeographies} graphPeriod={graphPeriod} workingForecast={workingForecast} setWorkingForecast={setWorkingForecast} competitorIndex={displayState}/>
                        </div>

                        <div className='input-and-label-standard horz-30'>
                            <h5 className='input-header'> Years Until Peak Penetration</h5>
                            {needsAlert.yearsToPeakPenetration && yearsToPeakPenetrationPopup}
                            <input className='text-input-standard text-left' min={0} max={20} type="number" onChange={(e) => updateInputs(e, "yearsToPeakPenetration")} value={_[displayState].yearsToPeakPenetration}></input>
                        </div>


                    {/* removed for now until GRANT FEEDBACK */}
                    {/* <DateFields section="dateOfLOE" graphPeriod={graphPeriod} _={_[displayState]} setWorkingForecast={setWorkingForecast} handleBlur={handleBlur} handleFocus={handleFocus} needsAlert={needsAlert}/> */}

                    {/* peak penetration should be the same no? */}
                    {/* <div className='input-and-label-standard'>
                        <h5 className='input-header'>Peak Penetration ( % )</h5>
                        {needsAlert.penetration && penetrationPopup}
                        <input className='text-input-standard text-left' type="number" onBlur={() => handleBlur("penetration")} onFocus={() => handleFocus("penetration")} onChange={(e) => updateInputs(e, "penetration")} value={_[displayState].penetration}></input>
                    </div> */}

                            <div className='input-and-label-standard horz-30'>
                                <h5 className='input-header'>Duration of Use</h5>
                                {needsAlert.duration && durationPopup}
                                <select className='text-input-select text-left' value={_[displayState].duration} onChange={(e) => updateInputs(e, "duration")}>
                                    <option className='input-option-standard' value="chronic">Chronic</option>
                                    <option className='input-option-standard' value="acute">Acute</option>
                                    <option className='input-option-standard' value="short">Short Term</option>
                                    <option className='input-option-standard' value="intermediate">Intermediate</option>
                                </select>
                            </div>
                            <div style={(displayState !== "inactive" && index === _.length - 1) ? {borderBottom: "solid rgb(226, 226, 226) 1px"} : undefined}>
                                <button id="competitor-btn-bottom-remove" className='input-action-btn' onClick={removeCompetitor}>Remove Competitor</button>
                            </div>
                            </React.Fragment>
                            }
                        </React.Fragment>
                    )
                })}
                {newCompetitorView && 
                    <React.Fragment>
                        {newCompetitorInputs}
                        <button style={{marginTop: "20px"}} id="competitor-btn-bottom" className='input-action-btn' onClick={addNewCompetitor}>Submit Competitor</button>
                    </React.Fragment>
                }
            </div>
        }
        {needsAlert.tooManyCompetitors && tooManyCompetitorsPopup}
        {_.length > 0 && _.length < 4 && !newCompetitorView && <button id="competitor-btn-bottom" className='input-action-btn' onClick={handleUnsetCompetitor}>Add New Competititor</button>}
    </div>
  )

  return (
    <CollapsingContainer containerName="Competition" data={data} engagedSection={engagedSection} setEngagedSection={setEngagedSection}/>
  )
}

export default InputsCompetition