import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { GEOGRAPHIES_CONST } from '../../constants';

import settingIcon from "../../icons/bancy-settings.svg";
import userIcon from "../../icons/bancy-user.svg";
import notepadIcon from "../../icons/bancroft_top_note_icon.svg";
import IsolatedCalculator from './IsolatedCalculator'
import Notepad from '../notepad/Notepad';
import DiseaseExcerpt from '../forecastPage/sidebar/subsections/patientCalculator/DiseaseExcerpt'
import searchDiseaseDB from '../../functions/helpers/diseaseFuzzyMatch'
import closeIcon from "../../icons/bancroft_X_icon.svg"

import "../../css/prevalencePage.css"

const PrevalencePage = ({addNoteToDB, delNoteFromDB, notepadRef, notes, setNotes, notepadPresets, setNotepadPresets}) => {

  const [showNotepad, setShowNotepad] = useState(false);
  const [diseaseName, setDiseaseName] = useState("");
  const [geographyList, setGeographyList] = useState(["United States"]);
  const [newGeography, setNewGeography] = useState("");
  const [excerpts, setExcerpts] = useState([]);

  useEffect(() => {
    console.log(diseaseName)
    const result = searchDiseaseDB(diseaseName);
    console.log(result)
    setExcerpts(result)
  }, [diseaseName])

  const navigate = useNavigate();

  const closeNotepad = () => {
    if (!showNotepad) {
        setShowNotepad(true);
    } else {
        if (notepadRef.current) {
            notepadRef.current.handleCloseNotepad();
        }
    }
  }

  const handleGeographies = (e) => {
    setNewGeography(e.target.value)
}

const submitNewGeography = (e) => {
    if (newGeography.length === 0) return;
    e.preventDefault()
    if(geographyList.indexOf(newGeography) === -1) {
        setGeographyList([...geographyList, newGeography])
        setNewGeography("");
    }
}

const handleRemoveGeo = (geo) => {
    const foundIndex = geographyList.indexOf(geo);
    if (foundIndex !== -1) {
        setGeographyList(prev => {
            const copy = [...prev];
            copy.splice(foundIndex, 1);
            return copy;
        })
    }
}

  return (
        <div className="master-container">
            <div className='InputSidebar-main flex-col bb-box'>
                <div className='fixed-sidebar-items'>
                    <div className='Bancroft-title'>
                        <div className='banc-title-sub'>
                            <h1 id='Bancroft-title'>bancroft</h1>
                        </div>
                    </div> 
                </div>
                <div className='inputs-container max-window flex-col bb-box'>
                    <div className='pp-disease-section'>
                        <h2 className='pp-disease-header'>Search Disease Database:</h2>
                        <div className='pp-excerpt-search'>
                            <input id="pp-d-search" className='text-input-standard text-left' type="text" onChange={(e) => setDiseaseName(e.target.value)} value={diseaseName}></input>
                        </div>
                        <p className='pp-d-subtext'>Excerpts</p>
                        {excerpts.length >= 1 
                            ?
                            <div className='pp-excerpts-holder'>
                                {excerpts.map((item, index) => {
                                    return <DiseaseExcerpt excerpt={item.item} key={`pp-excerpt${index}`} isIso={true}/>
                                })}
                            </div>
                            :
                            diseaseName.length > 0 ?
                            <p className='pp-no-excerpts'>No excerpts found.</p>
                            :
                            <p className='pp-no-excerpts'>Search our database.</p>
                        }
                        <div className='pp-excerpts-hr'></div>
                        <p className='pp-d-subtext'>Links</p>
                        <p className='pp-d-link-txt'>https://examplelink.com</p>
                        <p className='pp-d-link-txt'>https://examplelink.com</p>
                        <p className='pp-d-link-txt'>https://examplelink.com</p>
                    </div>
                </div>
            </div>               
                <div className='content-container flex-col'>
                    <div className='navBar-main'>
                        <div className='flex-row alignCenter'>
                            <h1 style={{marginLeft: "15px"}}  className='nav-forecast-title'>Prevalence Tool</h1>
                        </div>
                        <div className='nav-elems'>
                            <div id="nav-notepad" onClick={closeNotepad} className='nav-elem flex-row'>
                                <img className="svg-icon" src={notepadIcon} alt="notepad" />
                                <h4 className='nav-elem-text'>Notes</h4>
                            </div>
                            <div className='nav-elem flex-row' onClick={()=>navigate("/user")}>
                                <img className="svg-icon" src={userIcon} alt="user" />
                                <h4 className='nav-elem-text'>User Profile</h4>
                            </div>
                            <div className='nav-elem flex-row'>
                                <img className="svg-icon" src={settingIcon} alt="settings" />
                                <h4 className='nav-elem-text'>Settings</h4>
                            </div>
                        </div>
                        {showNotepad && <Notepad ref={notepadRef} addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets} showNotepad={showNotepad} setShowNotepad={setShowNotepad} notes={notes} setNotes={setNotes}/>}
                    </div>
                    <div className='pp-calc-holder'>
                        <div className='pp-geog-controls'>
                            <div className='flex-row alignCenter'>
                                <h3 className='pp-geog-title'>Geographies: </h3>
                                {geographyList.length <= 3 ? 
                                <React.Fragment>
                                    <input placeholder='Select' style={{marginBottom: "7px"}} id="input-select-geo-nav-iso" className='text-input-standard text-left' list="geographies" type="text" onChange={(e) => handleGeographies(e)} value={newGeography}></input>
                                    <datalist id="geographies">
                                        {GEOGRAPHIES_CONST.map(item => {
                                            return <option key={"geo-dropdown" + item}>{item}</option>
                                        })}
                                    </datalist>
                                </React.Fragment>
                                :
                                <h5 className='iso-max-geos'>Max Geographies</h5>
                                }
                                {geographyList.length <= 3 && <button id="add-geo-btn-iso" className='input-action-btn general-btn' onClick={submitNewGeography}>Add</button>}
                            </div>
                            <div className='flex-row alignCenter pp-active-geo-d'>
                                {geographyList.map((geo, index) => {
                                    return (
                                        <div onClick={() => handleRemoveGeo(geo)} key={`${index}pp-geo-btn`} className='pp-geo-active-btn flex-row alignCenter'>
                                            <p className='pp-geo-active-btn-txt'>{geo}</p>
                                            <img id="calc-icon-x-iso" className='container-icon' src={closeIcon} alt={"remove geography"}></img>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <IsolatedCalculator geographyList={geographyList} />
                    </div>
                </div>
            </div>
      )
   
}

export default PrevalencePage