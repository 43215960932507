import React, {useEffect, useRef, useState} from 'react'
import { toCommaSeperatedString } from '../../../../functions/helpers/generalHelpers';
import EditableField from './EditableField'
import MarketShareData from './MarketShareData';
import ForecastEvent from './ForecastEvent';


const TableColumn = ({currGeography, outerIndex, colData, currHover, setCurrHover, colIndex, marketShare, dimensions, setDimensions, isCollapsed, setIsCollapsed, evenBackground, forecastEvents, expenses, setWorkingForecast, forecastType}) => {

    const mshareIndex = forecastType === "drug" ? 4 : 7;
    const triggerRef = useRef(null)
    const [hovered, setHovered] = useState(false)
    const [timeoutId, setTimeoutId] = useState(null);
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (hovered && currHover !== -1 && currHover !== colIndex) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
            setHovered(false);
        }
    }, [colIndex, currHover, hovered, timeoutId]);

    const handleMouseEnter = () => {
        // Clear any existing timeout to prevent it from hiding the popup
        if (timeoutId) {
          clearTimeout(timeoutId);
          setTimeoutId(null);
        }
        // Show the popup
        setHovered(true);
        setCurrHover(colIndex);
      };
    
      const handleMouseLeave = () => {
        // Set a timeout to hide the popup
        const id = setTimeout(() => {
          setHovered(false);
        }, 1500);
        // Save the timeout ID so we can clear it if needed
        setTimeoutId(id);
      };
    
      useEffect(() => {
        // Cleanup effect to clear timeout if the component unmounts
        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }, [timeoutId]);
//   let wStyle = dimensions[colIndex].width > 0 ? {width: dimensions[colIndex].width} : null;
  let wStyle = null;
  const data = colData.map((item, index) => {
    // index 0 === date/tableheader
    if (index === 0) {
        let popup = null;
        if (forecastEvents.peakPen || forecastEvents.LOEEvent || forecastEvents.newComp.length > 0) {
            const starPath2 = "M24.8,2.7C12.5,2.7,2.5,12.7,2.5,25s10,22.3,22.3,22.3s22.3-10,22.3-22.3S37.1,2.7,24.8,2.7z";
            const polygonPath2 = "M35.4,39.8 24.8,32.1 14.2,39.8 18.3,27.3 7.6,19.6 20.8,19.7 24.8,7.1 28.8,19.6 42,19.6 31.3,27.3";
            popup = (
                <svg className='forecast-event-icon' height={18} viewBox="0 0 50 50">
                    <path d={starPath2} stroke={hovered ? "#f6921e" : "#ccc"} fill={hovered ? "#f6921e" : "#ccc"} />
                    <path d={polygonPath2} stroke={hovered ? "#f6921e" : "#ccc"} fill={"#fff"} />
                </svg>
            );
        }
        return (
            <div style={wStyle} ref={triggerRef} key={`${currGeography}table-header${item}${index}`} onMouseEnter={popup ? handleMouseEnter : null} onMouseLeave={popup ? handleMouseLeave : null} className='table-header-basic'>
                <div className='flex-row'>
                    <h4 className='table-header-text'>{item}</h4>
                    {popup}                
                </div>
            </div>
        )
    } else if(index === 1) {
        return <EditableField wStyle={wStyle} key={`${currGeography}table-item${item}${index}`} fieldData={toCommaSeperatedString(item)} evenBackground={evenBackground} borderTop/>
    } else if (index === mshareIndex && marketShare) {
        return <MarketShareData key={`${currGeography}table-mshare-main${index}`} data={item} dimensions={dimensions} setDimensions={setDimensions} isCollapsed={isCollapsed} colIndex={colIndex} evenBackground={evenBackground} setIsCollapsed={setIsCollapsed} />
        } else if (index === colData.length - 1) {
        //expenses
        let grossAfterExpenses = toCommaSeperatedString(item - expenses)
        return (
            <React.Fragment key={`${currGeography}table-item${index}-exp-and-gross`}>
                <EditableField currGeography={currGeography} index={outerIndex} wStyle={wStyle} fieldData={expenses} evenBackground={evenBackground} isExpenses={true} setWorkingForecast={setWorkingForecast}/>
                <EditableField wStyle={wStyle} fieldData={grossAfterExpenses} evenBackground={evenBackground} />
            </React.Fragment>
        )
    } else {
        return <EditableField wStyle={wStyle} key={`${currGeography}table-item${item}${index}`} fieldData={toCommaSeperatedString(item)} evenBackground={evenBackground} />
    }
  })

  return (
    <div className='table-col-div flex-col'>
        {hovered && <ForecastEvent triggerRef={triggerRef} data={forecastEvents}/>}
        {data}
    </div>
  )
}


export default TableColumn