import Fuse from 'fuse.js'

import { diseaseExcerpts } from '../../diseaseExcerpts'



const searchDiseaseDB = (searchString) => {
    console.log(searchString)
    if (searchString.length === 0) return [];
    const options = {
        includeScore: true,
        keys: ['diseaseName'],
    }
    const fuse = new Fuse(diseaseExcerpts, options);
    const result = fuse.search(searchString);
    console.log("asdasd")
    // we may be able to increase accuracy with the score component but testing will be required.
    let maximumScore = .4;
    let count = 0;
    const output = [];
    for (let i = 0; i < result.length; i++) {
        if (result[i].score > maximumScore) break;
        output.push(result[i]);
        count++;
        if (count === 3) {
            break;
        }
    }
    return output;
}

export default searchDiseaseDB;