import React, { useState, useEffect } from 'react'

import DataPage from '../components/dataPage/DataPage'

import { useNavigate } from 'react-router-dom'

import { LDD_DATA, LDD_DRUG_LIST, PHARMACIES_LIST } from '../LDDData'

const DataRoute = ({activeUser}) => {

    console.log(activeUser)
  return (

    (activeUser && activeUser.modules.data)
    ?
    <div className='max-window'>
        <DataPage LDDData={{main: LDD_DATA, drugList: LDD_DRUG_LIST, pharmacies: PHARMACIES_LIST}}/>
    </div>
    :
    <DataPreview />

  )
}

const DataPreview = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/user')
    }

    return (
        <div className='preview max-window'>
            <h1>Data</h1>
            <h3>Unlock bancrofts insightful data options</h3>
            <button className='input-action-btn' onClick={handleRedirect}>ADD MODULE</button>
        </div>
    )
}

export default DataRoute