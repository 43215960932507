import { getDatePeriods } from "./dateHandling";
import { ORDER_OF_ENTRY_MARKET_SHARE } from "../../constants";


const marketShareCalculator = (type, slope, periodFormat, length, startDate, competitors, forecastName, patientData) => {

    const linearChange = (period, currMShare, endMShare, timeToPeakPenetration) => {
        if (period >= timeToPeakPenetration) {
            return endMShare;
        } else {
            let sub = endMShare - currMShare;
            if (sub !== 0) {
                return currMShare + (sub / timeToPeakPenetration) * period;
            } else {
                return currMShare;
            }
        }
    }

    const exponentialChange = (period, currMShare, endMShare, timeToPeakPenetration) => {
        if (period >= timeToPeakPenetration) {
            return endMShare;
        } else {
            const initialFrac = currMShare === 0 ? .01 : currMShare;
            const r = Math.log(endMShare / initialFrac) / timeToPeakPenetration;
            const newMarketShare = initialFrac * Math.exp(r * period);
            return newMarketShare
        }
    }

    const logisticChange = (period, currMShare, endMShare, timeToPeakPenetration) => {
        const initialFrac = currMShare === 0 ? .01 : currMShare;
        const t_mid = timeToPeakPenetration / 2;
        const L = endMShare - initialFrac; // Maximum growth
        const k = 1 / t_mid; // Growth rate
        const newMarketShare = initialFrac + L / (1 + Math.exp(-k * (period - t_mid)));
        return newMarketShare;
    };    

    function addValues(patients) {
        return patients.incident + patients.prevalent
    }

    let currWorkingCompetitors = [...competitorsEntry(competitors, startDate, periodFormat, length)];
    if (currWorkingCompetitors.length === 0) return false;

    let primaryDrug = 1;
    let skip = 0;
    let periodOfChange = 0;
    let endShares = [1, 0];
    let newTimeTilPeak = 0;
    let output = [];

    for (let i = 1; i <= length; i++) {
        let newSkip = 0;
        let newCompetitorEvent = [];
        for (let j = skip; j < currWorkingCompetitors.length; j++) {
            if (currWorkingCompetitors[j].entryPeriod === i - 1) {
                newCompetitorEvent.push(currWorkingCompetitors[j].name)
                endShares = ORDER_OF_ENTRY_MARKET_SHARE[j + 2];
                newTimeTilPeak = currWorkingCompetitors[j].timeToPeakPenetration;
                periodOfChange = i;
                if (periodFormat === "month") {
                    newTimeTilPeak *= 12;
                } else if (periodFormat === "quarter") {
                    newTimeTilPeak *= 4;
                }
                for (let index = 0; index < currWorkingCompetitors.length; index++) {
                    const updatedMarketShare = output[output.length - 1][index + 1].marketShare;
                    currWorkingCompetitors[index] = { ...currWorkingCompetitors[index], marketShare: updatedMarketShare };
                }
                primaryDrug = output[output.length - 1][0].marketShare;
                newSkip++;
            } else if (currWorkingCompetitors[j].entryPeriod > i) {
                skip += newSkip;
                break
            }
        }

        // calculate rate of change 

        if (i === 1 || endShares[0] === 1) {
            primaryDrug = endShares[0];
            for (let index = 0; index < currWorkingCompetitors.length; index++) {
                currWorkingCompetitors[index].marketShare = endShares[1];
            }
            let pod = type === "drug" ? patientData[i - 1].patientsOnDrug : addValues(patientData[i - 1].patientsOnDrug);
            if (type === "drug") {
                output.push([{name: forecastName, marketShare: primaryDrug, patientsOnDrug: pod}, ...currWorkingCompetitors, newCompetitorEvent]);
            } else {
                output.push([{name: forecastName, marketShare: primaryDrug, patientsOnDrug: pod, genePatientsSplit: patientData[i - 1].patientsOnDrug}, ...currWorkingCompetitors, newCompetitorEvent]);
            }
        } else if (endShares[0] !== 1) {
            if (slope === "linear") {
                let pushObj = [];
                let competitorsShares = 0;
                let total = primaryDrug - endShares[0];
                const needsWork = [];
                for (let index = currWorkingCompetitors.length - 1; index >= 0; index--) {
                    const sh = {...currWorkingCompetitors[index]};
                    if (sh.entryPeriod <= i - 1) {
                        if (sh.marketShare > endShares[1]) {
                            needsWork.push(index);
                            total += sh.marketShare - endShares[1];
                        } else {
                            const updatedMarketShare = linearChange((i + 1 - periodOfChange), sh.marketShare, endShares[1], newTimeTilPeak);
                            let pod = type === "drug" ? patientData[i - 1].patientsOnDrug : addValues(patientData[i - 1].patientsOnDrug);
                            const updatedPatients = Math.ceil(pod * updatedMarketShare);
                            competitorsShares += updatedMarketShare - sh.marketShare;
                            pushObj.push({ ...sh, marketShare: updatedMarketShare, patientsOnDrug: updatedPatients })
                        }
                    } else {
                        pushObj.push({ ...sh })
                    }
                }
                for (let f = 0; f < needsWork.length; f++) {
                    let ind = needsWork[f];
                    const updatedMarketShare = currWorkingCompetitors[ind].marketShare - (competitorsShares / (total / (currWorkingCompetitors[ind].marketShare - endShares[1])));
                    let pod = type === "drug" ? patientData[i - 1].patientsOnDrug : addValues(patientData[i - 1].patientsOnDrug);
                    const updatedPatients = Math.ceil(pod * updatedMarketShare);
                    pushObj.push({ ...currWorkingCompetitors[ind], marketShare: updatedMarketShare, patientsOnDrug: updatedPatients })
                }

                let primaryShare = primaryDrug - (competitorsShares / (total / (primaryDrug - endShares[0])));
                // may be innacurate to 1 or two patients
                let pod = type === "drug" ? patientData[i - 1].patientsOnDrug : addValues(patientData[i - 1].patientsOnDrug);
                let primaryPatients = Math.ceil(pod * primaryShare);
                pushObj.sort((a,b) => (a.entry - b.entry))
                if (type === "drug") {
                    output.push([{name: forecastName, marketShare: primaryShare, patientsOnDrug: primaryPatients}, ...pushObj, newCompetitorEvent]);
                } else {
                    output.push([{name: forecastName, marketShare: primaryShare, patientsOnDrug: primaryPatients, genePatientsSplit: patientData[i - 1].patientsOnDrug}, ...pushObj, newCompetitorEvent]);
                }
            } else if (slope === "exponential") {
                let pushObj = [];
                let competitorsShares = 0;
                let total = primaryDrug - endShares[0];
                const needsWork = [];
                for (let index = currWorkingCompetitors.length - 1; index >= 0; index--) {
                    const sh = {...currWorkingCompetitors[index]};
                    if (sh.entryPeriod <= i - 1) {
                        if (sh.marketShare > endShares[1]) {
                            needsWork.push(index);
                            total += sh.marketShare - endShares[1];
                        } else {
                            const updatedMarketShare = exponentialChange((i + 1 - periodOfChange), sh.marketShare, endShares[1], newTimeTilPeak);
                            let pod = type === "drug" ? patientData[i - 1].patientsOnDrug : addValues(patientData[i - 1].patientsOnDrug);
                            const updatedPatients = Math.ceil(pod * updatedMarketShare);
                            competitorsShares += updatedMarketShare - sh.marketShare;
                            pushObj.push({ ...sh, marketShare: updatedMarketShare, patientsOnDrug: updatedPatients })
                        }
                    } else {
                        pushObj.push({ ...sh })
                    }
                }
                for (let f = 0; f < needsWork.length; f++) {
                    let ind = needsWork[f];
                    const updatedMarketShare = currWorkingCompetitors[ind].marketShare - (competitorsShares / (total / (currWorkingCompetitors[ind].marketShare - endShares[1])));
                    let pod = type === "drug" ? patientData[i - 1].patientsOnDrug : addValues(patientData[i - 1].patientsOnDrug);
                    const updatedPatients = Math.ceil(pod * updatedMarketShare);
                    pushObj.push({ ...currWorkingCompetitors[ind], marketShare: updatedMarketShare, patientsOnDrug: updatedPatients })
                }

                let primaryShare = primaryDrug - (competitorsShares / (total / (primaryDrug - endShares[0])));
                // may be innacurate to 1 or two patients
                let pod = type === "drug" ? patientData[i - 1].patientsOnDrug : addValues(patientData[i - 1].patientsOnDrug);
                let primaryPatients = Math.ceil(pod * primaryShare);
                pushObj.sort((a,b) => (a.entry - b.entry))
                if (type === "drug") {
                    output.push([{name: forecastName, marketShare: primaryShare, patientsOnDrug: primaryPatients}, ...pushObj, newCompetitorEvent]);
                } else {
                    output.push([{name: forecastName, marketShare: primaryShare, patientsOnDrug: primaryPatients, genePatientsSplit: patientData[i - 1].patientsOnDrug}, ...pushObj, newCompetitorEvent]);
                }

            } else if (slope === "logistic") {
                let pushObj = [];
                let competitorsShares = 0;
                let total = primaryDrug - endShares[0];
                const needsWork = [];
                for (let index = currWorkingCompetitors.length - 1; index >= 0; index--) {
                    const sh = {...currWorkingCompetitors[index]};
                    if (sh.entryPeriod <= i - 1) {
                        if (sh.marketShare > endShares[1]) {
                            needsWork.push(index);
                            total += sh.marketShare - endShares[1];
                        } else {
                            const updatedMarketShare = logisticChange((i + 1 - periodOfChange), sh.marketShare, endShares[1], newTimeTilPeak);
                            let pod = type === "drug" ? patientData[i - 1].patientsOnDrug : addValues(patientData[i - 1].patientsOnDrug);
                            const updatedPatients = Math.ceil(pod * updatedMarketShare);
                            competitorsShares += updatedMarketShare - sh.marketShare;
                            pushObj.push({ ...sh, marketShare: updatedMarketShare, patientsOnDrug: updatedPatients })
                        }
                    } else {
                        pushObj.push({ ...sh })
                    }
                }
                for (let f = 0; f < needsWork.length; f++) {
                    let ind = needsWork[f];
                    const updatedMarketShare = currWorkingCompetitors[ind].marketShare - (competitorsShares / (total / (currWorkingCompetitors[ind].marketShare - endShares[1])));
                    let pod = type === "drug" ? patientData[i - 1].patientsOnDrug : addValues(patientData[i - 1].patientsOnDrug);
                    const updatedPatients = Math.ceil(pod * updatedMarketShare);
                    pushObj.push({ ...currWorkingCompetitors[ind], marketShare: updatedMarketShare, patientsOnDrug: updatedPatients })
                }

                let primaryShare = primaryDrug - (competitorsShares / (total / (primaryDrug - endShares[0])));
                // may be innacurate to 1 or two patients
                let pod = type === "drug" ? patientData[i - 1].patientsOnDrug : addValues(patientData[i - 1].patientsOnDrug);
                let primaryPatients = Math.ceil(pod * primaryShare);
                pushObj.sort((a,b) => (a.entry - b.entry))
                if (type === "drug") {
                    output.push([{name: forecastName, marketShare: primaryShare, patientsOnDrug: primaryPatients}, ...pushObj, newCompetitorEvent]);
                } else {
                    output.push([{name: forecastName, marketShare: primaryShare, patientsOnDrug: primaryPatients, genePatientsSplit: patientData[i - 1].patientsOnDrug}, ...pushObj, newCompetitorEvent]);
                }

            } else {
                console.warn("sus code")
            }
        }

    }
    return output;
}

const competitorsEntry = (forecastCompetitors, startDate, periodFormat, length) => {
    let competitorEntry = [];
    for (let i = 0; i < forecastCompetitors.length; i++) {
        if (forecastCompetitors[i].competitorApprovalDate && forecastCompetitors[i].competitorTimeToPeak > 0) {
            let timeToPeak = forecastCompetitors[i].competitorTimeToPeak;
            let entryPeriod = getDatePeriods(startDate, periodFormat, length, forecastCompetitors[i].competitorApprovalDate);
            competitorEntry.push({name: forecastCompetitors[i].competitorName, entryPeriod: entryPeriod, timeToPeakPenetration: timeToPeak, patientsOnDrug: 0});
        }
    }
    competitorEntry.sort((a,b) => a.entryPeriod-b.entryPeriod);
    for (let i = 0; i < competitorEntry.length; i++) {
        competitorEntry[i].entry = i + 2;
    }
    return competitorEntry;
}

export {marketShareCalculator}