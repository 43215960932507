import React, { useState, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';

const GeoWarnings = ({ type, userSettings, changeUserSettings, confirmActions, setConfirmActions, triggerRef, handleCancel}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useLayoutEffect(() => {
    const rect = triggerRef.current.getBoundingClientRect();
    const top = rect.top + window.scrollY;
    const left = rect.left + window.scrollX;
    setPosition({ top, left });
  }, [triggerRef]);

  const style = {
    position: 'absolute',
    top: `${position.top}px`,
    left: `${position.left}px`,
    // your other styles
  };

  const multWarn = (
    <div className='geo-warning' style={style}>
        <h4 className='warning-txt-geo'>Warning</h4>
        <p className='warning-txt-info'>Selecting multiple geographies will override changes<br/> for the additionally selected geographies.</p>
        <label id="alter-geo-label" htmlFor="alter-geos">Do not show this message again</label>
        <input type="checkbox" id="alter-geos" checked={userSettings.multGeoVal} onChange={() => changeUserSettings("multiple-geo")} value={userSettings.multGeoVal} />
        <div className='flex-row'>
            <button id="explicit-width-input" className='input-action-btn general-btn' onClick={() => setConfirmActions({...confirmActions, mult: "confirmed"})}>CONFIRM</button>
            <button id="geo-cancel-btn" className='input-action-btn general-btn' onClick={() => handleCancel("mult")}>CANCEL</button>
        </div> 
    </div>
  );

  const removeWarn = (
    <div className='geo-warning' style={style}>
        <h4 className='warning-txt-geo'>Warning</h4>
        <p className='warning-txt-info'>This action completely removes the geography and cant be undone.</p>
        <label id="alter-geo-label" htmlFor="alter-geos">Do not show this message again</label>
        <input type="checkbox" id="alter-geos" checked={userSettings.delGeoVal} onChange={() => changeUserSettings("remove-geo")} value={userSettings.delGeoVal} />
        <div className='flex-row'>
            <button id="explicit-width-input" className='input-action-btn general-btn' onClick={() => setConfirmActions({...confirmActions, del: "confirmed"})}>CONFIRM</button>
            <button id="geo-cancel-btn" className='input-action-btn general-btn' onClick={() => handleCancel("del")}>CANCEL</button>
        </div> 
    </div>
  );

  const warningToDisplay = type === "delete" ? removeWarn : multWarn;

  return ReactDOM.createPortal(
    warningToDisplay,
    document.body
  );
}

export default GeoWarnings;
