import React, {useEffect} from 'react'

import UserPage from '../components/userPage/UserPage'

import "../css/helpers.css"

import { checkUser } from './authFunctions'

const UserRoute = ({activeUser, setActiveUser}) => {

    // console.log(activeUser)
    // useEffect(() => {
    //     checkUser(activeUser);
    // }, [])

  return (
    activeUser && 

    <div className='max-window'>
        <UserPage activeUser={activeUser} setActiveUser={setActiveUser} />
    </div>
  )
}

export default UserRoute