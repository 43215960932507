import React, {useState} from 'react'

import "../../css/dataPage.css";
import { deepClone } from '../../functions/helpers/generalHelpers';

const CompanyPage = ({CompanyData}) => {

    const [workingData, setWorkingData] = useState(deepClone(CompanyData.main));

    const [alphabetical, setAlphabetical] = useState(true);
    const [drugSearch, setDrugSearch] = useState("");

    const searchByDrug = (e) => {
        const searchString = e.target.value.toUpperCase();
        setDrugSearch(e.target.value);
        setWorkingData(prev => {
            let goodList = CompanyData.companyList.filter(drug => {
                return drug.toUpperCase().includes(searchString);
            })
            let copy = CompanyData.main;
            copy = copy.filter(drug => {
                return goodList.indexOf(drug.companyName) !== -1;
            })
            return copy;
        })
        if (!alphabetical) {
            handleSortAlphabetically(false);
        }
    }

    const sortAlphabetically = () => {
        setAlphabetical(!alphabetical);
        handleSortAlphabetically(!alphabetical)
    }

    const handleSortAlphabetically = (isAlphabetical) => {
        setWorkingData(prev => {
            prev.sort((a, b) => {
                let flag = isAlphabetical ? 1 : -1;
                if (a.CompanyName < b.CompanyName) {
                   return -1 * flag;
                }
                if (a.CompanyName > b.CompanyName) {
                  return 1 * flag;
                }
                return 0;
            })
            return prev;
        })
    }


  return (
    <div className='data-page-master max-window flex-col'>
        <div className='dp-search-tools-master'>
            <button onClick={sortAlphabetically}>{alphabetical ? "A-Z" : "Z-A"}</button>
            <div className='flex-row alignCenter'>
                <h5>Company Search</h5>
                <input className='text-input-standard' value={drugSearch} onChange={searchByDrug}></input>
            </div>
        </div>
        <div className='dp-table-master max-window flex-col'>
            <div className='dp-table-body flex-row'>
                <div className='dp-tb-drugs-div flex-col'>
                    <h2 className='dp-th-drug'>Company</h2>
                    {workingData.map((drug, index) => {
                        return (
                            
                            <div className='flex-row alignCenter dp-tb-drugs' key={`ldd-tbl${index}`}>
                                <h4 className='dp-tb-drug'>{drug.companyName}</h4>
                            </div>
                        )
                    })}
                </div>
                <div className='dp-tb-drugs-div flex-col'>
                    <h3 className='dp-th-pharmacies'>Ticker</h3>
                    {workingData.map((drug, index) => {
                        return (
                            <div key={`ldd-tbl=p${index}`} className='dp-tb-pharmacy flex-row'>
                                <h3 >{drug.companyTicker}</h3>
                            </div>
                        )
                    })}
                </div>
                <div className='dp-tb-pharmacies flex-col'>
                    <h3 className='dp-th-pharmacies'>Website</h3>
                    {workingData.map((drug, index) => {
                        return (
                            <div key={`ldd-tbl=p${index}`} className='dp-tb-pharmacy flex-row'>
                                <a href={drug.companyPipelineURL} target="_blank" rel="noopener noreferrer">go to Website</a>
                            </div>
                        )
                    })}
                </div>
                <div className='dp-tb-pharmacies flex-col'>
                    <h3 className='dp-th-pharmacies'>Pipeline</h3>
                    {workingData.map((drug, index) => {
                        return (
                            <div key={`ldd-tbl=p${index}`} className='dp-tb-pharmacy flex-row'>
                                <a href={drug.companyPipelineURL} target="_blank" rel="noopener noreferrer">go to Pipeline</a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </div>
  )
}

export default CompanyPage