import React, { useEffect, useState } from 'react'
import {Link} from "react-router-dom"

import "../css/landing.css"

import userIcon from "../icons/bancy-user-black.svg"
import forecastDataImage from "../assets/landingImages/forecast.jpeg"
import summaryImage from "../assets/landingImages/summary-img.png"
import prevalenceImage from "../assets/landingImages/prevalence.png"
import literallyImage from "../assets/landingImages/exportImage.png"
import chatImage from "../assets/landingImages/Chat.png"
import forecastCompImage from "../assets/landingImages/forecast-comp.png"
import bannerVideo from "../assets/landingImages/landing-page-demo.mov"
import bannerGIF from "../assets/landingImages/landing-new.gif"

import prevImage from "../assets/landingImages/prev-screenshot2.png"
import probImage from "../assets/landingImages/qa-screenshot.png"



import quickAnalysisIcon from "../icons/bancroft_icon_guage.svg"
import prevalenceIcon from "../icons/bancroft_icon_grouping.svg"
import forecastIcon from "../icons/bancy-forecast-icon.svg"

import { useNavigate } from 'react-router-dom'

const LandingRoute = ({activeUser, setIsSignup}) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (activeUser && activeUser.email) {
            navigate("/user")
        }
    }, [activeUser])

   
    
  return (
    <div className='landing-page-master flex-col'>
        <NavElem setIsSignup={setIsSignup}/>
        <div className='landing-page-main flex-col' >
            <IntroElem />
            <InfoElem />
            <PricingElem />
            <CloserElem />
            <div className='landing-page-closer flex-col'>  
                <div className="disclaimer-wrp ">
                    <h1 className='footer-title'>bancroft</h1>
                    <p className="disclaimer flex-col">Disclaimer: The information provided on this website by 
                        Bancroft Software LLC is for informational purposes only and is intended to support
                        the financial modeling and statistical analysis of pharmacuetical products and markets. 
                        It should not be interepreted as an offer to sell, a solicitation to buy, or a recommendation
                        for any specific financial product or strategy or investment. Bancroft Software LLC is not a 
                        broker, registered investment advisor, nor is it registered with any financial or securities 
                        regulatory authority to provide financial or investment advice. 
                        While we make every effort to ensure the accuracy of the information provided, we cannot 
                        guarantee its absolute accuracy. We encourage users to conduct their own research or consult 
                        with a qualified professional in the pharmaceutical and financial fields before making any 
                        decision based on information provided by our software. The pharmacuetical and financial 
                        markets involve risks, and the analysis provided by our tools should not be seen as predictive 
                        of future results. Bancroft Software LLC, including its owners, employees, and affiliates, 
                        will not be held liable for any losses or damages resulting from reliance on the information 
                        contained on this website. By using this website, you agree to the terms set forth in this 
                        disclaimer.
                    </p>
                </div>
                    
                <div className='landing-page-footer flex-row'>
                    <div className="copyright-wrp flex-col">
                        <h2 className='landing-page-copyright'>&copy; 2024 Bancroft Software LLC. All rights reserved.</h2>
                    </div>
                    <div className="holder flex-col"><p></p></div>
                    <div className="legal-wrp flex-col">
                        <div className="legal-txt-wrp flex-row">
                            <h2 className='legal-txt' onClick={() => window.open("https://app.termly.io/document/privacy-policy/5a134551-0d8f-4654-b817-c9c8e5fff090", "_blank")}>Privacy</h2>
                            <h2 className='legal-txt' onClick={() => window.open("https://app.termly.io/document/terms-of-service/2ee3abc1-da62-4a54-8455-e0b4ffc15503", "_blank")}>Terms</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

const CloserElem = () => {
    return (
        <p></p>
    );
}


const NavElem = ({setIsSignup}) => {
    const navigate = useNavigate();
    const scrolltoTarget = (target) => {
        const element = document.getElementById(target);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }    
    }

    const handleLogin = (type) => {
        if (type && type === "signup") setIsSignup(true);
        navigate("/login")
    } 

    return (
        <div className='landing-page-nav'>
            <div className='landing-page-nav-sub'>
                <div className='lpn-left'>
                    <h1 className='lpn-title' onClick={() => scrolltoTarget('gototop')}>bancroft</h1>
                    <h6 className='lpn-scrolls' onClick={() => scrolltoTarget('featuresSection')}>How It Works</h6>
                    <h6 className='lpn-scrolls' onClick={() => scrolltoTarget('infoSection')}>Features</h6>
                    <h6 className='lpn-scrolls' onClick={() => scrolltoTarget('pricingSection')}>Pricing</h6>
                </div>
                <div className='lpn-right'>
                    <div onClick={() => navigate("/login")} className='lpn-login flex-row'>
                        <img src={userIcon} alt="log in" className='lpn-icon'></img>
                        <h6 className='lpn-login-txt'>Log In</h6>
                    </div>
                    <button onClick={() => handleLogin("signup")} className='lpn-btn'>Sign Up</button>
                    <button onClick={() => handleLogin("demo")} className='lpn-btn'>Try Demo</button>
                </div>
            </div>
        </div>
    )
}

// inside landing-page-header we have a video background

const IntroElem = () => {
    const texts = ["Executives", "Investors ", "BD Teams "];
    const navigate = useNavigate();


    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {   
        const intervalId = setInterval(() => {
            setCurrentIndex((currentIndex) => (currentIndex + 1) % texts.length);
        }, 3000);

        return () => clearInterval(intervalId); 
    }, []);

    return (
        <div className='landing-page-header flex-col'  id="gototop">

        <div className='video-overlay'></div>  
            
            <div className="header-wrp"> 
            <img className="lpn-video" src={bannerGIF} alt="banner"></img>

            <h1 className='lpn-header'>
                <div id="main-info">
                    Navigate Pharma with Confidence & Efficiency
                {/* Confidence and Efficiency  */}
                 {/*Efficiency and Confidence  */}
                {/* Pharmaceutical Forecasting Prediction & Analysis */}
                </div> 
            </h1>
            <br></br>
            <h2 className='lpn-subheader'>
                <div id="main-info">
                Web-Based Pharmaceutical Forecasting, Prediction & Analysis for Executives, Investors, and BD Teams
                   {/* A Web-Based Platform for Executives, Investors, and BD Teams. */}
                </div>
            </h2>
            <div className="lpn-buttons">
            { /*
            gray button
            <button className='launch-btn-big lpn-btn'>LOGIN</button>
            
            */}
            <button onClick={() => navigate("/login")} className='lpn-btn-big lpn-btn' id="biglogin">LOGIN</button>
            
            <button onClick={() => navigate("/login")} className='lpn-btn-big lpn-btn' id="bigdemo">TRY DEMO</button>
            </div>
        
            </div>
        </div>
        
        
    )
}

const InfoElem = () => {

    const blurbIntel = [

        {
            // Forecast - Competition
            title: "Accurate Sales Forecasting Across Geographies",
            image: forecastCompImage,
            data: <React.Fragment><br></br><b>Forecast</b> &#8212; Swiftly project drug sales, incorporating market competition, disease prevalence, uptake curves, pricing strategies, loss of exclusivity, and more. In any and all geographies. Forecast empowers you with predictive insights to make informed decsisions. From small molecules to gene therapies and beyond.
            <br></br>
            <br></br>
            <b>Finance</b> &#8212; Dive deeper, seamlessly understand the value of oppuruinities with built-in DCF and IRR analysis.
            <br></br>
            <br></br>
            <b>Collaborate & Communicate</b> &#8212; Share your models directly on bancroft, export to Excel, or automatically generate reports from your models.

            </React.Fragment>
        },
        {
            // Forecast - Prevalence Calcualtions and Text
            title: "Trial & Approval Probabilities",
            image: probImage,
            data: <React.Fragment><br></br><b>Understand stastical probabilities for regulatory approvals and trial success</b>, taking into account therapeutic area, phase, molecule type, even qualitative inputs like the observed treatment effect, and the biological rationale.
            <br></br>
            <br></br>
            <b>Immediate insights into trial success rates and approval probabilties.</b> Make faster, smarter decisions in a market that never slows down.
            <br></br>
            <br></br>
            <b>Peak Revenue</b> &#8212; Get a quick understanding of revenue potential, turn to Quick Analysis. Calculate Peak Revenue
            </React.Fragment>
        },
        {
            // Forecast - Gene Therapy
            title: "Understand Prevalence",
            image: prevImage,
            data: <React.Fragment>
            <br></br>
            <b>Disease summaries and links</b> &#8212; Get right to work understanding diseases their prevalence and incidence to understand markets.
            <br></br>
            <br></br>
            <b>Calculate and project patient populations</b> &#8212; Use bancroft to calculate and understand the number of addressable patients in geographies.
            </React.Fragment>
        },
    ]


    const InfoBlurb = ({blurb}) => {
        return (
            <div className='lpn-info-blurb flex-col'>
                <h1 className='lpn-blurb-title'>{blurb.title}</h1>
                <p className='lpn-blurb-txt'>{blurb.data}</p>
            </div>
        )
    }

    const scrolltoTarget = (target) => {
        const element = document.getElementById(target);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }    
    }

    return (
        
        <div className='landing-page-info flex-col'>
            <br></br>
            
            <div className="mobile-nav">
                <h6 className='lpn' onClick={() => scrolltoTarget('featuresSection')}>How It Works</h6>
                <h6 className='lpn' onClick={() => scrolltoTarget('infoSection')}>Features</h6>
                <h6 className='lpn' onClick={() => scrolltoTarget('pricingSection')}>Pricing</h6>
            </div>

            <FeaturesElem  />
           
            {blurbIntel.map((blurb, index) => {
                if (index % 2 === 0) {
                    return (
                        <div className='lpn-info lpn-l'>
                            <InfoBlurb blurb={blurb} key={`blurb${index}`}/>
                            <div className='lpn-img-placeholder'>
                                <img className='lpn-img-size' src={blurb.image} alt={blurb.title}></img>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className='lpn-info lpn-r'>
                            <div className='lpn-img-placeholder'>
                                <img className='lpn-img-size' src={blurb.image} alt={blurb.title}></img>
                            </div>
                            <InfoBlurb blurb={blurb} key={`blurb${index}`}/>
                        </div>
                    )
                }
            })}
        </div>
    )
}

const FeaturesElem = () => {

  
    return (
        <div className='landing-page-features flex-col' id='featuresSection'>
            
            <div className='lpn-features-header-wrp'>
            <div className='lpn-features-summary'>
                <h1 className='lpn-features-header'> Make confident decisions. Anticipate changes.<br></br>Understand the business of pharma.</h1>
               
                <h2 className='lpb-features-banc'></h2>
            </div>
            </div>
            <div className='lpn-features-items'>
                <div className='lpn-features-item flex-col'>
                    
                    <div className='lpn-img-placeholder-circle'><img className="svg-icon" id="up-fore-icon" src={forecastIcon} alt="forecast" /></div>
                    <h1 className='lpn-blurb-title'>Forecast</h1>
                    <h2 className='lpn-blurb-subtitle'>Analyze Sales and Financials of Drugs</h2>
                     </div>
                <div className='lpn-features-item flex-col'>
                    <div className='lpn-img-placeholder-circle'><img className="svg-icon" id="up-quick-icon" src={quickAnalysisIcon} alt="quickAnalysis" /></div>
                    <h1 className='lpn-blurb-title'>Quick Analysis</h1>
                    <h2 className='lpn-blurb-subtitle'>Predict Approvals, Trial Success, and Peak Sales</h2>
                </div>
                <div className='lpn-features-item flex-col'>
                    <div className='lpn-img-placeholder-circle'><img className="svg-icon" id="up-prev-icon" src={prevalenceIcon} alt="prevalence" /></div>
                    <h1 className='lpn-blurb-title'>Prevalence</h1>
                    <h2 className='lpn-blurb-subtitle'>Understand Patient Populations, Across the Globe</h2>

                </div>
            </div>
            <div id="infoSection"></div>
        </div>
    )
}

const PricingElem = () => {
    return (
        <div className='landing-page-pricing-wrp' id='pricingSection'>
            <div className='landing-page-pricing flex-col'>
                <h2 className='lpn-pricing-head'>Pricing - Complete&nbsp;Package</h2>
                <p className='lpn-pricing-sub'>Enhance Productivity, Produce Insights. All-in-one Package.</p>
                <div className='lpn-pricing-box flex-row'>
                    <h1 className='lpn-pricing-box-txt'>
                        Annual Plan
                    </h1>
                    <h1 className='lpn-pricing-box-txt'>
                        $350/month
                    </h1>
                </div>
                <div className='lpn-pricing-box flex-row'>
                    
                    <h1 className='lpn-pricing-box-txt'>
                        Monthly&nbsp;Plan
                    </h1>
                    <h1 className='lpn-pricing-box-txt'>
                        $400/month
                    </h1>
                </div>
                <button className='lpn-btn lpn-btn-signup'>SIGN UP</button>
            </div>
        </div>
    )
}

export default LandingRoute
//  <h1>BANCROFT LANDING PAGE</h1>
// <Link to="/forecast">
//     <div className='link-site-nav'>
//         <h1>Forecast</h1>
//     </div>
// </Link>
// <Link to="/summary">
//     <div className='link-site-nav'>
//         <h1>Summary</h1>
//     </div>
// </Link>     