const countDecimals = (value) => {
    if(Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0; 
}

const toCommaSeperatedString = (val) => {
    if (typeof val === 'string') {
        val = parseInt(val);
    }
    return val.toLocaleString();
}

const allValidValues = (obj) => {
    let valid = true
    Object.keys(obj).forEach(item => {
        if (typeof obj[item] === 'string') {
          // Check for empty strings
          if (obj[item] === '') valid = false;
        } else if (typeof obj[item] === 'number') {
          // Check for zero
          if (obj[item] === 0) valid = false;
        }
    })
    return valid
}

const deepClone = obj => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
  
    if (Array.isArray(obj)) {
      return obj.map(deepClone);
    }
  
    const clonedObj = {};
    for (const key in obj) {
      clonedObj[key] = deepClone(obj[key]);
    }
  
    return clonedObj;
  };

const roundToTwoDecimals = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

const roundToNDecimals = (num, n) => {
    return Math.round((num + Number.EPSILON) * (10 ** n)) / (10 ** n);
}


export {countDecimals, allValidValues, toCommaSeperatedString, roundToNDecimals,roundToTwoDecimals, deepClone}