import React, { useState, useEffect } from 'react'

import CompanyPage from '../components/companyPage/CompanyPage'

import { COMPANY_DATA,  COMPANY_LIST} from '../CompanyData'

const DataRoute = () => {

  return (
    <div className='max-window'>
        <CompanyPage CompanyData={{main: COMPANY_DATA, companyList: COMPANY_LIST}}/>
    </div>
  )
}

export default DataRoute