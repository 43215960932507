import React, { useState } from 'react'
import "../../../css/navElements.css"
import closeIcon from "../../../icons/bancroft_X_icon.svg"
import axios from 'axios';
import ChartsForPng from '../graph/subsections/ChartsForPng';
import html2canvas from 'html2canvas'
import loadingGif from '../../../assets/bancy-loading.gif'
import successIcon from "../../../icons/check-solid.svg"

const ExportComponent = ({workingForecast, setShowExport, tableDataArray, financeData, graphDataArray}) => {

    const [success, setSuccess] = useState({report: false, excel: false});
    const [errorMessage, setErrorMessage] = useState({report: false, excel: false});
    const [currentlyExporting, setCurrentlyExporting] = useState({report: false, excel: false});

    const downloadExcel = () => {
        setCurrentlyExporting({...currentlyExporting, excel: true})
        setSuccess({...success, excel: false});
        setErrorMessage({...errorMessage, excel: false});
        const data = {forecast: workingForecast, table: tableDataArray, finance: {initialInvestment: financeData.initialInvestment, discountRate: financeData.discountRate }};
        axios.post('https://bancy-backend-01dc0125253f.herokuapp.com/export/excel', { data }, {
            // axios.post('http://localhost:8000/export/excel', { data }, {

          responseType: 'blob',
        })
        .then((response) => {
              // Create a Blob from the response
              const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
        
              // Create a link element and trigger a click to download the file
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `forecast-${workingForecast.name}.xlsx`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setSuccess({...success, excel: true})
              setCurrentlyExporting({...currentlyExporting, excel: false})
        })
        .catch((error) => {
            setErrorMessage({...errorMessage, excel: true})
            setCurrentlyExporting({...currentlyExporting, excel: false})
        })
      };

      const downloadReport = () => {
          // timeout to ensure graphs are loaded currectly 
        setCurrentlyExporting({...currentlyExporting, report: true})
        setSuccess({...success, report: false});
        setErrorMessage({...errorMessage, report: false});
        setTimeout(async () => {

            const graphs = await saveAsPNG();
            const data = {forecast: workingForecast, table: tableDataArray, graphs: graphs, finance: {initialInvestment: financeData.initialInvestment, discountRate: financeData.discountRate }};
            axios.post('https://bancy-backend-01dc0125253f.herokuapp.com/export/report', { data }, {
                // axios.post('http://localhost:8000/export/report', { data }, {

                responseType: 'blob',
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${workingForecast.name}.zip`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setSuccess({...success, report: true})
                setCurrentlyExporting({...currentlyExporting, report: false})
            })
            .catch((error) => {
                setErrorMessage({...errorMessage, report: true})
                setCurrentlyExporting({...currentlyExporting, report: false})
            })
        }, 3000)
      };

      const saveAsPNG = async () => {
        const toSendImages = [];
        const promises = [];
      
        for (const [index, graph] of graphDataArray.entries()) {
          const geog = [];
          const chartElement = document.getElementById(`chart-to-png-sales${index}`);
          const popElement = document.getElementById(`chart-to-png-population${index}`);
      
          if (chartElement && popElement) {
            const promise1 = html2canvas(chartElement).then((canvas) => {
              geog.push(canvas.toDataURL("image/png"));
            });
            promises.push(promise1);
      
            const promise2 = html2canvas(popElement).then((canvas) => {
              geog.push(canvas.toDataURL("image/png"));
            });
            promises.push(promise2);
      
            toSendImages.push(geog);
          }
        }
      
        await Promise.all(promises);
        return toSendImages;
      };
      
  return (
    <div className='nav-popup-basic'>
        {graphDataArray && <ChartsForPng graphDataArray={graphDataArray} workingForecast={workingForecast}/>}
        <div className='flex-row p-c-t-d'>
            <h4 className='p-calc-title'>Export Options</h4>
            <img id="calc-icon-x" onClick={() => setShowExport(false)} className='container-icon' src={closeIcon} alt={"close finance"}></img>
        </div>
        <div className='export-option'>
            <h3 className='export-report-head'>Full Report</h3>
            <ul className='export-report-list'>
                <li>pdf summary</li>
                <li>graph images</li>
                <li>excel sheet</li>
            </ul>
            <div className='flex-row alignCenter'>
                <button id="fin-btn" className='input-action-btn general-btn' onClick={downloadReport}>Export Full Report</button>
                {currentlyExporting.report && <img src={loadingGif} alt='loading' className='export-loader'></img>}
                {success.report && <img src={successIcon} alt='success' className='export-success'></img>}
            </div>
            {errorMessage.report && <p className='export-error'>Error exporting, <br/> If this persists contact <br></br>support@bancroftpharma.com </p>}
        </div>
        <div className='flex-row alignCenter'>
            <button id="fin-btn" className='input-action-btn general-btn' onClick={downloadExcel}>Export Excel Sheet Only</button>
            {currentlyExporting.excel && <img src={loadingGif} alt='loading' className='export-loader'></img>}
            {success.excel && <img src={successIcon} alt='success' className='export-success'></img>}
        </div>
        {errorMessage.excel && <p className='export-error'>Error exporting, <br/> If this persists contact <br></br>support@bancroftpharma.com </p>}
    </div>
  )
}

export default ExportComponent