import React, {useState} from 'react'
import Papa from "papaparse";

import "../css/csvstuff.css"

const CsvRoute = () => {

    const [fileData, setFileData] = useState([])

    const handleFile = (e) => {
  
        const json = [

        ];
        const thisFile = e.target.files[0];
        Papa.parse(thisFile, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              let endState = [];  
              results.data.forEach(res => {
                let indiv = Object.keys(res);
                let str = " "
                let name = "";
                let pharms = [];
                for (let i = 0; i < indiv.length; i++) {
                    if (res[indiv[i]].length < 0) {
                        break;
                    } else {
                        if (i === 0) {
                            name = res[indiv[i]];
                        } else if (res[indiv[i]].length > 0) {
                            pharms.push(res[indiv[i]]);
                        }
                        str += " " + res[indiv[i]] + " ";
                    }
                }
                if (str.length > 0) {
                    json.push({drugName: name, pharmacies: pharms});
                    endState.push(str);
                }

              })
              console.log(json);
              setFileData(endState);
            },
        });
    }

    console.log(fileData)
  return (
    <div className='csv-master'>
        <input type="file" onChange={handleFile}></input>
        <div className='csv-main'>

        {fileData.map((item, index) => {
            return <p key={`${index} csvbs`}>{item}</p>
        })}
        </div>

    </div>
  )
}

export default CsvRoute