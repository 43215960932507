const getNoteTitle = (note) => {
    let h1Version = "";
    if (note[0] === "<" && note[1] === "h" && note[2] === "1") {
        let begin = false;
        for (let i = 3; i < note.length; i++) {
            if (note[i] === ">") {
                begin = true;
            } else {
                if (begin) {
                    if (note[i] === "<") break;
                    h1Version += note[i];
                }
            }
        }
    }
    if (h1Version.length > 0) return h1Version;
    let cleanText = note.replace(/<\/?[^>]+(>|$)/g, "");
    let length = cleanText.length;
    if (length > 25) length = 25;
    let out = cleanText.slice(0, length);
    return out;
}

export default getNoteTitle