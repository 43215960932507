import React, {useEffect, useState, useRef} from 'react'

import WarningPopupSmall from '../../../util/WarningPopupSmall'
import { getDatePlaceHolder } from '../../../../functions/helpers/dateHandling'
import dateHandling from '../../../../functions/helpers/dateHandling'

const DateFields = ({workingGeographies, section, graphPeriod, workingForecast, startDateState, setInputState, setWorkingForecast, competitorIndex, isNewComp, setNewCompetitor}) => {

    // kinda sketchy code refer here if more date problems show up
    let target = "startDate";
    let subObj = "general";
    let titleText = "Model Start Date";
    if (section === "approvalDateCompetition") {
        titleText = "Approval Date or Expected";
        target = "approvalDate";
    } else if (section === "dateOfLOE") {
        titleText = "Date of Loss of Exclusivity";
        target = "dateOfLOE";
        subObj = "drug";
    }
    
    const [localState, setLocalState] = useState(target === "startDate" ? startDateState : (!isNewComp &&section === "approvalDateCompetition") ? workingForecast.geographiesData[workingGeographies[0]].competition[competitorIndex][target] : isNewComp ? "" : workingForecast.geographiesData[workingGeographies[0]][subObj][target]);
    const [focused, setFocused] = useState("never");
    const [needsAlert, setNeedsAlert] = useState(false);

    useEffect(() => {
        let pre = target === "startDate" ? startDateState : (!isNewComp &&section === "approvalDateCompetition") ? workingForecast.geographiesData[workingGeographies[0]].competition[competitorIndex][target] : isNewComp ? "" : workingForecast.geographiesData[workingGeographies[0]][subObj][target];
        let dateValue = "";
        // start length is greater than just "Q1/" which is a side effect of current handling
        if (pre.length === 4 && pre[2] !== "/" && graphPeriod === "year") {
            dateValue = pre;
        }
        else {
            if (pre.length > 3) {
                let targetYear = pre;
                if (pre[2] === "/") targetYear = pre.slice(3);
                if (graphPeriod === "year") {
                    dateValue = targetYear;
                } else if (graphPeriod === "month") {
                    dateValue = `01/${targetYear}`;
                } else if (graphPeriod === "quarter") {
                    dateValue = `Q1/${targetYear}`
                }
            } else {
                if (graphPeriod === "quarter") {
                    dateValue = "Q1/"
                }
            }
        }
        setLocalState(dateValue);
    }, [competitorIndex, isNewComp, section, subObj, target, graphPeriod, workingForecast, workingGeographies, startDateState])

    const dateRef = useRef(focused);

    useEffect(() => {
        if (focused === "no" && dateRef.current === "yes") {
        if (dateHandling(graphPeriod, localState)) {
            if (section === "approvalDateCompetition") {
                if (isNewComp) {
                    setNewCompetitor(prev => {
                        return {...prev, [target]: localState}
                    })
                } else {
                    setWorkingForecast(prevForecast => {
                        let newState = {...prevForecast};
                        let copyG = {...prevForecast.geographiesData}; 
                        workingGeographies.forEach(geog => {
                            copyG[geog].competition[competitorIndex][target] = localState;
                        })
                        return {...newState, geographiesData: {...copyG}};
                    });
                }
            } else {
                if (section === "dateOfLOE") {
                    setWorkingForecast(prevForecast => {
                        let newState = {...prevForecast};
                        let copyG = {...prevForecast.geographiesData}; 
                        workingGeographies.forEach(geog => {
                            copyG[geog].drug[target] = localState;
                        })
                        return {...newState, geographiesData: {...copyG}};
                    });
                } else {
                    setInputState(prev => ({...prev, startDate: localState}))
                }
            }
            setNeedsAlert(false);
        } else if (needsAlert === false) {
            setNeedsAlert(true)
        }
    }
       dateRef.current = focused;
    }, [workingGeographies, competitorIndex, setInputState, focused, graphPeriod, workingForecast, localState, needsAlert, section, setWorkingForecast, target, subObj, isNewComp, setNewCompetitor])

    const keyDownDate = (e) => {
        if (graphPeriod === "month") {
          if (e.key === 'Backspace') {
              if (localState.length === 3) {
                setLocalState(e.target.value.slice(0, 1))
              } else {
                  if (localState.length) {
                    setLocalState(e.target.value.slice(0, e.target.value.length - 1))
                  }
              }
          } 
      }
    }
  
    const dateChange = (e) => {
      if (graphPeriod === "year") {
          if (e.target.value.length < 5) {
            setLocalState(e.target.value)
          }
      } else if (graphPeriod === "month") {
          if (e.target.value.length) {
              if (e.target.value[e.target.value.length - 1].charCodeAt() > 47 && e.target.value[e.target.value.length - 1].charCodeAt() < 58) {                
                  if (e.target.value.length > localState.length) {
                      if (e.target.value.length < 3) {
                          let addition = e.target.value;
                          if (e.target.value.length === 2) {
                              addition += "/";
                          }
                          setLocalState(addition);
                      } else if (e.target.value.length < 8) {
                        setLocalState(e.target.value)
                      }
                  }
              }
          }
      } else {
          console.warn("sus code");
      }
    }
  
    const handleQuarterDate = (e, part) => {
      if (part === "q") {
          if (localState.length < 3) {
            setLocalState(e.target.value + "/")
          } else {
              let value = localState;
              value = `${e.target.value}/${localState.slice(3)}`;
              setLocalState(value)
          }
      } else if (part === 'y') {
          let value = localState
          if (e.target.value.length === 1) {
              value = `Q1/${e.target.value}`;
          } else if (e.target.value.length < 5) {
              value = `${localState.slice(0,2)}/${e.target.value}`;
          }
          setLocalState(value)
      }
    }

    const handleFocus = () => {
        setFocused("yes");
        setNeedsAlert(false);
      }
    
      const handleBlur = () => {
        setFocused("no");
      } 

    const datePlaceholder = getDatePlaceHolder(graphPeriod);
    const datePopup = <span><WarningPopupSmall message={"Please input a valid date."}/></span>

    const quarterDateInputs = (
        <div className='quarter-div-inputs flex-row'>
            <select id="quarter-selector" value={localState.slice(0,2)} className='text-input-select text-left' onBlur={handleBlur} onFocus={handleFocus} onChange={(e) => handleQuarterDate(e, "q")}>
                <option className='input-option-standard' value="Q1">Q1</option>
                <option className='input-option-standard' value="Q2">Q2</option>
                <option className='input-option-standard' value="Q3">Q3</option>
                <option className='input-option-standard' value="Q4">Q4</option>
            </select>
            <input id="year-input-q" className='text-input-standard text-left' placeholder="yyyy" type="text" onBlur={handleBlur} onFocus={handleFocus} onChange={(e) => handleQuarterDate(e, "y")} value={localState.slice(3)}></input>
        </div>
      )

  return (
    <div className='input-and-label-standard'>
            <h5 className='input-header'>{titleText}</h5>
            {needsAlert && datePopup}
            {graphPeriod === "quarter" ? quarterDateInputs : (
                <input className='text-input-standard text-left' placeholder={datePlaceholder} type="text" onKeyDown={keyDownDate} onBlur={handleBlur} onFocus={handleFocus} onChange={dateChange} value={localState}></input>
            )}
    </div>
  )
}

export default DateFields