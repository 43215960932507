import React, {useState} from 'react'
import generalIcon from "../../icons/bancy-forecast-icon.svg"
import salesIcon from "../../icons/bancy-drug.svg"
import competitionIcon from "../../icons/bancy-search.svg"
import carotIcon from "../../icons/bancy-caret2.svg"
import geographyIcon from "../../icons/bancroft_globe_icon.svg"
import documentIcon from "../../icons/bancroft_icons-document.svg"

const CollapsingContainer = ({displayed, containerName, data, engagedSection, setEngagedSection}) => {

  const [hover, setHover] = useState(false)
  const handleClick = () => {
    let value = engagedSection === containerName ? "inactive" : containerName;
    setEngagedSection(value)
  }

  const handleMEnter = () => {
    setHover(true)
  }

  const handleMLeave = () => {
    setHover(false)
  }

  let activeIcon = generalIcon;
  if (containerName === "Drug / Sales") {
    activeIcon = salesIcon;
  } else if (containerName === "Competition") {
    activeIcon = competitionIcon;
  } else if (containerName === "Geography") {
    activeIcon = geographyIcon;
  } else if (containerName === "Your Summaries") {
    activeIcon = documentIcon;
  }

  let warningDisplay = displayed && displayed.length === 0 && <h4 id="no-geo-warning" className='collapsing-container-btn-text'>Please Select A Geography</h4>

  let geographyDisplay = displayed && <h4 id="geo-text-sb" className='collapsing-container-btn-text'>{displayed[0]}</h4>

  if (displayed && displayed.length === 2) {
    geographyDisplay = (
        <div className='geographies-2 flex-col'>
            <h4 id="geo-2-txt" style={{paddingTop: "8px"}} className='collapsing-container-btn-text'>{displayed[0]}</h4>
            <h4 id="geo-2-txt" style={{paddingBottom: "8px"}} className='collapsing-container-btn-text'>{displayed[1]}</h4>
        </div>
    )
  }


  const geographyContainer = displayed && (
    <div id="geography-input-outer" className='collapsing-container'>
        <div onClick={handleClick} onMouseEnter={handleMEnter} onMouseLeave={handleMLeave} className={`collapsing-container-btn pad-horz-20 hoverShading`} id={hover ? "engaged-geography-dropdown" : "geography-dropdown"}>
            <div className='icon-and-header' id={"engaged-btn-sb-geo"}>
                {/* potentially show a hover */}
                {/* {hover && displayed[0] === "Multiple Geographies" && <MultipleGeographyHover displayed={displayed}/>} */}
                <img id={"engaged-icon-sb"} className='container-icon' src={activeIcon} alt={containerName}></img>
                {warningDisplay ? warningDisplay : geographyDisplay}
                <img id={engagedSection === containerName ? "carot-icon-sb-flipped" : "carot-icon-sb"} src={carotIcon} alt={containerName}></img>
            </div>
        </div>
        {engagedSection === containerName && 
            <div className="active-section-background">
                {data}
            </div>
        }
    </div>
  )

  return (
    containerName === "Geography" ? geographyContainer :
    <div id={containerName === "Competition" ? "bottom-box" : undefined} className='collapsing-container'>
        <div onClick={handleClick} onMouseEnter={handleMEnter} style={(containerName === "Your Summaries" || containerName === "Competition") ? {borderBottom: "solid 1px rgb(212, 212, 212)"} : null} onMouseLeave={handleMLeave} className={`collapsing-container-btn pad-horz-20 hoverShading`} id={(hover || engagedSection === containerName) ? "engagedDropdown" : null}>
            <div className='icon-and-header' id={(hover || engagedSection === containerName) ? "engaged-btn-sb" : undefined}>
                <img id={(engagedSection === containerName || hover) ? "engaged-icon-sb" : undefined} className={containerName === "Your Summaries" ? "container-document-icon" : 'container-icon'} src={activeIcon} alt={containerName}></img>
                <h4 className='collapsing-container-btn-text'>{containerName.toUpperCase()}</h4>
                <img id={engagedSection === containerName ? "carot-icon-sb-flipped" : "carot-icon-sb"} src={carotIcon} alt={containerName}></img>
            </div>
        </div>
        {engagedSection === containerName && 
            <div className="active-section-background">
                {data}
            </div>
        }
    </div>
  )
}

// const MultipleGeographyHover = ({displayed}) => {
//     return (
//         <div className='geo-hover-multiple'>
//             {displayed.map((geo, index) => {
//                 if (index === 0) return <h4>Geographies Selected:</h4>
//                 return <p className='geo-sel-hov-txt'>{geo}</p>
//             })}
//         </div>
//     )
// }

export default CollapsingContainer