import React, {useRef} from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { saveAs } from 'file-saver';
import { pdfExporter } from 'quill-to-pdf';
import getNoteTitle from './getNoteTitle'
import katex from 'katex';
window.katex = katex;

Quill.register('modules/imageResize', ImageResize);

const QuillEditorMinimal = React.forwardRef((props, ref) => {
    const { note, setNote, activeIndex, setInvalidExport } = props;

    const reffy = useRef(null)

  const toolbarOptions = [
    ['bold', 'italic', 'underline'],
  ];

  async function exportData() {
    if (reffy.current.editor) {
        try {
            const delta = reffy.current.editor.editor.delta; // gets the Quill delta
            const pdfAsBlob = await pdfExporter.generatePdf(delta); // converts to PDF
            saveAs(pdfAsBlob, getNoteTitle(note) + '.pdf'); // downloads from the browser
        } catch (error) {
            setInvalidExport(true);
            setTimeout(() => {
                setInvalidExport(false)
            }, 4000)
        }
    } else {
        console.warn("Error exporting")
    }
}

React.useImperativeHandle(ref, () => ({
    exportData,
}));

  return (
    <div className='inner-editor'>
        <ReactQuill
        ref={reffy}
          modules={{
            toolbar: toolbarOptions,
            formula: true,
            imageResize: {
              parchment: Quill.import('parchment'),
              modules: ['Resize', 'DisplaySize'],
            },
          }}
          theme="snow"
          value={note}
          onChange={(content) => setNote(content, activeIndex)}
        />
    </div>
  )
});

export default QuillEditorMinimal;