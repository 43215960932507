import React, {useState, useEffect} from 'react'
import axios from 'axios'
import '../css/chat.css'

const ChatRoute = () => {

    const [currPrompt, setCurrPrompt] = useState("")
    const [chatRes, setChatRes] = useState([]);
    // const [chatReq, set]

    const callChat = () => {
        const data = {userQuestion: currPrompt}
        axios.post('http://localhost:8000/chat', { data })
        // axios.post('https://bancy-backend-01dc0125253f.herokuapp.com/chat', { data })
            .then((response) => {
                let output = response.data.output;
                if (output) {
                    console.log(output)
                    setChatRes(prev => {
                        const copy = [...prev];
                        if (!Array.isArray(output)) {
                            return [output, ...copy]
                        } else {
                            return [...output, ...copy]
                        }
                    })
                } else {
                    setChatRes(["error !"])
                }
            })
            .catch(err => {
                console.log(err)
            })
        setCurrPrompt("")
    }
  return (
    <div className='max-window chat-master'>
        <div className='flex-col chat-box'>
            <div className='chat-res'>
                {chatRes.map((chatItem, index) => {
                    return (
                        <React.Fragment key={`${index}-chat-cll`}>
                            <p className='chat-item'>{chatItem}</p>
                            <br></br>
                        </React.Fragment>
                    )
                })}
            </div>
            <div className='chat-req'>
                <textarea className='chat-tb' onChange={(e) => setCurrPrompt(e.target.value)}></textarea>
            </div>
            <button onClick={callChat} className='input-action-btn'>SEND CHAT</button>
        </div>
    </div>
  )
}

export default ChatRoute