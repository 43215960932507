import React, {useState} from 'react'

import "../../css/dataPage.css";
import { deepClone } from '../../functions/helpers/generalHelpers';

const DataPage = ({LDDData}) => {

    const [workingData, setWorkingData] = useState(deepClone(LDDData.main));

    const [alphabetical, setAlphabetical] = useState(true);
    const [drugSearch, setDrugSearch] = useState("");
    const [pharmacySearch, setPharmacySearch] = useState("");

    const searchByPharmacy = (e) => {
        const searchString = e.target.value.toLowerCase();
        setPharmacySearch(e.target.value);
        setWorkingData(prev => {
            let goodList = LDDData.pharmacies.filter(pharma => {
                return pharma.toLowerCase().includes(searchString);
            })
            let copy = LDDData.main;
            copy = copy.filter(drug => {
                let flag = false;
                for (let i = 0; i < drug.pharmacies.length; i++) {
                    const pharma = drug.pharmacies[i];
                    if (goodList.indexOf(pharma) !== -1) {
                        flag = true;
                        break;
                    }
                }
                return flag;
            })
            return copy;
        })
        if (!alphabetical) {
            handleSortAlphabetically(false);
        }
    }

    const searchByDrug = (e) => {
        const searchString = e.target.value.toUpperCase();
        setDrugSearch(e.target.value);
        setWorkingData(prev => {
            let goodList = LDDData.drugList.filter(drug => {
                return drug.includes(searchString);
            })
            let copy = LDDData.main;
            copy = copy.filter(drug => {
                return goodList.indexOf(drug.drugName) !== -1;
            })
            return copy;
        })
        if (!alphabetical) {
            handleSortAlphabetically(false);
        }
    }

    const sortAlphabetically = () => {
        setAlphabetical(!alphabetical);
        handleSortAlphabetically(!alphabetical)
    }

    const handleSortAlphabetically = (isAlphabetical) => {
        setWorkingData(prev => {
            prev.sort((a, b) => {
                let flag = isAlphabetical ? 1 : -1;
                if (a.drugName < b.drugName) {
                   return -1 * flag;
                }
                if (a.drugName > b.drugName) {
                  return 1 * flag;
                }
                return 0;
            })
            return prev;
        })
    }


  return (
    <div className='data-page-master max-window flex-col'>
        <div className='dp-search-tools-master'>
            <button onClick={sortAlphabetically}>{alphabetical ? "A-Z" : "Z-A"}</button>
            <div className='flex-row alignCenter'>
                <h5>drug search</h5>
                <input className='text-input-standard' value={drugSearch} onChange={searchByDrug}></input>
            </div>
            <div className='flex-row alignCenter'>
                <h5>pharmacy search</h5>
                <input  className='text-input-standard text-left' list="pharmacies" type="text" onChange={searchByPharmacy} value={pharmacySearch}></input>
                <datalist id="pharmacies">
                    {LDDData.pharmacies.map((item, index) => {
                        return <option key={`pharma-dropdown${index}`}>{item}</option>
                    })}
                </datalist>
            </div>
        </div>
        <div className='dp-table-master max-window flex-col'>
            <div className='dp-table-body flex-row'>
                <div className='dp-tb-drugs-div flex-col'>
                    <h2 className='dp-th-drug'>DRUG</h2>
                    {workingData.map((drug, index) => {
                        return (
                            <div className='flex-row alignCenter dp-tb-drugs' key={`ldd-tbl${index}`}>
                                <h4 className='dp-tb-drug'>{drug.drugName}</h4>
                            </div>
                        )
                    })}
                </div>
                <div className='dp-tb-pharmacies flex-col'>
                    <h3 className='dp-th-pharmacies'>Pharmacies</h3>
                    {workingData.map((drug, index) => {
                        return (
                            <div key={`ldd-tbl=p${index}`} className='dp-tb-pharmacy flex-row'>
                                {drug.pharmacies.map((pharma, index) => {
                                    let style = pharma.toLowerCase() === pharmacySearch.toLowerCase() ? {color: "blue"} : undefined
                                    return <p key={`ldd-tbl-pharma${index}`} style={style} className='dp-tb-pharmacies-txt'>{pharma}</p>
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </div>
  )
}

export default DataPage