import React from 'react'
import carotIcon from "../../../../icons/down-carot.svg";


//tba make dropdown
const MarketShareKey = ({marketShareHeight, isCollapsed, setIsCollapsed}) => {
    return (
        <div style={{height: (marketShareHeight)}} id="market-share-key" className='dark-border'>
            <div className='flex-row'>
                <h4 className='table-key-text'>Market Share </h4>
                <span className={'m-share-key-btn'} onClick={() => setIsCollapsed(!isCollapsed)}><img className={isCollapsed ? "carot-icon" : "carot-icon mskbflipped"} src={carotIcon} alt="expand" /></span>
            </div>
        </div>
    )
}

const TableKeys = ({values, marketShare, marketShareHeight, isCollapsed, setIsCollapsed, handlePreLaunch, totalsView, forecastType}) => {
    let mshareIndex = forecastType === "drug" ? 3 : 6;
    return (
    <div className='table-keys-div flex-col'>
        {totalsView ?
            <div id="no-bg" className='table-key-basic border-bot'></div>
            :
            <div id="pre-launch-div" className='table-key-basic dark-border flex-row alignCenter'>
                    <h4 className='table-key-text'>Pre launch</h4>
                    <h4 onClick={() => handlePreLaunch("plus")} id="pre-launch-plus">+</h4>
                    <h4 onClick={() => handlePreLaunch("minus")} id="pre-launch-minus">-</h4>
            </div>
        }
        {values.map((val, index) => {
            if (marketShare && index === mshareIndex) {
                return (
                    <React.Fragment key={`marketshare-tbl${index}`}>
                    <MarketShareKey marketShareHeight={marketShareHeight} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}/>
                    <div className='table-key-basic dark-border border-basic'>
                        <h4 className='table-key-text'>{val}</h4>
                    </div>
                    </React.Fragment>
                )
            } else {
                return (
                    <div key={`table-key${val}${index}`}className='table-key-basic dark-border border-basic'>
                        <h4 className='table-key-text'>{val}</h4>
                    </div>
                )
            }
        })}
    </div>
  )
}

export default TableKeys