import React, { useEffect } from 'react'
import { useState } from 'react';
import '../../../css/InputSidebar.css';

import InputsDrugInformation from './subsections/InputsDrugInformation';
import InputsQuantitative from './subsections/InputsQuantitative';
import InputsNotetaking from './subsections/InputsNotetaking';

const InputSidebar = ({approvalType, peakSales, setApprovalType, drugType, therapeuticArea, setDrugType, setTherapeuticArea, setGeographies,  peakPenetration, setPeakPenetration, drugPrice, setDrugPrice, prevalenceTam, setPrevalenceTam, phase, setPhase, timeToLaunch, setTimeToLaunch, adcomm, setAdcomm, biologicalRationaleScore, setBiologicalRationaleScore, observedTreatmentEffect, setObservedTreatmentEffect, }) => {
  
  const [displayState, setDisplayState] = useState("default");
  const [engagedSection, setEngagedSection] = useState("general");

  return ( 
    <div className='InputSidebar-main flex-col'>
        <div className='fixed-sidebar-items'>
            <div className='Bancroft-title'>
                <div className='banc-title-sub'>
                    <h1 id='Bancroft-title'>bancroft</h1>
                </div>
            </div>
        </div>

        <div className='inputs-container max-window flex-col'>
                <React.Fragment>
                    <div className='input-section flex-col qa-app-div'>
                        <p id="label-drugname">Approval Type</p>
                        <select className='text-input-select' onChange={(e) => setApprovalType(e.target.value === 'true')} value={approvalType}>
                            <option value='true'>Approved</option>
                            <option value='false'>Not Approved</option>
                        </select>
                    </div>
                    {approvalType === false && <InputsDrugInformation drugType={drugType} therapeuticArea={therapeuticArea} setDrugType={setDrugType} setTherapeuticArea={setTherapeuticArea} setGeographies={setGeographies} engagedSection={engagedSection} setEngagedSection={setEngagedSection}/>}
                    
                    <InputsQuantitative peakSales={peakSales} engagedSection={engagedSection} setEngagedSection={setEngagedSection} approvalType={approvalType} drugPrice={drugPrice} setDrugPrice={setDrugPrice} peakPenetration={peakPenetration} setPeakPenetration={setPeakPenetration} prevalenceTam={prevalenceTam} setPrevalenceTam={setPrevalenceTam} adcomm={adcomm} setAdcomm={setAdcomm} observedTreatmentEffect={observedTreatmentEffect} setObservedTreatmentEffect={setObservedTreatmentEffect} biologicalRationaleScore={biologicalRationaleScore} setBiologicalRationaleScore={setBiologicalRationaleScore} timeToLaunch={timeToLaunch} setTimeToLaunch={setTimeToLaunch} phase={phase} setPhase={setPhase} />
                </React.Fragment>
            <div className='bottom-sidebar'>
                <br></br>
                <p className='copyright'>&copy; BANCROFT SOFTWARE LLC 2024</p>
            </div>
        </div>
    
    </div>
  )
}

export default InputSidebar