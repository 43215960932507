import './css/App.css';
import './css/helpers.css'

import React, {useState, useEffect, useRef, useCallback} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useNavigate,
} from "react-router-dom";

import axios from 'axios';
import debounce from 'lodash.debounce';


import LandingRoute from './routes/LandingRoute';
import ForecastRoute from './routes/ForecastRoute';
import SummaryRoute from './routes/SummaryRoute';
import CsvRoute from './routes/CsvRoute';
import DataRoute from './routes/DataRoute';
import CompanyData from './routes/CompanyDataRoute';
import LoginRoute from './routes/LoginRoute';
import UserRoute from './routes/UserRoute';
import ChatRoute from './routes/ChatRoute';

//import QuickAnalysisRoute from './routes/QuickAnalysisRoute';
import ShareRoute from './routes/ShareRoute';
import PrevalenceRoute from './routes/PrevalenceRoute';
import QuickAnalysisRoute from './routes/QuickAnalysisRoute';


function App() {

    const [activeUser, setActiveUser] = useState(null)
    const [userSettings, setUserSettings] = useState({multGeoVal: false, delGeoVal: false})
    const [notes, setNotes] = useState([]);
    const [notepadPresets, setNotepadPresets] = useState({lastNote: 0, position: null, dimensions: null})

    const [visitShareLink, setVisitShareLink] = useState(false);
    const [isSignup, setIsSignup] = useState(false);
    const navigate = useNavigate();


    const notepadRef = useRef();


    // useEffect(() => {
    //     console.log("App re-rendered");
    //   });

    // useEffect(() => {
    //     console.log(localStorage, activeUser)
    // }, [activeUser])

    const notesRef = useRef(notes);

    useEffect(() => {
        notesRef.current = notes;
    }, [notes]);

    useEffect(() => {
        const token = localStorage.getItem("jwtToken");
        
        if (token) {
            // Fetch user info
            axios.get("https://bancy-backend-01dc0125253f.herokuapp.com/user", { 
            // axios.get("http://localhost:8000/user", { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                setActiveUser(response.data.user);
            })
            .catch(err => {
                console.log(err);
            });
            axios.get("https://bancy-backend-01dc0125253f.herokuapp.com/notes", { 
            // axios.get("http://localhost:8000/user", { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                setNotes(response.data.notes);
            })
            .catch(err => {
                console.log(err);
            });
        } else {
        }
    }, []);

    const debouncedSave = useCallback(
        debounce(() => {
          sendNotesToDB();
        }, 1000),
        []
      );
    
      useEffect(() => {
        // Call debounced save whenever notes change
        debouncedSave();
      }, [notes, debouncedSave]); 

    const sendNotesToDB = () => {
        const token = localStorage.getItem("jwtToken");
        
        if (token) {
            const data = { notes: notesRef.current }
            axios.put("https://bancy-backend-01dc0125253f.herokuapp.com/notes", data, { 
            // axios.get("http://localhost:8000/user", { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                // console.log(response.data.result)
                // setNotes(response.data.result);
            })
            .catch(err => {
                console.log(err);
            });
        } else {
        }
    }

    const addNoteToDB = (note) => {
        const token = localStorage.getItem("jwtToken");
        
        if (token) {
            const data = { note }
            axios.post("https://bancy-backend-01dc0125253f.herokuapp.com/notes", data, { 
            // axios.get("http://localhost:8000/user", { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                setNotes(prev => {
                    let workingNote = prev.findIndex(elem => {
                        if (!elem.tempId) return false;
                        return elem.tempId === note.tempId;
                    });
                    if (workingNote !== -1) {
                        prev[workingNote] = response.data.savedNote;
                        delete prev[workingNote].tempId
                    }
                    return prev;
                });
            })
            .catch(err => {
                console.log(err);
            });
        } else {
            navigate("/login")
        }
    }

    const delNoteFromDB = (noteId) => {
        const token = localStorage.getItem("jwtToken");
        if (token) {
            axios.delete(`https://bancy-backend-01dc0125253f.herokuapp.com/notes?noteId=${noteId}`, { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                setNotes(prev => {
                    let workingNote = prev.findIndex(elem => elem._id === noteId);
                    if (workingNote !== -1) {
                        let newArray = [...prev];
                        newArray.splice(workingNote, 1);
                        return newArray;
                    }
                    return prev;
                });
            })
            .catch(err => {
                console.log(err);
            });
        } else {
            navigate("/login");
        }
    }
    


  return (
    <div className="App">
        <Routes>
          <Route
            path="/"
            exact
            element={<LandingRoute activeUser={activeUser} setIsSignup={setIsSignup}/>}
            />
          <Route
            path="/login"
            element={<LoginRoute setActiveUser={setActiveUser} activeUser={activeUser} isSignup={isSignup} visitShareLink={visitShareLink} />}
          />
          <Route
            path="/login/:recoveryID"
            element={<LoginRoute setIsSignup={setIsSignup} recoveryStart={true} setActiveUser={setActiveUser} activeUser={activeUser} isSignup={isSignup} visitShareLink={visitShareLink} />}
          />
          <Route
            path="/user"
            element={<UserRoute setActiveUser={setActiveUser} activeUser={activeUser} />}
          />
          <Route
            path="/forecast"
            element={<ForecastRoute addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} activeUser={activeUser} notepadRef={notepadRef} userSettings={userSettings} setUserSettings={setUserSettings} notes={notes} setNotes={setNotes} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets} />}
          />
          <Route
            path="/forecast/:projectID"
            element={<ForecastRoute quickStart={true} addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} activeUser={activeUser} notepadRef={notepadRef} userSettings={userSettings} setUserSettings={setUserSettings} notes={notes} setNotes={setNotes} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets} />}
          />
          <Route
            path="/summary"
            element={<SummaryRoute addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} activeUser={activeUser} notepadRef={notepadRef} userSettings={userSettings} setUserSettings={setUserSettings} notes={notes} setNotes={setNotes} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets}/>}
          />
          <Route
            path="/csvbuttcheeks"
            element={<CsvRoute />}
          />
          <Route
            path="/data"
            element={<DataRoute activeUser={activeUser} />}
          />
          <Route
            path="/companydata"
            element={<CompanyData />}
          />
          <Route
            path="/chat"
            element={<ChatRoute />}
          />
          <Route
            path="/quickanalysis"
            element={<QuickAnalysisRoute />}
            />
          <Route
            path="/share/:parameter"
            element={<ShareRoute activeUser={activeUser} setVisitShareLink={setVisitShareLink}/>}
          />
          <Route
            path="/prevalence"
            element={<PrevalenceRoute addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} notepadRef={notepadRef} notes={notes} setNotes={setNotes} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets}/>}
          />
        </Routes>
    </div>
  );
}

const AppWrapper = () => {
    return (
        <Router>
            <App />
        </Router>
    )
}

export {App, AppWrapper};
