import React, {useState, useEffect} from 'react'

import "../../css/InputSidebar.css";
import CollapsingContainer from '../util/CollapsingContainer';

import caretIcon from "../../icons/down-carot.svg"
import folderIcon from "../../icons/bancroft_icons_folder.svg";
import openFolderIcon from "../../icons/bancroft_icons_open-folder.svg";
import deletedFolderIcon from "../../icons/bancroft_icons-delete_folder.svg";
import addFolderIcon from "../../icons/bancroft_icons-add_folder.svg";
import sortIcon from "../../icons/bancroft_icons_sort.svg"
import documentIcon from "../../icons/bancroft_icons-document.svg";

import { deepClone } from '../../functions/helpers/generalHelpers';
import { v4 as uuidv4 } from 'uuid';


const SummarySidebar = ({saveOrUpdateSummaries, setActiveSummary, summariesState, setSummariesState, setActiveFolder}) => {

  const [engaged, setEngaged] = useState(false);

  const handleNewSummary = () => {
    setActiveSummary("new");
  }

  return (
    <div className='summary-sidebar'>
        <div className='Bancroft-title'>
            <div className='banc-title-sub'>
                <h1 id='Bancroft-title'>bancroft</h1>
            </div>
        </div>
        <div className='sum-sb-new'>
            <h5 className='input-header'>Take your articles or documents and summarize them into a brief and articulate file.</h5>
            <button className='input-action-btn' onClick={handleNewSummary}>NEW SUMMARY</button>
        </div>

        <YourSummaries saveOrUpdateSummaries={saveOrUpdateSummaries} engaged={engaged} setEngaged={setEngaged} summariesState={summariesState} setSummariesState={setSummariesState} setActiveFolder={setActiveFolder} setActiveSummary={setActiveSummary} />



        {/* <div className='bottom-sidebar'>
            <p className='copyright'>&copy; BLACKSHEEP 2023</p>
        </div> */}
    </div>
  )
}

const YourSummaries = ({saveOrUpdateSummaries, engaged, setEngaged, summariesState, setSummariesState, setActiveSummary, setActiveFolder}) => {
    // true === newest
    const [sortState, setSortState] = useState(true);
    const [searchState, setSearchState] = useState("");
    const [shownSummaries, setShownSummaries] = useState(summariesState);
    const [engagedSection, setEngagedSection] = useState("inactive");
    const [newFolderName, setNewFolderName] = useState("");


    useEffect(() => {
        setShownSummaries([...summariesState]);
        setSearchState("");
    }, [summariesState])

    const handleSearchChange = (e) => {
        let searchValue = e.target.value;
        setSearchState(searchValue);
        sortAndSearch(searchValue);
    }

    const sortAndSearch = (searchValue) => {
        if (searchValue === "" || searchValue === undefined) {
            setShownSummaries([...summariesState]);
        } else {
            let matches = [];
            for (let i = 0; i < summariesState.length; i++) {
                if (summariesState[i].title.includes(searchValue)) {
                    matches.push(summariesState[i]);
                }
                summariesState[i].documents.forEach(doc => {
                    if (doc.title.includes(searchValue)) {
                        matches.push(doc);
                    }
                })
            }
            setShownSummaries(prev => {
                return [...matches];
            })
            handleSortChange(true, [...matches]);
        }
    }

    const handleSortChange = (internal, list) => {

        let temp = !sortState;
        let newState;
        if (!internal) {
            temp = !temp;
            setSortState(!sortState);
            newState = deepClone(shownSummaries);
        } else {
            newState = deepClone(list);
        }
        
        newState.forEach(item => {
            if (item.documents && item.documents.length > 0) {
                let expectation = item.documents[0].timeStamp;
                for (let i = 1; i < item.documents.length; i++) {
                    if (!temp) {
                        if (item.documents[i].timeStamp < expectation) {
                            expectation = item.documents[i].timeStamp
                        }
                    } else {
                        if (item.documents[i].timeStamp > expectation) {
                            expectation = item.documents[i].timeStamp
                        }
                    }
                }
                item.comparison = expectation;
            } else {
                item.comparison = item.timeStamp;
            }
        })

        newState.sort((a,b) => {
            if (temp) {
                return a.comparison - b.comparison;
            } else {
                return b.comparison - a.comparison;
            }
        })

        newState.forEach(item => {
            if (item.comparison) {
                delete item.comparison;
            }
        })

        setShownSummaries([...newState])
    }

    const addNewFolder = () => {
        const newTitle = newFolderName ? newFolderName : getNewFolderTitle();
        let newFolder = {
            title: newTitle,
            documents: [],
            timeStamp: Date.now(),
            tempId: uuidv4(),
        }
        setSummariesState(prev => {
            return [newFolder, ...prev];
        })
        setNewFolderName("");
        saveOrUpdateSummaries("new", newFolder)
    }

    const getNewFolderTitle = () => {
        let flag = false;
        let count = 1;
        let folderName;
        while (!flag) {
            let attempt = `Folder0${summariesState.length + count}`;
            if (summariesState.findIndex(item => item.title === attempt) === -1) {
                flag = true;
                folderName = attempt
            }
            count++;
        }
        return folderName;
    }

    const yourSummariesData = (
        <div className='input-section'>
            <div className='input-and-label-standard'>

                {/* search and sort */} 
                <div className='pad-horz-20'>
                    <h5 className='sum-sb-h5'>Search previous summaries</h5>
                    <div className='flex-row sum-sb-search-div'>
                        <input id="sum-sb-search" className='text-input-standard text-left' onChange={handleSearchChange} value={searchState}></input>
                        {/* sort button */}
                        <div onClick={() => handleSortChange()} className='sum-sb-sort flex-row'>
                            <img className='sort-icon' alt="show-file" src={sortIcon}></img>
                            <p className='sort-txt'>{sortState ? "new" : "old"}</p>
                        </div>
                    </div>
                </div>

                {/* Your summaries */}

                <FileItemSidebarContainer shownSummaries={shownSummaries} setActiveSummary={setActiveSummary} setActiveFolder={setActiveFolder}/>

                {/* New folder */}

                <div className='sum-hr'></div>

                <div className='flex-col'>
                    <h6 className='sum-sb-new-folder-name'>Folder Name</h6>
                    <input id="sum-sb-search-x" className='text-input-standard' onChange={(e) => setNewFolderName(e.target.value)} value={newFolderName}></input>
                </div>
                <div onClick={addNewFolder} className='new-folder-btn flex-row sum-sb-pad'>
                    <img id="sum-add-color" className='folder-icon' alt="show-file" src={addFolderIcon}></img>
                    <h5 id="sum-add-txt" className='sum-sb-file-txt'>Create New Folder</h5>
                </div>

            </div>
        </div>
    )
    

    return (
        <CollapsingContainer displayed={engaged} containerName={"Your Summaries"} data={yourSummariesData} engagedSection={engagedSection} setEngagedSection={setEngagedSection} />
    )
}

const FileItemSidebarContainer = ({shownSummaries, setActiveFolder, setActiveSummary}) => {
    const [localSummaries, setLocalSummaries] = useState([...shownSummaries]);
    useEffect(() => {
        setLocalSummaries(prev => {
            const copy = [...shownSummaries];
            copy.sort((a,b) => {
                const aIsFolder = "documents" in a;
                const bIsFolder = "documents" in b;

                if (aIsFolder && !bIsFolder) {
                  return 1; // a comes after b
                }
                if (!aIsFolder && bIsFolder) {
                  return -1; // a comes before b
                }

                return 0; // no change in order
            })
            return [...copy];
        })
    }, [shownSummaries])

    return (
        <React.Fragment>
            {localSummaries.map((summary, index) => {
                let type = summary.documents ? "folder" : "document";
                return <FileItemSidebar key={`summary-sb-${index}`} type={type} item={summary} setActiveSummary={setActiveSummary} setActiveFolder={setActiveFolder} />
            })}
        </React.Fragment>
    )
}

const FileItemSidebar = ({type, item, setActiveSummary, setActiveFolder}) => {

    const [hover, setHover] = useState(false);

    const handleClick = () => {
        if (type === "document") {
            setActiveSummary(item);
        } else {
            setActiveFolder(item);
            setActiveSummary(false);
        }
    }

    let fileIcon;
    if (type === "document") {
        fileIcon = hover ? <img id="sum-icon-hover" className='doc-icon' alt="show-file" src={documentIcon}></img> : <img className='doc-icon' alt="show-file" src={documentIcon}></img>;
    } else {
        fileIcon = hover ? <img id="sum-icon-hover" className='open-folder-icon' alt="show-file" src={openFolderIcon}></img> : <img className='folder-icon' alt="show-file" src={folderIcon}></img>;
    }

    return (
        <div onClick={handleClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className='file-sum-sb flex-row sum-sb-pad'>
            {fileIcon}
            <h4 className={hover ? 'sum-hover-color sum-sb-file-txt' : 'sum-sb-file-txt'}>{item.title}</h4>
            {hover && <img className='sum-sb-caret' alt="show-file" src={caretIcon}></img>}
        </div>
    )
}

export default SummarySidebar