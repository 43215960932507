import React from 'react'

const WarningPopupSmall = ({message}) => {
  return (
    <div className='warning-popup-small-div'>
        <p className='warning-popup-small-text'>{message}</p>
    </div>
  )
}

export default WarningPopupSmall