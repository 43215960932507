import React, {useState, useEffect, useRef} from 'react'
import { Link, useNavigate } from "react-router-dom";

import InputSidebar from './sidebar/InputSidebar';
import GraphContainer from './graph/GraphContainer';
import TableContainer from './table/TableContainer';
import Notepad from '../notepad/Notepad';

import { allValidValues, roundToTwoDecimals } from '../../functions/helpers/generalHelpers';
import { generateData } from '../../functions/helpers/dataGeneration';

import { INITIAL_DATA_OBJ, INITIAL_FORECAST, INITIAL_FINANCE_OBJ } from '../../initials';

import getPatientsPerGeo from '../../functions/helpers/getPatientsPerGeo';
import settingIcon from "../../icons/bancy-settings.svg";
import userIcon from "../../icons/bancy-user.svg";
import caretIcon from "../../icons/bancy-caret2.svg";
import notepadIcon from "../../icons/bancroft_top_note_icon.svg";
import financeIcon from "../../icons/bancy-finance.svg";
import exportIcon from "../../icons/bancroft_download_note_icon.svg";
import ExportComponent from './extras/ExportComponent';
import FinanceComponent from './extras/FinanceComponent';
import ShareComponent from './extras/ShareComponent';

import { getPreLaunchPeriod, getDatePeriods } from '../../functions/helpers/dateHandling';
import Scenarios from './sidebar/subsections/Scenarios';

const ForecastPage = ({quickStart, quickStartID, workingProjects, setWorkingProjects, saveOrUpdateForecasts, addNoteToDB, delNoteFromDB, userForecasts, setUserForecasts, activeUser, notepadRef, userSettings, setUserSettings, notes, setNotes, notepadPresets, setNotepadPresets}) => {
    const [workingForecast, setWorkingForecast] = useState({...INITIAL_FORECAST});
    const [scenarioIndex, setScenarioIndex] = useState(0);
    const [workingGeographies, setWorkingGeographies] = useState(["United States"])
    const [tableDataArray, setTableDataArray] = useState([]);
    const [graphDataArray, setGraphDataArray] = useState([]);
    const [currGeographyIndex, setCurrGeographyIndex] = useState(0);
    const [sidebarSize, setSidebarSize] = useState("shown");
    const [showNotepad, setShowNotepad] = useState(false);
    const [showFinance, setShowFinance] = useState(false);
    const [financeData, setFinanceData] = useState({...INITIAL_FINANCE_OBJ})
    const [showExport, setShowExport] = useState(false);
    const [showScenarios, setShowScenarios] = useState(false);
    const [showShare, setShowShare] = useState(false)


    const initialCallRef = useRef(true);
    // const notepadRef = useRef();
    /// for debugging
    const navigate = useNavigate();

    useEffect(() => {
        if (quickStart) {
            let quickStartProject = userForecasts.filter(item => {
                return item._id === quickStartID
            });
            if (quickStartProject.length >= 1) {
                quickStartProject = quickStartProject[0];
                setWorkingProjects(quickStartProject)
                setWorkingForecast(quickStartProject.scenarios[0].forecast)
                setWorkingGeographies([quickStartProject.scenarios[0].forecast.geographies[0]])
            }
        }
    }, [])

  
  
    // useEffect(() => {
    //   console.log( "BIG DEBUG ENERGY", workingForecast, tableDataArray, graphDataArray)
    // }, [currGeographyIndex, graphDataArray, tableDataArray, workingForecast])
  
    useEffect(() => {
      if (workingForecast.geographies.length === 0) {
          setGraphDataArray([]);
          setTableDataArray([]);
      }
    }, [workingForecast.geographies])
  
    useEffect(() => {
        if (workingProjects && workingProjects.scenarios) {
            setWorkingProjects(prev => {
                const copy = {...prev};
                copy.scenarios[scenarioIndex].forecast = {...workingForecast}
                return copy;
            })
        }
      const forecastData = workingForecast.geographies.map(geo => {
          const perGeography = {
              type: workingForecast.type,
              forecastName: workingForecast.name,
              startDate: workingForecast.geographiesData[geo].general.startDate,
              length: workingForecast.geographiesData[geo].general.length,
              // initialPatients: workingForecast.sales.population,
              // not used for now
              // birthsPerYear: workingForecast.sales.birthsPerYear,
              peakPenetration: workingForecast.type === "drug" ? workingForecast.geographiesData[geo].general.penetration : {incident: workingForecast.geographiesData[geo].general.incidentPenetration, prevalent:workingForecast.geographiesData[geo].general.prevalentPenetration },
              timeToPeakPenetration: workingForecast.geographiesData[geo].general.yearsToPeakPenetration,
              periodFormat: workingForecast.geographiesData[geo].general.period,
              price: workingForecast.geographiesData[geo].general.price,
              cogs: workingForecast.geographiesData[geo].drug.cogs,
              slope: workingForecast.geographiesData[geo].general.slope,
          }
          const forecastExtras = {
              preLOEPriceChange: workingForecast.geographiesData[geo].drug.preLOE,
              postLOEPriceChange: workingForecast.geographiesData[geo].drug.postLOE,
              atLOEPriceChange: workingForecast.geographiesData[geo].drug.LOE,
              dateOfLOE: workingForecast.geographiesData[geo].drug.dateOfLOE,
          }
  
          const forecastCompetitors = [];
          //shorthand
          const sh = workingForecast.geographiesData[geo].competition;
          if (sh) {
              for (let i = 0; i < sh.length; i++) {
                  forecastCompetitors.push({
                      /// add more here later
                      competitorName: sh[i].name ? sh[i].name : `competitor${Math.ceil(Math.random() * 100)}`,
                      competitorApprovalStatus: sh[i].approval,
                      competitorApprovalDate: sh[i].approvalDate,
                      competitorDrugDuration: sh[i].duration,
                      competitorTimeToPeak: sh[i].yearsToPeakPenetration,
                  })
              }
          }
          return {perGeography: perGeography, forecastExtras: forecastExtras, forecastCompetitors: forecastCompetitors, geoName: geo}
        })
  
        let bigDataPool = [];
        forecastData.forEach((forecast, index) => {
            let geo = getPatientsPerGeo(workingForecast.geographies, index, workingForecast.geographiesData);
            let flag = allValidValues(forecast.perGeography);
            if (workingForecast.geographiesData[forecast.geoName].general.geographiesPatients) {
                if (Object.keys(workingForecast.geographiesData[forecast.geoName].general.geographiesPatients).length === 0) flag = false
            } else {
                flag = false;
            }
            if (flag) {
              bigDataPool.push({geography: forecast.geoName, data: generateData(forecast.perGeography, forecast.forecastExtras, forecast.forecastCompetitors, geo.patients)});
            }
        })
        if (bigDataPool.length > 0) {
            if (!workingForecast.isExample && !initialCallRef.current) {
                saveOrUpdateForecasts("update", workingProjects);
            }
            let newDataState = bigDataPool;
            setTableDataArray(newDataState);
            let newGraphData = [];
            let newFinanceData = {};
            for (let c = 0; c < newDataState.length; c++) {
                let working = newDataState[c];
                const useThis = workingForecast.geographiesData[working.geography];
                let adjustedPeriods = getDatePeriods(working.data.main[0][0], useThis.general.period, (useThis.general.length + 1));
                let thisSales = working.data.main.map((item, index) => {
                    // need to rework some stuff because this is ugly
                    let expenses = Math.abs(useThis.expenses[index]) || 0;
                    if (item.length === 9) {
                        return (
                            {
                                period: adjustedPeriods[index + 1],
                                Sales: item[6],
                                COGS: item[7],
                                Expenses: expenses,
                                "Gross Sales": item[8] - expenses,
                                LOEEvent: working.data.events[index].LOEEvent,
                                newComp: working.data.events[index].newComp,
                                tooltipLabel: `${adjustedPeriods[index]} - ${adjustedPeriods[index + 1]}`
                            }
                        )
                    } else {
                        return (
                            {
                                period: adjustedPeriods[index + 1],
                                Sales: item[5],
                                COGS: item[6],
                                Expenses: expenses,
                                "Gross Sales": item[7] - expenses,
                                LOEEvent: working.data.events[index].LOEEvent,
                                newComp: working.data.events[index].newComp,
                                tooltipLabel: `${adjustedPeriods[index]} - ${adjustedPeriods[index + 1]}`
                            }
                        )
                    }
                })
                const localSh = workingForecast.geographiesData[working.geography];

                let thisPopulation = [];
                for (let index = 0; index < working.data.main.length; index++) {
                    let item = working.data.main[index];
                    if (index === 0) {
                        const priorPopPeriod = getPreLaunchPeriod({modelPeriod: localSh.general.period, modelStart: localSh.general.startDate, index: 0,})
                        if (item.length === 9) {
                            let competitorsGraphData = {};
                            item[4].forEach((competitorItem, innerIndex) => {
                              if (innerIndex !== item[4].length - 1) {
                                  competitorsGraphData[competitorItem.name] = competitorItem.patientsOnDrug === 0 ? null : 0;
                              }
                            })
                            thisPopulation.push (  
                                {
                                    period: adjustedPeriods[index],
                                    penetration: 0,
                                    ...competitorsGraphData,
                                    tooltipLabel: `${priorPopPeriod} - ${adjustedPeriods[index]}`
                                }
                            )
                        } else {
                            thisPopulation.push (  
                                {
                                    period: adjustedPeriods[index],
                                    penetration: 0,
                                    "Patients On Drug": 0,
                                    tooltipLabel: `${priorPopPeriod} - ${adjustedPeriods[index]}`
                                }
                            )
                        }
                    }
                    if (item.length === 9) {
                        let competitorsGraphData = {};
                        item[4].forEach((competitorItem, innerIndex) => {
                          if (innerIndex !== item[4].length - 1) {
                              competitorsGraphData[competitorItem.name] = competitorItem.patientsOnDrug === 0 ? null : competitorItem.patientsOnDrug;
                              if (competitorItem.patientsOnDrug !== 0 && index !== 0) {
                                if (thisPopulation[index][competitorItem.name] === null) {
                                    thisPopulation[index][competitorItem.name] = 0;
                                }
                              }
                              if ("genePatientsSplit" in competitorItem) {
                                competitorsGraphData["genePatientsSplit"] = competitorItem.genePatientsSplit;
                              }
                          }
                        })
                        thisPopulation.push (  
                            {
                                period: adjustedPeriods[index + 1],
                                penetration: item[2],
                                ...competitorsGraphData,
                                peakPen: working.data.events[index].peakPen,
                                tooltipLabel: `${adjustedPeriods[index]} - ${adjustedPeriods[index + 1]}`
                            }
                        )
                    } else {
                        thisPopulation.push (  
                            {
                                period: adjustedPeriods[index + 1],
                                penetration: item[2],
                                "Patients On Drug": item[3],
                                peakPen: working.data.events[index].peakPen,
                                tooltipLabel: `${adjustedPeriods[index]} - ${adjustedPeriods[index + 1]}`
                            }
                        )
                    }
                }

                let preLaunchForFinance = workingForecast.geographiesData[working.geography].preLaunch.reverse().map(period => period.expenses * -1);
                newFinanceData[working.geography] = [...preLaunchForFinance,...thisSales.map(salesIt => salesIt["Gross Sales"])];
                let preLaunchSales = [];
                for (let m = 0; m < localSh.preLaunch.length; m++) {
                    let expenses = localSh.preLaunch[m].expenses;
                    const modelData = {
                        modelPeriod: localSh.general.period,
                        modelStart: localSh.general.startDate,
                        index: m,
                    }
                    const modelDataPrior = {
                        modelPeriod: localSh.general.period,
                        modelStart: localSh.general.startDate,
                        index: m + 1,
                    }
                    const thisModelPeriod = getPreLaunchPeriod(modelData)
                    if (m === 0) {
                        preLaunchSales.push({
                            Expenses: expenses,
                            period: thisModelPeriod,
                            Sales: 0,
                            COGS: 0,
                            "Gross Sales": 0 - expenses,
                            tooltipLabel: `${getPreLaunchPeriod(modelDataPrior)} - ${thisModelPeriod}`
                        });
                    }
                    else {
                        preLaunchSales.push({
                            Expenses: expenses,
                            period: thisModelPeriod,
                            tooltipLabel: `${getPreLaunchPeriod(modelDataPrior)} - ${thisModelPeriod}`
                        });
                    }
                        if (m === localSh.preLaunch.length - 1) { 
                            preLaunchSales.push( {
                                Expenses: 0,
                                period: getPreLaunchPeriod(modelDataPrior),
                                tooltipLabel: `${getPreLaunchPeriod({modelPeriod: localSh.general.period, modelStart: localSh.general.startDate,index: m + 2,})} - ${getPreLaunchPeriod(modelDataPrior)}`
                            });
                        }
                }
                preLaunchSales.reverse();
                const firstPeriodLabel = getPreLaunchPeriod({modelPeriod: localSh.general.period, modelStart: localSh.general.startDate, index: 0})
                const firstPeriod = {
                    period: adjustedPeriods[0],
                    Sales: 0,
                    COGS: 0,
                    Expenses: 0,
                    "Gross Sales": 0,
                    tooltipLabel: `${firstPeriodLabel} - ${adjustedPeriods[0]}`
                }
                if (preLaunchSales.length === 0) preLaunchSales.push(firstPeriod);
                const thisSalesWithPreLaunch = [...preLaunchSales, ...thisSales];

                newGraphData.push({geography: working.geography, sales: thisSalesWithPreLaunch, population: thisPopulation})
            }
            setFinanceData(prev => ({...prev, cashFlows: newFinanceData}))
            setGraphDataArray(newGraphData)   
         }
         if (initialCallRef.current) {
            initialCallRef.current = false;
        }
    },[workingForecast, saveOrUpdateForecasts])
  
    const closeNotepad = () => {
      if (!showNotepad) {
          setShowNotepad(true);
      } else {
          if (notepadRef.current) {
              notepadRef.current.handleCloseNotepad();
          }
      }
    }
  
    const changeUserSettings = (type) => {
      if (type === "remove-geo") {
          setUserSettings({...userSettings, delGeoVal: !userSettings.delGeoVal})
      } else if (type === "multiple-geo") {
          setUserSettings({...userSettings, multGeoVal: !userSettings.multGeoVal})
      }
    }

    const handlePreLaunch = (type) => {
        if (type === "plus") {
            setWorkingForecast(prev => {
                const copy = {...prev};
                copy.geographiesData[copy.geographies[currGeographyIndex]].preLaunch = [...copy.geographiesData[copy.geographies[currGeographyIndex]].preLaunch, {expenses: 0}];
                return {...copy};
            })
        } else if (workingForecast.geographiesData[workingForecast.geographies[currGeographyIndex]].preLaunch.length > 0) {
            setWorkingForecast(prev => {
                const copy = {...prev};
                copy.geographiesData[copy.geographies[currGeographyIndex]].preLaunch.pop();
                return {...copy};
            })
        }
    }
    const handleScenarioIndexChange = (e) => {
        let val = parseInt(e.target.value)
        setScenarioIndex(val);
        setWorkingForecast({...workingProjects.scenarios[val].forecast})
        setTableDataArray([]);
        setGraphDataArray([]);
    }

    const toggleScenarios = () => {
        setShowScenarios(!showScenarios)
    }

  return (
    <div className={sidebarSize === "shown" ? 'master-container' : "master-container-x"}>
            <InputSidebar quickStart={quickStart} setWorkingProjects={setWorkingProjects} workingProjects={workingProjects} saveOrUpdateForecasts={saveOrUpdateForecasts} setUserForecasts={setUserForecasts} userForecasts={userForecasts} userSettings={userSettings} changeUserSettings={changeUserSettings} currGeographyIndex={currGeographyIndex} workingGeographies={workingGeographies} setWorkingGeographies={setWorkingGeographies} sidebarSize={sidebarSize} setSidebarSize={setSidebarSize} setCurrGeographyIndex={setCurrGeographyIndex} workingForecast={workingForecast} setWorkingForecast={setWorkingForecast} />
            <div className='content-container flex-col'>
                <div className='navBar-main'>
                    {workingForecast.name.length > 0 && (
                            <React.Fragment>

                            {sidebarSize === "hidden" ? (
                                <div className='flex-row alignCenter'>
                                    <img onClick={() => setSidebarSize("shown")} className='open-sb-icon' src={caretIcon} alt={"close-sidebar"}></img>
                                    <div className='flex-col'>
                                    <h1 className='nav-forecast-title'>Forecast: <span className='nav-forecast-title-name'>{workingForecast.name}</span></h1>
                                    <div className='flex-row alignCenter scenarios-top-div'>
                                        <h5 className='scenarios-top-txt'>Scenario:</h5>
                                        <h5 onClick={toggleScenarios} className='scenarios-top-btn'>{workingProjects.scenarios[scenarioIndex].name}</h5>
                                        {showScenarios && <Scenarios scenarioIndex={scenarioIndex}  handleScenarioIndexChange={handleScenarioIndexChange} workingForecast={workingForecast} setWorkingForecast={setWorkingForecast} workingProjects={workingProjects} setWorkingProjects={setWorkingProjects}/>}
                                    </div>
                                </div>
                                </div>
                                )
                                :
                                <div className='flex-col'>
                                    <h1 className='nav-forecast-title'>Forecast: <span className='nav-forecast-title-name'>{workingForecast.name}</span></h1>
                                    <div className='flex-row alignCenter scenarios-top-div'>
                                        <h5 className='scenarios-top-txt'>Scenario:</h5>
                                        <h5 onClick={toggleScenarios} className='scenarios-top-btn'>{workingProjects.scenarios[scenarioIndex].name}</h5>
                                        {showScenarios && <Scenarios scenarioIndex={scenarioIndex} handleScenarioIndexChange={handleScenarioIndexChange} workingForecast={workingForecast} setWorkingForecast={setWorkingForecast} workingProjects={workingProjects} setWorkingProjects={setWorkingProjects}/>}
                                    </div>
                                </div>
                            }
                            </React.Fragment>
                        )
                    }
                    <div className='nav-elems'>
                        
                     
                        {tableDataArray.length > 0 &&
                        <React.Fragment>
                            <div onClick={() => setShowShare(!showShare)} className='nav-elem flex-row'>
                                {/* <img id="export-hp-icon" className="svg-icon" src={exportIcon} alt="notepad" /> */}
                                <h4 className='nav-elem-text'>Share</h4>
                            </div>
                            {showShare && <ShareComponent workingProjects={workingProjects} setShowShare={setShowShare}/>}
                            <div onClick={() => setShowExport(!showExport)} className='nav-elem flex-row'>
                                <img id="export-hp-icon" className="svg-icon" src={exportIcon} alt="notepad" />
                                <h4 className='nav-elem-text'>Export</h4>
                            </div>
                            {showExport && <ExportComponent workingForecast={workingForecast} financeData={financeData} tableDataArray={tableDataArray} graphDataArray={graphDataArray} setShowExport={setShowExport}/>}
                            <div onClick={() => setShowFinance(!showFinance)} className='nav-elem flex-row'>
                                <img id="finance-icon" className="svg-icon" src={financeIcon} alt="finance tools" />
                                <h4 className='nav-elem-text'>Finance</h4>
                            </div>
                            {showFinance && <FinanceComponent setFinanceData={setFinanceData} setShowFinance={setShowFinance} financeData={financeData}/>}
                        </React.Fragment>
                        }
                        <div id="nav-notepad" onClick={closeNotepad} className='nav-elem flex-row'>
                            <img className="svg-icon" src={notepadIcon} alt="notepad" />
                            <h4 className='nav-elem-text'>Notes</h4>
                        </div>
                        <div className='nav-elem flex-row' onClick={()=>navigate("/user")}>
                            <img className="svg-icon" src={userIcon} alt="user" />
                            <h4 className='nav-elem-text'>User Profile</h4>
                        </div>
                        <div className='nav-elem flex-row'>
                            <img className="svg-icon" src={settingIcon} alt="settings" />
                            <h4 className='nav-elem-text'>Settings</h4>
                        </div>
                    </div>
                    {showNotepad && <Notepad ref={notepadRef} addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets} showNotepad={showNotepad} setShowNotepad={setShowNotepad} notes={notes} setNotes={setNotes}/>}
                </div>
                {tableDataArray && tableDataArray.length > 0 && currGeographyIndex !== -1 && tableDataArray[currGeographyIndex] &&
                    <TableContainer workingGeographies={workingGeographies} handlePreLaunch={handlePreLaunch} setCurrGeographyIndex={setCurrGeographyIndex} currGeographyIndex={currGeographyIndex} workingForecast={workingForecast} setWorkingForecast={setWorkingForecast} tableDataArray={tableDataArray} />
                }
                {/* // if we end up populating before sales info this may change */}
                {graphDataArray && graphDataArray.length > 0 && graphDataArray[0].sales && graphDataArray[0].sales.length > 0 && currGeographyIndex !== -1 && graphDataArray[currGeographyIndex] &&
                    <GraphContainer period={workingForecast.geographiesData[workingForecast.geographies[currGeographyIndex]].general.period} workingForecast={workingForecast} setWorkingForecast={setWorkingForecast} graphDataArray={graphDataArray[currGeographyIndex]} />
                }
            </div>
        </div>
  )
}

export default ForecastPage