const INITIAL_FORECAST = {
    type: "drug",
    name: "",
    geographies: ["United States"],
    geographiesData: {
        "United States": {
            preLaunch: [],
            expenses: [],
            general: {
                period: "year",
                startDate: "",
                length: 0,
                diseaseName: "",
                geographiesPatients: {},
                penetration: 0,
                incidentPenetration: 0,
                prevalentPenetration: 0,
                yearsToPeakPenetration: 0,
                slope: "linear",
                price: 0,
            },
            drug: {
                cogs: 25,
                // price: 0,
                preLOE: 0,
                postLOE: 0,
                LOE: 0,
                dateOfLOE: "",
                // tbd
                exclusivity: [],
                //
                route: "oral",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [], 
        }
    }  
}

const INITIAL_DATA_OBJ = {
    type: "drug",
    preLaunch: [],
    expenses: [],
    general: {
        period: "year",
        startDate: "",
        length: 0,
        diseaseName: "",
        geographiesPatients: {},
        penetration: 0,
        incidentPenetration: 0,
        prevalentPenetration: 0,
        yearsToPeakPenetration: 0,
        slope: "linear",
        price: 0,
    },
    drug: {
        cogs: 25,
        // price: 0,
        preLOE: 0,
        postLOE: 0,
        LOE: 0,
        dateOfLOE: "",
        // tbd
        exclusivity: [],
        //
        route: "oral",
        // TBD
        efficacy: 0,
        //
        duration: "chronic",
    },
    competition: [], 
}

const COMPETITION_OBJECT = {
    name: "",
    approval: false, // Approved, not Approved
    approvalDate: "",
    dateOfLOE: "",
    penetration: 0,
    incidentPenetration: 0,
    prevalentPenetration: 0,
    yearsToPeakPenetration: 0,
    duration: "chronic", // Chronic, Acute, etc...
    
    // not in use  
    expectedSales: 0,
    
    // possibly autofill these values? 
    noOfApprovedDrugs: 0,
    noOfPipelineDrugs: 0,
}

const INITIAL_FINANCE_OBJ = {
    NPV: 0,
    IRR: 0,
    initialInvestment: 0,
    //as Percent
    discountRate: 0,
    cashFlows: {},
}

export {COMPETITION_OBJECT, INITIAL_DATA_OBJ, INITIAL_FORECAST, INITIAL_FINANCE_OBJ}