import React, {useEffect, useState} from 'react'
import axios from 'axios'
import SummaryPage from '../components/summary/SummaryPage'

import { v4 as uuidv4 } from 'uuid';


import { useNavigate } from 'react-router-dom'

import "../css/helpers.css"

const tempSummaries = [
    {
        title: "folder1",
        documents: [
            {
                title: "AMO-02 Clinical Report",
                source: `Press Release
                AMO Pharma Announces Affirming Data from REACH-CDM
                Clinical Trial for AMO-02 in Treatment of Myotonic
                Dystrophy
                Sep 6, 2023
                Results to be reviewed during 2023 Myotonic Dystrophy Annual Conference show
                statistically and clinically significant efficacy benefits in multiple areas including cognitive
                performance, reduction in biomarkers and improvements in walk/run measurements.
                AMO-02 was safe and well tolerated during the study with no SAEs related to treatment.
                Primary outcome measure based on clinician administered subjective rating showed an
                unexpected placebo effect that masked beneficial effect of treatment.
                Company planning meetings with regulators to outline path forward in development of
                AMO-02 including plans for study in patients with adult-onset myotonic dystrophy.
                LONDON, Sept. 6, 2023 /PRNewswire/ -- AMO Pharma Limited ("AMO Pharma"), a privately
                held clinical-stage specialty biopharmaceutical company focusing on rare childhoodonset neurogenetic disorders with limited or no treatment options, today announced
                results from the company's REACH-CDM clinical study of the investigational therapy AMO02 in the treatment of children and adolescents with congenital myotonic dystrophy.
                Results will be reviewed on Saturday, September 9 at 3:30 PM ET in a briefing event during
                the 2023 Annual Meeting of the Myotonic Dystrophy Foundation being held at the
                Renaissance Washington DC Downtown Hotel in Washington, DC September 7-9, 2023.
                Topline results from the study based on a clinician administered rating instrument
                intended to canvas the entire clinical phenotype of congenital DM1 showed a positive
                benefit in both the treatment and placebo groups, which may be attributed to difficulties
                in patient monitoring and compliance with reporting protocols caused by the COVID
                pandemic. While the study did not meet the primary endpoint of a statistically significant
                benefit over placebo based on the FDA-authorized physician-completed rating scale,
                clinically and statistically significant benefit was achieved during analysis of a range of
                functional and objective assessments in the treatment group compared to placebo.
                Treatment with AMO-02 was associated with clinically significant improvements in
                cognitive performance (Peabody Picture Vocabulary Test, p<0.05), reduction in a widely
                used biomarker of skeletal and cardiac muscle integrity (creatine phosphokinase, p<0.05)
                and improvement in the 10m walk/run test (p=0.054). A composite statistical analysis of
                outcomes assessing motor skills, muscle strength, cognitive ability, daily living skills and
                biomarker data showed a statistically significant benefit of treatment with AMO-02
                compared to placebo (p<0.05). The benefits seen with AMO-02 were related to
                pharmacokinetic parameters showing that increased plasma levels of AMO-02 resulted in
                greater clinical improvement.
                "We are very encouraged by the consistent benefit shown across multiple clinically
                confirmed measures of efficacy," said Dr. Joe Horrigan, Chief Medical Officer at AMO
                Pharma. "These data reflect a broad profile of benefit in cognitive, motor, muscle, real
                world adaptive behavior and biochemical measures associated with treatment with no
                reported treatment-related serious adverse events."
                Patients enrolled in the REACH-CDM trial were invited to continue treatment in an openlabel extension study (OES) and 98% of patients opted to continue treatment in that study.
                Patients were again given the option of continuing treatment at the conclusion of the 1-
                year OES study and 85% of patients opted to continue treatment.
                "We are immensely grateful to all the families who took part in this study, patient
                advocates in the myotonic dystrophy community, investigators who worked with us to
                face the challenges presented by the COVID pandemic, and our investors for supporting
                this work," said Dr. Ibs Mahmood, Chief Executive Officer at AMO Pharma. "These results
                provide strong further validation of the potential benefits of treatment with AMO-02 in
                multiple key areas that represent the most severe symptoms and disabilities associated
                with DM1. We are now working to discuss next steps with regulators in order to advance
                this programme."
                About AMO-02
                AMO-02 (tideglusib) is in development for the treatment of congenital myotonic
                dystrophy and has potential for use in adult-onset myotonic dystrophy, additional central
                nervous system (CNS), neuromuscular and other orphan indications. AM0-02 is a clinical
                stage investigational medicine for the treatment of the severe form of congenital
                myotonic dystrophy known as CDM1 or Steinert disease. AMO-02 has a dual mechanism
                disrupting the pathogenic RNA repeat in CDM1 and inhibiting excess levels of the kinase
                GSK3β.
                The REACH-CDM pivotal trial was a double-blind, placebo-controlled, randomized study in
                children and adolescents with congenital-onset myotonic dystrophy intended to support
                a future submission for marketing authorization in congenital myotonic dystrophy. The
                study included 56 participants at leading specialist sites in the US, Canada, New Zealand
                and Australia.
                Data associated with functional and objective measures were collected during the study,
                including motor and muscle assessments (10 meter walk/run, myometry, lip strength and
                lean muscle mass as assessed by DXA scan), cognitive performance (Peabody Picture
                Vocabulary Test, NIH Toolbox Picture Sequence Memory Test, NIH Toolbox Dimensional
                Change Card Sort Test), adaptive (real world) function (Vineland Adaptive Behavior
                Communication Scale, Vineland Adaptive Behavior Daily Living Scale, Vineland Adaptive
                Behavior Socialization Scale), and bone density (DXA scan) and creatine kinase
                measurements. More participants showed a positive response following AMO-02
                treatment than placebo on 10 of the 12 quantifiable measures.
                About AMO Pharma
                AMO Pharma is a clinical-stage specialty biopharmaceutical company working to identify
                and advance promising therapies for the treatment of serious and debilitating diseases in
                patient populations with significant areas of unmet need, including rare and severe
                childhood onset neurogenetic disorders with limited or no treatment options. In addition
                to developing AMO-02 for congenital myotonic dystrophy, the company is also
                progressing AMO-01 as a clinical stage treatment for Phelan-McDermid syndrome and
                AMO-04 as a clinic-ready potential medicine for Rett syndrome and related disorders.
                AMO-02, AMO-01 and AMO-04 are investigational medicines that have not yet been
                approved for the treatment of patients anywhere in the world. Advice provided to AMO
                Pharma by regulators is under the condition that any scientific advice given is not legally
                binding with regards to any future application for the product concerned, neither on the
                part of MHRA/Commission on Human Medicines (CHM) nor on the Company.
                Furthermore, advice cannot be taken as indicative of any future agreed position.
                For more information, please visit the AMO Pharma website at
                SOURCE AMO Pharma Limited`,
                summary: `The REACH-CDM clinical trial for AMO-02, a treatment for congenital myotonic dystrophy, did not meet its primary endpoint of a statistically significant benefit over placebo based on an FDA-authorized physician-completed rating scale. However, the trial did show statistically significant benefits in multiple areas including cognitive performance, reduction in biomarkers, and improvements in walk/run measurements.\n\nThe primary endpoint was not achieved due to an unexpected placebo effect that masked the beneficial effect of the treatment. This may have been due to difficulties in patient monitoring and compliance with reporting protocols caused by the COVID pandemic.\n\nDespite not meeting the primary endpoint, the trial showed significant findings in other areas. Treatment with AMO-02 was associated with clinically significant improvements in cognitive performance, reduction in a widely used biomarker of skeletal and cardiac muscle integrity (creatine phosphokinase), and improvement in the 10m walk/run test. A composite statistical analysis of outcomes assessing motor skills, muscle strength, cognitive ability, daily living skills, and biomarker data showed a statistically significant benefit of treatment with AMO-02 compared to placebo.\n\nThe control group in this study was given a placebo. The benefits seen with AMO-02 were related to pharmacokinetic parameters showing that increased plasma levels of AMO-02 resulted in greater clinical improvement.\n\nBased on the trial's outcomes, AMO Pharma is planning meetings with regulators to outline the path forward in the development of AMO-02, including plans for a study in patients with adult-onset myotonic dystrophy. The company is encouraged by the consistent benefit shown across multiple clinically confirmed measures of efficacy and the lack of reported treatment-related serious adverse events.`,
                timeStamp: Date.now(),
            },
            {
                title: "summxys",
                source: "sdfsdfsdfsd fsdkfjsdfkjbsdf",
                summary: "summary copypasta blaksjsd sd sdkn skdnskdn",
                timeStamp: Date.now(),
            },
            {
                title: "summxys kjsbfkaj",
                source: "sdfsdfsdfsd fsdkfjsdfkjbsdf",
                summary: "summary coasdasd asdasd asdsdkn skdnskdn",
                timeStamp: Date.now(),
            },
            {
                title: "pharma summary ayooo",
                source: "sdfsdfsdfsd fsdkfjsdfkjbsdf",
                summary: "summary coasdasd asdasd asdsdkn skdnskdn",
                timeStamp: Date.now(),
            },
        ],
        timeStamp: Date.now(),
    },
    {
        title: "folder2",
        documents: [

        ],
        timeStamp: Date.now(),
    },
    {
        title: "folder3",
        documents: [
            {
                title: "pharma beat",
                source: "sdfsdfsdfsd fsdkfjsdfkjbsdf",
                summary: "summary copypasta blaksjsd sd sdkn skdnskdn",
                timeStamp: Date.now(),
            },
            {
                title: "pharma cock",
                source: "sdfsdfsdfsd fsdkfjsdfkjbsdf",
                summary: "summary coasdasd asdasd asdsdkn skdnskdn",
                timeStamp: Date.now(),
            },
            {
                title: "pharma pharma",
                source: "sdfsdfsdfsd fsdkfjsdfkjbsdf",
                summary: "summary coasdasd asdasd asdsdkn skdnskdn",
                timeStamp: Date.now(),
            },
        ],
        timeStamp: Date.now(),
    },
]
const SummaryRoute = ({addNoteToDB, delNoteFromDB, activeUser, notepadRef, notes, setNotes, notepadPresets, setNotepadPresets}) => {

  const [summariesState, setSummariesState] = useState([]);
  useEffect(() => {
    console.log(summariesState)
  }, [summariesState])

  const navigate = useNavigate();

  useEffect(() => {
    console.log("hiii")
    if (activeUser && activeUser.modules.summary) {
        console.log("heree")
        const token = localStorage.getItem("jwtToken");
        
        if (token) {
            // Fetch user info
            axios.get("https://bancy-backend-01dc0125253f.herokuapp.com/summary", { 
            // axios.get("http://localhost:8000/summary", { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                console.log(response.data.summaries)
                setSummariesState(response.data.summaries);
            })
            .catch(err => {
                console.log(err);
            });
        } else {
            navigate("/login")
        }
    }
  }, [activeUser, navigate])

  const saveOrUpdateSummaries = (action, folder1, folder2) => {
    const token = localStorage.getItem("jwtToken");
    if (!token) return navigate("/login");
    let data;
    switch (action) {
        case "new":
            console.log(folder1)
             
            data = { summaryFolder: folder1 };
            axios.post("https://bancy-backend-01dc0125253f.herokuapp.com/summary", data, { 

            // axios.post("http://localhost:8000/summary", data, { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                console.log(response)
                setSummariesState(prev => {
                    let workingFolder = prev.findIndex(elem => {
                        if (!elem.tempId) return false;
                        return elem.tempId === folder1.tempId;
                    });
                    if (workingFolder !== -1) {
                        prev[workingFolder] = response.data.savedSummaryFolder;
                        delete prev[workingFolder].tempId
                    }
                    return prev;
                });
            })
            .catch(err => {
                console.log(err);
            });
            break;
        case "update":
            // "https://bancy-backend-01dc0125253f.herokuapp.com/summary" 

            data = { summaryFolders: [folder1] };
            axios.put("https://bancy-backend-01dc0125253f.herokuapp.com/summary", data, { 

            // axios.put("http://localhost:8000/summary", data, { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                console.log(response.data.summaries)
                setSummariesState(prev => {
                    let workingFolder = prev.findIndex(elem => elem._id === folder1._id);
                    if (workingFolder !== -1) {
                        prev[workingFolder] = response.data.updatedSummaryFolder;
                    }
                    return prev;
                });
            })
            .catch(err => {
                console.log(err);
            });
            break;
        case "move":
            // "https://bancy-backend-01dc0125253f.herokuapp.com/summary" 

            data = { summaryFolders: [folder1, folder2] };
            axios.put("https://bancy-backend-01dc0125253f.herokuapp.com/summary", data, { 

            // axios.put("http://localhost:8000/summary", data, { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                console.log(response.data.summaries)
                setSummariesState(prev => {
                    let workingFolder = prev.findIndex(elem => elem._id === folder1._id);
                    if (workingFolder !== -1) {
                        prev[workingFolder] = response.data.updatedSummaryFolder;
                    }
                    workingFolder = prev.findIndex(elem => elem._id === folder2._id);
                    if (workingFolder !== -1) {
                        prev[workingFolder] = response.data.updatedSummaryFolder2;
                    }
                    return prev;
                });
            })
            .catch(err => {
                console.log(err);
            });
            break;
        case "delFolder":
            // "https://bancy-backend-01dc0125253f.herokuapp.com/summary" 
            axios.delete(`https://bancy-backend-01dc0125253f.herokuapp.com/summary?summaryId=${folder1._id}`,{ 

            // axios.delete("http://localhost:8000/summary", data, { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                console.log(response.data.summaries)
                setSummariesState(prev => {
                    let workingFolder = prev.findIndex(elem => elem._id === folder1._id);
                    if (workingFolder !== -1) {
                        prev.splice(workingFolder, 1);
                    }
                    return prev;
                });
            })
            .catch(err => {
                console.log(err);
            });
            break;
        default:
            break;
    }

  }

  return (

    (activeUser && activeUser.modules.summary)
    ?
    <div className='max-window'>
        <SummaryPage addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} saveOrUpdateSummaries={saveOrUpdateSummaries} notepadRef={notepadRef} summariesState={summariesState} setSummariesState={setSummariesState}notes={notes} setNotes={setNotes} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets}/>
    </div>
    :
    <SummaryPreview />

  )
}

const SummaryPreview = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/user')
    }

    return (
        <div className='preview max-window'>
            <h1>Summary</h1>
            <h3>Unlock bancrofts powerful summarization tool.</h3>
            <button className='input-action-btn' onClick={handleRedirect}>ADD MODULE</button>
        </div>
    )
}

export default SummaryRoute