import React, {useState, useLayoutEffect} from 'react'

import caretIcon from "../../icons/down-carot.svg"
import folderIcon from "../../icons/bancroft_icons_folder.svg";
import openFolderIcon from "../../icons/bancroft_icons_open-folder.svg";
import deletedFolderIcon from "../../icons/bancroft_icons-delete_folder.svg";
import addFolderIcon from "../../icons/bancroft_icons-add_folder.svg";
import sortIcon from "../../icons/bancroft_icons_sort.svg"
import checkIcon from "../../icons/bancroft_checkbox.svg";
import hamburgerIcon from "../../icons/bars-solid.svg"
import { deepClone } from '../../functions/helpers/generalHelpers';

const FolderView = ({saveOrUpdateSummaries, folder, handleNewSummary, summariesState, setSummariesState, setActiveSummary}) => {

  const [sortState, setSortState] = useState(true);
  const [checkedState, setCheckedState] = useState([]);
  const [shownFiles, setShownFiles] = useState([...folder.documents]);
  const [moveAction, setMoveAction] = useState(false);
  const [delAction, setDelAction] = useState(false);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [tempFolderName, setTempFolderName] = useState("");
  const [triggeredNewName, setTriggeredNewName] = useState(false);

  const folderList = summariesState.map(item => item.title);

  useLayoutEffect(() => {
    setShownFiles([...folder.documents]);
  }, [folder])

  const handleMove = () => {
    if (moveAction) {
        setMoveAction(false);
    } else {
        if (checkedState.length > 0) {
            setMoveAction(true);
        }
    }
  }

  const handleDelete = () => {
    if (delAction) {
        setDelAction(false);
    } else {
        if (checkedState.length > 0) {
            setDelAction(true);
        }
    }
  }


  const handleSuccess = () => {
    setMoveAction(false);
    setDelAction(false);
    setCheckedState([]);
  }
  
  const handleSortChange = () => {
    setShownFiles(prev => {
        let copy = [...prev];
        copy.sort((a,b) => {
            if (sortState) {
                return a.timeStamp - b.timeStamp;
            } else {
                return b.timeStamp - a.timeStamp;
            }
        })
        return [...copy];
    })
    setSortState(!sortState)
  }

  const handleCheckDoc = (file, checked) => {

    if (file === "all") {
        if (checked) {
            const allFiles = shownFiles.map(file => file.title);
            setCheckedState(allFiles);
        } else {
            setCheckedState([]);
        }
    } else {
        if (checked) {
            if (checkedState.indexOf(file.title) === -1) {
                setCheckedState(prev => {
                    return [...prev, file.title];
                })
            }
        } else {
            let ind = checkedState.indexOf(file.title)
            if (ind !== -1) {
                setCheckedState(prev => {
                    let copy = [...prev];
                    copy.splice(ind, 1);
                    return [...copy];
                })
            }
        }
    }
  }


  return (
    <div className='folder-view-master flex-col sum-basic-container'>
        <div className='folder-name-div flex-row fv-rel'>
            <img id="sum-icon-hover" className='folder-icon-big' alt="show-file" src={openFolderIcon}></img>
            <h2 className='folder-name-div-txt'>{triggeredNewName ? tempFolderName : folder.title}</h2>
            {folder.title !== "Deleted Files" && <img onClick={() => setShowFolderModal(!showFolderModal)} className='folder-icon-menu' alt="show-file" src={hamburgerIcon}></img>}
            {folder.title !== "Deleted Files" && showFolderModal && <FolderActionsModal saveOrUpdateSummaries={saveOrUpdateSummaries} folder={folder} setTriggeredNewName={setTriggeredNewName} setShowFolderModal={setShowFolderModal} setSummariesState={setSummariesState} tempFolderName={tempFolderName} setTempFolderName={setTempFolderName} />}
        </div>
        <div className='folder-view-content'>
            <FileTools saveOrUpdateSummaries={saveOrUpdateSummaries} handleSuccess={handleSuccess} folder={folder} summariesState={summariesState} setSummariesState={setSummariesState} folderList={folderList} delAction={delAction} moveAction={moveAction}  handleMove={handleMove} handleDelete={handleDelete} shownFiles={shownFiles} sortState={sortState} handleSortChange={handleSortChange} handleCheckDoc={handleCheckDoc} checkedState={checkedState}/>
            {shownFiles.map((file, index) => {
                return <FileItem key={`fv-file${index}`} setActiveSummary={setActiveSummary} file={file} handleCheckDoc={handleCheckDoc} checkedState={checkedState}/>
            })}
            {folder.documents.length === 0 && (
                <div className='fv-no-docs'>
                    <h2>You have no summaries here.</h2>
                    <button className='input-action-btn' onClick={handleNewSummary}>NEW SUMMARY</button>
                </div>
            )}
        </div>
    </div>
  )
}

const FileTools = ({saveOrUpdateSummaries, handleSuccess, summariesState, folder, setSummariesState, sortState, handleSortChange, handleCheckDoc, checkedState, shownFiles, handleMove, handleDelete, moveAction, delAction, folderList}) => {

    const [moveHover, setMoveHover] = useState(false);
    const [delHover, setDelHover] = useState(false);

    const isChecked = checkedState.length !== 0 && checkedState.length === shownFiles.length;
    return (
        <div className='fv-file fv-bt flex-row'>
            {isChecked ? <img onClick={() => handleCheckDoc("all", false)} className='fv-checkbox' src={checkIcon} alt={"check item"}></img> : <div onClick={() => handleCheckDoc("all", true)} className='fv-file-unchecked'></div> }
            <div onMouseEnter={() => setMoveHover(true)} onMouseLeave={() => setMoveHover(false)} onClick={handleMove} className='flex-row fv-rel'>
                <img id={(moveAction || moveHover) ? "sum-add-color" : undefined} className='folder-icon' alt="show-file" src={addFolderIcon}></img>
                <h5 className='fv-tool-txt'>Move To</h5>
                {moveAction && <MoveToModal saveOrUpdateSummaries={saveOrUpdateSummaries} setMoveHover={setMoveHover} handleSuccess={handleSuccess} summariesState={summariesState} folder={folder} checkedState={checkedState} setSummariesState={setSummariesState} folderList={folderList} />}
            </div>
            <div onMouseEnter={() => setDelHover(true)} onMouseLeave={() => setDelHover(false)} onClick={handleDelete} className='flex-row fv-rel'>
                <img id={(delAction || delHover) ? "sum-add-color" : undefined} className='folder-icon' alt="show-file" src={deletedFolderIcon}></img>
                <h5 className='fv-tool-txt'>Delete</h5>
                {delAction && <DeleteModal saveOrUpdateSummaries={saveOrUpdateSummaries} setDelHover={setDelHover} handleSuccess={handleSuccess} folder={folder} checkedState={checkedState} setSummariesState={setSummariesState} />}
            </div>
            <div onClick={handleSortChange} className='sum-sb-sort flex-row'>
                <img className='sort-icon' alt="show-file" src={sortIcon}></img>
                <p className='sort-txt'>{sortState ? "new" : "old"}</p>
            </div>
        </div>
    )
}

const FileItem = ({file, handleCheckDoc, checkedState, setActiveSummary}) => {

    const [hover, setHover] = useState(false)

    const months = [
        "Jan", 
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ]

    const isChecked = checkedState.indexOf(file.title) !== -1;

    let fileDate = new Date(file.timeStamp);
    let month = months[fileDate.getMonth()];
    fileDate = `${month} ${fileDate.getDate()}, ${fileDate.getFullYear()}`
    return (
        <div id={hover ? "fv-bg-hover" : undefined} className='fv-file-x flex-row' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div >
                {isChecked ? <img onClick={() => handleCheckDoc(file, false)} className='fv-checkbox' src={checkIcon} alt={"check item"}></img> : <div onClick={() => handleCheckDoc(file, true)} className='fv-file-unchecked'></div> }
            </div>
            <div onClick={() => setActiveSummary(file)} className='fv-file-clickable'>
                <div className='fv-file-content flex-col'>
                    <h3 className={hover ? 'fv-file-name sum-hover-color' : 'fv-file-name'}>{file.title}</h3>
                    <h4 className='fv-file-date'>Created on <span className='fv-file-date-val'>{fileDate}</span></h4>
                </div>
                <img className='sum-sb-caret' alt="show-file" src={caretIcon}></img>
            </div>
        </div>
    )
}

const FolderActionsModal = ({saveOrUpdateSummaries, setTriggeredNewName, setShowFolderModal, setSummariesState, setTempFolderName, tempFolderName, folder}) => {

    const handleDeleteFolder = () => {
        let delIndexID;
        let forDB;
        let makeDeleted = false;
        setSummariesState(prev => {
            const copy = deepClone(prev);
            let index = copy.findIndex(item => item.title === folder.title);
            if (index !== -1) {
                delIndexID = copy[index]._id;
                const movedDocs = deepClone(copy[index].documents);
                copy.splice(index, 1);
                let deletedFoldersIndex = copy[copy.findIndex(itm => itm.title === "Deleted Files")]
                if (deletedFoldersIndex) {
                    copy[deletedFoldersIndex].documents = [...deepClone(movedDocs), ...copy[deletedFoldersIndex].documents];
                    forDB = copy[deletedFoldersIndex];
                } else {
                    makeDeleted = true;
                    const deletedFolders = {
                        title: "Deleted Files",
                        documents: [...deepClone(movedDocs)],
                        timeStamp: Date.now(),
                    }
                    forDB = deletedFolders
                    copy.push({...deletedFolders});
                }
            } 
            return [...copy];
        })
        if (makeDeleted) {
            saveOrUpdateSummaries("delFolder", delIndexID)
            saveOrUpdateSummaries("new", forDB);
        } else if (forDB.title) {
            saveOrUpdateSummaries("delFolder", delIndexID)
            saveOrUpdateSummaries("update", forDB);
        }
        setShowFolderModal(false);
    }

    const handleNewName = () => {
        if (tempFolderName.length > 0) {
            let forDB;
            setSummariesState(prev => {
                let index = prev.findIndex(item => item.title === folder.title);
                if (index !== -1) {
                    prev[index].title = tempFolderName;
                    forDB = prev[index]
                }
                return [...prev];
            })
            setTempFolderName("");
            setTriggeredNewName(false);
            setShowFolderModal(false);
            if (forDB.title) {
                saveOrUpdateSummaries("update", forDB)
            }
        }
    }

    const handleNewNameChange = (e) => {
        setTempFolderName(e.target.value);
        setTriggeredNewName(true);
    }

    return (
        <div className='fv-modal folder-opts-modal flex-col'>
            <h5 className='sum-sb-h5'>Rename Folder</h5>
            <input id="sum-sb-search" className='text-input-standard' onChange={handleNewNameChange} value={tempFolderName}></input>
            <button id="opts-btn-r" className='input-action-btn' onClick={handleNewName}>CONFIRM</button>
            <div className='opts-hr'></div>
            <h5 className='sum-sb-h5'>Delete Folder</h5>
            <button className='input-action-btn fv-delete fvdx' onClick={handleDeleteFolder}>DELETE</button>

        </div>
    )
}

const MoveToModal = ({saveOrUpdateSummaries, summariesState, folderList, setSummariesState, checkedState, folder, handleSuccess, setMoveHover}) => {

    const thisFolderList = folderList.filter(item => item !== folder.title);
    const ModalItem = ({toFolder}) => {
        const [hover, setHover] = useState(false);

        const handleClick = () => {
            let forDB;
            let forDB_2;
            setSummariesState(prev => {
                const copy = deepClone(prev);
                let toIndex = copy.findIndex(itm => itm.title === toFolder);
                if (toIndex !== -1) {
                    let to = copy[toIndex];
                    let from = copy[copy.findIndex(itm => itm.title ===folder.title)];
                    let filtered = from.documents.filter( ( el ) => !checkedState.includes( el.title ) );
                    let movedDocs = from.documents.filter( ( el ) => checkedState.includes( el.title ) );
                    from.documents = deepClone(filtered);
                    to.documents = [...deepClone(movedDocs), ...to.documents];
                    forDB = to;
                    forDB_2 = from;
                }
                return [...copy];
            })
            if (forDB_2 && forDB) {
                saveOrUpdateSummaries("move", forDB, forDB_2)
            }
            setMoveHover(false);
            handleSuccess();
        }

        let fileIcon = hover ? <img id="sum-icon-hover" className='folder-icon' alt="show-file" src={folderIcon}></img> : <img className='folder-icon' alt="show-file" src={folderIcon}></img>;
        return (
            <div onClick={handleClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className='fv-modal-item flex-row'>
                {fileIcon}
                <h3 className={'fv-modal-txt'}>{toFolder}</h3>
            </div>
        )
    }
    
    const getNewFolderTitle = () => {
        let flag = false;
        let count = 1;
        let folderName;
        while (!flag) {
            let attempt = `Folder0${summariesState.length + count}`;
            if (summariesState.findIndex(item => item.title === attempt) === -1) {
                flag = true;
                folderName = attempt
            }
            count++;
        }
        return folderName;
    }

    const handleMoveToNew = () => {
        const thisNewFolder = {
            title: getNewFolderTitle(),
            documents: [],
            timeStamp: Date.now(),
        }
        let forDB;
        let forDB_2;
        setSummariesState(prev => {
            const copy = deepClone(prev);
            let from = copy[copy.findIndex(itm => itm.title === folder.title)];
            let filtered = from.documents.filter( ( el ) => !checkedState.includes( el.title ) );
            let movedDocs = from.documents.filter( ( el ) => checkedState.includes( el.title ) );
            from.documents = deepClone(filtered);
            thisNewFolder.documents = [...deepClone(movedDocs)];
            copy.push({...thisNewFolder});
            forDB = from;
            forDB_2 = thisNewFolder;
            return [...copy];
        })
        if (forDB && forDB_2) {
            saveOrUpdateSummaries("update", forDB);
            saveOrUpdateSummaries("new", forDB_2);
        }
        setMoveHover(false);
        handleSuccess();
    }

    return (
        <div className='fv-modal flex-col'>
            {thisFolderList.map((toFolder, index) => {
                return <ModalItem key={`fv-modal${index}`} toFolder={toFolder}/>
            })}
            {/* add folder  */}
            <div className='mi-add-folder flex-row' onClick={handleMoveToNew}>
                <img id="mi-icon-color" className='folder-icon' alt="show-file" src={addFolderIcon}></img>
                <h3 className={'fv-modal-txt mi-txt'}>Create New Folder</h3>
            </div>
        </div>
    )
}

const DeleteModal = ({saveOrUpdateSummaries, setSummariesState, checkedState, folder, handleSuccess, setDelHover}) => {

    const handleDelete = () => {
        if (folder.title === "Deleted Files") {
            handlePermDelete();
            return;
        }
        let forDB;
        let forDB_2;
        let createDeletedFolder = false;
        setSummariesState(prev => {
            const copy = deepClone(prev);
            let from = copy[copy.findIndex(itm => itm.title === folder.title)];
            let filtered = from.documents.filter( ( el ) => !checkedState.includes( el.title ) );
            let movedDocs = from.documents.filter( ( el ) => checkedState.includes( el.title ) );
            from.documents = deepClone(filtered);
            let deletedFoldersIndex = copy.findIndex(itm => itm.title === "Deleted Files");
            if (deletedFoldersIndex !== -1) {
                copy[deletedFoldersIndex].documents = [...deepClone(movedDocs), ...copy[deletedFoldersIndex].documents];
                forDB = copy[deletedFoldersIndex];
                forDB_2 = from;
            } else {
                createDeletedFolder = true;
                const deletedFolders = {
                    title: "Deleted Files",
                    documents: [...deepClone(movedDocs)],
                    timeStamp: Date.now(),
                }
                forDB = deletedFolders;
                forDB_2 = from;
                copy.push({...deletedFolders});
            }
            return [...copy];
        })
        if (createDeletedFolder) {
            saveOrUpdateSummaries("new", forDB);
            saveOrUpdateSummaries("update", forDB_2);
        } else if (forDB && forDB_2) {
            saveOrUpdateSummaries("move", forDB, forDB_2);
        }
        setDelHover(false);
        handleSuccess();
    }

    const handlePermDelete = () => {
        let forDB;
        setSummariesState(prev => {
            const copy = deepClone(prev);
            let deletedFoldersIndex = copy.findIndex(itm => itm.title === "Deleted Files");
            if (deletedFoldersIndex !== -1) {
                copy[deletedFoldersIndex].documents = copy[deletedFoldersIndex].documents.filter( ( el ) => !checkedState.includes( el.title ) );
                forDB = copy[deletedFoldersIndex];
            } 
            return [...copy];
        })
        if (forDB) {
            saveOrUpdateSummaries("update", forDB)
        }
        setDelHover(false);
        handleSuccess();
    }


    return (
        <div className='fv-modal del-modal'>
            <h5 className='fv-del-modal-txt'>{folder.title === "Deleted Files" && "Permanently"} Delete Files!?</h5>
            <button onClick={handleDelete} className='input-action-btn fv-delete'>DELETE</button>
        </div>
    )
}

export default FolderView