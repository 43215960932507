import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

import CollapsingContainer from '../../../util/CollapsingContainer';
import titleCase from '../../../../functions/helpers/titleCase';
import WarningPopupSmall from '../../../util/WarningPopupSmall';
import DateFields from './DateFields';
import PatientCalculator from './patientCalculator/PatientCalculator';
import { toCommaSeperatedString } from '../../../../functions/helpers/generalHelpers';


const InputsGeneral = ({setGraphPeriod, workingGeographies, workingForecast, engagedSection, setEngagedSection, setWorkingForecast, graphPeriod, setCurrGeographyIndex}) => {

  const [inputState, setInputState] = useState({...workingForecast.geographiesData[workingGeographies[0]].general});
  const [needsAlert, setNeedsAlert] = useState({period: false, startDate: false, length: false, penetration: false, yearsToPeakPenetration: false, slope: false, price: false});
  const [isFocused, setIsFocused] = useState({startDate: "never", length: "never", penetration: "never", slope: "never", yearsToPeakPenetration: "never", price: "never"});
  const [showCalculator, setShowCalculator] = useState(false);
  const [patientsState, setPatientsState] = useState({});
  const [diseaseState, setDiseaseState] = useState(workingForecast.geographiesData[workingGeographies[0]].general.diseaseName);

  useEffect(() => {
    let newPatientsState = {}
    workingGeographies.forEach(item => {
        if (workingForecast.geographiesData[item].general.geographiesPatients) {
            newPatientsState[item] = {...workingForecast.geographiesData[item].general.geographiesPatients}
        }
    })
    setPatientsState({...newPatientsState})
  }, [workingForecast.geographiesData, workingGeographies])

  useEffect(() => {
    setInputState({...workingForecast.geographiesData[workingGeographies[0]].general});
  }, [workingForecast, workingGeographies]);
  
  // REFS AND USEEFFECT
  // Used to keep track of an elements focus, 
  // When focused and then unfocused if error handling is passed then graph will update.


//   const startDateRef = useRef(isFocused.startDate);
//   const lengthRef = useRef(isFocused.length);
//   const penetrationRef = useRef(isFocused.penetration);
//   const yearsToPeakPenetrationRef = useRef(isFocused.yearsToPeakPenetration);
//   const slopeRef = useRef(isFocused.slope);
//   const priceRef = useRef(isFocused.price)

  const updatePeriod = (e) => {

    // auto adjust length to match period
    let lengthValue = inputState.length;
    if (lengthValue !== 0) {
        if (e.target.value === "month") {
            if (inputState.period === "year") {
                lengthValue *= 12;
            } else if (inputState.period === "quarter") {
                lengthValue *= 3;
            }
            // current max
            if(lengthValue > 60) lengthValue = 60;
        } else if (e.target.value === "quarter") {
            if (inputState.period === "year") {
                lengthValue *= 4;
            } else if (inputState.period === "month") {
                lengthValue /= 3;
            }
            // current max
            if(lengthValue > 40) lengthValue = 40;
        } else if (e.target.value === "year") {
            if (inputState.period === "quarter") {
                lengthValue /= 4;
            } else if (inputState.period === "month") {
                lengthValue /= 12;
            }
            // current max
            if(lengthValue > 20) lengthValue = 20;
        }
    }
    setInputState({...inputState, period: e.target.value, length: Math.ceil(lengthValue)});
    setGraphPeriod(e.target.value);
  }

  const handleFocus = (type) => {
    setIsFocused({...isFocused, [type]: "yes"});
    setNeedsAlert((prev) => ({...prev, [type]: false}));
  }

  const handleBlur = (type) => {
    setIsFocused({...isFocused, [type]: "no"});
  }

  const lengthHandling = (e) => {
    setInputState({...inputState, length: e.target.value});
  }

  const periodValidation = (val) => {
    if (val === "year" ||
        val === "month" ||
        val === "quarter") {
            return true
    }
    return false
  }

  const lengthValidation = (val) => {
    let maxVal = 20;
    if (inputState.period === "month") {
        maxVal = (5 * 12);
    } else if (inputState.period === "quarter") {
        maxVal = (10 * 4);
    }
    if ((val >= 1 && val<= maxVal)) {
        return true;
    } 
    return false;
  }

  const slopeValidation = (val) => {
    if (val === "linear" ||
        val === "exponential" ||
        val === "logistic") {
            return true;
    }
    return false;
  }
  const priceValidation = (val) => {
    return val > 0;
  }
  const yearsToPeakValidation = (val) => {
    let newVal = parseFloat(val)
    return newVal >= 0;
  }

  const durationValidation = (fieldValue) => {
    if (fieldValue === "intermediate" ||
        fieldValue === "chronic" ||
        fieldValue === "acute" ||
        fieldValue === "intermediate") {
        return true;
    } else {
        return false;
    }
  }
  const penetrationValidation = (fieldValue) => {
    if (fieldValue >= 0 && fieldValue <= 100) {
        return true;
    } else {
        return false;
    }
  }

  const patientsValidation = (obj) => {
    let keys = Object.keys(obj);
    if (keys.length === 0) return false
    let safe = false;
    keys.forEach(k => {
        if (obj[k] && obj[k].patients !== 0) {
            safe = true;
        }
    })
    return safe
  }

  const submitGeneralData = () => {
    const validationObj = {
        period: periodValidation,
        length: lengthValidation,
        price: priceValidation,
        slope: slopeValidation,
        penetration: penetrationValidation,
        incidentPenetration: penetrationValidation,
        prevalentPenetration: penetrationValidation,

        duration: durationValidation,
        yearsToPeakPenetration: yearsToPeakValidation,
        // error handling done elsewhere or not needed
        diseaseName: () => true,
        startDate: () => true,
        geographiesPatients: patientsValidation,
    }

    let allValid = true;
    Object.keys(inputState).forEach(k => {
        if (k !== "_id") {
            const validationFunction = validationObj[k];
            if (validationFunction && validationFunction(k === "geographiesPatients" ? patientsState : inputState[k])) {
                setNeedsAlert({...needsAlert, [k]: false})
            } else {
                allValid = false;
                if (needsAlert[k] === false) {
                    setNeedsAlert({...needsAlert, [k]: true})
                }
            }
        }
    })
    if (allValid) {
        setWorkingForecast(prevForecast => {
            let copyF = {...prevForecast};
            let copyG = {...copyF.geographiesData}
            workingGeographies.forEach(geo => {
                copyG[geo].general = {...inputState, geographiesPatients: {...patientsState[geo]}};
            })
            return {...copyF, geographiesData: {...copyG}};
        });
    }
}


//   const handleGeographies = (e) => {
//     setNewGeography(e.target.value);
//   }

//   const submitNewGeography = (e) => {
//     e.preventDefault();
//     if (inputState.geographies.indexOf(newGeography) === -1) {
//         setWorkingForecast(prevForecast => {
//             let newGeographies = [...prevForecast.general.geographies];
//             newGeographies.push(newGeography);
//             return ({
//                 ...prevForecast,
//                 general: {
//                     ...prevForecast.general,
//                     geographies: newGeographies,
//                 }
//             })
//         })
//         setNewGeography("")
//         setNewGeoInput(false)
//     } else {
//         // error message repeat geography NEEDS WORK
//     }
//   }

//   const removeGeography = (geo) => {
//     setCurrGeographyIndex(0);
//     setWorkingForecast(prevForecast => {
//         let newGeographies = [...prevForecast.general.geographies];
//         let newGeoPatData = {...prevForecast.general.geographiesPatients}
//         if (newGeoPatData[geo]) {
//             delete newGeoPatData[geo];
//         }
//         newGeographies.splice(newGeographies.indexOf(geo), 1);
//         return ({
//             ...prevForecast,
//             general: {
//                 ...prevForecast.general,
//                 geographies: newGeographies,
//                 geographiesPatients: {...newGeoPatData}
//             }
//         })
//     })
//   }

  const updateInputs = (e, target) => {
    if (target === "diseaseName") setDiseaseState(e.target.value)
    setInputState({...inputState, [target]: e.target.value});
  }

  let periodValue = `( ${titleCase(inputState.period)}'s )`;
  
  const maxLength = inputState.period === "year" ? 20 : inputState.period === "month" ? 60 : 40;
  const lengthPopup = <span><WarningPopupSmall message={<React.Fragment>Please input a valid length <br></br> ( max {maxLength} )</React.Fragment>}/></span>
  const penetrationPopup = <span><WarningPopupSmall message={"Please enter a valid penetration value."}/></span>
  const yearsToPeakPenetrationPopup = <span><WarningPopupSmall message={"Please enter a valid date."}/></span>
  const slopePopup = <span><WarningPopupSmall message={"Please enter a valid slope."}/></span>
  const pricePopup = <span><WarningPopupSmall message={"Please input a valid price."}/></span>

  const data = (
    <div className='input-section active-section-background flex-col pad-horz-20'>
        <div className='input-and-label-standard'>
            <h5 className='input-header'>Model Period</h5>
            <select id="explicit-width-input" className='text-input-select text-left' value={inputState.period} onChange={updatePeriod}>
                <option className='input-option-standard' value="year">Year</option>
                <option className='input-option-standard' value="quarter">Quarter</option>
                <option className='input-option-standard' value="month">Month</option>
            </select>
        </div>
        <DateFields workingGeographies={workingGeographies} setInputState={setInputState} startDateState={inputState.startDate} graphPeriod={graphPeriod} section="general" workingForecast={workingForecast} setWorkingForecast={setWorkingForecast} />
        <div className='input-and-label-standard'>
            <h5 className='input-header'>Model Length <span id="graph-length-period-identifier">{periodValue}</span></h5>
            {needsAlert.length && lengthPopup}
            <input id="explicit-width-input" className='text-input-standard text-left' type="number" onBlur={() => handleBlur("length")} onFocus={() => handleFocus("length")} onChange={lengthHandling} value={inputState.length}></input>
        </div>

        <h5 className='input-header'>Disease Name</h5>
        <input className='text-input-standard text-left' type="text" onChange={(e) => updateInputs(e, "diseaseName")} value={inputState.diseaseName}></input>

        <div className='p-calc-compressed'>
            <div onClick={() => setShowCalculator(!showCalculator)} className='flex-row calc-pat-div'>
                <div className='orange-triangle'></div>
                <h4 className='p-calc-btn'>Calculate Patients</h4>
            </div>
            {showCalculator && <PatientCalculator diseaseState={diseaseState} patientsState={patientsState} setPatientsState={setPatientsState} workingGeographies={workingGeographies} setShowCalculator={setShowCalculator} workingForecast={workingForecast} setWorkingForecast={setWorkingForecast} geography={inputState.geographies} />}
        </div>

        {workingForecast.type === "drug" ? 
            <React.Fragment>
                <h5 className='input-header' id="p-text-x">Patients</h5>
                {workingGeographies.map((geoItem, index) => {
                let geoPat = 0;
                if (patientsState[geoItem]) geoPat = patientsState[geoItem].patients;
                    return (
                        <div key={`geo-n-p${geoItem}`} className='geo-div-general'>
                            <h6 key={`geo-n-p${geoItem}`} className='geo-name-plain'>{geoItem}: {geoPat && <span>{toCommaSeperatedString(geoPat)}</span>}</h6>                        
                        </div>
                    )
                })}
            </React.Fragment>
            :
            <React.Fragment>
                <h5 className='input-header' id="p-text-x">Prevalent Patients</h5>
                {workingGeographies.map((geoItem, index) => {
                let geoPat = 0;
                if (patientsState[geoItem]) geoPat = patientsState[geoItem].prevalentPatients;
                    return (
                        <div key={`geo-n-p${geoItem}`} className='geo-div-general'>
                            <h6 key={`geo-n-p${geoItem}`} className='geo-name-plain'>{geoItem}: {geoPat && <span>{toCommaSeperatedString(geoPat)}</span>}</h6>                        
                        </div>
                    )
                })}
                <h5 className='input-header' id="p-text-x">Incident Patients</h5>
                {workingGeographies.map((geoItem, index) => {
                let geoPat = 0;
                if (patientsState[geoItem]) geoPat = patientsState[geoItem].incidentPatients;
                    return (
                        <div key={`geo-n-p${geoItem}`} className='geo-div-general'>
                            <h6 key={`geo-n-p${geoItem}`} className='geo-name-plain'>{geoItem}: {geoPat && <span>{toCommaSeperatedString(geoPat)}</span>}</h6>                        
                        </div>
                    )
                })}
            </React.Fragment>
        }
        

        <div className='input-and-label-standard'>
            <h5 className='input-header'>WAC / Price</h5>
            {needsAlert.price && pricePopup}
            <input className='text-input-standard text-left' min={0} type="number" onBlur={() => handleBlur("price")} onFocus={() => handleFocus("price")} onChange={(e) => updateInputs(e, "price")} value={inputState.price}></input>
        </div>

        {workingForecast.type === "drug" ? 
            <div className='input-and-label-standard'>
                <h5 className='input-header'>Peak Penetration ( % )</h5>
                {needsAlert.penetration && penetrationPopup}
                <input className='text-input-standard text-left' min={0} max={100} type="number" onBlur={() => handleBlur("penetration")} onFocus={() => handleFocus("penetration")} onChange={(e) => updateInputs(e, "penetration")} value={inputState.penetration}></input>
            </div>
        :
            <React.Fragment>
                <div className='input-and-label-standard'>
                    <h5 className='input-header'>Peak Penetration ( % )<br/> Prevalent</h5>
                    {needsAlert.penetration && penetrationPopup}
                    <input className='text-input-standard text-left' min={0} max={100} type="number" onBlur={() => handleBlur("prevalentPenetration")} onFocus={() => handleFocus("prevalentPenetration")} onChange={(e) => updateInputs(e, "prevalentPenetration")} value={inputState.prevalentPenetration}></input>
                </div>
                <div className='input-and-label-standard'>
                    <h5 className='input-header'>Peak Penetration ( % )<br/> Incident</h5>
                    {/* {needsAlert.penetration && penetrationPopup} */}
                    <input className='text-input-standard text-left' min={0} max={100} type="number" onBlur={() => handleBlur("incidentPenetration")} onFocus={() => handleFocus("incidentPenetration")} onChange={(e) => updateInputs(e, "incidentPenetration")} value={inputState.incidentPenetration}></input>
                </div>
            </React.Fragment>
        }
        <div className='input-and-label-standard'>
            <h5 className='input-header'> Years Until Peak Penetration</h5>
            {needsAlert.yearsToPeakPenetration && yearsToPeakPenetrationPopup}
            <input className='text-input-standard text-left' min={0} max={20} type="number" onBlur={() => handleBlur("yearsToPeakPenetration")} onFocus={() => handleFocus("yearsToPeakPenetration")} onChange={(e) => updateInputs(e, "yearsToPeakPenetration")} value={inputState.yearsToPeakPenetration}></input>
        </div>
        <div className='input-and-label-standard'>
            <h5 className='input-header'>Penetration Slope</h5>
            {needsAlert.slope && slopePopup}
            <select className='text-input-select text-left' onBlur={() => handleBlur("slope")} onFocus={() => handleFocus("slope")} onChange={(e) => updateInputs(e, "slope")} value={inputState.slope} >
                <option className='input-option-standard' value="linear">Linear</option>
                <option className='input-option-standard' value="exponential">Exponential</option>
                <option className='input-option-standard' value="logistic">Logistic</option>
            </select>        
        </div>

        {/* // not currently working will move to new structure soon */}
        <button id="explicit-width-input" className='input-action-btn general-btn' onClick={submitGeneralData}>SUBMIT</button>
    </div>
  )

  return (
    <CollapsingContainer containerName="General Data" data={data} engagedSection={engagedSection} setEngagedSection={setEngagedSection}/>
  )
}

export default InputsGeneral