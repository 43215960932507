import React, {useState} from 'react'

const DiseaseExcerpt = ({excerpt, isIso}) => {

    console.log(excerpt)
  const [showExcerpt, setShowExcerpt] = useState(false);

  const mod1 = isIso ? 100 : 60;
  const mod2 = isIso ? 90 : 50;
  // 60 subbject to change
  let diseaseInfo = excerpt.affectedPopulations.length <= mod1 ? excerpt.affectedPopulations : excerpt.affectedPopulations.slice(0, mod2);

  return (
    <div onClick={() => setShowExcerpt(!showExcerpt)} className='disease-excerpt-div'>
        {showExcerpt ? 
            <React.Fragment>
                <p id="iso-disease-name" className='disease-excerpt-name'>{excerpt.diseaseName}</p>
                <p id='iso-disease-txt' className='disease-excerpt-text'>{excerpt.affectedPopulations}</p>
            </React.Fragment>
            :
            <React.Fragment>
                <p id="iso-disease-name" className='disease-excerpt-name'>{excerpt.diseaseName}</p>
                <p id='iso-disease-txt' className='disease-excerpt-text faded-div'>{diseaseInfo}</p>
            </React.Fragment>
        }
    </div>
  )
}

export default DiseaseExcerpt