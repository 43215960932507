import React, {useState} from 'react'

import { example1 } from '../../../../exampleForecasts'

const PriorForecasts = ({userForecasts, issueNewForecast, saveOrUpdateForecasts, engageOldForecast}) => {
    const thisExample = {scenarios: [{name: "base", forecast: {...example1}},{name: "best", forecast: {...example1}},{name: "worst", forecast: {...example1}}]};
    thisExample.isExample = true;
  return (
    <div className='prior-forecasts-main flex-col max-window pad-horz-20'>
        <h4 id="prior-forecast-header" className='input-header'>Prior Forecasts</h4>
        {/* loop through history and have buttons for each forecast to engage */}
        <div className='prior-forecasts-grouping flex-col'>
            {userForecasts && userForecasts.toReversed().map((forecast, index) => {
                return <PriorForecast key={`p-forecast${index}`} engageOldForecast={engageOldForecast} forecast={forecast} issueNewForecast={issueNewForecast} saveOrUpdateForecasts={saveOrUpdateForecasts}/>
            })}
            {(!userForecasts || userForecasts.length < 2) && <p onClick={() => engageOldForecast(thisExample)} className='prior-forecast-btn'>Example Forecast</p>}
        </div>


    </div>
  )
}

const PriorForecast = ({forecast, issueNewForecast, saveOrUpdateForecasts, engageOldForecast}) => {
    const [expanded, setExpanded] = useState(false)
    return (
        <div className='prior-forecast-item' onClick={() => setExpanded(!expanded)}>
            <h2 className='pf-title'>{'>'}{forecast.scenarios[0].forecast.name}</h2>
            {expanded && 
            <React.Fragment>
                <button className='input-action-btn' onClick={() => engageOldForecast(forecast)}>OPEN FORECAST</button>
                <button className='input-action-btn'>EXPORT FORECAST</button>
                <button className='input-action-btn' onClick={() => saveOrUpdateForecasts("delForecast", forecast)}>DELETE FORECAST</button>
            </React.Fragment>
            }
        </div>
    )
}

export default PriorForecasts