import React from 'react'

import EditableField from './EditableField'
import { toCommaSeperatedString } from '../../../../functions/helpers/generalHelpers'

const TotalsColumn = ({colData, evenBackground}) => {

    const data = colData.map((item, index) => {
        if (index === 0) {
            return (
                <div key={`table-header${item}${index}`}  className='table-header-basic border-bot'>
                    <div className='flex-row'>
                        <h4 className='table-header-text'>{item}</h4>
                    </div>
                </div>
            )
        }
        else {
            return <EditableField key={`table-item${item}${index}`} fieldData={toCommaSeperatedString(item)} evenBackground={evenBackground} />
        }
      })
    return (
        <div className='table-col-div flex-col'>
            {data}
        </div>
    )
}

export default TotalsColumn