
import React, {useState, useEffect, useRef} from 'react'
import InputsNotetaking from './sidebar/subsections/InputsNotetaking'
import { roundToTwoDecimals } from '../../functions/helpers/generalHelpers'

// COMPONENTS
///////////////////////////////////////////////



///////////////////////////////////////////////



const QuickAnalysisBody = ({approvalType, result, qaNotes, setqaNotes, pipelineCompetitionProductsDetails, setPipelineCompetitionProductsDetails, pipelineCompetitionProducts, setPipelineCompetitionProducts, approvedCompetitionProductsDetails, setApprovedCompetitionProductsDetails, routeOfAdministration, setRouteOfAdministration, lossOfExclusivity, setLossOfExclusivity, approvedCompetitionProducts, setApprovedCompetitionProducts}) => {
    return (
        <div className='qa-page-results flex-col'>
            <div className='qa-data-section flex-row'>
                {/* <div id={approvalType === "false" ? "greyed-sales" : undefined} className='sales-section qa-res-section'>
                    <h2>Peak Sales</h2>
                    <p>Sales: {peakSales}</p>
                    <p>Patients: {}</p>
                    <p>Price: {}</p>
                </div> */}

                {approvalType === false &&
                <div className='clinical-section qa-res-section flex-row'>
                    <BarGraphFeature graphType={"adjusted"} result={{startPhase: result.startPhase, approval: result.AdjustedApproval, total: result.AdjustedTotal, phaseOne: result.AdjustedPhaseOne, phaseTwo: result.AdjustedPhaseTwo, phaseThree: result.AdjustedPhaseThree}} />
                    <BarGraphFeature graphType={"raw"} result={{startPhase: result.startPhase, approval: result.approval, total: result.total, phaseOne: result.phaseOne, phaseTwo: result.phaseTwo, phaseThree: result.phaseThree}} />
                </div>
                }
            </div>
            <div className='qa-notes-section'>
                {/* <InputsNotetaking
                                  approvalType={approvalType}
                                  qaNotes={qaNotes}
                                  setqaNotes={setqaNotes}
                                  routeOfAdministration={routeOfAdministration}
                                  setRouteOfAdministration={setRouteOfAdministration}
                                  lossOfExclusivity={lossOfExclusivity}
                                  setLossOfExclusivity={setLossOfExclusivity}
                                  approvedCompetitionProducts={approvedCompetitionProducts}
                                  setApprovedCompetitionProducts={setApprovedCompetitionProducts}
                                  approvedCompetitionProductsDetails={approvedCompetitionProductsDetails}
                                  setApprovedCompetitionProductsDetails={setApprovedCompetitionProductsDetails}
                                  pipelineCompetitionProducts={pipelineCompetitionProducts}
                                  setPipelineCompetitionProducts={setPipelineCompetitionProducts}
                                  pipelineCompetitionProductsDetails={pipelineCompetitionProductsDetails}
                                  setPipelineCompetitionProductsDetails={setPipelineCompetitionProductsDetails}/> */}
            </div>
        </div>
    )
}

const BarGraphFeature = ({result, graphType}) => {
    const graphTitle = graphType === "raw" ? "Raw Probabilities for Therapeutic Area" : "Adjusted Probabilities at Each Stage";
    return (
        <div className='qa-bar-graph-container'>
            <h2 className='qa-bar-graph-header'>{graphTitle}</h2>
            <div className='qa-bar-graph-main'>
                {(result.startPhase === 0 || result.startPhase === "phaseOne") && 
                    <div className='qa-bar-graph-item'>
                        <div className='qa-bar-graph-outline'>
                            <div style={{backgroundColor: graphType === "raw" ? "#c3890b" : "#3c8ab6", height: `${result.phaseOne * 100 - 8}%`}} className='qa-bar-graph-graph'>
                                <p className='qa-bar-graph-val'>{roundToTwoDecimals(100 * result.phaseOne)}%</p>
                            </div>
                        </div>
                        <h2 className='qa-bar-graph-label'>Phase 1 - <br/> Phase 2</h2>
                    </div>
                }
                {(result.startPhase === 0 || result.startPhase === "phaseOne" || result.startPhase === "phaseTwo") && 
                    <div className='qa-bar-graph-item'>
                        <div className='qa-bar-graph-outline'>
                            <div style={{backgroundColor: graphType === "raw" ? "#c3890b" : "#3c8ab6", height: `${result.phaseTwo * 100 - 8}%`}} className='qa-bar-graph-graph'>
                                <p className='qa-bar-graph-val'>{roundToTwoDecimals(100 * result.phaseTwo)}%</p>
                            </div>
                        </div>
                        <h2 className='qa-bar-graph-label'>Phase 2 - <br/> Phase 3</h2>
                    </div>
                }
                {(result.startPhase === 0 || result.startPhase === "phaseOne" || result.startPhase === "phaseTwo" || result.startPhase === "phaseThree") && 
                    <div className='qa-bar-graph-item'>
                        <div className='qa-bar-graph-outline'>
                            <div style={{backgroundColor: graphType === "raw" ? "#c3890b" : "#3c8ab6", height: `${result.phaseThree * 100 - 8}%`}} className='qa-bar-graph-graph'>
                                <p className='qa-bar-graph-val'>{roundToTwoDecimals(100 * result.phaseThree)}%</p>
                            </div>
                        </div>
                        <h2 className='qa-bar-graph-label'>Phase 3 - <br/> Submission</h2>
                    </div>
                }
                <div className='qa-bar-graph-item'>
                        <div className='qa-bar-graph-outline'>
                            <div style={{backgroundColor: graphType === "raw" ? "#c3890b" : "#3c8ab6", height: `${result.approval * 100- 8}%`}} className='qa-bar-graph-graph'>
                                <p className='qa-bar-graph-val'>{roundToTwoDecimals(100 * result.approval)}%</p>
                            </div>
                        </div>
                    <h2 className='qa-bar-graph-label'>Submission - <br/> Approval</h2>
                </div>
            </div>
            <div className='bar-graph-lower'>
                <h3 style={{color: graphType === "raw" ? "#c3890b" : "#3c8ab6"}} className='qa-graph-curr-stage'>Current Stage - Approval</h3>
                <h3 className='qa-curr-stage-val'>{roundToTwoDecimals(result.total * 100)}%</h3>
            </div>
        </div>
    )
}

export default QuickAnalysisBody