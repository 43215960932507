import React, {useState, useRef, useEffect} from 'react'

import '../../../css/graph.css'
import LineChartComponent from './subsections/LineChartComponent'

const GraphContainer = ({workingForecast, setWorkingForecast, graphDataArray, period}) => {
  const [displayState, setDisplayState] = useState("sales")

  // helps determine how many labels to put on graph
  const [graphWidth, setGraphWidth] = useState(0);
  const ref = useRef(null)
  const updateWidth = () => {
    if (ref.current) {
      setGraphWidth(ref.current.offsetWidth);
    }
  };
  useEffect(() => {
    // Initial update
    updateWidth();
    // Listen for window resize events
    window.addEventListener('resize', updateWidth);
    // Cleanup
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <div className='flex-col'>
        <div className='flex-row table-geo-tabs'>

            <div id={displayState === "sales" ? "curr-geo-btn" : null} className="geo-btn-tbl" onClick={() => setDisplayState("sales")}>Sales</div>
            <div id={displayState === "population" ? "curr-geo-btn" : null} className="geo-btn-tbl" onClick={() => setDisplayState("population")}>Population</div>
        </div>
        <div className='graph-container-outer' ref={ref}>
            <h3 className='graph-geo-txt'>{graphDataArray.geography}</h3>
            {displayState === "sales" ?
                <LineChartComponent forecastName={workingForecast.name} forecastType={workingForecast.type} chartData={graphDataArray.sales} type={"sales"} period={period} graphWidth={graphWidth} />
                :
                <LineChartComponent forecastName={workingForecast.name} forecastType={workingForecast.type} chartData={graphDataArray.population} type={"population"} period={period} graphWidth={graphWidth} />
            }
        </div>
    </div>

  )
}

export default GraphContainer