const getPatientsPerGeo = (geographies, index, geoData) => {
    if (geographies[index]) {
        let geoObj = geoData[geographies[index]].general.geographiesPatients;
        if (geoObj === undefined) return false;
        if(Object.keys(geoObj).length === 0) return false
        let patientsReturn = geoObj.prevalentPatients ? {prevalent: geoObj.prevalentPatients, incident: geoObj.incidentPatients} : geoObj.patients;
        return {geography: geoObj, patients: patientsReturn}
    } else {
        return false
    }
}

export default getPatientsPerGeo