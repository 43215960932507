import React from 'react'
import '../../../../css/graph.css'
import LineChartForPng from './LineChartForPng'

const ChartsForPng = ({graphDataArray, workingForecast}) => {
  return (
    <div className='off-screen-rendering'>
        {graphDataArray.map((graphData, index) => {
            let period = workingForecast.geographiesData[graphData.geography].general.period;
            return (
                <React.Fragment key={`chart-to-png${index}`}>
                    <div style={{border: "none"}} id={`chart-to-png-sales${index}`} className="graph-container-outer off-screen-chart">
                        <h3 id="off-screen-text" className='graph-geo-txt'>{graphData.geography} - <span style={{fontWeight: "100"}}>Sales</span></h3>
                        <LineChartForPng forecastName={workingForecast.name} forecastType={workingForecast.type} chartData={graphData.sales} type={"sales"} period={period} graphWidth={540}/>
                    </div>
                    <div style={{border: "none"}} id={`chart-to-png-population${index}`} className="graph-container-outer off-screen-chart">
                        <h3 id="off-screen-text" className='graph-geo-txt'>{graphData.geography} - <span style={{fontWeight: "100"}}>Patients on Drug</span></h3>
                        <LineChartForPng forecastName={workingForecast.name} forecastType={workingForecast.type} chartData={graphData.population} type={"population"} period={period} graphWidth={540}/>
                    </div>
                </React.Fragment>
            )
        })}
    </div>
  )
}

export default ChartsForPng