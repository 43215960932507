import React, {useState} from 'react'
import CollapsingContainerQA from '../../../util/CollapsingContainerQA';

const InputsDrugInformation = ({engagedSection, setEngagedSection, setDrugType, setTherapeuticArea, therapeuticArea, drugType}) => {

    const data = (
        <div className='input-section active-section-background flex-col pad-horz-20'>
            <div className='input-and-label-standard'>
                <h5 className='input-header'>Drug Type:</h5>
                <select name="drug_type" id="explicit-width-input" className='text-input-select text-left' value={drugType} onChange={e => setDrugType(e.target.value)}>
                    <option value="Small Molecule" defaultValue>Small Molecule</option>
                    <option value="Biologic">Biologic</option>
                </select>
                <h5 className='input-header'>Geographies:</h5>
                <select name="geographies" id="geographies" disabled>
                    <option value="United States" defaultValue>United States</option>
                </select>
                <p id="geographies_note">We are working on adding additional geographies.</p>
                <h5 className='input-header'>Therapeutic Areas:</h5>
                <select id="explicit-width-input" className='text-input-select text-left' value={therapeuticArea} onChange={e => setTherapeuticArea(e.target.value)}>
                    <option value="allergy">Allergy</option>
                    <option value="autoimmune">Autoimmune</option>
                    <option value="cardiovascular">Cardiovascular</option>
                    <option value="endocrine">Endocrine</option>
                    <option value="gastrointestinal">Gastrointestinal</option>
                    <option value="hematology">Hematology</option>
                    <option value="infectious_disease">Infectious Disease</option>
                    <option value="metabolic">Metabolic</option>
                    <option value="musculoskeletal">Musculoskeletal</option>
                    <option value="neurology">Neurology</option>
                    <option value="psychiatry">Psychiatry</option>
                    <option value="oncology">Oncology</option>
                    <option value="ophthalmology">Ophthalmology</option>
                    <option value="respiratory">Respiratory</option>
                    <option value="urology">Urology</option>
                    <option value="none">None</option>
                </select>
            </div>
        </div>
      )
    
      return data;
}

export default InputsDrugInformation