import React, {useEffect, useState, useCallback, useRef} from 'react'
import CollapsingContainer from '../../../util/CollapsingContainer'
import GeoWarnings from './GeoWarnings'
import geoDelIcon from "../../../../icons/bancroft_X_icon.svg"
import geoCheckIcon from "../../../../icons/bancroft_checkbox.svg"

import { deepClone } from '../../../../functions/helpers/generalHelpers'

import { GEOGRAPHIES_CONST } from '../../../../constants'
import { INITIAL_DATA_OBJ } from '../../../../initials'

const InputsGeography = ({userSettings, changeUserSettings, currGeographyIndex, setCurrGeographyIndex, workingGeographies, setWorkingGeographies, workingForecast, setWorkingForecast, engagedSection, setEngagedSection}) => {

    const [newGeography, setNewGeography] = useState("")
    const [isAllGeographies, setIsAllGeographies] = useState(false);
    const [displayed, setDisplayed] = useState([workingGeographies[0]]);
    const [warnings, setWarnings] = useState({mult: false, del: false});
    const [confirmActions, setConfirmActions] = useState({mult: "default", del: "default"});
    const [heldGeo, setHeldGeo] = useState(null);

    const thisHereRef = useRef(null);

    useEffect(() => {
        if (isAllGeographies) {
            setDisplayed(["All Geographies"])
        } else {
            if (workingGeographies.length > 2) {
                setDisplayed(["Multiple Geographies", ...workingGeographies])
            } else {
                setDisplayed([...workingGeographies]);
            }
        }
    }, [isAllGeographies, workingGeographies])

    const makeTarget = useCallback((geo) => {
        if (geo === "All Geographies") {
            if (isAllGeographies) {
                setIsAllGeographies(false);
                setWorkingGeographies([]);
            } else {
                setIsAllGeographies(true);
                setWorkingGeographies([...workingForecast.geographies]);
                setWorkingForecast(prev => {
                    const copy = deepClone(prev);
                    const copyG = deepClone(copy.geographiesData);
                    workingForecast.geographies.forEach((geog) => {
                        const preservePatients = deepClone(copyG[geog].general.geographiesPatients);
                        const templateGeo = deepClone(copyG[workingForecast.geographies[0]]);
                        templateGeo.general.geographiesPatients = preservePatients;
                        copyG[geog] = templateGeo;
                    });
                    
                    return { ...copy, geographiesData: copyG };
                });
            }
        } else {
            if (isAllGeographies) {
                setIsAllGeographies(false);
                setWorkingGeographies([geo]);
                setCurrGeographyIndex(workingForecast.geographies.indexOf(geo));
            } else {
                if (workingGeographies.indexOf(geo) === -1) {
                    if (workingGeographies.length === 0) {
                        setCurrGeographyIndex(workingForecast.geographies.indexOf(geo));
                    }
                    if (workingGeographies.length + 1 === workingForecast.geographies.length && workingGeographies.length + 1 > 2) {
                        setIsAllGeographies(true);
                        setWorkingGeographies([...workingForecast.geographies]);
                        setWorkingForecast(prev => {
                            const copy = deepClone(prev);
                            const copyG = deepClone(copy.geographiesData);
                            
                            workingForecast.geographies.forEach((geog) => {
                                const preservePatients = deepClone(copyG[geog].general.geographiesPatients);
                                const templateGeo = deepClone(copyG[workingForecast.geographies[0]]);
                                templateGeo.general.geographiesPatients = preservePatients;
                                copyG[geog] = templateGeo;
                            });
                            
                            return { ...copy, geographiesData: copyG };
                        });
                    } else {
                        let copyArr = [...workingForecast.geographies];
                        let certain = [...workingGeographies, geo];
                        setWorkingGeographies(prev => {
                            let pre = [...prev, geo];
                            pre.sort((a, b) => {
                                return copyArr.indexOf(a) - copyArr.indexOf(b);
                            })
                            return [...pre];
                        })
                        setWorkingForecast(prev => {
                            const copy = deepClone(prev);
                            const copyG = deepClone(copy.geographiesData);
                            
                            certain.forEach((geog) => {
                                const preservePatients = deepClone(copyG[geog].general.geographiesPatients);
                                const templateGeo = deepClone(copyG[certain[0]]);
                                templateGeo.general.geographiesPatients = preservePatients;
                                copyG[geog] = templateGeo;
                            });
                            
                            return { ...copy, geographiesData: copyG };
                        });
                    }
                } else {
                    let pre = [...workingGeographies];
                    pre.splice(pre.indexOf(geo), 1)
                    setWorkingGeographies([...pre]);
                    if (pre.length === 1) {
                        setCurrGeographyIndex(workingForecast.geographies.indexOf(pre[0]));
                    }
                }
            }
        }
    }, [isAllGeographies, setCurrGeographyIndex, workingForecast, setWorkingForecast, setWorkingGeographies, workingGeographies])

    const handleMakeTarget = (geo) => {
        let passed = true;
        if ((geo === "All Geographies" && workingGeographies.length === workingForecast.geographies.length)) passed = false;
        if (workingGeographies.indexOf(geo) !== -1) passed = false;
        if (workingGeographies.length === 0) passed = false;
        if (passed) {
            if (!userSettings.multGeoVal) {
                setWarnings({...warnings, mult: true});
                setHeldGeo(geo);
            } else {
                makeTarget(geo)
            }
        } else {
            makeTarget(geo)
        }
    }

    const handleRemove = (geo) => {
        if (!userSettings.delGeoVal) {
            setWarnings({...warnings, del: true});
            setHeldGeo(geo);
        } else {
            removeGeo(geo)
        }
    }

    const removeGeo = useCallback((geo) => {
        let stable = workingForecast.geographies.length - 1 > 0;
        if (!stable) {
            setCurrGeographyIndex(-1);
        }
        setWorkingGeographies(prev => {
            let pre = [...prev];
            pre.splice(pre.indexOf(geo), 1)
            return [...pre];
        })
        setWorkingForecast(prevForecast => {
            let copy = deepClone(prevForecast);            
            let copyA = [...copy.geographies];
            const index = copyA.indexOf(geo);
            if (index > -1) {
                copyA.splice(index, 1);
            }
            let copyG = deepClone(copy.geographiesData);
            delete copyG[geo];
            copy.geographies = copyA;
            copy.geographiesData = copyG;
            return copy;
        });

    }, [setCurrGeographyIndex, setWorkingForecast, setWorkingGeographies, workingForecast.geographies.length])

    useEffect(() => {
        if (confirmActions.mult === "default" && confirmActions.del === "default") return
        if (confirmActions.mult === "confirmed") {
            setConfirmActions({...confirmActions, mult: "default"});
            setWarnings({...warnings, mult: false})
            makeTarget(heldGeo)
        }
        if (confirmActions.del === "confirmed") {
            removeGeo(heldGeo)
            setConfirmActions({...confirmActions, del: "default"});
            setWarnings({...warnings, del: false})
        }
    }, [confirmActions, heldGeo, makeTarget, removeGeo, warnings])
    
    const handleGeographies = (e) => {
        setNewGeography(e.target.value)
    }

    const submitNewGeography = (e) => {
        if (newGeography.length === 0) return;
        e.preventDefault()
        if(workingForecast.geographies.indexOf(newGeography) === -1) {
            setWorkingForecast(prevForecast => {
                let copy = deepClone(prevForecast);
                let copyG = deepClone(copy.geographiesData);
                copyG[newGeography] = deepClone(INITIAL_DATA_OBJ);
                copy.geographies = [...copy.geographies, newGeography];
                return { ...copy, geographiesData: copyG };
            });
            setNewGeography("");
        }
        if (currGeographyIndex === -1) setCurrGeographyIndex(0);
    }

    const handleCancel = (type) => {
        if (type === "mult") {
            setConfirmActions({...confirmActions, mult: "default"});
            setWarnings({...warnings, mult: false})
        } else {
            setConfirmActions({...confirmActions, del: "default"});
            setWarnings({...warnings, del: false})
        }
    }

    const cursorActions = (warnings.mult || warnings.del) ? {pointerEvents: "none"} : undefined;

    const data = (
        <div className='input-section active-section-background flex-col pad-horz-20' style={cursorActions}>
            <div className='input-and-label-standard'>
                <h5 ref={thisHereRef} className='input-header-geography '>Select Target Geography</h5>
                {warnings.mult && <GeoWarnings handleCancel={handleCancel} triggerRef={thisHereRef} type={"mult"} confirmActions={confirmActions} userSettings={userSettings} changeUserSettings={changeUserSettings} setConfirmActions ={setConfirmActions} />}
                {warnings.del && <GeoWarnings handleCancel={handleCancel} triggerRef={thisHereRef} type={"delete"} confirmActions={confirmActions} userSettings={userSettings} changeUserSettings={changeUserSettings} setConfirmActions={setConfirmActions} />}
                {workingForecast.geographies.length > 2 && <GeoSelectorComponent geo={"All Geographies"} handleMakeTarget={handleMakeTarget} isTarget={isAllGeographies}/>}
                {workingForecast.geographies.map((geo, index) => {
                    let isActive = workingGeographies.indexOf(geo) !== -1 && !isAllGeographies;
                    return <GeoSelectorComponent key={`geo-seletor${index}`} geo={geo} handleRemove={handleRemove} handleMakeTarget={handleMakeTarget} isTarget={isActive} />
                })}
                <h5 className='input-header-geography'>Add Additional Geography</h5>
                <div className='flex-row geo-div-xyz'>
                    <input id="input-select-geo-nav" className='text-input-standard text-left' list="geographies" type="text" onChange={(e) => handleGeographies(e)} value={newGeography}></input>
                        <datalist id="geographies">
                            {GEOGRAPHIES_CONST.map(item => {
                                return <option key={"geo-dropdown" + item}>{item}</option>
                            })}
                        </datalist>
                    <button id="add-geo-btn" className='input-action-btn general-btn' onClick={submitNewGeography}>Add</button>
                </div>
            </div>
        </div>
    )
    return (
        <CollapsingContainer displayed={displayed} containerName="Geography" data={data} engagedSection={engagedSection} setEngagedSection={setEngagedSection} isGeography={{}}/>
      )
}

const GeoSelectorComponent = ({geo, handleMakeTarget, handleRemove, isTarget}) => {
    return (
        <div className='flex-row geo-div-xyz'>
            {isTarget ? <img onClick={() => handleMakeTarget(geo)} id={isTarget ? "target-check-geo" : undefined} className='check-geo-sb' src={geoCheckIcon} alt={"select" + geo}></img>
                        :
                        <div onClick={() => handleMakeTarget(geo)} className='unchecked-geo'></div>
            }
            <p id={isTarget ? "geo-sb-text-engaged" : undefined} className='geo-sb-text'>{geo}</p>
            {geo !== "All Geographies" && <img onClick={() => handleRemove(geo)} className='x-geo-sb' src={geoDelIcon} alt={"delete" + geo}></img>}
        </div>
    )
}

export default InputsGeography
