import React, {useState} from 'react'

import { getPreLaunchPeriod } from '../../../../functions/helpers/dateHandling';

const PreLaunchColumn = ({colData, setWorkingForecast, activeGeography}) => {

    const [expenses, setExpenses] = useState(colData.expenses);

    const handleChange = (e) => {
        let value = e.target.value;
        setExpenses(value);
        setWorkingForecast(prev => {
            const copy = {...prev};
            copy.geographiesData[activeGeography].preLaunch[colData.index].expenses = value;
            return {...copy};
        })
    }

    const thisPeriod = getPreLaunchPeriod(colData);
  return (
    <div className='table-col-div flex-col dash-border border-bot' id="pre-launch-col">
        <div id="pre-launch-date" className='table-header-basic border-bot'>
            <h4 className='table-header-text'>{thisPeriod}</h4>
        </div>
        <div className='spacer-pl-expenses'>
            <h5 className='tbl-expenses-txt'>Pre-Launch</h5>
            <input id="expenses-input" className='text-input-standard' value={expenses} onChange={handleChange}></input>
        </div>
    </div>
  )
}

export default PreLaunchColumn