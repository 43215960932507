import React, {useState, useRef, useEffect} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { toCommaSeperatedString } from '../../../../functions/helpers/generalHelpers';

import { getPreLaunchPeriod } from '../../../../functions/helpers/dateHandling';


const EventTooltip = ({data}) => {
    return (
        <React.Fragment>
            {data.peakPen && <p className="graph-tooltip-text-e">Peak penetration achieved.</p>}
            {data.LOEEvent && <p className="graph-tooltip-text-e">Loss of exclusivity incurred.</p>}
            {data.newComp && data.newComp.length > 0 && (
            <React.Fragment>
                {data.newComp.map(item => {
                    return <p key={`newcomp${item}`} className="graph-tooltip-text-e">Competitor {item} joins the market.</p>
                })}
            </React.Fragment> 
            )}
        </React.Fragment>
    )
}

const LineChartComponent = ({forecastName, forecastType, chartData, type, period, graphWidth}) => {

    const geneHelperFn = (data, sample) => {
        // sample means we have competitors

        const determiner = sample ? sample : "Patients On Drug"
        if (data[determiner] === 0) return 0;
        if (!data[determiner]) return;
        if (sample) {
            return data[determiner];
        } else {
            return data[determiner].incident + data[determiner].prevalent;
        }
    }


    const colors = ["#3c8ab6", '#c3250b', "#c3890b", '#8e4db6', '#04A777', '#C36F09'];
    const chartCompetitors = [];
    if (type === "population" && !chartData[0]["Patients On Drug"]) {
        Object.keys(chartData[0]).forEach(key => {
            if (key !== "period" &&
                key !== "penetration" &&
                key !== "peakPen" &&
                key !== "Patients On Drug" &&
                key !== "tooltipLabel" &&
                key !== "newComp" &&
                key !== "genePatientsSplit" &&
                key !== "marketShare") {
                    chartCompetitors.push(key);
                }
        })
    }
    // useEffect(() => {
    //     const svg = document.querySelector('.recharts-surface'); // Assuming there's only one chart on the page
    //     if (svg) {
    //         const lines = Array.from(svg.querySelectorAll('.recharts-line-curve'));
    //         const dots = Array.from(svg.querySelectorAll('.recharts-dot'));
    //         lines.forEach(line => svg.removeChild(line));
    //         lines.forEach(line => svg.appendChild(line));
    //     }
    // }, [chartData]);
    // AWAIT GRANT FEEDBACK
    const formatter = (tick) => {
        let flag = tick < 0 ? -1 : 1;
        tick = Math.abs(tick);
        if (tick === 0) {
            return "0";
        } else if (tick < 10000) {
            return `${tick * flag}`;
        } else if (tick < 1000000) {
            return `${flag * (tick / 1000)} K`;
        } else {
            return `${flag * (tick / 1000000)} M`;
        }
    };

    const CustomizedYAxisTick = (props) => {
        const { x, y, payload } = props;
        let val = formatter(payload.value);
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={-5} y={-10} dy={16} textAnchor="end" fill="#000" style={{ fontWeight: '500', fontSize: "14px" }}>
              {val}
            </text>
          </g>
        );
      };

      const CustomizedXAxisTick = (props) => {
        const { x, y, payload, index } = props;
        let xmargin = 15;
        let length = chartData.length * 45;
        if (period === "quarter" || period === "month") {
            xmargin = 25;
            length /= 45;
            length *= 75;
        }
        let step = Math.ceil(length / graphWidth);
        if (index % step === 0) {
            return (
                <g transform={`translate(${x},${y})`}>
                  <text x={xmargin} y={0} dy={16} textAnchor="end" fill="#000" style={{ fontWeight: '500', fontSize: "14px" }}>
                    {payload.value}
                  </text>
                </g>
              );
        }
        return null;
      };


      // showing special dots

      
      const CustomizedDot = (props) => {
          const { cx, cy, fill, stroke} = props;
          const starPath1 = "M24.8,2.7C12.5,2.7,2.5,12.7,2.5,25s10,22.3,22.3,22.3s22.3-10,22.3-22.3S37.1,2.7,24.8,2.7z";
          const innerCirclePath1 = "M24.8,5.2c10.9,0,19.8,8.9,19.8,19.8s-8.9,19.8-19.8,19.8S5,35.9,5,25S13.9,5.2,24.8,5.2";
          const polygonPath1 = "M42,19.6 28.8,19.6 24.8,7.1 20.8,19.7 7.6,19.6 18.3,27.3 14.2,39.8 24.8,32.1 35.4,39.8 31.3,27.3";
          return (
            <svg x={cx - 7.5} y={cy - 7.5} width={15} height={15} viewBox="0 0 50 50">
                <path d={starPath1} stroke={fill} fill={stroke} />
                <path d={innerCirclePath1} stroke={fill} fill={"#FFF"} />
                <path d={polygonPath1} stroke={fill} fill={stroke} />
            </svg>
        );
    };
    const CustomActiveDot = (props) => {
        const { cx, cy, fill,stroke, payload, dataKey, name } = props;
        if (forecastType === "drug") {
            if (!payload[dataKey]) return null;
        } else  {
            if (type === "population") {
                if (!payload[name]) return null;
            } else {
                if (!payload[dataKey]) return null;
            }
        }
      if (payload.LOEEvent || (payload.newComp && payload.newComp.length > 0)|| payload.peakPen) { 
        const starPath2 = "M24.8,2.7C12.5,2.7,2.5,12.7,2.5,25s10,22.3,22.3,22.3s22.3-10,22.3-22.3S37.1,2.7,24.8,2.7z";
        const polygonPath2 = "M35.4,39.8 24.8,32.1 14.2,39.8 18.3,27.3 7.6,19.6 20.8,19.7 24.8,7.1 28.8,19.6 42,19.6 31.3,27.3";
        return (
            <svg x={cx - 7.5} y={cy - 7.5} width={15} height={15} viewBox="0 0 50 50">
                <path d={starPath2} stroke={fill} fill={stroke} />
                <path d={polygonPath2} stroke={fill} fill={"#fff"} />
            </svg>
        );
    }
      return <circle cx={cx} cy={cy} r={3} fill={fill} stroke={fill}/>;
    };
    
      const RenderDot = (props) => {
        const { cx, cy, stroke, payload, dataKey } = props;
        if (forecastType === "drug") {
            if (!payload[dataKey]) return null;
        } else  {
            if (type === "population") {
                if (!payload[props.name]) return null;
            } else {
                if (!payload[dataKey]) return null;
            }
        }
        if (payload.LOEEvent || (payload.newComp && payload.newComp.length > 0) || payload.peakPen) {
          return <CustomizedDot cx={cx} cy={cy} fill={stroke} stroke={stroke}/>;
        }
        // Return null to use the default dot
        return <circle cx={cx} cy={cy} r={3} fill={"#fff"} stroke={stroke} strokeWidth={2}/>;
    };


    // may customize with code here later
    // add content={<CustomTooltip />} to tooltip

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          let sh = payload[0].payload;
          const determiner = "genePatientsSplit" in sh ? "genePatientsSplit" : "Patients On Drug";
          return (
            <div className="recharts-defualt-tooltip tooltip-div">
              <p className="recharts-tooltip-label tooltip-label">{payload[0].payload.tooltipLabel}</p>
              {chartCompetitors.length === 0 ? 
                forecastType === "drug" || payload[0].value === 0
                ?
                    <div className="graph-tooltip-item">
                        <div className='graph-legend-circle' style={{backgroundColor: payload[0].color}}></div>
                        <span className="graph-tooltip-text">{`${payload[0].name}: ${toCommaSeperatedString(payload[0].value)}`}</span>
                    </div>
                :
                    <React.Fragment>
                    <div style={{marginBottom: 0}} className="graph-tooltip-item">
                        <div className='graph-legend-circle' style={{backgroundColor: payload[0].color}}></div>
                        <span className="graph-tooltip-text">{`${payload[0].name}: ${toCommaSeperatedString(payload[0].value)}`}</span>
                    </div>
                    <div id="gene-tooltip-item">
                        <span id='gene-tooltip-patients' className='graph-tooltip-text'>{`Prevalent: ${toCommaSeperatedString(sh[determiner].prevalent)}`}</span>
                        <br></br>
                        <span id='gene-tooltip-patients' className='graph-tooltip-text'>{`Incident: ${toCommaSeperatedString(sh[determiner].incident)}`}</span>
                    </div>
                    </React.Fragment>
                :
                forecastType === "drug"
                ?
                <React.Fragment>
                    {payload.map((entry, index) => {
                        if (entry.dataKey === "tooltipLabel") return null
                        return (
                            <div key={`item-${index}`} className="graph-tooltip-item">
                                <div className='graph-legend-circle' style={{backgroundColor: entry.color}}></div>
                                <span className="graph-tooltip-text">{`${entry.name}: ${toCommaSeperatedString(entry.value)}`}</span>
                            </div>
                        )
                    })}
                </React.Fragment>
                :
                <React.Fragment>
                    {payload.map((entry, index) => {
                        if (entry.dataKey === "tooltipLabel") return null
                        const renderedDetails = determiner === "genePatientsSplit" ?
                            entry.name === forecastName ? 
                            <React.Fragment>
                            <div style={{marginBottom: 0}} className="graph-tooltip-item">
                                <div className='graph-legend-circle' style={{backgroundColor: payload[0].color}}></div>
                                <span className="graph-tooltip-text">{`${payload[0].name}: ${toCommaSeperatedString(payload[0].value)}`}</span>
                            </div>
                            <div id="gene-tooltip-item">
                                <span id='gene-tooltip-patients' className='graph-tooltip-text'>{`Prevalent: ${toCommaSeperatedString(sh[determiner].prevalent)}`}</span>
                                <br></br>
                                <span id='gene-tooltip-patients' className='graph-tooltip-text'>{`Incident: ${toCommaSeperatedString(sh[determiner].incident)}`}</span>
                            </div>
                            </React.Fragment>
                            :
                            <div key={`item-${index}`} className="graph-tooltip-item">
                                <div className='graph-legend-circle' style={{backgroundColor: entry.color}}></div>
                                <span className="graph-tooltip-text">{`${entry.name}: ${toCommaSeperatedString(entry.value)}`}</span>
                            </div>
                            :
                            <div key={`item-${index}`} className="graph-tooltip-item">
                                <div className='graph-legend-circle' style={{backgroundColor: entry.color}}></div>
                                <span className="graph-tooltip-text">{`${entry.name}: ${toCommaSeperatedString(entry.value)}`}</span>
                            </div>
                        return renderedDetails;
                    })}
                </React.Fragment>
              }
              {forecastType === "drug" || sh.penetration === 0
                ?
                <div className="graph-tooltip-item">
                    <div className='graph-legend-circle' style={{backgroundColor: "#000"}}></div>
                    <span className="graph-tooltip-text">{`Penetration: ${sh.penetration}`}</span>
                </div>
                :
                <React.Fragment>
                <div style={{marginBottom: 0}} className="graph-tooltip-item">
                <div className='graph-legend-circle' style={{backgroundColor: "#000"}}></div>
                    <span className="graph-tooltip-text">{`Penetration:`}</span>
                </div>
                <div id="gene-tooltip-item">
                    <span id='gene-tooltip-patients' className='graph-tooltip-text'>{`Prevalent: ${sh.penetration.prevalent}`}</span>
                    <br></br>
                    <span id='gene-tooltip-patients' className='graph-tooltip-text'>{`Incident: ${sh.penetration.incident}`}</span>
                </div>
                </React.Fragment>
              }
              {payload[0].payload.marketShare && chartCompetitors.length === 0 &&
                <div className="graph-tooltip-item">
                    <div className='graph-legend-circle' style={{backgroundColor: "#000"}}></div>
                    <span className="graph-tooltip-text">{`Market Share: ${sh.marketShare}`}</span>
                </div>
              }
              {(sh.peakPen) && <EventTooltip data={{peakPen: sh.peakPen}}/>}
            </div>
          );
        }
      
        return null;
      };

      const CustomSalesTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            let sh = payload[0].payload;
            return (
                <div className="recharts-defualt-tooltip tooltip-div">
                    <p className="recharts-tooltip-label tooltip-label">{payload[0].payload.tooltipLabel}</p>
                    {payload.map((entry, index) => {
                        return (
                            <div key={`item-${index}`} className="graph-tooltip-item">
                                <div className='graph-legend-circle' style={{backgroundColor: entry.color}}></div>
                                <span className="graph-tooltip-text">{`${entry.name}: ${toCommaSeperatedString(entry.value)}`}</span>
                            </div>
                        )
                    })}
                    {(sh.LOEEvent || (sh.newComp && sh.newComp.length > 0)) && <EventTooltip data={{LOEEvent: sh.LOEEvent, newComp: sh.newComp}}/>}
                </div>
            )
        }
        return null;
      };

      const CustomLegend = (props) => {
        const { payload } = props;
        return (
          <div className='graph-legend'>
            {payload.map((entry, index) => {
                if (entry.dataKey === "tooltipLabel") return null;
                return(
              <div key={`item-${index}`} className="graph-legend-item">
                {/* Custom symbol */}
                <div className='graph-legend-circle' style={{backgroundColor: entry.color}}></div>
                {/* Custom text */}
                <span>{entry.value}</span>
              </div>
            )
                })}
          </div>
        );
      };

    // default sales

    let whichTooltip = <Tooltip content={CustomSalesTooltip} />

    let lines = (
        <React.Fragment>
            <Line strokeWidth={2} activeDot={<CustomActiveDot stroke={'#3c8ab6'} />} dot={<RenderDot stroke={'#3c8ab6'} />} type="monotone" dataKey="Sales" stroke="#3c8ab6" />
            <Line strokeWidth={2} activeDot={<CustomActiveDot stroke={"#c3250b" } />} dot={<RenderDot stroke={"#c3250b"} />} type="monotone" dataKey="COGS" stroke="#c3250b" />
            <Line strokeWidth={2} activeDot={<CustomActiveDot stroke={"#8e4db6"} />} dot={<RenderDot stroke={"#8e4db6"} />} type="monotone" dataKey="Expenses" stroke="#8e4db6" />
            <Line strokeWidth={2} activeDot={<CustomActiveDot stroke={"#c3890b"} />} dot={<RenderDot stroke={"#c3890b"} />} type="monotone" dataKey="Gross Sales" stroke="#c3890b" />

        </React.Fragment>
    )
    if (type === "population") {
        whichTooltip = <Tooltip content={CustomTooltip} />
        lines = (
            <React.Fragment>
                {chartCompetitors.length > 0 ? 
                    forecastType === "drug" 
                    ?
                    <React.Fragment>
                        {chartCompetitors.map((sample, index) => {
                            let thisStroke = colors[index];
                            if (index === 0) {
                                return (<Line key={`line${index}xbc`} strokeWidth={2} activeDot={<CustomActiveDot stroke={thisStroke} />} dot={<RenderDot stroke={thisStroke} />} type="monotone" dataKey={sample} stroke={thisStroke} />)
                            } else {
                                return (<Line key={`line${index}xbc`} strokeWidth={2} type="monotone" dataKey={sample} stroke={thisStroke} />)
                            }
                        })}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {chartCompetitors.map((sample, index) => {
                            let thisStroke = colors[index];
                            if (index === 0) {
                                return (<Line key={`line${index}xbc`} strokeWidth={2} activeDot={<CustomActiveDot name={sample} stroke={thisStroke} />} dot={<RenderDot stroke={thisStroke} />} type="monotone" dataKey={(data) => geneHelperFn(data, sample)} name={sample} stroke={thisStroke} />)
                            } else {
                                return (<Line key={`line${index}xbc`} strokeWidth={2} type="monotone" dataKey={(data) => geneHelperFn(data, sample)} name={sample} stroke={thisStroke} />)
                            }
                        })}
                    </React.Fragment>
                :
                forecastType === "drug" ? 
                <Line strokeWidth={2} activeDot={<CustomActiveDot stroke={"#3c8ab6"} />} dot={<RenderDot stroke={"#3c8ab6"} />} type="monotone" dataKey="Patients On Drug" stroke="#3c8ab6" />
                :
                <React.Fragment>
                    <Line strokeWidth={2} activeDot={<CustomActiveDot name={"Patients On Drug"} stroke={"#3c8ab6"} />} dot={<RenderDot stroke={"#3c8ab6"} />} type="monotone" dataKey={data => geneHelperFn(data)} name="Patients On Drug" stroke="#3c8ab6" />
                </React.Fragment>
            }
            </React.Fragment>
        )
    } else if (type !== "sales") {
        console.warn("sus code")
    }


    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis interval={0} tick={<CustomizedXAxisTick />} dataKey="period" />
          <YAxis tick={<CustomizedYAxisTick />} />
          {whichTooltip}
          <Legend content={<CustomLegend />}/>
          {lines}
        </LineChart>
      </ResponsiveContainer>
    );
}

export default LineChartComponent