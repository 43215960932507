import React, {useState, useEffect, useCallback} from 'react'

import "../../../css/navElements.css"
import { calculateIRR, calculateNPV } from "../../../functions/helpers/financeTools"
import { roundToTwoDecimals, toCommaSeperatedString } from '../../../functions/helpers/generalHelpers'
import WarningPopupSmall from '../../util/WarningPopupSmall'
import closeIcon from "../../../icons/bancroft_X_icon.svg"

const FinanceComponent = ({financeData, setShowFinance, setFinanceData}) => {
  const [warning, setWarning] = useState(false);
  const [warning2, setWarning2] = useState(false)
  const [discountRate, setDiscountRate] = useState(financeData.discountRate);
  const [initialInvestment, setInitialInvestment] = useState(financeData.initialInvestment);
  const [NPV, setNPV] = useState(financeData.NPV);
  const [IRR, setIRR] = useState(financeData.IRR);

  const getCashFlows = (flows) => {
    let cashFlows = [];
    Object.keys(flows).forEach(k => {
        flows[k].forEach((cash, index) => {
            if (cashFlows[index]) {
                cashFlows[index] += Math.round(cash / 10000);
            } else {
                cashFlows[index] = Math.round(cash / 10000);
            }
        })
    })
    return cashFlows;
  } 

  const handleInvestment = (e) => {
    let val = parseFloat(e.target.value);
    if (warning && val >= 0) setWarning(false);
    setInitialInvestment(e.target.value);
  }

  const handleDiscountRate = (e) => {
    let val = parseFloat(e.target.value);
    if (warning2 && val >= 0) setWarning2(false);
    setDiscountRate(e.target.value);
  }

  const handleIRR = useCallback(() => {
    if (parseFloat(initialInvestment) <= 0 && financeData.cashFlows[0] > 0) {
        setWarning(true);
    } else {
        let cashFlows = getCashFlows(financeData.cashFlows);
        let investment = Math.round((parseFloat(initialInvestment) / -10000));
        let newIRR = calculateIRR([investment, ...cashFlows])
        setIRR(newIRR);
        setFinanceData({...financeData, initialInvestment: initialInvestment, IRR: newIRR});
    }
}, [financeData, initialInvestment, setFinanceData])

const handleNPV = useCallback(() => {
    if (parseFloat(discountRate) < 0) {
        setWarning2(true);
    } else {
        let cashFlows = getCashFlows(financeData.cashFlows);
        let newNPV = calculateNPV((parseFloat(discountRate) / 100), [...cashFlows]) * 10000;
        setNPV(newNPV);
        setFinanceData({...financeData, discountRate: discountRate, NPV: newNPV});
    }
}, [discountRate, financeData, setFinanceData])

 useEffect(() => {
    if (financeData.initialInvestment > 0 && discountRate >= 0) {        
        handleIRR();
        handleNPV();
    }
  }, [])

  return (
    <div className='nav-popup-basic'>
        <div id="fin-head" className='flex-row p-c-t-d'>
            <h4 className='p-calc-title'>NPV & IRR Calculator</h4>
            <img id="calc-icon-x" onClick={() => setShowFinance(false)} className='container-icon' src={closeIcon} alt={"close finance"}></img>
        </div>
        <h5 className='input-header'>Discount Rate ( % )</h5>
        <input className='text-input-standard text-left' type="number"  onChange={handleDiscountRate} value={discountRate}></input>
        <button id="fin-btn" className='input-action-btn general-btn' onClick={handleNPV}>Calculate NPV</button>
        <h5 id="fin-1" className='input-header'>Initial Investment</h5>
        <input className='text-input-standard text-left' type="number"  onChange={handleInvestment} value={initialInvestment}></input>
        <button id="fin-btn" className='input-action-btn general-btn' onClick={handleIRR}>Calculate IRR</button>
        {warning && <WarningPopupSmall message={<React.Fragment>Initial investment must be a <br/> number larger than 0. <br/><br/>Discount rate should not be negative.</React.Fragment>}/>}
        <div id="fin-hr" className='modal-hr'></div>
        <h4 className='fin-2'>Net Present Value: {NPV !== 0 && toCommaSeperatedString(NPV)}</h4>
        <h4 className='fin-2'>Internal Rate of Return: {IRR !== 0 && roundToTwoDecimals(IRR) + "%"}</h4>
    </div>
  )
}

export default FinanceComponent