import React, {useEffect, useState, useRef} from 'react'
import { Link, useNavigate } from "react-router-dom";

import "../../css/summary.css"
import FolderView from './FolderView';
import SummarySidebar from './SummarySidebar';
import ActiveSummaryView from './ActiveSummaryView';
import Notepad from '../notepad/Notepad';

import userIcon from "../../icons/bancy-user.svg";
import settingIcon from "../../icons/bancy-settings.svg";
import folderIcon from "../../icons/bancroft_icons_folder.svg"
import notepadIcon from "../../icons/bancroft_top_note_icon.svg"

const SummaryPage = ({ addNoteToDB, delNoteFromDB, saveOrUpdateSummaries, notepadRef, summariesState, setSummariesState, notes, setNotes, notepadPresets, setNotepadPresets}) => {
  const [activeFolder, setActiveFolder] = useState(null);
  const [activeSummary, setActiveSummary] = useState(null);
  const [showNotepad, setShowNotepad] = useState(false);

// const notepadRef = useRef(null);
const navigate = useNavigate();

  useEffect(() => {
    setActiveFolder(prev => {
        if (prev) {
            let index = summariesState.findIndex(item => item.title === prev.title);
            if (index !== -1) {
                return {...summariesState[index]};
            }
        }
    })
  }, [summariesState])


  const handleNewSummary = () => {
    setActiveSummary("new");
  }

  const handleMoveToFolder = () => {
    let val = summariesState.filter(item => {
        if (item.documents.findIndex(itm => itm.title === activeSummary.title) !== -1) {
            return true;
        } else {
            return false;
        }
    })
    if (val[0]) {
        setActiveFolder(val[0])
        setActiveSummary(null);
    }
  }

  let activeSummaryFolder = (activeSummary && activeSummary.timeStamp) ? true : false;
  if (activeSummaryFolder) {
    let val = summariesState.filter(item => {
        if (item.documents.findIndex(itm => itm.title === activeSummary.title) !== -1) {
            return true;
        } else {
            return false;
        }
    })
    activeSummaryFolder = val[0].title;
  }

  const internalMakeNote = (noteTitle, noteBody) => {
    setShowNotepad(true);
    setNotes(prev => {
        let newNote = "";
        const newNoteTitle = noteTitle ? `<h1>${noteTitle}</h1><br></br>` : "";
        newNote += newNoteTitle;
        const newNoteBody = noteBody.split("\n");
        console.log(newNoteBody)
        newNoteBody.forEach(para => {
            if (para.length > 0) {
                newNote += `<p>${para}</p><br>`
            }
        })
        return [`${newNote}`, ...prev];
    })
  }

  const closeNotepad = () => {
    if (!showNotepad) {
        setShowNotepad(true);
    } else {
        if (notepadRef.current) {
            notepadRef.current.handleCloseNotepad();
        }
    }
  }

  return (
    <div className='summary-main max-window'>
        <SummarySidebar saveOrUpdateSummaries={saveOrUpdateSummaries} summariesState={summariesState} setSummariesState={setSummariesState} setActiveFolder={setActiveFolder} setActiveSummary={setActiveSummary}/>
        <div className='summary-content'>
            <div className='navBar-main'>
                <h1 className='nav-forecast-title'>Summary</h1>
                {activeSummaryFolder && 
                    <div className='as-folder-top flex-row'>
                        <img id="as-icon-top" className='folder-icon' alt="show-file" src={folderIcon}></img>
                        <h5 onClick={handleMoveToFolder} className='as-folder-top-txt'>{activeSummaryFolder}</h5>
                    </div>
                }
                
                <div className='nav-elems'>
                    <div className='nav-elem'>
                        <Link to="/forecast"><button className='nav-link-btn'>FORECAST</button></Link>
                    </div>
                    <div id="nav-notepad" onClick={closeNotepad} className='nav-elem flex-row'>
                        <img className="svg-icon" src={notepadIcon} alt="notepad" />
                        <h4 className='nav-elem-text'>Notes</h4>
                    </div>
                    <div className='nav-elem flex-row' onClick={() => navigate('/user')}>
                        <img className="svg-icon" src={userIcon} alt="user" />
                        <h4 className='nav-elem-text'>User Profile</h4>
                    </div>
                    <div className='nav-elem flex-row'>
                        <img className="svg-icon" src={settingIcon} alt="settings" />
                        <h4 className='nav-elem-text'>Settings</h4>
                    </div>
                </div>
                {showNotepad && <Notepad ref={notepadRef} addNoteToDB={addNoteToDB} delNoteFromDB={delNoteFromDB} notepadPresets={notepadPresets} setNotepadPresets={setNotepadPresets} showNotepad={showNotepad} setShowNotepad={setShowNotepad} notes={notes} setNotes={setNotes}/>}
            </div>
            {activeFolder && !activeSummary && <FolderView saveOrUpdateSummaries={saveOrUpdateSummaries} setActiveSummary={setActiveSummary} folder={activeFolder} handleNewSummary={handleNewSummary} summariesState={summariesState} setSummariesState={setSummariesState}/>}
            {activeSummary && <ActiveSummaryView saveOrUpdateSummaries={saveOrUpdateSummaries} internalMakeNote={internalMakeNote} activeSummary={activeSummary} summariesState={summariesState} setSummariesState={setSummariesState} />}

        </div>
    </div>
  )
}

export default SummaryPage