import '../css/quickAnalysis.css';

import React, { useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import QuickAnalysisPage from '../components/quickAnalysisPage/QuickAnalysisPage';
import { useNavigate } from 'react-router-dom';
import { example1 } from '../exampleForecasts';

const QuickAnalysisRoute = ({activeUser}) => {

    const navigate = useNavigate();

    return (

        <QuickAnalysisPage />

    )
}




export default QuickAnalysisRoute