import React from 'react'
import { useState } from 'react'

import pencilIcon from "../../../../icons/pen-solid.svg"
import { toCommaSeperatedString } from '../../../../functions/helpers/generalHelpers';

const EditableField = ({index, currGeography, fieldData, borderTop, evenBackground, wStyle, isExpenses, setWorkingForecast}) => {
  
  const [data, setData] = useState(fieldData);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    setData(e.target.value)
  }

  const handleEdit = () => {
    if (isEditing) {
        setIsEditing(false);
        setWorkingForecast(prev => {
            const copy = {...prev};
            copy.geographiesData[currGeography].expenses[index] = data;
            return {...copy};
        })
    } else {
        setIsEditing(true);
    }
  }

  const displayed = isExpenses ? toCommaSeperatedString(fieldData) : fieldData


  let classInfo = borderTop ? 'single-edit-field dash-border border-bot border-top flex-row alignCenter' : 'flex-row alignCenter single-edit-field dash-border border-bot';
  return (
    <div style={wStyle} id={evenBackground ? "even-bg" : null} className={classInfo}>
        {!isEditing && <p className='single-edit-field-text'>{displayed}</p> }
        {isExpenses && 
            <React.Fragment>
                {isEditing && <input id="tbl-input-field" className='text-input-standard' value={data} onChange={handleChange}></input>}
                <img onClick={handleEdit} id="pencil-edit-tbl" className='svg-icon' src={pencilIcon} alt='edit expenses'></img>
            </React.Fragment> 
        }
    </div>
  )
}


export default EditableField