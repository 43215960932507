import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'

const ForecastEvent = ({data, triggerRef}) => {

    const [position, setPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
      const rect = triggerRef.current.getBoundingClientRect();
      const top = rect.top + window.scrollY;
      const left = rect.left + window.scrollX;
      setPosition({ top, left });
    }, [triggerRef]);
  
    const style = {
      position: 'absolute',
      top: `${position.top}px`,
      left: `${position.left}px`,
      // your other styles
    };
  const polygonPath2 = "M35.4,39.8 24.8,32.1 14.2,39.8 18.3,27.3 7.6,19.6 20.8,19.7 24.8,7.1 28.8,19.6 42,19.6 31.3,27.3";
  const starPath2 = "M24.8,2.7C12.5,2.7,2.5,12.7,2.5,25s10,22.3,22.3,22.3s22.3-10,22.3-22.3S37.1,2.7,24.8,2.7z";
  return ReactDOM.createPortal(
    <div className='forecast-event-blurb flex-row' style={style}>
        <div className='blurb-svg'>
            <svg height={18} viewBox="0 0 50 50">
                <path d={starPath2} stroke={"#fff"} fill={"#fff"} />
                <path d={polygonPath2} stroke={"#fff"} fill={"#f6921e"} />
            </svg>
        </div>
        <div className='blurb-data'>
            {data.peakPen && <h4 className='forecast-event-blurb-text'>Peak penetration achieved.</h4>}
            {data.LOEEvent && <h4 className='forecast-event-blurb-text'>Loss of exclusivity incurred.</h4>}
            {data.newComp.length > 0 && (
            <React.Fragment>
                {data.newComp.map(item => {
                    return <h4 key={`newcomp${item}`} className='forecast-event-blurb-text'>Competitor {item} joins the market.</h4>
                })}
            </React.Fragment>
            )}
        </div>
      </div>
      ,
      document.body // This tells React to append this div directly to <body>
  );
}

export default ForecastEvent