const example1 = {
    type: "drug",
    name: "blackSheep",
    geographies: ["United States", "China", "Indonesia"],
    geographiesData: {
        "United States": {
            preLaunch: [],
            expenses: [],

            general: {
                period: "quarter",
                startDate: "Q1/2022",
                length: 24,
                penetration: 15,
                incidentPenetration: 0,
                prevalentPenetration: 0,
                yearsToPeakPenetration: 2,
                slope: "linear",
                price: 90000,
                diseaseName: "krabbe",
                geographiesPatients: {
                        "total": 334856000,
                        "births": 3722822,
                        "patients": 46880,
                        "prevalence": {
                            "decimal": 0.00014,
                            "ratio": {
                                "numerator": 14,
                                "denominator": 100000
                            }
                        },
                        "primary": "prevalence"
                }
            },
            drug: {
                cogs: 25,
                preLOE: 4,
                postLOE: -3,
                LOE: -30,
                dateOfLOE: "Q2/2024",
                // tbd
                exclusivity: [],
                //
                route: "oral",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [
                {
                    name: "drug1",
                    approval: true, // Approved, not Approved
                    approvalDate: "Q2/2023",
                    dateOfLOE: "",
                    penetration: 0,
                    incidentPenetration: 0,
                    prevalentPenetration: 0,
                    yearsToPeakPenetration: 2,
                    duration: "chronic", // Chronic, Acute, etc...
                    
                    // not in use  
                    expectedSales: 0,
                    
                    // possibly autofill these values? 
                    noOfApprovedDrugs: 0,
                    noOfPipelineDrugs: 0,
                },
                {
                    name: "drug2",
                    approval: true, // Approved, not Approved
                    approvalDate: "Q4/2024",
                    dateOfLOE: "",
                    penetration: 0,
                    incidentPenetration: 0,
                    prevalentPenetration: 0,
                    yearsToPeakPenetration: 3,
                    duration: "chronic", // Chronic, Acute, etc...
                    
                    // not in use  
                    expectedSales: 0,
                    
                    // possibly autofill these values? 
                    noOfApprovedDrugs: 0,
                    noOfPipelineDrugs: 0,
                }
            ], 
        },
        "China": {
            preLaunch: [],
            expenses: [],
            general: {
                period: "quarter",
                startDate: "Q1/2022",
                length: 24,
                penetration: 15,
                incidentPenetration: 0,
                prevalentPenetration: 0,
                yearsToPeakPenetration: 2,
                slope: "linear",
                price: 90000,
                diseaseName: "",
                geographiesPatients: {
                        "total": 1411750000,
                        "births": 10881567,
                        "patients": 197645,
                        "prevalence": {
                            "decimal": 0.00014,
                            "ratio": {
                                "numerator": 14,
                                "denominator": 100000
                            }
                        },
                        "primary": "prevalence"
                }
            },
            drug: {
                cogs: 25,
                preLOE: 4,
                postLOE: -3,
                LOE: -30,
                dateOfLOE: "Q2/2024",
                // tbd
                exclusivity: [],
                //
                route: "oral",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [
                {
                    name: "drug1",
                    approval: true, // Approved, not Approved
                    approvalDate: "Q2/2023",
                    dateOfLOE: "",
                    penetration: 0,
                    incidentPenetration: 0,
                    prevalentPenetration: 0,
                    yearsToPeakPenetration: 2,
                    duration: "chronic", // Chronic, Acute, etc...
                    
                    // not in use  
                    expectedSales: 0,
                    
                    // possibly autofill these values? 
                    noOfApprovedDrugs: 0,
                    noOfPipelineDrugs: 0,
                },
                {
                    name: "drug2",
                    approval: true, // Approved, not Approved
                    approvalDate: "Q4/2024",
                    dateOfLOE: "",
                    penetration: 0,
                    incidentPenetration: 0,
                    prevalentPenetration: 0,
                    yearsToPeakPenetration: 3,
                    duration: "chronic", // Chronic, Acute, etc...
                    
                    // not in use  
                    expectedSales: 0,
                    
                    // possibly autofill these values? 
                    noOfApprovedDrugs: 0,
                    noOfPipelineDrugs: 0,
                }
            ], 
        },
        "Indonesia": {
            preLaunch: [],
            expenses: [],

            general: {
                period: "quarter",
                startDate: "Q1/2022",
                length: 24,
                penetration: 15,
                incidentPenetration: 0,
                prevalentPenetration: 0,
                yearsToPeakPenetration: 2,
                slope: "linear",
                price: 90000,
                diseaseName: "",
                geographiesPatients: {
                        "total": 1411750000,
                        "births": 10881567,
                        "patients": 1900,
                        "prevalence": {
                            "decimal": 0.00014,
                            "ratio": {
                                "numerator": 14,
                                "denominator": 100000
                            }
                        },
                        "primary": "prevalence"
                }
            },
            drug: {
                cogs: 25,
                preLOE: 4,
                postLOE: -3,
                LOE: -30,
                dateOfLOE: "Q2/2024",
                // tbd
                exclusivity: [],
                //
                route: "oral",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [
                {
                    name: "drug1",
                    approval: true, // Approved, not Approved
                    approvalDate: "Q2/2023",
                    dateOfLOE: "",
                    penetration: 0,
                    incidentPenetration: 0,
                    prevalentPenetration: 0,
                    yearsToPeakPenetration: 2,
                    duration: "chronic", // Chronic, Acute, etc...
                    
                    // not in use  
                    expectedSales: 0,
                    
                    // possibly autofill these values? 
                    noOfApprovedDrugs: 0,
                    noOfPipelineDrugs: 0,
                },
                {
                    name: "drug2",
                    approval: true, // Approved, not Approved
                    approvalDate: "Q4/2024",
                    dateOfLOE: "",
                    penetration: 0,
                    incidentPenetration: 0,
                    prevalentPenetration: 0,
                    yearsToPeakPenetration: 3,
                    duration: "chronic", // Chronic, Acute, etc...
                    
                    // not in use  
                    expectedSales: 0,
                    
                    // possibly autofill these values? 
                    noOfApprovedDrugs: 0,
                    noOfPipelineDrugs: 0,
                }
            ], 
        }
    }
}

const molgramostimExample = {
    name: "Molgramostim",
    geographies: ["United States", "Pakistan", "Indonesia", "China", "Philippines"],
    geographiesData: {
        "United States": {
            preLaunch: [],
            expenses: [],

            general: {
                period: "year",
                startDate: "2026",
                length: 20,
                diseaseName: "aPAP",
                price: 150000,
                penetration: 50,
                yearsToPeakPenetration: 6,
                slope: "linear",
                geographiesPatients: {
                        "total": 334856000,
                        "births": 3722822,
                        "patients": 2344,
                        "prevalence": {
                            "decimal": 0.000007,
                            "ratio": {
                                "numerator": 7,
                                "denominator": 1000000
                            }
                        },
                        "primary": "prevalence"
                }
            },
            drug: {
                cogs: 25,
                preLOE: 0,
                postLOE: -1,
                LOE: -15,
                dateOfLOE: "2032",
                // tbd
                exclusivity: [],
                //
                route: "other",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [], 
        },
        "Indonesia": {
            preLaunch: [],
            expenses: [],

            general: {
                period: "year",
                startDate: "2026",
                length: 20,
                diseaseName: "aPAP",
                price: 150000,
                penetration: 50,
                yearsToPeakPenetration: 6,
                slope: "linear",
                geographiesPatients: {
                        "total": 277749853,
                        "births": 4496383,
                        "patients": 1945,
                        "prevalence": {
                            "decimal": 0.000007,
                            "ratio": {
                                "numerator": 7,
                                "denominator": 1000000
                            }
                        },
                        "primary": "prevalence",
                        "birthsIncidence": {
                            "decimal": 0.00111,
                            "ratio": {
                                "numerator": 111.00000000000001,
                                "denominator": 100000
                            },
                            "lifeExpectancy": 15
                        }
                    }
            },
            drug: {
                cogs: 25,
                preLOE: 0,
                postLOE: -1,
                LOE: -15,
                dateOfLOE: "2032",
                // tbd
                exclusivity: [],
                //
                route: "other",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [], 
        },
        "Pakistan": {
            preLaunch: [],
            expenses: [],

            general: {
                period: "year",
                startDate: "2026",
                length: 20,
                diseaseName: "aPAP",
                price: 150000,
                penetration: 50,
                yearsToPeakPenetration: 6,
                slope: "linear",
                geographiesPatients: {
                    "total": 220425254,
                    "births": 6374741,
                    "patients": 1543,
                    "prevalence": {
                        "decimal": 0.000007,
                        "ratio": {
                            "numerator": 7,
                            "denominator": 1000000
                        }
                    },
                    "primary": "prevalence"
                    }
            },
            drug: {
                cogs: 25,
                preLOE: 0,
                postLOE: -1,
                LOE: -15,
                dateOfLOE: "2032",
                // tbd
                exclusivity: [],
                //
                route: "other",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [], 
        },
        "Philippines": {
            preLaunch: [],
            expenses: [],

            general: {
                period: "year",
                startDate: "2026",
                length: 20,
                diseaseName: "aPAP",
                price: 150000,
                penetration: 50,
                yearsToPeakPenetration: 6,
                slope: "linear",
                geographiesPatients: {
                    "total": 110587187,
                    "births": 2485008,
                    "patients": 775,
                    "prevalence": {
                        "decimal": 0.000007,
                        "ratio": {
                            "numerator": 7,
                            "denominator": 1000000
                        }
                    },
                    "primary": "prevalence"
                    }
            },
            drug: {
                cogs: 25,
                preLOE: 0,
                postLOE: -1,
                LOE: -15,
                dateOfLOE: "2032",
                // tbd
                exclusivity: [],
                //
                route: "other",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [], 
        },
        "China": {
            preLaunch: [],
            expenses: [],

            general: {
                period: "year",
                startDate: "2026",
                length: 20,
                diseaseName: "aPAP",
                price: 150000,
                penetration: 50,
                yearsToPeakPenetration: 6,
                slope: "linear",
                geographiesPatients: {
                    "total": 1411750000,
                    "births": 10881567,
                    "patients": 9883,
                    "prevalence": {
                        "decimal": 0.000007,
                        "ratio": {
                            "numerator": 7,
                            "denominator": 1000000
                        }
                    },
                    "primary": "prevalence"
                    }
            },
            drug: {
                cogs: 25,
                preLOE: 0,
                postLOE: -1,
                LOE: -15,
                dateOfLOE: "2032",
                // tbd
                exclusivity: [],
                //
                route: "other",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [], 
        }
    }
    
}

const zygelExample = {
    name: "Zygel",
    geographies: ["United States", "Mexico"],
    geographiesData: {
        "United States": {
            preLaunch: [],
            expenses: [],

            general: {
                period: "year",
                startDate: "2026",
                length: 20,
                diseaseName: "FXS",
                penetration: 25,
                yearsToPeakPenetration: 7,
                slope: "logistic",
                price: 50000,
                geographiesPatients: {
                        "total": 334856000,
                        "births": 3722822,
                        "patients": 2344,
                        "prevalence": {
                            "decimal": 0.000007,
                            "ratio": {
                                "numerator": 7,
                                "denominator": 1000000
                            }
                        },
                        "primary": "prevalence"
                },
            },
            drug: {
                cogs: 25,
                preLOE: 1,
                postLOE: -1,
                LOE: -20,
                dateOfLOE: "2032",
                // tbd
                exclusivity: [],
                //
                route: "other",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [], 
        },
        "Mexico": {
            preLaunch: [],
            expenses: [],

            general: {
                period: "year",
                startDate: "2026",
                length: 20,
                diseaseName: "FXS",
                penetration: 25,
                yearsToPeakPenetration: 7,
                slope: "logistic",
                price: 50000,
                geographiesPatients: {
                    "total": 128665641,
                    "births": 1882362,
                    "patients": 901,
                    "prevalence": {
                        "decimal": 0.000007,
                        "ratio": {
                            "numerator": 7,
                            "denominator": 1000000
                        }
                    },
                    "primary": "prevalence"
                },
            },
            drug: {
                cogs: 25,
                preLOE: 1,
                postLOE: -1,
                LOE: -20,
                dateOfLOE: "2032",
                // tbd
                exclusivity: [],
                //
                route: "other",
                // TBD
                efficacy: 0,
                //
                duration: "chronic",
            },
            competition: [], 
        }
    }
}

export {example1, molgramostimExample, zygelExample}