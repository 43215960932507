import React, {useEffect, useState, useRef} from 'react'
import QuillEditor from './QuillEditor'
import QuillEditorMinimal from './QuillEditorMinimal';
import 'katex/dist/katex.min.css';
import 'react-resizable/css/styles.css';  // Don't forget to import the styles
import "../../css/notepad.css"
import { ResizableBox } from 'react-resizable';
import Draggable from 'react-draggable';
import addNoteIcon from "../../icons/bancroft_add_note_icon.svg";
import trashIcon from "../../icons/bancroft_trash_note_icon.svg";
import allNotesIcon from "../../icons/bancroft_list_note_icon.svg";
import exportIcon from "../../icons/bancroft_download_note_icon.svg";
import closeIcon from "../../icons/bancroft_X_icon.svg"
import useWindowDimensions from '../util/useWindomDimensions';
import getNoteTitle from './getNoteTitle';
import { v4 as uuidv4 } from "uuid"


const Notepad = React.forwardRef((props, ref) => {
    const {notes, setNotes, showNotepad, setShowNotepad, notepadPresets, setNotepadPresets, addNoteToDB, delNoteFromDB} = props;

    const childRef1 = useRef();
    const childRef2 = useRef();

    const { height, width } = useWindowDimensions();

    const [boxWidth, setBoxWidth] = useState(notepadPresets.dimensions ? notepadPresets.dimensions.x : 300);
    const [boxHeight, setBoxHeight] = useState(notepadPresets.dimensions ? notepadPresets.dimensions.y : 400);

    const [view, setView] = useState(((notes && notes.length === 0) || notepadPresets.lastNote === -1) ? "All Notes" : "default");
    const [noteTally, setNoteTally] = useState(notes.length > 0 ? notes.length : 0);
    const [activeNote, setActiveNote] = useState(notepadPresets.lastNote);
    const [quillActiveNote, setQuillActiveNote] = useState(activeNote);
    const [invalidExport, setInvalidExport] = useState(false)

    useEffect(() => {
        console.log(notes, quillActiveNote, activeNote)
    }, [notes, quillActiveNote, activeNote])

    const handleResize = (size) => {
        setBoxWidth(size.width);
        setBoxHeight(size.height);
    }

    const addNote = () => {
        const newNote = {
            tempId: uuidv4(),
            text: `<h1>Note ${noteTally + 1}</h1>`
        }
        setNotes(prev => {
            return [...prev, newNote];
        })
        setActiveNote(notes.length);
        setNotepadPresets({...notepadPresets, lastNote: notes.length})
        setNoteTally(noteTally + 1);
        addNoteToDB(newNote);
        if (view !== "default") setView("default")
    }  
      
      useEffect(() => {
        setQuillActiveNote(activeNote)
      }, [activeNote]);
      
   const setNote = (content, index) => {
    setNotes(prev => {
      if (prev[index] !== content) {
        let newNotes = [...prev];
        newNotes[index].text = content;
        return newNotes;
      }
      return prev;
    });
  }

  const deleteNote = () => {
    const forDB = notes[activeNote]._id;
    setNotes(prev => {
        let newNotes = [...prev];
        newNotes.splice(activeNote, 1);
        return [...newNotes];
    });
    setActiveNote(-1);
    setNotepadPresets({...notepadPresets, lastNote: -1});
    delNoteFromDB(forDB)
    setView("All Notes")
  }

  const handleExport = () => {
    if (boxWidth > 300) {
        childRef1.current.exportData();
    } else {
        childRef2.current.exportData();
    }
  }

    const handleChangeNote = (targetNote) => {
        setActiveNote(targetNote);
        setNotepadPresets({...notepadPresets, lastNote: targetNote})
        setView("default");
    }

    const handleAllNotes = () => {
        setView("All Notes");
        setActiveNote(-1);
        setNotepadPresets({...notepadPresets, lastNote: -1})

    }

    const handleStop = (event, dragElement) => {
        if (dragElement.x + boxWidth > 0 && dragElement.x + (boxWidth * 2) < width && dragElement.y + 20 > 0 && dragElement.y + 100 < height) {
            setNotepadPresets({...notepadPresets, position: {x: dragElement.x, y: dragElement.y}})
        } else {
            setNotepadPresets({...notepadPresets, position: {x: width - 700, y: 100}})
        }
    };

    const handleStopResize = (size) => {
        console.log(size)
        let newDimensions = false;
        if (boxWidth + 50 < width && boxHeight + 50 < height) newDimensions = {x: boxWidth, y: boxHeight};

        if (newDimensions) {
            setNotepadPresets({...notepadPresets, dimensions: {...newDimensions}})
        }

    }

    const handleCloseNotepad = () => {
        if (showNotepad) {
            let newDimensions = {x: 300, y: 400};
            let newNPPS = {...notepadPresets, lastNote: activeNote};
            if (newNPPS.position && newNPPS.position.x !== width - 700) {   
                if (boxWidth + 50 < width && boxHeight + 50 < height) newDimensions = {x: boxWidth, y: boxHeight};
            }
            newNPPS.dimensions = newDimensions;
            setNotepadPresets({...newNPPS})
            setShowNotepad(false)
        } else {
            setShowNotepad(true);
        }
    }
    
    React.useImperativeHandle(ref, () => ({
        handleCloseNotepad,
    }));
    const tabs = (
        <div className='note-tabs'>
            <h1>All Notes</h1>
            {   
                notes.map((note, index) => {
                    let noteTitle = getNoteTitle(note.text);
                    return <h2 onClick={() => handleChangeNote(index)} className='note-tab'>&bull; {noteTitle}</h2>
                })
            }
        </div>
    )


    return (
      <Draggable defaultPosition={notepadPresets.position ? notepadPresets.position : {x: width - 700, y: 100}} onStop={handleStop} handle=".drag-handle">
        <div className='notepad-outer'>
            <div className="drag-handle"></div>
          <ResizableBox width={notepadPresets.dimensions ? notepadPresets.dimensions.x : 300} height={notepadPresets.dimensions ? notepadPresets.dimensions.y : 400} minConstraints={[200, 260]} onResize={(event, { size }) => handleResize(size) } onResizeStop={(event, { size }) => handleStopResize(size)} >
             <React.Fragment>
            <div className='notepad-nav flex-row'>
                {invalidExport && (
                        <div className='invalid-export'>
                            <h4>Export Failed</h4>
                            <p>Notes with certain data such as svgs are not available for export.</p>
                        </div>
                    )
                }
                <div onClick={addNote} id="first-icon-np" className='icon-and-header'>
                    <img id="notepad-icon" src={addNoteIcon} alt={"close notepad"}></img>
                    <h5 className='notepad-icon-text'>Add Note</h5>
                </div>
                <div onClick={handleAllNotes} className='icon-and-header'>
                    <img id="notepad-icon" src={allNotesIcon} alt={"close notepad"}></img>
                    <h5 className='notepad-icon-text'>All Notes</h5>
                </div>
                <img id="notepad-icon-x" onClick={handleCloseNotepad} className='container-icon' src={closeIcon} alt={"close notepad"}></img>
            </div>
            {(view === "default" && quillActiveNote !== -1 ) ? (
                <div className="quill-editor">
                    {boxWidth > 300 ? 
                        <QuillEditor ref={childRef1} note={notes[quillActiveNote].text} setInvalidExport={setInvalidExport} setNote={setNote} activeIndex={quillActiveNote}/>
                        :
                        <QuillEditorMinimal ref={childRef2} note={notes[quillActiveNote].text} setInvalidExport={setInvalidExport} setNote={setNote} activeIndex={quillActiveNote}/>
                    }
                </div>
                )
                :
                tabs
            }
            </React.Fragment>
            </ResizableBox>
            {view === "default" && 
                <div className='notes-options flex-row'>
                    <img id="notepad-icon-y" onClick={handleExport}  src={exportIcon} alt={"export note"}></img>
                    <img id="notepad-icon-y" onClick={deleteNote}  src={trashIcon} alt={"delete note"}></img>
                </div>
            }
        </div>
      </Draggable>
    )
  });
export default Notepad