import { getDatePeriods } from "./dateHandling";

import { ORDER_OF_ENTRY_MARKET_SHARE } from "../../constants";

// Patient data [] generated from uptake formulas and is length of user selected periods

const reportSalesData = (type, forecastName, patientData, price, cogs, startDate, periodFormat, length, forecastExtras, marketShareData) => {


    function addValues(patients) {
        return patients.incident + patients.prevalent
    }

    let periodOfLOE = false;
    if (forecastExtras.dateOfLOE.length !== 0) {
        periodOfLOE = getDatePeriods(startDate, periodFormat, length, forecastExtras.dateOfLOE);
    }

    let workingPrice = price;
    if (periodFormat === "month") {
        workingPrice /= 12;
    } else if (periodFormat === "quarter") {
        workingPrice /= 4;
    }

    // turn cogs into working decimal
    cogs = cogs / 100;
    let salesDataArray = [];
    for (let i = 0; i < patientData.length; i++) {
        // change price if LOE data is inputted
        // false by default but in odd situations may be zero so checking if num
        if (typeof periodOfLOE === 'number') {
            if (i === periodOfLOE) {
                if (forecastExtras.atLOEPriceChange !== 0) {
                    workingPrice *= (1 + (forecastExtras.atLOEPriceChange / 100))
                }
            } else if (i > 0) {
                if ((periodFormat === "year") ||
                    (periodFormat === "month" && (i % 12) === 0) ||
                    (periodFormat === "quarter" && (i % 4) === 0)) {
                    if (i < periodOfLOE) {
                        if (forecastExtras.preLOEPriceChange !== 0) {
                            workingPrice *= (1 + (forecastExtras.preLOEPriceChange / 100))
                        }
                    } else if (i > periodOfLOE) {
                        if (forecastExtras.postLOEPriceChange !== 0) {
                            workingPrice *= (1 + (forecastExtras.postLOEPriceChange / 100))
                        }
                    }
                }
            }
        }
        
        let pod = type === "drug" ? patientData[i].patientsOnDrug : addValues(patientData[i].patientsOnDrug);

        let newSales = pod * workingPrice;

        // will require future work for competitor drug entering first
        if (marketShareData !== false) {
            newSales *= marketShareData[i][0].marketShare;
        }

        let newCogs = newSales * cogs;
        let LOEEvent = false;
        if (periodOfLOE && periodOfLOE === i) LOEEvent = true;

        let salesObj = {
            cost: workingPrice,
            sales: newSales,
            cogs: newCogs,
            gross: (newSales - newCogs),
            LOEEvent: LOEEvent,
        }
        salesDataArray.push(salesObj);
    }
    return salesDataArray;
}

const toPercentage = (val) => {
    return new Intl.NumberFormat('default', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(val / 100);
}


export {reportSalesData, toPercentage};