import React, {useState, useRef, useEffect} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';


const LineChartForPng = ({forecastName, forecastType, chartData,period, graphWidth, type}) => {

    const colors = ["#3c8ab6", '#c3250b', "#c3890b", '#8e4db6', '#04A777', '#C36F09'];
    const geneHelperFn = (data, sample) => {
        // sample means we have competitors

        const determiner = sample ? sample : "Patients On Drug"
        if (data[determiner] === 0) return 0;
        if (!data[determiner]) return;
        if (sample) {
            return data[determiner];
        } else {
            return data[determiner].incident + data[determiner].prevalent;
        }
    }


    const chartCompetitors = [];
    if (type === "population" && !chartData[0]["Patients On Drug"]) {
        Object.keys(chartData[0]).forEach(key => {
            if (key !== "period" &&
                key !== "penetration" &&
                key !== "peakPen" &&
                key !== "Patients On Drug" &&
                key !== "tooltipLabel" &&
                key !== "newComp" &&
                key !== "genePatientsSplit" &&
                key !== "marketShare") {
                    chartCompetitors.push(key);
                }
        })
    }
const indices = []
chartData.forEach((item, index) => {
    if (type === "sales") {
        if (item.LOEEvent || (item.newComp && item.newComp.length > 0)) {
            indices.push(index);
        }
    } else {
        if (item.peakPen) {
            indices.push(index);
        }
    }
})


const formatter = (tick) => {
    let flag = tick < 0 ? -1 : 1;
    tick = Math.abs(tick);
    if (tick === 0) {
        return "0";
    } else if (tick < 10000) {
        return `${tick * flag}`;
    } else if (tick < 1000000) {
        return `${flag * (tick / 1000)} K`;
    } else {
        return `${flag * (tick / 1000000)} M`;
    }
};

    const CustomizedYAxisTick = (props) => {
        const { x, y, payload } = props;
        let val = formatter(payload.value);
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={-5} y={-10} dy={16} textAnchor="end" fill="#000" style={{ fontWeight: '500', fontSize: "12px", fontFamily: "Arial" }}>
              {val}
            </text>
          </g>
        );
      };

      const CustomizedXAxisTick = (props) => {
        const { x, y, payload, index } = props;
        let xmargin = 15;
        let length = chartData.length * 45;
        if (period === "quarter" || period === "month") {
            xmargin = 25;
            length /= 45;
            length *= 75;
        }
        let step = Math.ceil(length / graphWidth);
        if (index % step === 0) {
            return (
                <g transform={`translate(${x},${y})`}>
                  <text x={xmargin} y={0} dy={16} textAnchor="end" fill="#000" style={{ fontWeight: '500', fontSize: "12px", fontFamily: "arial" }}>
                    {payload.value}
                  </text>
                </g>
              );
        }
        return null;
      };

      const CustomLegend = (props) => {
        const { payload } = props;
        return (
          <div className='graph-legend gl-right'>
            {payload.map((entry, index) => (
              <div key={`item-${index}`} className="graph-legend-item">
                {/* Custom symbol */}
                <div className='graph-legend-circle-offscreen' style={{backgroundColor: entry.color}}></div>
                {/* Custom text */}
                <span style={{fontWeight: '200', fontSize: "10px", fontFamily: "arial"}}>{entry.value}</span>
              </div>
            ))}
          </div>
        );
      };

      const CustomizedDot = (props) => {
        const { cx, cy, index } = props;
        const numberInside = indices.indexOf(index) + 1;

        return (
            <svg x={cx - 10} y={cy - 8} width={20} height={20} viewBox="0 0 20 20">
            {/* Circle Border */}
            <circle cx="10" cy="10" r="7" stroke={"#000"} strokeWidth="2" fill="#FFF" />
            
            {/* Number inside the circle */}
            <text x="10" y="14" fontSize="10" fontFamily="Arial" textAnchor="middle" fill={"#000"}>
                {numberInside}
            </text>
            </svg>
        );
    };


      const RenderDot = (props) => {
        const { cx, cy, payload, index } = props;
        if (type === "sales") {
            if (payload.LOEEvent || (payload.newComp && payload.newComp.length > 0)) {
              return <CustomizedDot index={index} cx={cx} cy={cy} />;
            }
        } else {
            if (payload.peakPen) {
                return <CustomizedDot index={index} cx={cx} cy={cy} />;
            }
        }
        // Return null to use the default dot
        return null;
    };

    let lines = (
        <React.Fragment>
            <Line strokeWidth={1}  dot={false} type="monotone" dataKey="Sales" stroke="#3c8ab6" />
            <Line strokeWidth={1}  dot={false} type="monotone" dataKey="COGS" stroke="#c3250b" />
            <Line strokeWidth={1} dot={false} stroke="#8e4db6" type="monotone" dataKey="Expenses" />
            <Line strokeWidth={1}  dot={<RenderDot stroke={"#c3890b"} />} type="monotone" dataKey="Gross Sales" stroke="#c3890b" />
            
        </React.Fragment>
    )
    if (type === "population") {
        lines = (
        <React.Fragment>
                {chartCompetitors.length > 0 ? 
                    <React.Fragment>
                        {chartCompetitors.map((sample, index) => {
                            let thisStroke = colors[index];
                            if (index === 0) {
                                return (<Line strokeWidth={1} dot={<RenderDot stroke={thisStroke} />} type="monotone" dataKey={sample} stroke={thisStroke} />)
                            } else {
                                return (<Line strokeWidth={1} dot={false} type="monotone" dataKey={sample} stroke={thisStroke} />)
                            }
                        })}
                    </React.Fragment>
                :
                <Line strokeWidth={1} dot={<RenderDot stroke={"#3c8ab6"} />} type="monotone" dataKey="Patients On Drug" stroke="#3c8ab6" />
            }
            </React.Fragment>
        )
    } else if (type !== "sales") {
        console.warn("sus code")
    }


    return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis interval={0} tick={<CustomizedXAxisTick />} dataKey="period" />
            <YAxis tick={<CustomizedYAxisTick />} />
            {(type === "sales" || chartCompetitors.length > 0) && <Legend align="right" content={<CustomLegend />}/>}
            {lines}
          </LineChart>
        </ResponsiveContainer>
      );
}

export default LineChartForPng