import React,{ useEffect, useRef } from 'react';
import { roundToTwoDecimals } from '../../../../functions/helpers/generalHelpers'

const MarketShareData = ({data, colIndex, dimensions, setDimensions, isCollapsed, setIsCollapsed, evenBackground}) => {
    const ref = useRef(null);
  
    useEffect(() => {
        const observeSize = (entries) => {
          for (const entry of entries) {
            const { width, height } = entry.contentRect;
            setDimensions((prevDimensions) => ({
              ...prevDimensions,
              [colIndex]: { width, height },
            }));
          }
        };
      
        const resizeObserver = new ResizeObserver(observeSize);
      
        // Store the current ref in a variable
        const currentRef = ref.current;
      
        if (currentRef) {
          resizeObserver.observe(currentRef);
        }
      
        // Use the stored ref in the cleanup function
        return () => {
          if (currentRef) {
            resizeObserver.unobserve(currentRef);
          }
        };
    }, []);
  
      let classInfo = evenBackground ? 'even-bg border-bot dash-border' : 'dash-border border-bot';
  
      // let hStyle = dimensions[colIndex].height > 0 ? {height: dimensions[colIndex].height} : null;
      let hStyle = null;
      let wStyle = null;
      // let wStyle = dimensions && dimensions[colIndex] && dimensions[colIndex].width > 0 ? {width: dimensions[colIndex].width} : null;
      return (
          <React.Fragment>
              {isCollapsed ? 
                  <div style={wStyle} onClick={() => setIsCollapsed(!isCollapsed)} ref={ref} id="m-share-collapsed" className={"single-edit-field " + classInfo}>
                      <p className='market-share-data'>{`${data[0].name}: ${roundToTwoDecimals(data[0].marketShare * 100)}%`}</p>
                  </div>
                  :
                  <div className={'msbb ' + classInfo}>
                  {
                      data.map((drug, index) => {
                          if (index === data.length - 1) return null;
                          let mshare = roundToTwoDecimals(drug.marketShare * 100);
                          let lighter = drug.marketShare === 0 ? .4 : 1;
                          let wordLength = drug.name.length;
  
                          // UDJUST THESE IF NEEDED
                          let breakLength = 14;
                          let wrapLength = 18;
                          let individualData = (
                              <React.Fragment>
                                  <p style={{opacity: lighter}}  className='market-share-data-x'>{`${drug.name}: ${mshare}%`}</p>
                                  <p style={{opacity: lighter}}  className='market-share-data-x'>{`Patients: ${drug.patientsOnDrug}`}</p>
                              </React.Fragment>
                          )
                          if (wordLength > wrapLength) {
                              individualData = (
                                  <React.Fragment>
                                      <p style={{opacity: lighter}} id="m-share-wrap-text"  className='market-share-data-x'>{`${drug.name}: ${mshare}%`}</p>
                                      <p style={{opacity: lighter}} id="m-share-wrap-text"  className='market-share-data-x'>{`Patients: ${drug.patientsOnDrug}`}</p>
                                  </React.Fragment>
                              )
                          } else if (wordLength > breakLength) {
                              individualData = (
                                  <React.Fragment>
                                      <p style={{opacity: lighter}}  className='market-share-data-x'>{`${drug.name}:`}<br></br><span id="m-share-break">{mshare}%</span></p>
                                      <p style={{opacity: lighter}}  className='market-share-data-x'>{`Patients: ${drug.patientsOnDrug}`}</p>
                                  </React.Fragment>
                              )
                          }
                          return (
                              <div className='market-share-data-div' key={`drug-competitor-data${drug.name}${index}`}>
                                  {individualData}
                              </div>
                          )
                      })
                  }
                  </div>
              }
          </React.Fragment>
      )
  }

  export default MarketShareData