import { linearGrowth, exponentialGrowth, logisticGrowth, linearGrowthGene, exponentialGrowthGene, logisticGrowthGene } from './growthModels';
import { reportSalesData } from './salesCalculations';
import { toPercentage } from './salesCalculations';
import { getDatePeriods } from './dateHandling';
import { marketShareCalculator } from './marketShareCalculator';


// DATA RECIEVED FROM MAIN COMPONENTS
// RETURNS AN ARRAY AS TO PRESERVE ORDERING FOR TABLE

const generateData = (forecastData, forecastExtras, forecastCompetitors, patients) => {
    let patientData = []
    if (forecastData.slope === "logistic") {
        if (forecastData.type === "drug") {
            patientData = logisticGrowth(forecastData.length, patients, forecastData.peakPenetration, forecastData.timeToPeakPenetration, forecastData.periodFormat, forecastData.birthsPerYear, forecastExtras);
        } else {
            patientData = logisticGrowthGene(forecastData.length, patients.prevalent,patients.incident, forecastData.peakPenetration.prevalent,forecastData.peakPenetration.incident, forecastData.timeToPeakPenetration, forecastData.periodFormat, forecastData.birthsPerYear, forecastExtras);
        }
    } else if (forecastData.slope === "exponential") {
        if (forecastData.type === "drug") {
            patientData = exponentialGrowth(forecastData.length, patients, forecastData.peakPenetration, forecastData.timeToPeakPenetration, forecastData.periodFormat, forecastData.birthsPerYear, forecastExtras);
        } else {
            patientData = exponentialGrowthGene(forecastData.length, patients.prevalent,patients.incident, forecastData.peakPenetration.prevalent,forecastData.peakPenetration.incident, forecastData.timeToPeakPenetration, forecastData.periodFormat, forecastData.birthsPerYear, forecastExtras);
        }
    } else if (forecastData.slope === "linear") {
        if (forecastData.type === "drug") {
            patientData = linearGrowth(forecastData.length, patients, forecastData.peakPenetration, forecastData.timeToPeakPenetration, forecastData.periodFormat, forecastData.birthsPerYear, forecastExtras);
        } else {
            patientData = linearGrowthGene(forecastData.length, patients.prevalent,patients.incident, forecastData.peakPenetration.prevalent,forecastData.peakPenetration.incident, forecastData.timeToPeakPenetration, forecastData.periodFormat, forecastData.birthsPerYear, forecastExtras);
        }
    } else {
        // may add more
        console.warn("unsupported")
    }
    let marketShareData = marketShareCalculator(forecastData.type, forecastData.slope, forecastData.periodFormat, forecastData.length, forecastData.startDate, forecastCompetitors, forecastData.forecastName, [...patientData]);
    let salesData = reportSalesData(forecastData.type, forecastData.forecastName, patientData, forecastData.price, forecastData.cogs, forecastData.startDate, forecastData.periodFormat, forecastData.length, forecastExtras, marketShareData);
    let dateData = getDatePeriods(forecastData.startDate, forecastData.periodFormat, forecastData.length);
    let output = [];
    let forecastEvents = [];
    for (let i = 0; i < salesData.length; i++) {
        let lp; 
        let pp;
        if (forecastData.type === "drug") {
            lp = Math.ceil(patientData[i].livePopulation);
            pp = toPercentage(patientData[i].penetration);
        } else {
            pp = {prevalent: toPercentage(patientData[i].penetration.prevalent), incident: toPercentage(patientData[i].penetration.incident)}
            lp = {prevalent: Math.ceil(patientData[i].livePopulation.prevalent), incident: Math.ceil(patientData[i].livePopulation.incident)}
        }
        let newEvents = {peakPen: false, LOEEvent: false, newComp: false}
        let newOutput = [
            dateData[i],
            lp,
            pp,
            patientData[i].patientsOnDrug,
            salesData[i].cost,
            Math.ceil(salesData[i].sales),
            Math.ceil(salesData[i].cogs),
            Math.ceil(salesData[i].gross),
        ]
        if (marketShareData !== false) {
            newOutput.splice(4, 0, marketShareData[i])
            if (marketShareData[i][marketShareData[i].length - 1].length > 0) {
                newEvents.newComp = marketShareData[i][marketShareData[i].length - 1];
            }
        }
        if (patientData[i].peakAchieved) {
            newEvents.peakPen = true;
        }
        if (salesData[i].LOEEvent) {
            newEvents.LOEEvent = true;
        }
        forecastEvents.push(newEvents)
        output.push(newOutput)
    }

    return {main: output, events: forecastEvents};
  }

  export {generateData}