import React from 'react'
import CollapsingContainerQA from '../../../util/CollapsingContainerQA';
import { toCommaSeperatedString } from '../../../../functions/helpers/generalHelpers';

const InputsQuantitative = ({engagedSection,peakSales, setEngagedSection, peakPenetration, observedTreatmentEffect, setObservedTreatmentEffect, setPeakPenetration, drugPrice, setDrugPrice, prevalenceTam, setPrevalenceTam, approvalType, phase, setPhase, timeToLaunch, setTimeToLaunch, adcomm, setAdcomm, biologicalRationaleScore, setBiologicalRationaleScore }) => {
    const data = (
        <div className='input-section active-section-background flex-col pad-horz-20'>
            <div className='input-and-label-standard'>
                {approvalType === false &&
                <React.Fragment>
                    <div className="border-bottom">
                <h5 className='input-header'>Phase:</h5>
                <select id="explicit-width-input" className='text-input-select text-left' value={phase} onChange={e => setPhase(e.target.value)}>
                    {/* <option value="Preclinical">Preclinical</option> */}
                    <option value="phaseOne">Phase 1</option>
                    <option value="phaseTwo">Phase 2</option>
                    <option value="phaseThree">Phase 3</option>
                </select>
                <h5 className='input-header'>Time-to-Launch:</h5>
                <select id="explicit-width-input" className='text-input-select text-left' value={timeToLaunch} onChange={e => setTimeToLaunch(e.target.value)}>
                    <option value="<1 Year">&#60;1 Year</option>
                    <option value="1 Year">1 Year</option>
                    <option value="2 Years">2 Years</option>
                    <option value="3 Years">3 Years</option>
                    <option value="4+ Years">4+ Years</option>
                </select>
                <h5 className='input-header'>Biological Rationale:</h5>
                <input type="range" id="biological-rationale" min="-5" max="5" step="1" value={biologicalRationaleScore} onChange={e => setBiologicalRationaleScore(parseInt(e.target.value))}></input>
                <h5 className='input-header'>Observed Treatment Effect:</h5>
                <input type="range" id="biological-rationale" min="-5" max="5" step="1" value={observedTreatmentEffect} onChange={e => setObservedTreatmentEffect(parseInt(e.target.value))}></input>
                <br></br>
                </div>
                </React.Fragment>
                }
                
                </div>
                <h5 className='input-header'>Prevalence; Total Addressable Market (TAM):</h5>
                <input type="number" className='text-input-standard text-left' min="0" max="50000000000" step="1" value={prevalenceTam} onChange={e => setPrevalenceTam(e.target.value)}></input>
                <h5 className='input-header'>Drug Price; WAC:</h5>
                <input type="number" className='text-input-standard text-left' min="0" max="1000000000" step="1" value={drugPrice} onChange={e => setDrugPrice(e.target.value)}></input>
                <h5 className='input-header'>Peak Penetration:</h5>
                <input type="number" className='text-input-standard text-left' min="0" max="100" step="0.01" value={peakPenetration} onChange={e => setPeakPenetration(e.target.value)}></input>
                <h2 className='qa-sales-header'>Peak Sales:</h2>
                <h2 className='qa-sales-val'>$ {toCommaSeperatedString(Math.round(peakSales))}</h2>
       
        </div>
      )
    
      return data;
}

export default InputsQuantitative