import React, {useState} from 'react'
import "../../../css/navElements.css"
import closeIcon from "../../../icons/bancroft_X_icon.svg"
import axios from 'axios';
import ChartsForPng from '../graph/subsections/ChartsForPng';
import html2canvas from 'html2canvas'


const ShareComponent = ({workingProjects, setShowShare}) => {

    const [linkState, setLinkState] = useState("")

    const generateLink = async() => {
        const token = localStorage.getItem("jwtToken");

        const data = { forecast: workingProjects };

            axios.post("https://bancy-backend-01dc0125253f.herokuapp.com/forecast/share", data, { 

            // axios.post("http://localhost:8000/summary", data, { 
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                const parsedData = response.data.savedSharedModel._id;
                setLinkState(`https://bancroftpharma.com/share/${parsedData}`)
            })
            .catch(err => {
                console.log(err);
            });
    }

  return (
    <div className='nav-popup-basic'>
        <div className='flex-row p-c-t-d'>
            <h4 className='p-calc-title'>Share Model</h4>
            <img id="calc-icon-x" onClick={() => setShowShare(false)} className='container-icon' src={closeIcon} alt={"close share"}></img>
        </div>
        <div className='export-option'>
            <h3 className='export-report-head'>Share your model with others via link.</h3>
            <h5 >Model will be shared in its current state,<br></br> other users will not modify your model</h5>
        </div>
        <button id="fin-btn" className='input-action-btn general-btn' onClick={generateLink}>Generate Link</button>
        {linkState.length > 0 && <h5 className='share-link-txt'>{linkState}</h5>}
    </div>
  )
}

export default ShareComponent