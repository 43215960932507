
const logisticGrowth = (length, initialPatients, peakPenetration, timeToPeakPenetration, periodFormat, birthsPerYear) => {

    // convert to fraction
    const asFrac = peakPenetration / 100;

    // reflect birthrate
    let livePopulation = initialPatients;

    if (periodFormat === "month") {
        timeToPeakPenetration *= 12;
        birthsPerYear *= 12;
    } else if (periodFormat === "quarter") {
        timeToPeakPenetration *= 4;
        birthsPerYear *= 4;
    }

    const t_mid = timeToPeakPenetration / 2;
    const r = Math.log(9) / t_mid; // You can adjust the 999 to control the steepness of the curve
    const drugUsageData = [];
    let flag = false;
    for (let i = 1; i <= length; i++) {
        const penetration = asFrac / (1 + Math.exp(-r * (i - t_mid)));
        const patientsOnDrug = penetration * livePopulation;

        let penAchieved = false;
        if (penetration === asFrac && !flag) {
            penAchieved = true;
            flag = true;
        }
        drugUsageData.push({
            livePopulation: Math.ceil(livePopulation),
            penetration: penetration * 100,
            patientsOnDrug: Math.ceil(patientsOnDrug),
            peakAchieved: penAchieved,
        });

        // update total afflicted
        // disbaled for now and needs work
        // livePopulation *= (1 + birthsPerYear);
    }

    return drugUsageData;
}

const logisticGrowthGene = (length, initialPatientsPrevalent, initialPatientsIncident, peakPenetrationPrevalent, peakPenetrationIncident, timeToPeakPenetration, periodFormat, birthsPerYear) => {

    // convert to fraction
    const asFrac = peakPenetrationPrevalent / 100;

    const asFracI = peakPenetrationIncident / 100;


    // reflect birthrate
    let yearlyIncident = initialPatientsIncident;
    let livePrevalent = initialPatientsPrevalent;

    if (periodFormat === "month") {
        timeToPeakPenetration *= 12;
        birthsPerYear *= 12;
    } else if (periodFormat === "quarter") {
        timeToPeakPenetration *= 4;
        birthsPerYear *= 4;
    }

    const t_mid = timeToPeakPenetration / 2;
    const r = Math.log(9) / t_mid; // You can adjust the 999 to control the steepness of the curve
    const drugUsageData = [];
    let flag = false;
    for (let i = 1; i <= length; i++) {
        const penetration = asFrac / (1 + Math.exp(-r * (i - t_mid)));
        const penetrationI = asFracI / (1 + Math.exp(-r * (i - t_mid)));
        const patientsOnDrug = penetration * livePrevalent;
        const patientsOnDrugI = penetrationI * yearlyIncident;


        let penAchieved = false;
        if (penetration === asFrac && !flag) {
            penAchieved = true;
            flag = true;
        }
        drugUsageData.push({
            livePopulation: {prevalent: Math.ceil(livePrevalent), incident: Math.ceil(initialPatientsIncident)},
            penetration: {prevalent: penetration * 100, incident: penetrationI * 100},
            patientsOnDrug: {prevalent: Math.ceil(patientsOnDrug), incident: Math.ceil(patientsOnDrugI)},
            peakAchieved: penAchieved,
        });

        // livePrevalent += (yearlyIncident - patientsOnDrugI);
        livePrevalent -= patientsOnDrug;


        // update total afflicted
        // disbaled for now and needs work
        // livePopulation *= (1 + birthsPerYear);
    }

    return drugUsageData;
}


const linearGrowth = (length, initialPatients, peakPenetration, timeToPeakPenetration, periodFormat, birthsPerYear) => {

    // convert to fraction
    const asFrac = peakPenetration / 100;

    if (periodFormat === "month") {
        timeToPeakPenetration *= 12;
        birthsPerYear *= 12;
    } else if (periodFormat === "quarter") {
        timeToPeakPenetration *= 4;
        birthsPerYear *= 4;
    }

    // reflect births 
    let livePopulation = initialPatients;

        
    const drugUsageData = [];
    let flag = false;
    for (let i = 1; i <= length; i++) {
        let penetration;
        let patientsOnDrug;
        if (i <= timeToPeakPenetration) {
            patientsOnDrug = (i * asFrac / timeToPeakPenetration) * livePopulation;
            penetration = (i * asFrac / timeToPeakPenetration) * 100;
        } else {
            patientsOnDrug = asFrac * livePopulation;
            penetration = peakPenetration;
        }
        let penAchieved = false;
        if (penetration === peakPenetration && !flag) {
            penAchieved = true;
            flag = true;
        }
        drugUsageData.push({
            livePopulation: Math.ceil(livePopulation),
            penetration: penetration,
            patientsOnDrug: Math.ceil(patientsOnDrug),
            peakAchieved: penAchieved,
        });

        // update total afflicted
        // disbaled for now and needs work
        // livePopulation *= (1 + birthsPerYear);

    }
    
    return drugUsageData;
}

const linearGrowthGene = (length, initialPatientsPrevalent, initialPatientsIncident, peakPenetrationPrevalent, peakPenetrationIncident, timeToPeakPenetration, periodFormat, birthsPerYear) => {

    // convert to fraction
    const asFrac = peakPenetrationPrevalent / 100;
    const asFrac2 = peakPenetrationIncident / 100;



    if (periodFormat === "month") {
        timeToPeakPenetration *= 12;
        birthsPerYear *= 12;
    } else if (periodFormat === "quarter") {
        timeToPeakPenetration *= 4;
        birthsPerYear *= 4;
    }

    // reflect births 
    let livePopulation = initialPatientsPrevalent;
    let yearlyIncident = initialPatientsIncident;


        
    const drugUsageData = [];
    let flag = false;
    for (let i = 1; i <= length; i++) {
        let penetration;
        let patientsOnDrug;
        let penetrationI;
        let patientsOnDrugI;
        if (i <= timeToPeakPenetration) {
            patientsOnDrug = (i * asFrac / timeToPeakPenetration) * livePopulation;
            penetration = (i * asFrac / timeToPeakPenetration) * 100;
            patientsOnDrugI = (i * asFrac2 / timeToPeakPenetration) * yearlyIncident;
            penetrationI = (i * asFrac2 / timeToPeakPenetration) * 100;
        } else {
            patientsOnDrug = asFrac * livePopulation;
            penetration = peakPenetrationPrevalent;
            patientsOnDrugI = asFrac2 * yearlyIncident;
            penetrationI = peakPenetrationIncident
        }
        let penAchieved = false;
        if (penetration === peakPenetrationPrevalent && !flag) {
            penAchieved = true;
            flag = true;
        }
        drugUsageData.push({
            livePopulation: {prevalent: Math.ceil(livePopulation), incident: Math.ceil(yearlyIncident)},
            penetration: {prevalent: penetration, incident: penetrationI},
            patientsOnDrug: {prevalent: Math.ceil(patientsOnDrug), incident: Math.ceil(patientsOnDrugI)},
            peakAchieved: penAchieved,
        });

        // update total afflicted
        // disbaled for now and needs work
        // livePopulation *= (1 + birthsPerYear);
        // livePopulation += (yearlyIncident - patientsOnDrugI);
        livePopulation -= patientsOnDrug;


    }
    
    return drugUsageData;
}

const exponentialGrowth = (length, initialPatients, peakPenetration, timeToPeakPenetration, periodFormat, birthsPerYear) => {

    // reflect births 
    let livePopulation = initialPatients;

    if (periodFormat === "month") {
        timeToPeakPenetration *= 12;
        birthsPerYear *= 12;
    } else if (periodFormat === "quarter") {
        timeToPeakPenetration *= 4;
        birthsPerYear *= 4;
    }

    // Convert to fraction
    const peakFrac = peakPenetration / 100;
    const initialFrac = peakFrac * 0.1; // Starting at 10% of the peak

    const r = Math.log(peakFrac / initialFrac) / timeToPeakPenetration;
    const drugUsageData = [];
    let flag = false;
    for (let i = 1; i <= length; i++) {
        const penetration = i <= timeToPeakPenetration ? initialFrac * Math.exp(r * i) : peakFrac;
        const patientsOnDrug = penetration * livePopulation;


        let penAchieved = false;
        if (penetration === peakFrac && !flag) {
            penAchieved = true;
            flag = true;
        }
        drugUsageData.push({
            livePopulation: Math.ceil(livePopulation),
            penetration: penetration * 100,
            patientsOnDrug: Math.ceil(patientsOnDrug),
            peakAchieved: penAchieved,
        });

        // update total afflicted
        // disbaled for now and needs work
        // livePopulation *= (1 + birthsPerYear);
    }

    return drugUsageData;
}


const exponentialGrowthGene = (length, initialPatientsPrevalent, initialPatientsIncident, peakPenetrationPrevalent, peakPenetrationIncident, timeToPeakPenetration, periodFormat, birthsPerYear) => {

    // reflect births 
    let livePopulation = initialPatientsPrevalent;

    if (periodFormat === "month") {
        timeToPeakPenetration *= 12;
        birthsPerYear *= 12;
    } else if (periodFormat === "quarter") {
        timeToPeakPenetration *= 4;
        birthsPerYear *= 4;
    }

    // Convert to fraction
    const peakFrac = peakPenetrationPrevalent / 100;
    const initialFrac = peakFrac * 0.1; // Starting at 10% of the peak
    const peakFracI = peakPenetrationIncident / 100;
    const initialFracI = peakFracI * 0.1; // Starting at 10% of the peak

    const r = Math.log(peakFrac / initialFrac) / timeToPeakPenetration;
    const rI = Math.log(peakFracI / initialFracI) / timeToPeakPenetration;

    const drugUsageData = [];
    let flag = false;
    for (let i = 1; i <= length; i++) {
        const penetration = i <= timeToPeakPenetration ? initialFrac * Math.exp(r * i) : peakFrac;
        const patientsOnDrug = penetration * livePopulation;
        const penetrationI = i <= timeToPeakPenetration ? initialFracI * Math.exp(rI * i) : peakFracI;
        const patientsOnDrugI = penetrationI * initialPatientsIncident;


        let penAchieved = false;
        if (penetration === peakFrac && !flag) {
            penAchieved = true;
            flag = true;
        }
        drugUsageData.push({
            livePopulation: {prevalent: Math.ceil(livePopulation), incident: Math.ceil(initialPatientsIncident)},
            penetration: {prevalent: penetration * 100, incident: penetrationI * 100},
            patientsOnDrug: {prevalent: Math.ceil(patientsOnDrug), incident: Math.ceil(patientsOnDrugI)},
            peakAchieved: penAchieved,
        });

        // update total afflicted
        // disbaled for now and needs work
        // livePopulation *= (1 + birthsPerYear);
        // livePopulation += (initialPatientsIncident - patientsOnDrugI);
        livePopulation -= patientsOnDrug;

    }

    return drugUsageData;
}




export {logisticGrowth, linearGrowth, exponentialGrowth, logisticGrowthGene, exponentialGrowthGene, linearGrowthGene};